import React, { useState, useRef, useEffect, FC, RefObject } from "react"
import { Range } from "react-range"
import { useOrchestrator } from "../ReleaseRegistryContextProvider"
import { KSpan } from "kahuna-base-react-components"
import { PlayOutline, StopOutline } from "heroicons-react"

const AudioPlayerRange = ({
  src,
  updateTrackMultiple,
  previewStart,
  previewEnd,
  audioRef,
  interval
}: {
  src: string
  updateTrackMultiple: (attributeName: string, value: any, attributeName2: string, value2: any) => void
  previewStart: number
  previewEnd: number
  audioRef: RefObject<HTMLAudioElement>
  interval: number
}) => {
  // TODO: this might be not chosen (undefined). When it is undefined it causes component to not work correctly
  const INTERVAL = interval
  const [startTime, setStartTime] = useState(previewStart)
  const [endTime, setEndTime] = useState(previewEnd)
  const [duration, setDuration] = useState(previewStart + INTERVAL)

  useEffect(() => {
    if (audioRef && audioRef.current) {
      const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration)
      }
      audioRef.current.addEventListener("loadedmetadata", handleLoadedMetadata)

      return () => {
        if (audioRef.current) {
          audioRef.current.removeEventListener("loadedmetadata", handleLoadedMetadata)
        }
      }
    }
  }, [])

  useEffect(() => {
    setStartTime(previewStart)
    setEndTime(previewEnd)
  }, [previewStart, previewEnd])

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60

    const formattedMinutes = String(minutes).padStart(2, "0")
    const formattedSeconds = String(remainingSeconds).padStart(2, "0")

    return `${formattedMinutes}:${formattedSeconds}`
  }

  return (
    <div className="flex flex-col items-start justify-center">
      <div style={{ visibility: "hidden", height: 1 }}>
        <audio ref={audioRef} controls>
          <source src={src} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      </div>
      <div className="flex justify-between gap-6">
        <KSpan text={`Preview Start Time: ${startTime ? formatTime(startTime) : "00:00"}`} fontSize={10} />
        <KSpan text={`End Time: ${endTime ? formatTime(endTime) : "00:00"}`} fontSize={10} />
      </div>
      <div
        style={{
          width: "300px",
          background: "rgb(242, 242, 242)",
          padding: 20,
          boxShadow: "0px 40px 96px -8px rgba(88, 92, 95, 0.20)",
          borderRadius: 6,
          backgroundImage: "url('/catalog_icons/stroke.svg')"
        }}
        className="flex justify-center pl-16 pr-16"
      >
        <Range
          step={1}
          min={0}
          max={Number(duration.toFixed(2))}
          values={[startTime]}
          onChange={(values) => {
            const previewEnd = values[0] + INTERVAL
            setStartTime(values[0])

            const end = previewEnd < duration ? previewEnd : duration
            setEndTime(end)
            updateTrackMultiple("preview-start", values[0], "preview-end", duration)
            if (audioRef?.current) {
              audioRef.current.currentTime = startTime
            }
          }}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                height: "1px",
                width: "70%"
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                position: "relative",
                outline: "none",
                cursor: "pointer",
                width: 130
              }}
            >
              <div className="flex justify-center">
                <div className="absolute" style={{ background: "white", marginTop: 2, marginRight: 9 }}>
                  <KSpan text="60s" color="#111" />
                </div>
              </div>
              <img src="/catalog_icons/clip-drag.svg" alt="thumb" />
            </div>
          )}
        />
      </div>
    </div>
  )
}

export default AudioPlayerRange
