import { Dialog } from "@headlessui/react"
import { FC } from "react"
import * as React from "react"
import { KButton, KSpan, KTitleSpan } from "kahuna-base-react-components"

type ErrorModalProps = {
  text?: string
  setText: (text?: string) => void
}
const ErrorModal: FC<ErrorModalProps> = (props) => {
  const { text, setText } = props

  const renderOpen = () => {
    if (text) return true
    else return false
  }

  return (
    <Dialog open={renderOpen()} onClose={() => setText(undefined)} className="relative z-50">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="mt-10 bg-white elev-b-sm rounded-lg overflow-hidden">
          <div
            className={`bg-gray-50 text-xs text-gray-500 font-medium 
              uppercase border-b flex justify-center`}
          >
            <KTitleSpan text="Error" fontSize={24} />
          </div>
          <div className="w-full max-w-sm m-auto flex-column p-4 w-[650px] text-center">
            <KSpan text={text || ""} />
          </div>
          <div className="flex gap-4 p-4 px-8">
            <KButton text={"Close"} background="transparent" onClick={() => setText(undefined)} />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ErrorModal
