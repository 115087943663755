import { TableColumn, User } from "../../../../../types"
import { TABLE_COLUMN_TYPE } from "../../../../../constants"
import { CSSProperties } from "react"
import { Check, X } from "heroicons-react"

const headerStyle: CSSProperties = { textAlign: "left", width: "25%" }
const cellStyle: CSSProperties = { fontWeight: 400, lineHeight: "20px", color: "#111827" }
const cellStyleAlt: CSSProperties = { fontWeight: 400, lineHeight: "20px", color: "#6B7280" }

const adminUserColumns: TableColumn[] = [
  {
    header: "Username",
    attribute: "username",
    type: TABLE_COLUMN_TYPE.TEXT,
    headerStyle,
    cellStyle,
    sortable: true,
    sortFilterField: "username",
    filter: true
  },
  {
    header: "First Name",
    attribute: "firstName",
    type: TABLE_COLUMN_TYPE.TEXT,
    headerStyle: { ...headerStyle, width: "20%" },
    cellStyle,
    sortable: true,
    sortFilterField: "first_name",
    filter: true
  },
  {
    header: "Last Name",
    attribute: "lastName",
    type: TABLE_COLUMN_TYPE.TEXT,
    headerStyle: { ...headerStyle, width: "20%" },
    cellStyle,
    sortable: true,
    sortFilterField: "last_name",
    filter: true
  },
  {
    header: "User Type",
    attribute: "role.name",
    type: TABLE_COLUMN_TYPE.TEXT,
    headerStyle: { ...headerStyle, width: "15%" },
    cellStyle: cellStyleAlt
  },
  {
    header: "New Release",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    headerStyle: { ...headerStyle, width: "15%" },
    cellStyle,
    render: (row: User) => (
      <div>
        {row.permissionUser?.length > 0 ? (
          <Check className="text-green-400 h-5 w-5" />
        ) : (
          <X className="text-red-400 h-5 w-5" />
        )}
      </div>
    )
  }
]

export default adminUserColumns
