import { USERS_ACTION } from "../actions/usersActions"
import { Action, UsersState } from "../types"

export default (state = new UsersState(), action: Action) => {
  switch (action.type) {
    case USERS_ACTION.FETCH_SOCIAL_MEDIA_LINKS:
      return { ...state, socialMediaList: action.payload }
    case USERS_ACTION.DELETE_SOCIAL_MEDIA_LINK:
      return {
        ...state,
        socialMediaList: state.socialMediaList.filter((socialMedia) => socialMedia.id !== action.payload)
      }
    case USERS_ACTION.FETCH_EVENTS:
      return { ...state, eventList: action.payload }
    case USERS_ACTION.DELETE_EVENT:
      return {
        ...state,
        eventList: state.eventList.filter((event) => event.id !== action.payload)
      }
    default:
      return state
  }
}
