import "../styles/Login.css"
import React, { FC, useState } from "react"
import LoginForm from "./LoginForm"
import LoginLayout from "../LoginLayout"
import { connect } from "react-redux"
import { LoginFormValues, LoginPageProps } from "../../../../types"
import { useNavigate } from "react-router-dom"
import { DEFAULT_ROUTE } from "../../../../constants"
import { login } from "../../../../actions/loginActions"
import { KButton, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
import EmailIcon from "../../../ui/img/auth/e-mail.svg"
import PasswordIcon from "../../../ui/img/auth/password.svg"
import EyeIcon from "../../../ui/img/auth/eye.svg"
import EyeSlashIcon from "../../../ui/img/auth/eye-slash.svg"

const Login: FC<LoginPageProps> = (props) => {
  const [form, setForm] = useState<LoginFormValues>({ username: "", password: "" })
  const [showPassword, setShowPassword] = useState(false)

  const { login } = props
  const history = useNavigate()

  const handleSubmit = () => {
    login(form, () => {
      history(DEFAULT_ROUTE)
    })
  }

  return (
    <LoginLayout>
      <div style={{ height: "100%" }}>
        <div className={"grid align-middle justify-center items-center"} style={{ width: "100%", height: "90%" }}>
          <div className={"grid"}>
            <div className={"flex"}>
              <KTitleSpan text={"Welcome back"} />
            </div>
            <div className={"flex mt-4"} style={{width: 500}}>
              <KSpan text={"Please enter you email address and password"} />
            </div>
            <div className={"mt-8"}>
              <KInput
                value={form.username || ""}
                onChange={(text) => setForm({ ...form, username: text })}
                placeholder={"E-mail address"}
                leftIcon={EmailIcon}
              />
            </div>
            <div className={"mt-4"}>
              <KInput
                value={form.password || ""}
                onChange={(text) => setForm({ ...form, password: text })}
                type={showPassword ? "text" : "password"}
                placeholder={"Password"}
                leftIcon={PasswordIcon}
                rightIconClick={() => setShowPassword(!showPassword)}
                rightIcon={showPassword ? EyeIcon : EyeSlashIcon}
              />
            </div>
            <div className={"mt-4"}>
              <KButton onClick={() => handleSubmit()} text={"Sign In"} background="black" textColor="white"/>
              <div className="mt-2">
                <KButton
                  shadowDisabled={true}
                  background="transparent"
                  onClick={() => history(`/forgot-password?email=${form.username}`)}
                  text={"Forgot Password"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginLayout>
  )
}

export default connect(null, { login })(Login)
