import "./styles/Affiliate.css"
import React, { FC, useEffect, useState } from "react"
import AffiliateChart from "./AffiliateChart"
import AffiliateTable from "./AffiliateTable"
import { Affiliate } from "../../../types"
import { affiliate } from "../../../apis"
import { mapListToCamelCase } from "../../../utility"

const AffiliatePage: FC = () => {
  const [affiliateList, setAffiliateList] = useState<Affiliate[]>([])

  useEffect(() => {
    affiliate(`/`, "get").then((resp) => {
      setAffiliateList(mapListToCamelCase(resp.data.results))
    })
  }, [])

  return (
    <div>
      <div className="flex flex-col justify-start">
        <div className="mt-6 grid grid-cols-3 gap-4 bg-[#f5f5f5] rounded-[10px] pb-6">
          <AffiliateChart />
        </div>
      </div>
      <AffiliateTable affiliateList={affiliateList} />
    </div>
  )
}

export default AffiliatePage
