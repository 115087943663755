import { TableColumn } from "../../../../types"
import { TABLE_COLUMN_TYPE } from "../../../../constants"
import React, { CSSProperties } from "react"
import DownloadReportButton from "../DownloadReportButton"

const cellStyle = { fontWeight: 400, lineHeight: "20px", color: "white", padding:"20px" }
const headerStyle: CSSProperties = { textAlign: "left", width: "25%" }

const reportsColumns: TableColumn[] = [
  //Sıra index'ine gerek kalmadigi icin yorum satiri yaptim.
  /*{
    header: "#",
    headerStyle: { ...headerStyle, width: "10%" },
    cellStyle: { ...cellStyle, color: "#6B7280" },
    type: TABLE_COLUMN_TYPE.TEXT,
    attribute: "index"
  },*/
  {
    header: "Period",
    headerStyle: { ...headerStyle, width: "15%", justifyContent: "justify-start" },
    cellStyle,
    type: TABLE_COLUMN_TYPE.TEXT,
    attribute: "quarter"
  },
  {
    header: "Revenue",
    headerStyle: { ...headerStyle, width: "25%", justifyContent: "justify-center" },
    cellStyle,
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row) => `${row.revenue.toFixed(2)} ${row.currency}`,
    attribute: "revenue"
  },
  {
    header: "Total Payment",
    headerStyle: { ...headerStyle, width: "25%", justifyContent: "justify-center" },
    cellStyle,
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row) => `${row.total_payment.toFixed(2)} ${row.currency}`,
    attribute: "total_payment"
  },
  {
    header: "Payment Date",
    headerStyle: { ...headerStyle, width: "25%", justifyContent: "justify-center" },
    cellStyle,
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row) => {
      const toFindDuplicates = (array: number[]) => array.filter((item, index) => array.indexOf(item) !== index)
      const duplicateElements = toFindDuplicates(row.payment_day || [])
      if (duplicateElements.length === 2) {
        return `${duplicateElements[0]}`
      }
      return row.payment_day?.join(", ")
    },
    attribute: "payment_day"
  },
  {
    header: "",
    headerStyle: { ...headerStyle, width: "10%", justifyContent: "justify-end" },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row) => {
      return <DownloadReportButton reportId={`${parseInt(row.quarter)}${row.quarter.split(" ")[1].toLowerCase()}`} />
    }
  }
]

export default reportsColumns
