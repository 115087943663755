import "./styles/Tooltip.css"
import { CSSProperties, FC, useRef, useState } from "react"
import * as React from "react"
import { TooltipProps } from "../../types"

const Tooltip: FC<TooltipProps> = (props) => {
  const [shown, setShown] = useState(false)
  const childrenRef = useRef(null)

  const renderTooltip = () => {
    const childrenPosition = childrenRef.current?.getBoundingClientRect()
    const tooltipPosition: CSSProperties = {}

    switch (
      props.place // TODO implement cases
    ) {
      case "top":
      case "bottom":
        tooltipPosition.top = childrenPosition?.top
        tooltipPosition.left = childrenPosition?.left
        break
      case "left":
      case "right":
      default:
        tooltipPosition.top = childrenPosition?.top
        tooltipPosition.left = childrenPosition?.left
    }

    tooltipPosition.width = childrenPosition?.width

    return (
      shown &&
      props.content && (
        <div
          className={`border-${props.type === "error" ? "red-600" : props.type === "warn" ? "amber-400" : "black"}
           absolute top-[${tooltipPosition.top}px] left-[${tooltipPosition.left}px]
           ${!props.disableBackground && "border border-2 bg-[rgba(0,0,0,0.8)] rounded-lg z-10"} 
           p-2 text-sm z-10 text-white delay-100 duration-150 fade-in-image`}
          style={{
            /*top: tooltipPosition.top, left: tooltipPosition.left, */ width: props.width || tooltipPosition.width
          }}
        >
          {props.content}
        </div>
      )
    )
  }

  return (
    <div>
      <span ref={childrenRef} onMouseEnter={() => setShown(true)} onMouseLeave={() => setShown(false)}>
        {props.children}
      </span>
      {renderTooltip()}
    </div>
  )
}

export default Tooltip
