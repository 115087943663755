import Button from "../../ui/form/Button"
import { DownloadReportButtonProps } from "../../../types"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { downloadReport } from "../../../actions/reportsActions"
import { successToastOptions } from "../../../constants"
import { toast } from "react-toastify"
import React, { useState } from "react"
import Tooltip from "../../ui/Tooltip"
import { KButton } from "kahuna-base-react-components"

const DownloadReportButton = (props: DownloadReportButtonProps) => {
  const [disabled, setDisabled] = useState(false)
  const [tooltipContent, setTooltipContent] = useState(
    "The report for the selected quarter will be sent to your email."
  )
  const onClick = async () => {

    toast.success("Your report is being prepared.", successToastOptions)
    setDisabled(true)
    setTooltipContent("Your report is being prepared. Check your email.")

    if (!props.isAdmin) {
      await props.downloadReport(props.reportId)
    } else if (props.isAdmin && props.selectedUserId) {
      await props.downloadReport(props.reportId, props.selectedUserId, props.approveDisabled)
    }

    setDisabled(false)
    setTooltipContent("The report for the selected quarter will be sent to your email.")
  }

  return (
    <Tooltip place="bottom" content={tooltipContent}>
      <div className="w-[100px] flex flex-row justify-end">
        <KButton onClick={onClick} disabled={disabled} width="36px" height="36px" padding="1px 1px" icon='/mail-unread-line.svg' background="white"/>
      </div>
    </Tooltip>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user
  }
}

export default connect(mapStateToProps, { downloadReport })(DownloadReportButton)
