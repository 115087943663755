import { UserForm } from "../../../../types"
import { USER_PERMISSION, emailPattern } from "../../../../constants"

const adminUserValidator = (formValues: UserForm) => {
  const errors: any = {}

  if (!formValues.firstName) {
    errors.firstName = "You must enter a first name"
  }
  if (!formValues.lastName) {
    errors.lastName = "You must enter a last name"
  }
  if (!formValues.username) {
    errors.username = "You must enter an email"
  } else if (!formValues.username.match(emailPattern)) {
    errors.username = "You must enter a valid email"
  }

  if (formValues.permissionIds?.includes(USER_PERMISSION.UPLOAD_BMV)) {
    const bmwCustomId = formValues.bmwCustomId?.length || 0
    if (bmwCustomId < 2 || bmwCustomId > 3) {
      errors.bmwCustomId = "Must be 2 - 3 characters"
    }
  }
  
  return errors
}

export default adminUserValidator
