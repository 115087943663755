import React, { FC, useEffect, useState } from "react"
import { Dialog } from "@headlessui/react"
import { RotorStyle, RotorVideo, RotorVideoOutput } from "../../../types"
import { releases } from "../../../apis"
import { X } from "heroicons-react"
import { KButton, KSpan } from "kahuna-base-react-components"
import Loading from "../Loading"

import "../styles/Rotor.css"

interface RotorVideoComponentProps {
  accessToken?: string
  setAccessToken: (accessToken?: string) => void
  artistName: string
  artworkUrl: string
  audioUrl: string
  trackName: string
  trackId: string
}

enum SelectedTabs {
  STYLE = 1,
  PREVIEWS = 2,
  VIDEOS = 3
}

const RotorVideoComponent: FC<RotorVideoComponentProps> = (props) => {
  const { accessToken, setAccessToken, trackId, trackName, audioUrl, artworkUrl, artistName } = props

  const [selectedTab, setSelectedTab] = useState(SelectedTabs.STYLE)
  const [rotorStyles, setRotorStyles] = useState<RotorStyle[]>([])
  const [selectedStyle, setSelectedStyle] = useState<number>()
  const [rotorPreview, setRotorPreview] = useState<RotorVideo>()
  const [selectedVariant, setSelectedVariant] = useState<number>()
  const [rotorVideos, setRotorVideos] = useState<RotorVideo[]>([])
  const [totalVideoCount, setTotalVideoCount] = useState(0)
  const [processingVideoCount, setProcessingVideoCount] = useState(0)
  const [videoIntervalId, setVideoIntervalId] = useState<NodeJS.Timer>()
  const [totalVariantCount, setTotalVariantCount] = useState(0)
  const [processingVariantCount, setProcessingVariantCount] = useState(0)
  const [variantIntervalId, setVariantIntervalId] = useState<NodeJS.Timer>()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (accessToken) {
      setLoading(true)
      releases(`/release/rotor_video_initialize/`, "post", {
        track_id: trackId,
        track_name: trackName,
        audio_url: audioUrl,
        artwork_url: artworkUrl
      }).then((resp) => {
        if (resp?.data?.data) {
          setRotorStyles(resp?.data?.data)
        }
        setLoading(false)
      })
    } else {
      setProcessingVariantCount(0)
      setTotalVariantCount(0)
      setProcessingVideoCount(0)
      setTotalVideoCount(0)
      setRotorVideos([])
      setSelectedStyle(undefined)
      setSelectedVariant(undefined)
      setSelectedTab(SelectedTabs.STYLE)
    }
  }, [accessToken])

  const fetch_videos = () => {
    releases(`/release/rotor_list_videos/?track_id=${trackId}`, "get").then((resp) => {
      if (resp?.data?.data) {
        const rotorVideos = resp?.data?.data

        setProcessingVideoCount(
          rotorVideos.filter(
            (rotorVideo: RotorVideo) =>
              rotorVideo.attributes.outputs.filter((output: RotorVideoOutput) => output.progress === 100).length
          ).length
        )

        setTotalVideoCount(rotorVideos.length)
        setRotorVideos(rotorVideos)
      }
    })
  }

  useEffect(() => {
    clearInterval(videoIntervalId)
    clearInterval(variantIntervalId)
    if (selectedTab === SelectedTabs.VIDEOS) {
      fetch_videos()
    }
  }, [selectedTab])

  const importVideoInterval = async () => {
    const newIntervalId = setInterval(async () => {
      fetch_videos()
    }, 5000)
    setVideoIntervalId(newIntervalId)
  }

  useEffect(() => {
    clearInterval(videoIntervalId)

    if (!accessToken) {
      return
    }

    if (processingVideoCount === totalVideoCount) {
      return
    }
    importVideoInterval()
  }, [accessToken, rotorPreview, processingVideoCount, totalVideoCount])

  const importVariantInterval = async () => {
    const newIntervalId = setInterval(async () => {
      releases(`/release/rotor_list_previews/?track_id=${trackId}&style_id=${selectedStyle}`, "get")
        .then((resp) => {
          if (resp?.data?.data) {
            const rotorPreview = resp?.data?.data
            const processingVariantCount = rotorPreview.attributes.outputs.filter(
              (output: RotorVideoOutput) => output.progress === 100
            ).length

            setProcessingVariantCount(processingVariantCount)
            setTotalVariantCount(rotorPreview.attributes.outputs.length)
            setRotorPreview(rotorPreview)
          }
        })
        .catch(() => {
          setTotalVariantCount(0)
          setProcessingVariantCount(0)
        })
    }, 5000)
    setVariantIntervalId(newIntervalId)
  }

  useEffect(() => {
    clearInterval(variantIntervalId)

    if (!accessToken) {
      return
    }

    if (processingVariantCount === totalVariantCount) {
      return
    }
    importVariantInterval()
  }, [accessToken, rotorPreview, processingVariantCount, totalVariantCount])

  const onSelectStyle = (selectedStyle: number) => {
    setSelectedTab(SelectedTabs.PREVIEWS)

    setLoading(true)
    releases(`/release/rotor_create_preview/`, "post", {
      track_id: trackId,
      style_id: selectedStyle
    })
      .then((resp) => {
        if (resp?.data?.data) {
          const rotorPreview = resp?.data?.data
          setProcessingVariantCount(
            rotorPreview.attributes.outputs.filter((output: RotorVideoOutput) => output.progress === 100).length
          )

          setTotalVariantCount(rotorPreview.attributes.outputs.length)
          setRotorPreview(rotorPreview)
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setTotalVariantCount(0)
        setProcessingVariantCount(0)
        setLoading(false)
      })
  }

  const onSelectVariant = (selectedVariant: number) => {
    setLoading(true)
    releases(`/release/rotor_create_video/`, "post", {
      track_id: trackId,
      style_id: selectedStyle,
      variant_id: selectedVariant
    })
      .then((resp) => {
        if (resp?.data?.data) {
          setSelectedTab(SelectedTabs.VIDEOS)
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setTotalVideoCount(0)
        setProcessingVideoCount(0)
        setLoading(false)
      })
  }

  const renderRotorStyles = () => {
    return (
      <div className="flex" style={{ height: "85%" }}>
        <div className="flex flex-wrap" style={{ height: "100%", overflowY: "scroll" }}>
          {rotorStyles.map((rotorStyle: RotorStyle) => {
            return (
              <div
                style={{ background: selectedStyle === rotorStyle.attributes.id ? "lightgrey" : "", borderRadius: 5 }}
                key={rotorStyle.attributes.id}
                className="cursor-pointer p-6"
                onClick={() => setSelectedStyle(rotorStyle.attributes.id)}
              >
                <div className="grid items-center">
                  <video controls width="100%" style={{ width: 200, borderRadius: 5 }}>
                    <source src={rotorStyle.attributes.preview} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <div className="mt-4 flex justify-center">
                    <KSpan text={rotorStyle.attributes.title} fontSize={18} />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className="mt-6" style={{ width: 150 }}>
          {selectedStyle && (
            <KButton
              text="Select Style"
              width="150px"
              onClick={() => onSelectStyle(selectedStyle)}
              background="black"
              textColor="white"
            />
          )}
        </div>
      </div>
    )
  }

  const renderRotorPreviews = () => {
    return (
      <div className="flex justify-between" style={{ height: "85%" }}>
        <div className="flex flex-wrap" style={{ height: "100%", overflowY: "scroll" }}>
          {rotorPreview?.attributes?.outputs.map((videoOutput: RotorVideoOutput) => {
            return (
              <div
                style={{
                  background: selectedVariant === videoOutput.variant_id ? "lightgrey" : "",
                  borderRadius: 5,
                  height: 280
                }}
                key={videoOutput.id}
                className="cursor-pointer p-6"
                onClick={() => setSelectedVariant(videoOutput.variant_id)}
              >
                <div className="grid items-center">
                  {videoOutput.video_url ? (
                    <video controls width="100%" style={{ width: "fit-content", height: 200, borderRadius: 5 }}>
                      <source src={videoOutput.video_url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <div className="flex justify-center items-center" style={{ height: 200, width: 350 }}>
                      <div className="loader-circle" />
                    </div>
                  )}

                  <div className="mt-2 grid justify-center" style={{ justifyItems: "center" }}>
                    <KSpan text={videoOutput.aspect_ratio} fontSize={15} />
                    {videoOutput.progress !== 100 && (
                      <KSpan text={`Progress: ${videoOutput.progress?.toString() || "0"} %`} fontSize={15} />
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className="mt-6" style={{ width: 150 }}>
          {selectedVariant && (
            <KButton
              text="Render Video"
              width="150px"
              onClick={() => onSelectVariant(selectedVariant)}
              background="black"
              textColor="white"
            />
          )}
        </div>
      </div>
    )
  }

  const renderRotorVideos = () => {
    const videoOutputs = rotorVideos.flatMap((rotorVideo: RotorVideo) => {
      return rotorVideo.attributes.outputs.flatMap((output: RotorVideoOutput) => {
        return output
      })
    })

    return (
      <div className="flex" style={{ height: "85%" }}>
        <div className="flex flex-wrap gap-4" style={{ height: "100%", overflowY: "scroll" }}>
          <div
            style={{ borderRadius: 5, height: 300, width: 180, border: "1px solid lightgrey", cursor: "pointer" }}
            onClick={() => setSelectedTab(SelectedTabs.STYLE)}
          >
            <div className="grid justify-center items-center" style={{ width: 180, height: 300, borderRadius: 5 }}>
              <div className="mt-4">
                <div className="flex justify-center items-center mb-4">
                  <img
                    src="/navigation_icons/plus.svg"
                    width={40}
                    height={40}
                    style={{ border: "1px solid lightgrey", borderRadius: 999, height: 40, width: 40 }}
                  />
                </div>
                <KSpan text={"New Video"} fontSize={18} />
              </div>
            </div>
          </div>
          {videoOutputs.map((videoOutput: RotorVideoOutput) => {
            return (
              <div
                style={{ borderRadius: 5, height: 300, border: "1px solid lightgrey" }}
                key={videoOutput.id}
                onClick={() => setSelectedVariant(videoOutput.variant_id)}
              >
                <div className="grid items-center">
                  {videoOutput.video_url ? (
                    <video controls width="100%" style={{ width: "fit-content", height: "220px", borderRadius: 5 }}>
                      <source src={videoOutput.video_url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <div className="flex justify-center items-center" style={{ height: 220, width: 300 }}>
                      <div className="loader-circle" />
                    </div>
                  )}

                  <div className="mt-2 grid justify-center" style={{ justifyItems: "center" }}>
                    <KSpan text={videoOutput.aspect_ratio} fontSize={15} />
                    {videoOutput.progress !== 100 && (
                      <KSpan text={`Progress: ${videoOutput.progress?.toString() || "0"} %`} fontSize={15} />
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <Dialog open={accessToken !== undefined} onClose={() => setAccessToken(undefined)}>
      <div className="fixed w-screen h-screen top-0 left-0 z-250 flex items-center justify-center bg-[#0000004d]">
        <div className="p-6 shadow-md w-[98%] h-[97%] rounded-[10px] bg-[#fff]">
          <div className="flex justify-between">
            <div className={"bg-white flex hadow-amber-600"}>
              <div>
                <img style={{ borderRadius: "5px" }} width={50} src={artworkUrl} alt={"table_image"} />
              </div>
              <div className={"ml-5"}>
                <p>{trackName}</p>
                <p className={"text-sm text-gray-500"}>{artistName}</p>
              </div>
            </div>
            <X className="h-4 mr-[-6px] cursor-pointer" onClick={() => setAccessToken(undefined)} />
          </div>
          <div className="flex mt-4">
            <div className="cursor-pointer" onClick={() => setSelectedTab(SelectedTabs.VIDEOS)}>
              <KSpan text="Videos" textDecoration={selectedTab === SelectedTabs.VIDEOS ? "underline" : ""} />
            </div>
            {(selectedTab === SelectedTabs.STYLE || selectedTab === SelectedTabs.PREVIEWS) && (
              <div className="ml-4 flex">
                <div className="mr-4">
                  <KSpan text=">" />
                </div>
                <div className="cursor-pointer" onClick={() => setSelectedTab(SelectedTabs.STYLE)}>
                  <KSpan text="Create new" textDecoration={selectedTab === SelectedTabs.STYLE ? "underline" : ""} />
                </div>
              </div>
            )}
            {selectedTab === SelectedTabs.PREVIEWS && (
              <div className="ml-4 flex">
                <div className="mr-4">
                  <KSpan text=">" />
                </div>
                <div className="cursor-pointer">
                  <KSpan text="Choose formats" textDecoration="underline" />
                </div>
              </div>
            )}
          </div>
          <div className="mt-4" style={{ height: "100%" }}>
            {loading && <Loading />}
            {selectedTab === SelectedTabs.STYLE && renderRotorStyles()}
            {selectedTab === SelectedTabs.PREVIEWS && renderRotorPreviews()}
            {selectedTab === SelectedTabs.VIDEOS && renderRotorVideos()}
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default RotorVideoComponent
