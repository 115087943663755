import { AnalysisTopSongs, TableColumn } from "../../../../types"
import { TABLE_COLUMN_TYPE } from "../../../../constants"
import React, { CSSProperties } from "react"
import { ArrowDownLeftIcon, ArrowUpRightIcon, Bars2Icon } from "@heroicons/react/20/solid"

const headerStyle: CSSProperties = { textAlign: "left", width: "25%" }
const cellStyle: CSSProperties = { fontWeight: 400, lineHeight: "20px", color: "#111827" }

const columns: TableColumn[] = [
  /*{
    header: "#",
    type: TABLE_COLUMN_TYPE.ROW_NUMBER,
    headerStyle: { ...headerStyle, width: "1%" },
    cellStyle
  },*/
  {
    header: "Song Name",
    type: TABLE_COLUMN_TYPE.TEXT,
    attribute: "track",
    headerStyle,
    cellStyle
  },
  {
    header: "Streams",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: AnalysisTopSongs) => {
      return <div>{row.quantity.toLocaleString("tr-TR")}</div>
    },
    headerStyle: { ...headerStyle, width: "5%" },
    cellStyle
  },
  {
    header: "Change",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: AnalysisTopSongs) => {
      return (
        <div className={"flex gap-2"}>
          {row.changeQuantity.toLocaleString("tr-TR")}
          {row.changeQuantity === 0 ? (
            <Bars2Icon className={"text-amber-400 h-5 w-5"} />
          ) : row.changeQuantity > 0 ? (
            <ArrowUpRightIcon className={"text-green-400 h-5 w-5"} />
          ) : (
            <ArrowDownLeftIcon className={"text-red-400 h-5 w-5"} />
          )}
        </div>
      )
    },
    headerStyle: { ...headerStyle, width: "5%" },
    cellStyle
  },
  {
    header: "Percentage",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: AnalysisTopSongs) => {
      return (
        <div className={"flex gap-2"}>
          <div className={"bg-primary-50 rounded-md"} style={{ width: `${row.percentage}%` }} />
          {row.percentage.toLocaleString("tr-TR", { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%
        </div>
      )
    },
    headerStyle,
    cellStyle
  }
]

export default columns
