import { Dialog } from "@headlessui/react"
import { KButton } from "kahuna-base-react-components"
import React, { useEffect, useRef, useState } from "react"

const SignatureCanvas = ({ onSave, signatureModalOpen, setSignatureModalOpen }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [isDrawing, setIsDrawing] = useState(false)

  const startDrawing = (e) => {
    setIsDrawing(true)
  }

  const endDrawing = () => {
    setIsDrawing(false)
    const canvas = canvasRef.current

    if (!canvas) return

    const dataUrl = canvas.toDataURL("image/png")
    onSave(dataUrl)
  }

  const clearCanvas = () => {
    const canvas = canvasRef.current

    if (!canvas) return

    const context = canvas.getContext("2d")

    if (!context) return

    context.clearRect(0, 0, canvas.width, canvas.height)
    context.beginPath() // Clear the path so it doesn't continue from the last point

    onSave(null)
  }

  const draw = (e) => {
    if (!isDrawing) return

    const canvas = canvasRef.current

    if (!canvas) return

    const context = canvas.getContext("2d")

    if (!context) return

    context.lineWidth = 2
    context.lineCap = "round"
    context.strokeStyle = "black"

    const rect = canvas.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top

    context.lineTo(x, y)
    context.stroke()
  }

  const saveSignature = () => {
    setSignatureModalOpen(false)
  }

  return (
    <Dialog open={signatureModalOpen} onClose={() => setSignatureModalOpen(false)}>
      <div
        className="fixed w-screen h-screen top-0 left-0 flex items-center justify-center bg-[#0000004d] "
        style={{ zIndex: 9999 }}
      >
        <div className="p-6 shadow-md rounded-[10px] bg-[#fff]">
          <canvas
            ref={canvasRef}
            width={500}
            height={200}
            style={{ border: "1px solid black" }}
            onMouseDown={startDrawing}
            onMouseUp={endDrawing}
            onMouseMove={draw}
          />
          <div className="mt-2 flex justify-between">
            <KButton
              width="auto"
              onClick={() => clearCanvas()}
              text={"Clear"}
              background="transparent"
              shadowDisabled={true}
            />
            <KButton width="100px" onClick={() => saveSignature()} text={"Save"} background="black" textColor="white" />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default SignatureCanvas
