import { KButton, KDropdown, KInput, KSpan, KTitleSpan, KTooltip } from "kahuna-base-react-components"
//@ts-ignore
import ArrowLeftIcon from "../../../../../assets/release-registry-icons/arrow-left.svg"
//@ts-ignore
import CompletedIcon from "../../../../../assets/release-registry-icons/completed.svg"
//@ts-ignore
import IncompleteIcon from "../../../../../assets/release-registry-icons/incomplete.svg"
//@ts-ignore
import SearchIcon from "../../../../../assets/release-registry-icons/search.svg"
//@ts-ignore
import ChevronDownIcon from "../../../../../assets/release-registry-icons/chevron-down.svg"
//@ts-ignore
import CheckIcon from "../../../../../assets/release-registry-icons/check-double.svg"
//@ts-ignore
import DeleteIcon from "../../../../../assets/release-registry-icons/delete.svg"
//@ts-ignore
import VectorIcon from "../../../../../assets/release-registry-icons/vector.svg"
import React, { useEffect, useRef, useState } from "react"
import { ReleaseRegistryUploadTracksTabs } from "../../../../../actions/types"
import { useOrchestrator } from "../ReleaseRegistryContextProvider"
import { LCReleaseFormData, SelectOption } from "../../../../../types"
import { MultiValue } from "react-select"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import ArtistInitials from "../../../../ui/ArtistInitials"
import { checkMissingTrackFields, setLabel } from "../../../../../utility"
import AudioPlayer from "../../track-detail/AudioPlayer"

const Artist = ({
  setSelectedTab,
  saveTrackDetail
}: {
  setSelectedTab: (tab: ReleaseRegistryUploadTracksTabs) => void
  saveTrackDetail: (selectedTab: boolean) => void
}) => {
  const {
    applyArtistToAll,
    selectedTrack,
    setSelectedTrack,
    updateTrack,
    options,
    saveTrackPrimitive,
    saveTrackAndOffers,
    artistOptions,
    onSearchArtist,
    setIncludedContributors
  } = useOrchestrator()
  const [artistName, setArtistName] = useState<string>("")
  const [roleOptions, setRoleOptions] = useState<KSelectOption[]>([{ value: -1, value2: "artist", label: "Primary" }])
  const [selectedRole, setSelectedRole] = useState<KSelectOption | MultiValue<KSelectOption>>(roleOptions[0])
  const [addedArtists, setAddedArtists] = useState<SelectOption[]>([])
  const [includedRoles, setIncludedRoles] = useState<string[]>([])
  const [missingTrackFields, setMissingTrackFields] = useState<string[]>([])
  const [playingAudioRefs, setPlayingAudioRefs] = useState<HTMLAudioElement[]>([])

  useEffect(() => {
    if (!selectedTrack) {
      return
    }
    const missingFields = checkMissingTrackFields(selectedTrack)
    if (missingFields) {
      setMissingTrackFields(missingFields)
    }
  }, [selectedTrack])

  const updateArtistAndContributors = (trackDataAttributes) => {
    const includedRoles: string[] = ["artist"]
    Object.entries(trackDataAttributes).forEach(([key, value]) => {
      if (options.roleOptions.includes(key) && value) {
        includedRoles.push(key)
      }
    })
    setIncludedRoles([...includedRoles])
    setIncludedContributors([...includedRoles]) //this is for track artist and contributors, not for release
  }
  useEffect(() => {
    if (options.roleOptions && selectedTrack) {
      updateArtistAndContributors(selectedTrack?.data?.attributes)
    }
  }, [options.roleOptions, selectedTrack])

  const onClickArtistDublicate = (key: string, selectedTrack?: LCReleaseFormData) => {
    if (!selectedTrack) {
      return
    }

    const values = selectedTrack.data.attributes[key]
    applyArtistToAll(key, values)
  }

  const renderArtist = (role: string, keyIndex: number) => {
    if (!selectedTrack || !selectedTrack.data) {
      return
    }
    const index = roleOptions.findIndex((roleOption) => roleOption.value2 === role)
    if (selectedTrack.data.attributes[role]) {
      return (
        selectedTrack.data.attributes[role].length > 0 && (
          <div key={`${role}-${keyIndex}`} className="flex flex-row justify-between items-center">
            <div className="flex flex-row gap-4 items-center">
              <div className="flex flex-row">
                {selectedTrack.data.attributes[role].map((value, i: number) => {
                  return (
                    <span
                      key={`${value}-${i}`}
                      className="w-8 h-8 flex justify-center items-center bg-[#F0F0F0] rounded-full border-2 border-[#FFF]"
                      style={{
                        position: "relative",
                        left: `calc(-${6 * i}px)`,
                        boxShadow: "0px -8px 16px 0px rgba(181, 181, 181, 0.48) inset"
                      }}
                    >
                      <KSpan text={value[0].toLocaleUpperCase()} color="#1F1F1F" fontWeight={500} />
                    </span>
                  )
                })}
              </div>

              <KSpan text={selectedTrack.data.attributes[role].join(", ")} color="#1F1F1F" fontWeight={400} />
            </div>
            <div className="flex flex-row gap-4 items-center" style={{width: 300}}>
              <KDropdown
                selected={roleOptions[index]}
                onSelect={(selected) => {
                  const value2 = (selected as KSelectOption).value2

                  if (value2 === role || !value2) {
                    return
                  }

                  if (includedRoles.includes(value2)) {
                    const updatedRoles = [...includedRoles.filter((availableRole) => availableRole !== role)]
                    setIncludedRoles(updatedRoles)
                    delete selectedTrack.data.attributes[role]
                    setSelectedTrack({ ...selectedTrack })
                  } else {
                    if (role === "artist") {
                      selectedTrack.data.attributes["artist"] = []
                    } else {
                      selectedTrack.data.attributes[value2] = [...selectedTrack.data.attributes[role]]
                      delete selectedTrack?.data?.attributes[role]
                      const updatedRoles = [...includedRoles.map((availableRole) => (availableRole === role ? value2 : availableRole))]
                      setIncludedRoles(updatedRoles)
                      setSelectedTrack({ ...selectedTrack })
                    }
                  }
                }}
                options={role === "artist" ? [{ label: "Primary", value: index, value2: "artist" }] : roleOptions}
                rightIcon={ChevronDownIcon}
                defaultValue={roleOptions[index]}
                shadowDisabled={true}
                width={250}
                padding="6px 6px 6px 12px"
                gap="4px"
              />
              <img src={VectorIcon} />
              <KTooltip
                padding="0px"
                backgroundColor="#FFF"
                content={
                  <div className="w-[140px] p-2.5">
                    <KSpan
                      text={`Apply "${roleOptions[index].label}" on all tracks`}
                      fontWeight={500}
                      color="#111"
                      fontSize={12}
                    />
                  </div>
                }
              >
                <KButton
                  padding="6px 6px"
                  background="#FFF"
                  width="32px"
                  height="32px"
                  icon={CheckIcon}
                  shadowDisabled
                  hoverBackground="#F0F0F0"
                  onClick={() => {
                    onClickArtistDublicate(role, selectedTrack)
                  }}
                />
              </KTooltip>

              <KButton
                padding="6px 6px"
                background="#FFF"
                width="32px"
                height="32px"
                icon={DeleteIcon}
                shadowDisabled
                hoverBackground="#F0F0F0"
                onClick={() => {
                  if (role === "artist") {
                    selectedTrack.data.attributes["artist"] = []
                  } else {
                    delete selectedTrack.data.attributes[role]
                    const updatedRoles = includedRoles.filter((availableRole) => availableRole !== role)
                    setIncludedRoles(updatedRoles)
                  }
                  setSelectedTrack({ ...selectedTrack })
                }}
              />
            </div>
          </div>
        )
      )
    }
  }

  const handleSelect = (artist: string) => {
    const alreadyAdded = addedArtists.some((artist) => artist.label === artistName && artist.value === artistName)

    if (artist === "+ Add New" && !alreadyAdded) {
      setAddedArtists([{ label: artistName, value: artistName }, ...addedArtists])
    }
    if (artist === "+ Add New") {
      artist = artistName
    }

    const value2 = (selectedRole as KSelectOption).value2 // key (twelve-string-guitar, artist, four-string-guitar )

    if (!value2) {
      return
    }

    const attributeValue = selectedTrack?.data?.attributes[value2]

    if (!includedRoles.includes(value2)) {
      if (value2 === "artist") {
        setIncludedRoles([value2, ...includedRoles])
      } else {
        setIncludedRoles([...includedRoles, value2])
      }
    }

    if (attributeValue && !attributeValue.includes(artist)) {
      updateTrack(value2, [...attributeValue, artist])
    } else if (attributeValue && attributeValue.includes(artist)) {
      updateTrack(value2, [...attributeValue])
    } else {
      updateTrack(value2, [artist])
    }
    setArtistName("")
  }

  const renderOptions = () => {
    return (
      <div className="w-full flex flex-col pt-5 bg-white max-h-[300px] overflow-auto">
        {[...artistOptions, ...addedArtists].map((artistOption: SelectOption, index: number) => {
          return (
            <div
              key={`artistOption-${index}`}
              className="flex flex-row justify-start items-center gap-4 cursor-pointer hover:bg-[#F0F0F0] py-1 pl-[14px] rounded-[10px]"
              onClick={() => {
                handleSelect(artistOption.label as string)
              }}
            >
              <span
                className="w-8 h-8 flex justify-center items-center bg-[#F0F0F0] rounded-full "
                style={{
                  boxShadow: "0px -8px 16px 0px rgba(181, 181, 181, 0.48) inset"
                }}
              >
                <KSpan text={(artistOption.label as string)[0].toLocaleUpperCase()} color="#1F1F1F" fontWeight={500} />
              </span>
              <KSpan text={artistOption.label as string} color="#0F0F0F" />
            </div>
          )
        })}
      </div>
    )
  }

  useEffect(() => {
    const typeChangeRoleOptions = options.roleOptions?.map((option: string, index: number) => {
      return { value: index, value2: option, label: setLabel(option) }
    })
    setRoleOptions([{ value: -1, value2: "artist", label: "Primary" }, ...typeChangeRoleOptions])
  }, [options])

  return (
    <div className="w-[616px] max-sm:w-auto flex flex-col gap-6">
      <div className="flex flex-col gap-3 justify-start">
        <div className="flex flex-row gap-4 items-center">
          <div>
            <KButton
              icon={ArrowLeftIcon}
              onClick={() => {
                setSelectedTab(ReleaseRegistryUploadTracksTabs.TRACK_DETAILS)
              }}
              width="36px"
              height="36px"
              padding="8px"
              background="#F0F0F0"
              shadowDisabled
              hoverBackground="#E7E7E7"
            />
          </div>
          <KTitleSpan text="Track Artist" fontSize={40} lineHeight="48px" letterSpacing="-0.4px" color="#1F1F1F" />
        </div>
        <KSpan text="Your Spotify artist name uniquely identifies you on the platform." />
      </div>
      <div className="flex grow flex-col gap-3">
        <div className="grow flex flex-row gap-1.5 border-[1px] border-[#E7E7E7] p-1.5 rounded-[6px] items-center justify-between">
          <div className="w-8 h-8 flex items-center justify-center cursor-pointer">
            <AudioPlayer
              src={selectedTrack?.data?.attributes?.["preview-url"]}
              playingAudioRefs={playingAudioRefs}
              setPlayingAudioRefs={setPlayingAudioRefs}
            />
          </div>
          <div className="flex grow">
            <KInput
              value={selectedTrack?.data?.attributes?.title || ""}
              background="#FFF"
              padding="8px 32px 8px 10px"
              placeholder="Add track name in the previous tab"
              disabled
              boxShadow="none"
              width={300}
              onChange={() => {
                //Do Nothing
              }}
            />
          </div>
          <div className="flex flex-row gap-8 items-center">
            {<ArtistInitials artists={selectedTrack?.data?.attributes["artist"] || []} />}
            <div className="flex flex-row gap-1 pr-2 items-center">
              {missingTrackFields.length > 0 ? (
                <KTooltip
                  position="left"
                  content={
                    <div className="flex items-center justify-center w-[200px] ">
                      <KSpan
                        text={missingTrackFields?.join(", ") || ""}
                        fontSize={11}
                        fontWeight={500}
                        color="#1F1F1F"
                      />
                    </div>
                  }
                >
                  <img src={IncompleteIcon} alt="incomplete-icon" />
                </KTooltip>
              ) : (
                <img src={CompletedIcon} alt="completed-icon" />
              )}
              <span className="flex flex-row items-center pt-0.5">
                <KSpan
                  text={missingTrackFields.length > 0 ? "Incomplete" : "Completed"}
                  color="#3D3D3D"
                  fontSize={12}
                  fontWeight={500}
                  lineHeight="12px"
                />
              </span>
            </div>
          </div>
        </div>
        <div
          className={"flex items-center relative pr-1.5"}
          style={{
            width: "100%",
            height: "44px",
            background: artistName ? "#FFF" : "#F5F5F5",
            borderRadius: 10,
            boxShadow: "0 0 0 1px rgba(17, 17, 17, 0.04), 0 1px 1px 0 rgba(17, 17, 17, 0.04)"
          }}
        >
          <div
            className={`grow`}
            style={{
              background: artistName ? "#FFF" : "#F5F5F5"
            }}
          >
            <KInput
              value={artistName}
              onChange={(text: string) => {
                setArtistName(text)
                onSearchArtist(text)
              }}
              placeholder={"Start typing"}
              leftIcon={SearchIcon}
              shadowDisabled={true}
              padding="8px 8px 8px 14px"
              gap="8px"
            />
          </div>
          <div className="w-[250px]">
            <KDropdown
              selected={roleOptions[0]}
              onSelect={(selected) => {
                if (selected) {
                  setSelectedRole(selected)
                }
              }}
              options={roleOptions}
              rightIcon={ChevronDownIcon}
              defaultValue={roleOptions[0]}
              shadowDisabled={true}
            />
          </div>
          {artistName && <div className="absolute top-[110%] z-50 w-full">{renderOptions()}</div>}
        </div>
        <div style={{ maxHeight: "28vh", overflowY: "scroll", overflowX: "hidden" }}>
          {includedRoles.map((availableRole, i) => {
            return renderArtist(availableRole, i)
          })}
        </div>
      </div>

      <div>
        <KButton
          text="Continue"
          onClick={() => {
            setSelectedTab(ReleaseRegistryUploadTracksTabs.TRACK_SHARE_HOLDERS)
          }}
          background="#000"
          textColor="#FFF"
          disabled={
            !selectedTrack?.data?.attributes["artist"] || !(selectedTrack?.data?.attributes["artist"]?.length > 0)
          }
        />
      </div>
      <div className="flex flex-row justify-center">
        <KButton
          text="Save and Return to Tracks"
          hoverBackground="#E7E7E7"
          onClick={() => {
            saveTrackDetail(false)
          }}
          background="#FFF"
          shadowDisabled
        />
      </div>
    </div>
  )
}

export default Artist
