import React, { FC } from "react"
import { PrivateRouteProps } from "../types"
import { Navigate } from "react-router-dom"
import MasterTemplate from "../components/layouts/master/MasterTemplate"
import { connect } from "react-redux"
import { RootState } from "../store"
import NotFound from "../components/NotFound"
import { DEFAULT_ROUTE, DEFAULT_ROUTES } from "../constants"
import NotAvailableForMobile from "../components/NotAvailableForMobile"

const PrivateRoute: FC<PrivateRouteProps> = (props) => {
  if (
    window.innerWidth < 1024 &&
    !window.location.host.includes("localhost") &&
    !window.location.pathname.includes("smart-link") &&
    !window.location.pathname.includes("artist")
  ) {
    return <NotAvailableForMobile />
  }

  if (props.redirect) return <Navigate to={props.redirect} />
  // Permission check for user roles
  if (props.loggedIn && props.permittedRoles?.length > 0 && !props.permittedRoles.includes(props.userRole)) {
    return (
      <NotFound
        title={"Sorry"}
        description={"This page you have been looking has not been found"}
        buttonText={"Go to Overview"}
        buttonLink={DEFAULT_ROUTE}
        imageWidth={350}
        titleSize={36}
        descriptionSize={20}
      />
    )
  }

  // Redirect after logging in.
  if (!props.private && props.loggedIn && !props.overrideDefaultRoute) {
    return <Navigate to={DEFAULT_ROUTES[props.userRole]} />
  }

  // Let user go if not private or already logged in.
  if (!props.private || props.loggedIn) {
    return <MasterTemplate>{props.children}</MasterTemplate>
  }

  // Otherwise redirect to log in.
  return <Navigate to="/login" />
}
const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
    userRole: state.auth.user.roleId
  }
}
export default connect(mapStateToProps, {})(PrivateRoute)
