import { Provider } from "react-redux"
import { RootProps } from "./types"
import { store } from "./store"
import * as React from "react"

const Root = (props: RootProps) => {
  // @ts-ignore
  return <Provider store={store}>{props.children}</Provider>
}

export default Root
