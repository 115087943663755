import { KButton, KSpan } from "kahuna-base-react-components"
import React, { useEffect, useRef, useState } from "react"
//@ts-ignore
import PlayIcon from "../../../../../assets/release-registry-icons/play.svg"
//@ts-ignore
import PauseIcon from "../../../../../assets/release-registry-icons/pause.svg"

const AudioPlayerProgress = ({
  src,
  trackName,
  trackArtist,
  trackDuration,
  trackDurationSecond,
  playingAudioRefs,
  setPlayingAudioRefs
}: {
  src: string
  trackName: string
  trackArtist: string
  trackDuration: string | number
  trackDurationSecond: number
  playingAudioRefs: HTMLAudioElement[]
  setPlayingAudioRefs: (playingAudioRefs: HTMLAudioElement[]) => void
}) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [currentTime, setCurrentTime] = useState<number>(0)

  const handleAudio = () => {
    if (!audioRef || !audioRef.current) {
      return
    }
    if (isPlaying) {
      audioRef.current.pause()
      setIsPlaying(false)
    } else {
      playingAudioRefs.forEach((ref: any) => ref?.current?.pause())
      audioRef.current.play()
      setIsPlaying(true)
      //@ts-ignore
      setPlayingAudioRefs([audioRef])
    }
  }

  useEffect(() => {
    const ref = playingAudioRefs.find((ref: any) => ref === audioRef)
    if (!ref && isPlaying) {
      setIsPlaying(false)
    }
  }, [playingAudioRefs])

  const updateTime = () => {
    if (!audioRef.current) {
      return
    }
    setCurrentTime(audioRef.current?.currentTime)
  }

  const handleProgressBarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!audioRef.current) return

    const progressBar = e.currentTarget
    const boundingRect = progressBar.getBoundingClientRect()
    const clickPositionX = e.clientX - boundingRect.left
    const progressBarWidth = boundingRect.width
    const percentageClicked = (clickPositionX / progressBarWidth) * 100

    const newTime = (percentageClicked / 100) * Number(trackDurationSecond)
    audioRef.current.currentTime = newTime
    setCurrentTime(newTime)
  }

  const renderProgressBar = () => {
    return (
      <div
        className="relative flex flex-row gap-0 h-3 w-full cursor-pointer items-center"
        onClick={handleProgressBarClick}
      >
        <div
          className="h-1.5"
          style={{
            width: `${(currentTime / Number(trackDurationSecond)) * 100}%`,
            backgroundColor: "#3D3D3D",
            borderTopLeftRadius: "1000px",
            borderBottomLeftRadius: "1000px"
          }}
        ></div>
        <div
          className="h-1.5"
          style={{
            width: `${100 - ((currentTime / Number(trackDurationSecond)) * 100)}%`,
            backgroundColor: "#D4D4D3",
            borderTopRightRadius: "1000px",
            borderBottomRightRadius: "1000px"
          }}
        ></div>
        <div
          className="h-2.5 w-2.5 rounded-[6px]"
          style={{
            position: "absolute",
            left: `calc(${(currentTime / Number(trackDurationSecond)) * 100}% - 2px)`,
            top: "1px",
            background: "#3D3D3D"
          }}
        ></div>
      </div>
    )
  }

  return (
    <div className={"flex flex-row gap-1.5 p-1.5 items-center h-11 justify-between"}>
      <KButton
        height="32px"
        width="32px"
        padding="8px"
        background="#FFF"
        icon={isPlaying ? PauseIcon : PlayIcon}
        onClick={() => {
          if (src) {
            handleAudio()
          }
        }}
        disabled={!src}
        shadowDisabled
      />
      <div className="flex flex-col justify-start grow">
        <KSpan text={trackName} color={trackName === "missing title" ? "#ff6666" : "#3D3D3D"} fontWeight={500} />
        <KSpan
          text={trackArtist}
          color={trackArtist === "missing artist" ? "#ff6666" : "#737373"}
          fontSize={12}
          lineHeight="12px"
        />
      </div>
      <div className="flex w-32">
        <KSpan text={trackDuration.toString()} color="#3D3D3D" fontSize={12} lineHeight="16px" />
      </div>
      <audio ref={audioRef} controls style={{ display: "none" }} onTimeUpdate={updateTime}>
        <source src={src} type="audio/mp3" />
      </audio>
      <div className="flex flex-row items-center w-[100px]">{renderProgressBar()}</div>
    </div>
  )
}

export default AudioPlayerProgress
