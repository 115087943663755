import { KButton, KInput, KSpan, KTextArea, KTitleSpan, KTooltip } from "kahuna-base-react-components"
//@ts-ignore
import ArrowLeftIcon from "../../../../../assets/release-registry-icons/arrow-left.svg"
//@ts-ignore
import CompletedIcon from "../../../../../assets/release-registry-icons/completed.svg"
//@ts-ignore
import IncompleteIcon from "../../../../../assets/release-registry-icons/incomplete.svg"
import React, { useEffect, useRef, useState } from "react"
import { ReleaseRegistryPages, ReleaseRegistryUploadTracksTabs } from "../../../../../actions/types"
import ArtistInitials from "../../../../ui/ArtistInitials"
//@ts-ignore
import UploadCloudIcon from "../../../../../assets/release-registry-icons/upload-cloud.svg"
//@ts-ignore
import InfoCustomIcon from "../../../../../assets/release-registry-icons/info-custom.svg"
import { useOrchestrator } from "../ReleaseRegistryContextProvider"
import AudioPlayer from "../../track-detail/AudioPlayer"
import { toast } from "react-toastify"
import { loadingToastOptions } from "../../../../../constants"
import LinearProgress from "../component/linear-progress/LinearProgress"
import { checkMissingTrackFields } from "../../../../../utility"
import { users } from "../../../../../apis"
import RotorVideoComponent from "../../../../ui/components/RotorVideoComponent"

const Lyrics = ({
  setSelectedTab,
  saveTrackDetail
}: {
  setSelectedTab: (tab: ReleaseRegistryUploadTracksTabs) => void
  saveTrackDetail: (selectedTab: boolean) => void
}) => {
  const { selectedTrack, updateTrack, lyricsWidget, releaseForm, audioSrcMap, rotorAccessToken, setRotorAccessToken } =
    useOrchestrator()
  const fileReaderRef = useRef<FileReader | null>(null) // Ref to store FileReader instance
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [dragging, setDragging] = useState<boolean>(false)
  const [uploadFile, setUploadFile] = useState(selectedTrack?.data?.attributes?.lyrics ? false : true)

  const [errorInUpload, setErrorInUpload] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)
  const [missingTrackFields, setMissingTrackFields] = useState<string[]>([])
  const [playingAudioRefs, setPlayingAudioRefs] = useState<HTMLAudioElement[]>([])

  useEffect(() => {
    if (!selectedTrack) {
      return
    }
    const missingFields = checkMissingTrackFields(selectedTrack)
    if (missingFields) {
      setMissingTrackFields(missingFields)
    }
  }, [selectedTrack])

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const openWidget = (trackId: string, isrc: string, title: string, artist: string, album: string) => {
    if (lyricsWidget && audioSrcMap[trackId]) {
      const audioSrc = audioSrcMap[trackId]
      lyricsWidget.open({
        audio: audioSrc,
        ISRC: isrc,
        title,
        artist,
        album
      })
    }
  }

  const handleLyricsUpload = (e: any) => {
    setErrorInUpload(false)
    setLoading(true)
    if (e.target.files.length > 0) {
      const file: File = e.target.files[0]
      if (file) {
        const reader = new FileReader()
        fileReaderRef.current = reader

        reader.onloadend = (e) => {
          if (e.target?.readyState === FileReader.DONE) {
            updateTrack("lyrics", e.target.result)
          }
        }
        reader.onerror = () => {
          setErrorInUpload(true)
          setLoading(false)
        }

        // Forced error for testing purposes (it is only for test, causes to forced error. Do not use it)
        /*setTimeout(() => {
          const errorEvent = new ProgressEvent('error')
          reader.dispatchEvent(errorEvent)
        }, 1000)*/

        reader.readAsText(file)
      } else {
        setLoading(false)
      }

      setTimeout(() => {
        setLoading(false)
        setUploadFile(false)
      }, 2000)
    }
  }
  const handleDragOver = (e: any) => {
    e.preventDefault()
    setDragging(true)
  }

  const handleDragLeave = () => {
    setDragging(false)
  }

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()
    setDragging(false)

    const file = e.dataTransfer.files[0]
    if (file) {
      handleLyricsUpload({ target: { files: [file] } })
    }
  }

  const getRotorAccessToken = () => {
    setLoading(true)
    users(`/rotor_authenticate/`, "get")
      .then((resp) => {
        if (resp?.data) {
          setRotorAccessToken(resp?.data)
        }
        setLoading(false)
      })
      .catch((_err) => {
        setLoading(false)
      })
  }

  const renderRotorVideos = () => {
    if (!rotorAccessToken || !selectedTrack) {
      return <div />
    }

    return (
      <RotorVideoComponent
        accessToken={rotorAccessToken}
        setAccessToken={setRotorAccessToken}
        artistName={selectedTrack.data.attributes["artist"].join(", ")}
        artworkUrl={
          releaseForm.product.data.attributes["front-cover"] || releaseForm.product.data.attributes["cover-url"]
        }
        audioUrl={selectedTrack?.data?.attributes["preview-url"]}
        trackName={selectedTrack.data.attributes.title}
        trackId={`${releaseForm.product.data.id}_${selectedTrack.data.id}`}
      />
    )
  }

  const onClickAutoGenerateVideos = () => {
    const artworkUrl =
      releaseForm.product.data.attributes["front-cover"] || releaseForm.product.data.attributes["cover-url"]

    if (!selectedTrack?.data?.attributes?.title) {
      toast.success("Missing track title.", loadingToastOptions)
    } else if (!selectedTrack?.data?.attributes?.["preview-url"]) {
      toast.success("Missing audio file.", loadingToastOptions)
    } else if (!selectedTrack?.data?.attributes?.["artist"]?.length) {
      toast.success("Missing artist.", loadingToastOptions)
    } else if (!artworkUrl) {
      toast.success("Missing Cover File.", loadingToastOptions)
    } else {
      getRotorAccessToken()
    }
  }

  return (
    <div className="w-[616px] max-sm:w-auto flex flex-col gap-6">
      <div className="flex flex-col gap-3 justify-start">
        <div className="flex flex-row gap-4 items-center">
          <div>
            <KButton
              icon={ArrowLeftIcon}
              onClick={() => {
                setSelectedTab(ReleaseRegistryUploadTracksTabs.CONTRIBUTOR_CONSENT_FILES)
              }}
              width="36px"
              height="36px"
              padding="8px"
              background="#F0F0F0"
              shadowDisabled
              hoverBackground="#E7E7E7"
            />
          </div>
          <KTitleSpan text="Lyrics" fontSize={40} lineHeight="48px" letterSpacing="-0.4px" color="#1F1F1F" />
        </div>
        <KSpan text="Your Spotify artist name uniquely identifies you on the platform." />
      </div>
      <div className="w-full flex flex-col gap-4 max-h-[50vh] overflow-auto">
        <div className="grow flex flex-row gap-1.5 border-[1px] border-[#E7E7E7] p-1.5 rounded-[6px] items-center justify-between">
          <div className="w-8 h-8 flex items-center justify-center cursor-pointer">
            <AudioPlayer
              src={selectedTrack?.data?.attributes?.["preview-url"]}
              playingAudioRefs={playingAudioRefs}
              setPlayingAudioRefs={setPlayingAudioRefs}
            />
          </div>
          <div className="flex grow">
            <KInput
              value={selectedTrack?.data?.attributes?.title}
              background="#FFF"
              padding="8px 32px 8px 10px"
              placeholder="Add track name"
              disabled
              boxShadow="none"
              width={300}
              onChange={() => {
                //Do Nothing
              }}
            />
          </div>
          <div className="flex flex-row gap-8 items-center">
            {<ArtistInitials artists={selectedTrack?.data?.attributes["artist"] || []} />}
            <div className="flex flex-row gap-1 pr-2">
              {missingTrackFields.length > 0 ? (
                <KTooltip
                  position="left"
                  content={
                    <div className="flex items-center justify-center w-[200px] ">
                      <KSpan
                        text={missingTrackFields?.join(", ") || ""}
                        fontSize={11}
                        fontWeight={500}
                        color="#1F1F1F"
                      />
                    </div>
                  }
                >
                  <img src={IncompleteIcon} alt="incomplete-icon" />
                </KTooltip>
              ) : (
                <img src={CompletedIcon} alt="completed-icon" />
              )}
              <span className="flex flex-row items-center pt-0.5">
                <KSpan
                  text={missingTrackFields.length > 0 ? "Incomplete" : "Completed"}
                  color="#3D3D3D"
                  fontSize={12}
                  fontWeight={500}
                  lineHeight="12px"
                />
              </span>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row gap-1 p-1 bg-[#F5F5F5] rounded-full">
          <div
            className="rounded-full"
            style={{
              boxShadow: uploadFile
                ? "0px 32px 24px -12px rgba(17, 17, 17, 0.06), 0px 11px 4px 0px rgba(17, 17, 17, 0.01), 0px 6px 4px 0px rgba(17, 17, 17, 0.02), 0px 3px 3px 0px rgba(17, 17, 17, 0.03), 0px 1px 1px 0px rgba(17, 17, 17, 0.04), 0px 0px 0px 1px rgba(17, 17, 17, 0.04)"
                : "none",
              width: "calc(50% - 2px)"
            }}
          >
            <KButton
              text="Upload a file"
              onClick={() => {
                setUploadFile(true)
              }}
              borderRadius={999}
              padding="8px 4px"
              shadowDisabled
              height="36px"
              background={uploadFile ? "#FFF" : "#F5F5F5"}
              textColor={uploadFile ? "#111" : "#989898"}
            />
          </div>
          <div
            className="flex-grow rounded-full"
            style={{
              boxShadow: !uploadFile
                ? "0px 32px 24px -12px rgba(17, 17, 17, 0.06), 0px 11px 4px 0px rgba(17, 17, 17, 0.01), 0px 6px 4px 0px rgba(17, 17, 17, 0.02), 0px 3px 3px 0px rgba(17, 17, 17, 0.03), 0px 1px 1px 0px rgba(17, 17, 17, 0.04), 0px 0px 0px 1px rgba(17, 17, 17, 0.04)"
                : "none",
              width: "calc(50% - 2px)"
            }}
          >
            <KButton
              text="Write the lyrics"
              onClick={() => {
                setUploadFile(false)
              }}
              borderRadius={999}
              padding="8px 4px"
              shadowDisabled
              height="36px"
              background={!uploadFile ? "#FFF" : "#F5F5F5"}
              textColor={!uploadFile ? "#111" : "#989898"}
            />
          </div>
        </div>
        <div>
          {uploadFile ? (
            <div
              className={`bg-[#F5F5F5] hover:bg-[#F0F0F0] rounded-[10px]`}
              style={{
                display: uploadFile ? "" : "none",
                backgroundColor: dragging ? "rgba(0, 0, 0, 0.32)" : ""
              }}
            >
              <label
                htmlFor="file"
                className={`${dragging ? "bg-[rgba(0, 0, 0, 0.32)]" : "bg-transparent"}
            `}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <div className="flex items-center justify-center w-full">
                  {
                    <div className="flex flex-col items-center justify-center gap-5 p-8">
                      <div className={"flex justify-center"} style={{ height: 40 }}>
                        <img src={UploadCloudIcon} />
                      </div>
                      <div className={"flex flex-col gap-1 justify-center items-center"}>
                        <KSpan text={"Choose a file or drag & drop it here."} color={"#111"} fontWeight={500} />
                        <KSpan text={"DOCX and TXT formats, up to 10MB."} fontSize={12} lineHeight="16px" />
                      </div>
                      <div
                        style={{
                          pointerEvents: "auto"
                        }}
                      >
                        <KButton
                          text="Browse File"
                          onClick={handleBrowseClick}
                          height="32px"
                          padding="6px 10px"
                          background="#FFF"
                          textColor="#111"
                        />
                      </div>
                    </div>
                  }
                  <input
                    className="hidden"
                    ref={fileInputRef}
                    id="file"
                    type="file"
                    accept=".txt, .docx"
                    multiple
                    onChange={(e) => {
                      handleLyricsUpload(e)
                    }}
                  />
                </div>
              </label>
            </div>
          ) : (
            <div style={{ paddingRight: 1, paddingLeft: 1 }}>
              <KTextArea
                height={100}
                placeholder={"Write the lyrics here"}
                value={selectedTrack?.data?.attributes?.lyrics || ""}
                onChange={(value) => updateTrack("lyrics", value)}
              />
            </div>
          )}
        </div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}
        <div className="flex flex-row justify-between">
          <div className="flex flex-col gap-3">
            <div className="flex flex-row gap-2 justify-start items-center h-4">
              <div>
                <KInput
                  value={"checkTerms"}
                  onChange={() => {
                    updateTrack("parental-advisory", !selectedTrack?.data?.attributes["parental-advisory"])
                  }}
                  type={"checkbox"}
                  checked={selectedTrack?.data?.attributes["parental-advisory"]}
                  shadowDisabled={true}
                  width={16}
                  height={16}
                  accentColor={"black"}
                  padding="0px"
                  borderRadius={10}
                />
              </div>
              <div className="flex flex-row items-center">
                <KSpan
                  text="Parental Advisory"
                  color="#1F1F1F"
                  lineHeight="16px"
                  fontWeight={selectedTrack?.data?.attributes["parental-advisory"] ? 500 : 400}
                />
                <KTooltip
                  content={
                    <div className="w-[150px] flex items-center">
                      <KSpan
                        text="Check if the lyrics contain explicit content."
                        fontWeight={500}
                        color="#111"
                        fontSize={12}
                      />
                    </div>
                  }
                >
                  <img src={InfoCustomIcon} alt="info" width={20} height={20} />
                </KTooltip>
              </div>
            </div>
            <div className="flex flex-row gap-2 justify-start items-center h-4">
              <div>
                <KInput
                  value={"checkTerms"}
                  onChange={() => {
                    updateTrack("clean", !selectedTrack?.data?.attributes["clean"])
                  }}
                  type={"checkbox"}
                  checked={selectedTrack?.data?.attributes["clean"]}
                  shadowDisabled={true}
                  width={16}
                  height={16}
                  accentColor={"black"}
                  padding="0px"
                  borderRadius={10}
                />
              </div>
              <div className="flex flex-row items-center">
                <KSpan
                  text="Clean"
                  color="#1F1F1F"
                  lineHeight="16px"
                  fontWeight={selectedTrack?.data?.attributes["clean"] ? 500 : 400}
                />
                <KTooltip
                  position="right"
                  content={
                    <div className="w-[170px] flex items-center">
                      <KSpan
                        text="Check only if there is an explicit version of this track."
                        fontWeight={500}
                        color="#111"
                        fontSize={12}
                      />
                    </div>
                  }
                >
                  <img src={InfoCustomIcon} alt="info" width={20} height={20} />
                </KTooltip>
              </div>
            </div>
          </div>
          <div className="flex gap-2">
            {!uploadFile && (
              <KButton
                background="linear-gradient(rgb(30, 117, 223),rgb(31, 149, 183)) rgb(30, 117, 223)"
                // background="rgb(31, 149, 183)"
                textColor="white"
                width="220px"
                text="Auto Generate Lyrics"
                onClick={() => {
                  if (selectedTrack?.data?.attributes["preview-url"]) {
                    const attributes = selectedTrack?.data?.attributes
                    openWidget(
                      selectedTrack?.data?.id || "null",
                      attributes["isrc-code"],
                      attributes.title,
                      attributes.artist?.join(", ") || "-",
                      releaseForm.product.data.attributes.name
                    )
                  } else {
                    toast.success("You should upload track first!.", loadingToastOptions)
                  }
                }}
              />
            )}
            <KButton
              width="50px"
              icon="/catalog_icons/video.svg"
              padding="0px"
              background="linear-gradient(270deg, #e1387c 0%, #02bad8 100%)"
              textColor="white"
              disabled={loading}
              onClick={() => onClickAutoGenerateVideos()}
            />
          </div>
        </div>
      </div>
      <div>
        <KButton
          text="Save and Return to Tracks"
          onClick={() => {
            saveTrackDetail(false)
          }}
          background="#000"
          textColor="#FFF"
        />
      </div>
      {renderRotorVideos()}
    </div>
  )
}
export default Lyrics
