import { PaymentFormProps, PaymentMethod } from "../../../../types"
import paymentMethodValidator from "./paymentMethodValidator"
import React, { FC, useEffect, useState } from "react"
import { connect } from "react-redux"
import { createPaymentMethod, deletePaymentMethod, updatePaymentMethod } from "../../../../actions"
import { KButton, KInput, KTitleSpan } from "kahuna-base-react-components"
import Warning from "./Warning"

const PaymentForm: FC<PaymentFormProps> = (props) => {
  const {
    initialValues,
    editable,
    deletePaymentMethod,
    updatePaymentMethod,
    createPaymentMethod,
    setAdding,
    edit,
    setEdit,
    adminPanelUserId,
    title,
    addPaymentMethod,
    setAddPaymentMethod,
    choosenMethod
  } = props

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    choosenMethod || {
      bankName: "",
      iban: "",
      accountHolderName: "",
      swiftCode: ""
    }
  )

  const formatIban = (value: string | undefined) => {
    if (value) {
      const filteredValue = `TR${value.replace(/[^\d]/g, "")}`
      if (filteredValue.length > 26) {
        return filteredValue
          .substring(0, 26)
          .replace(/(.{4})/g, "$1 ")
          .trim()
      }
      return filteredValue.replace(/(.{4})/g, "$1 ").trim()
    }
    return ""
  }

  useEffect(() => {
    setPaymentMethod((prev) => {
      return { ...prev, iban: formatIban(prev.iban) }
    })
  }, [paymentMethod.iban])

  return (
    <>
      <div className="pt-8 pb-4 px-6 w-[440px] shadow-md rounded-[10px] bg-[#fff]">
        <div className="flex flex-col items-center gap-8">
          <div className="flex flex-col gap-8 items-center">
            <span
              style={{
                padding: 16,
                borderRadius: "9999px",
                background: "var(--bg-white-0, #FFF)",
                boxShadow:
                  "0px 32px 24px -12px rgba(17, 17, 17, 0.06), 0px 11px 4px 0px rgba(17, 17, 17, 0.01), 0px 6px 4px 0px rgba(17, 17, 17, 0.02), 0px 3px 3px 0px rgba(17, 17, 17, 0.03), 0px 1px 1px 0px rgba(17, 17, 17, 0.04), 0px 0px 0px 1px rgba(17, 17, 17, 0.04)"
              }}
            >
              <img className="w-8 h-8" src="/account_icons/wallet-filled.svg" />
            </span>
            <KTitleSpan text={title || ""} fontSize={24} lineHeight="28px" color="#000" />
          </div>
          <div className="flex flex-col gap-4 items-center w-full ">
            <KInput
              value={paymentMethod.bankName}
              placeholder="Title"
              padding="14px"
              onChange={(value) => {
                setPaymentMethod({ ...paymentMethod, bankName: value })
              }}
            />
            <KInput
              value={paymentMethod.iban}
              placeholder="IBAN"
              padding="14px"
              onChange={(value) => {
                setPaymentMethod({ ...paymentMethod, iban: value })
              }}
            />
            <KInput
              value={paymentMethod.accountHolderName}
              placeholder="Account Holder"
              padding="14px"
              onChange={(value) => {
                setPaymentMethod({ ...paymentMethod, accountHolderName: value })
              }}
            />
            <KInput
              value={paymentMethod.swiftCode || ""}
              placeholder="Swift Bic Code"
              padding="14px"
              onChange={(value) => {
                setPaymentMethod({ ...paymentMethod, swiftCode: value })
              }}
            />
            <Warning warning="The account holder name and your account name must match" />
            <div className="flex mt-8 gap-3" style={{ width: "100%" }}>
              <KButton
                text="Cancel"
                height="48px"
                onClick={() => {
                  if (edit === undefined && addPaymentMethod !== undefined && setAddPaymentMethod !== undefined) {
                    setAddPaymentMethod(false)
                  } else if (edit !== undefined && setEdit !== undefined && addPaymentMethod === undefined) {
                    setEdit(false)
                  }
                }}
                shadowDisabled={true}
                background="#fff"
                textColor="#000"
              />
              <KButton
                background="#000"
                textColor="#FFF"
                height="48px"
                text="Accept"
                onClick={() => {
                  const errors = paymentMethodValidator(paymentMethod) // checks if there is any mistake about given values
                  //localStorage.setItem("paymentMethod", JSON.stringify(paymentmethod))
                  if (edit === undefined && addPaymentMethod !== undefined && setAddPaymentMethod !== undefined) {
                    if (Object.keys(errors).length === 0) {
                      setAddPaymentMethod(false)
                      if (paymentMethod.swiftCode === "") {
                        delete paymentMethod.swiftCode
                        createPaymentMethod(paymentMethod, adminPanelUserId)
                      } else {
                        createPaymentMethod(paymentMethod, adminPanelUserId)
                      }
                    }
                  } else if (edit !== undefined && setEdit !== undefined && addPaymentMethod === undefined) {
                    if (Object.keys(errors).length === 0) {
                      setEdit(false)
                      if (paymentMethod.swiftCode === "") {
                        delete paymentMethod.swiftCode
                        updatePaymentMethod(paymentMethod)
                      } else {
                        updatePaymentMethod(paymentMethod)
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default connect(null, { createPaymentMethod, deletePaymentMethod, updatePaymentMethod })(PaymentForm)
