import { SubscriptionsType, TableColumn } from "../../../../types"
import { TABLE_COLUMN_TYPE } from "../../../../constants"
import React from "react"
import { convertToTurkeyTime } from "../../../../utility"
import { KButton, KDropdown, KSpan, KTextArea } from "kahuna-base-react-components"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"

export const statusOptions: KSelectOption[] = [
  { label: "Subscribed", value: 0, value2: "subscribed" },
  { label: "Attended demo", value: 1, value2: "demo" }
]

const subscriptionColumns = (
  description: { [key: string]: string },
  setDescription: any,
  onClickDetails: (meetingId: string) => void,
  dbStatus: { [key: string]: string },
  setDbStatus: any,
  addToDatabase: (meetingId: string, description: string, db_status: string, type: string, start_time: string, created_at: string) => void
): TableColumn[] => [
  {
    header: "ID",
    headerStyle: {
      textAlign: "left",
      width: "18%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: SubscriptionsType) => <KSpan text={row.calendly_id} color="#000" />
  },
  {
    header: "Date",
    headerStyle: {
      textAlign: "left",
      width: "10%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: SubscriptionsType) => {
      return <KSpan text={convertToTurkeyTime(row.start_time)} color="#000" />
    }
  },
  {
    header: "Created",
    headerStyle: {
      textAlign: "left",
      width: "10%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: SubscriptionsType) => {
      return <KSpan text={convertToTurkeyTime(row.created_at)} color="#000" />
    }
  },
  {
    header: "Calendly Status",
    headerStyle: {
      textAlign: "left",
      width: "10%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: SubscriptionsType) => {
      return <KSpan text={row.calendly_status} color="#000" />
    }
  },
  {
    header: "DB Status",
    headerStyle: {
      textAlign: "left",
      width: "8%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: SubscriptionsType) => {
      const defaultValue = dbStatus[row.calendly_id]
      return (
        <div
          key={`status-kdropdown-${dbStatus[row.calendly_id]}-${row.calendly_id}`}
          className="flex flex-col gap-2 w-full"
        >
          <KDropdown
            width={130}
            placeholder="DB Status"
            options={statusOptions}
            rightIcon="/analytics_icons/caret-down-new.svg"
            defaultValuePrimitive={defaultValue}
            padding="8px 14px"
            menuWidth={150}
            menuLeftMargin={-14}
            background={defaultValue === "demo" || defaultValue === "subscribed" ? "#FFF" : "#F5F5F5"}
            activeBackground="#FFF"
            onSelect={(selected) => {
              const new_db_status = (selected as KSelectOption)?.value2 || "-"
              setDbStatus({ ...dbStatus, [row.calendly_id]: new_db_status })
              addToDatabase(row.calendly_id, description[row.calendly_id], new_db_status, "demo", row.start_time, row.created_at)
            }}
            isEllipsis
          />
        </div>
      )
    }
  },
  {
    header: "Admin Notes",
    headerStyle: {
      textAlign: "left",
      width: "34%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: SubscriptionsType) => {
      return (
        <div>
          <KTextArea
            value={description[row.calendly_id]}
            onChange={(value: string) => {
              setDescription({ ...description, [row.calendly_id]: value })
            }}
            onBlur={() => {
              if (description[row.calendly_id] !== row.description) {
                addToDatabase(row.calendly_id, description[row.calendly_id], dbStatus[row.calendly_id], "demo", row.start_time, row.created_at)
              }
            }}
          />
        </div>
      )
    }
  },
  {
    header: "",
    headerStyle: {
      textAlign: "left",
      width: "10%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: SubscriptionsType) => {
      return (
        <KButton
          text="Details"
          width="auto"
          background="#FFF"
          onClick={() => {
            onClickDetails(row.calendly_id)
          }}
        />
      )
    }
  }
]

export default subscriptionColumns
