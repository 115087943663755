import React, { FC, ReactNode, useEffect, useState } from "react"
import { KButton } from "kahuna-base-react-components"

const PreviousNextButtons = ({
    isPreviousDisabled,
    isNextDisabled,
    previousClick,
    nextClick
}:{
    isPreviousDisabled: boolean,
    isNextDisabled: boolean,
    previousClick: (...args: any[]) => void,
    nextClick: (...args: any[]) => void

}) => {

  return (
    <div>
      <hr className="mt-2 mb-2" />
      <div className="ml-3 mr-3 flex justify-between">
        <KButton
          text="Previous"
          onClick={previousClick}
          background="white"
          width="auto"
          disabled={isPreviousDisabled}
        />
        <KButton
          text="Next"
          onClick={nextClick}
          background="white"
          width="auto"
          disabled={isNextDisabled}
        />
      </div>
    </div>
  )
}

export default PreviousNextButtons
