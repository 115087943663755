import { PaymentPageProps } from "../../../../types"
import * as React from "react"
import { FC, useEffect, useState } from "react"
import { connect } from "react-redux"
import PaymentForm from "./PaymentForm"
import { fetchPaymentMethods } from "../../../../actions"
import { RootState } from "../../../../store"
import Title from "../Title"
import { KButton } from "kahuna-base-react-components"
import { Dialog } from "@headlessui/react"
import ListComponent from "./ListComponent"

const Payment: FC<PaymentPageProps> = (props) => {
  const [adding, setAdding] = useState(false)

  const [edit, setEdit] = useState(false)

  const [editableMethodKey, setEditableMethodKey] = useState<string>("") // hangi odeme yonteminin editlenecegini seciyor

  useEffect(() => {
    props.fetchPaymentMethods(props.adminPanelUserId)
  }, [props.adminPanelUserId])

  const [addPaymentMethod, setAddPaymentMethod] = useState<boolean>(false)

  const renderPaymentMethods = () => {
    return (
      <>
        <div className="flex flex-col ">
          <div className="flex flex-row justify-between items-center">
            <Title
              sectionTitle="Payment methods"
              sectionDetail="Select IBAN wire and check"
              iconPath="/account_icons/credit-card.svg"
            />
            <KButton
              text="Add"
              textColor="#666"
              leftIcon="/account_icons/add-line.svg"
              onClick={() => {
                setAddPaymentMethod(true)
              }}
              shadowDisabled={true}
              background="#ffffff"
              width="auto"
              height="36px"
              padding="8px"
              gap="4px"
            />
          </div>
          <div
            className="flex flex-row flex-wrap gap-4 mt-3 w-full"
            style={{ overflowY: "scroll", height: "75vh", display: "block" }}
          >
            {Object.keys(props.paymentMethods).map((key) => (
              <ListComponent
                paymentMethod={props.paymentMethods[key]}
                setEdit={setEdit}
                key={`list-component-${key}`}
                paymentId={key.toString()}
                setEditableMethodKey={setEditableMethodKey}
              />
            ))}
          </div>
          <Dialog
            open={addPaymentMethod}
            onClose={() => {
              setAddPaymentMethod(false)
            }}
          >
            <div className="fixed top-0 z-200 left-0 w-screen h-screen flex items-center justify-center bg-[#0000004d] ">
              <PaymentForm
                title="Add bank account"
                addPaymentMethod={addPaymentMethod}
                setAddPaymentMethod={setAddPaymentMethod}
                adminPanelUserId={props.adminPanelUserId}
              />
            </div>
          </Dialog>
          <Dialog
            open={edit}
            onClose={() => {
              setEdit(false)
            }}
          >
            <div className="fixed top-0 z-200 left-0 w-screen h-screen flex items-center justify-center bg-[#0000004d] ">
              <PaymentForm
                title="Edit bank account"
                edit={edit}
                setEdit={setEdit}
                choosenMethod={props.paymentMethods[editableMethodKey]}
                adminPanelUserId={props.adminPanelUserId}
              />
            </div>
          </Dialog>
        </div>
        {/*<div className="flex flex-row justify-between mt-3 mb-6">
          <div className="text-2xl font-bold pt-1">{adding ? "Add new IBAN" : "Payment methods"}</div>
          <div className="flex gap-2">
            {!adding && <Button text={renderEditButton()} className={`!w-min ${editing && "bg-gray-100"}`} light />}
            <Button text={renderAddButton()} className="!w-min" light />
          </div>
        </div>
    {renderBody()}*/}
      </>
    )
  }

  return props.adminPanelUserId ? renderPaymentMethods() : <>{renderPaymentMethods()}</>
}

const mapStateToProps = (state: RootState) => {
  return { paymentMethods: state.paymentMethods, adminPanelUserId: state.admin.adminPanelUserId }
}

export default connect(mapStateToProps, { fetchPaymentMethods })(Payment)
