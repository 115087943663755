import { KButton, KInput, KSpan, KTitleSpan, KTooltip } from "kahuna-base-react-components"
//@ts-ignore
import ArrowLeftIcon from "../../../../../assets/release-registry-icons/arrow-left.svg"
//@ts-ignore
import CompletedIcon from "../../../../../assets/release-registry-icons/completed.svg"
//@ts-ignore
import IncompleteIcon from "../../../../../assets/release-registry-icons/incomplete.svg"
import React, { useEffect, useRef, useState } from "react"
import { ReleaseRegistryUploadTracksTabs } from "../../../../../actions/types"
import { useOrchestrator } from "../ReleaseRegistryContextProvider"
import { AssetRegistryPublishingShareHolder } from "../../../../../types"
import { releases } from "../../../../../apis"
import ArtistInitials from "../../../../ui/ArtistInitials"
import { checkMissingTrackFields } from "../../../../../utility"
import AudioPlayer from "../../track-detail/AudioPlayer"
import PublishingShareHolderModal from "../../asset-registry/tracks-and-assets/PublishingShareHolderModal"
import { ROLE_NAMES, ROLES } from "../../../../../constants"

const PublishingShareHolders = ({
  setSelectedTab,
  saveTrackDetail
}: {
  setSelectedTab: (tab: ReleaseRegistryUploadTracksTabs) => void
  saveTrackDetail: (selectedTab: boolean) => void
}) => {
  const { publishingShareHolderList, setPublishingShareHolderList, selectedTrack, releaseForm } = useOrchestrator()
  const [selectedPublishingShareHolder, setSelectedPublishingShareHolder] = useState<
    AssetRegistryPublishingShareHolder | undefined
  >(undefined)

  const [missingTrackFields, setMissingTrackFields] = useState<string[]>([])

  const [playingAudioRefs, setPlayingAudioRefs] = useState<HTMLAudioElement[]>([])

  useEffect(() => {
    if (!selectedTrack) {
      return
    }
    const missingFields = checkMissingTrackFields(selectedTrack)
    if (missingFields) {
      setMissingTrackFields(missingFields)
    }
  }, [selectedTrack])

  const confirmPublishingShareHolder = () => {
    releases(`/publishing-share-holder/save/`, "post", {
      ...selectedPublishingShareHolder,
      product_id: releaseForm.product.data.id
    }).then((resp) => {
      if (resp.data) {
        setPublishingShareHolderList(resp.data)
      }
    })
    setSelectedPublishingShareHolder(undefined)
  }

  const onDeletePublishingShareHolder = (existedShareHolder) => {
    if (!existedShareHolder.id) {
      return
    }

    releases(
      `/publishing-share-holder/delete/?id=${existedShareHolder.id}&isrc=${existedShareHolder.isrc}`,
      "delete"
    ).then((resp) => {
      if (resp.data) {
        setPublishingShareHolderList(resp.data)
      }
    })
  }

  const renderPublishingShareHolderList = (shareHolders: string[], roleId: number) => {
    return shareHolders.map((shareHolder: string, _index: number) => {
      const existedShareHolder = publishingShareHolderList.find(
        (psh) => psh.name === shareHolder && psh.role === roleId
      ) || {
        name: shareHolder,
        role: roleId,
        share: "",
        isrc: selectedTrack?.data?.attributes["isrc-code"]
      }
      return (
        <div key={`share_holder_${shareHolder}`} className="p-4 border flex justify-between">
          <div className="flex">
            <span className="text-gray-500" style={{ maxWidth: 200, minWidth: 200 }}>{`${ROLE_NAMES[roleId]} #${
              _index + 1
            }: `}</span>
            <span style={{ maxWidth: 200, minWidth: 200 }}>{shareHolder}</span>
          </div>
          <span>{existedShareHolder.share || "0"}%</span>
          <div className="flex">
            <img
              src="/catalog_icons/edit-line.svg"
              style={{ cursor: "pointer", marginRight: 12 }}
              onClick={() => setSelectedPublishingShareHolder(existedShareHolder)}
            />
            <img
              src="/catalog_icons/delete-bin.svg"
              style={{ cursor: "pointer" }}
              onClick={() => onDeletePublishingShareHolder(existedShareHolder)}
            />
          </div>
        </div>
      )
    })
  }

  const renderPublishingShareHolders = () => {
    const composerList = selectedTrack?.data?.attributes["composer"] || []
    const lyricistList = selectedTrack?.data?.attributes["lyricist"] || []
    const arrangerList = selectedTrack?.data?.attributes["arranger"] || []

    return (
      <div className={"mt-2"}>
        {renderPublishingShareHolderList(composerList, ROLES.COMPOSER)}
        {renderPublishingShareHolderList(lyricistList, ROLES.LYRICIST)}
        {renderPublishingShareHolderList(arrangerList, ROLES.ARRANGER)}
      </div>
    )
  }

  return (
    <div className="w-[616px] max-sm:w-auto flex flex-col gap-6">
      <div className="flex flex-col gap-3 justify-start">
        <div className="flex flex-row gap-4 items-center">
          <div>
            <KButton
              icon={ArrowLeftIcon}
              onClick={() => {
                setSelectedTab(ReleaseRegistryUploadTracksTabs.TRACK_SHARE_HOLDERS)
              }}
              width="36px"
              height="36px"
              padding="8px"
              background="#F0F0F0"
              shadowDisabled
              hoverBackground="#E7E7E7"
            />
          </div>
          <KTitleSpan
            text="Publishing Share Holders"
            fontSize={40}
            lineHeight="48px"
            letterSpacing="-0.4px"
            color="#1F1F1F"
          />
        </div>
        <KSpan text="Your Spotify artist name uniquely identifies you on the platform." />
      </div>
      <div className="flex flex-grow flex-col gap-3">
        <div className="grow flex flex-row gap-1.5 border-[1px] border-[#E7E7E7] p-1.5 rounded-[6px] items-center justify-between">
          <div className="w-8 h-8 flex items-center justify-center cursor-pointer">
            <AudioPlayer
              src={selectedTrack?.data?.attributes?.["preview-url"]}
              playingAudioRefs={playingAudioRefs}
              setPlayingAudioRefs={setPlayingAudioRefs}
            />
          </div>
          <div className="flex grow">
            <KInput
              value={selectedTrack?.data?.attributes?.title}
              background="#FFF"
              padding="8px 32px 8px 10px"
              placeholder="Add track name"
              disabled
              boxShadow="none"
              width={300}
              onChange={() => {
                //Do Nothing
              }}
            />
          </div>
          <div className="flex flex-row gap-8 items-center">
            {<ArtistInitials artists={selectedTrack?.data?.attributes["artist"] || []} />}
            <div className="flex flex-row gap-1 pr-2">
              {missingTrackFields.length > 0 ? (
                <KTooltip
                  position="left"
                  content={
                    <div className="flex items-center justify-center w-[200px] ">
                      <KSpan
                        text={missingTrackFields?.join(", ") || ""}
                        fontSize={11}
                        fontWeight={500}
                        color="#1F1F1F"
                      />
                    </div>
                  }
                >
                  <img src={IncompleteIcon} alt="incomplete-icon" />
                </KTooltip>
              ) : (
                <img src={CompletedIcon} alt="completed-icon" />
              )}
              <span className="flex flex-row items-center pt-0.5">
                <KSpan
                  text={missingTrackFields.length > 0 ? "Incomplete" : "Completed"}
                  color="#3D3D3D"
                  fontSize={12}
                  lineHeight="12px"
                  fontWeight={500}
                />
              </span>
            </div>
          </div>
        </div>
        <div style={{ overflowY: "scroll", maxHeight: "30vh" }}>{renderPublishingShareHolders()}</div>
      </div>
      <div>
        <KButton
          text="Continue"
          onClick={() => {
            setSelectedTab(ReleaseRegistryUploadTracksTabs.CONTRIBUTOR_CONSENT_FILES)
          }}
          background="#000"
          textColor="#FFF"
        />
      </div>
      <div className="flex flex-row justify-center">
        <KButton
          text="Save and Return to Tracks"
          hoverBackground="#E7E7E7"
          onClick={() => {
            saveTrackDetail(false)
          }}
          background="#FFF"
          shadowDisabled
        />
      </div>
      <PublishingShareHolderModal
        selectedPublishingShareHolder={selectedPublishingShareHolder}
        setSelectedPublishingShareHolder={setSelectedPublishingShareHolder}
        handleConfirm={() => confirmPublishingShareHolder()}
      />
    </div>
  )
}
export default PublishingShareHolders
