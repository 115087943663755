import React, { FC, ReactNode, useEffect, useState } from "react"
import { PaginationProps } from "../../../types"
import Button from "../form/Button"
import { KButton } from "kahuna-base-react-components"

const Pagination: FC<PaginationProps> = (props) => {
  const { hidden, pageCount, currentPage, onClickPage, size = "lg" } = props
  if (hidden) return null
  const [pageNumbers, setPageNumbers] = useState<ReactNode>([])

  const buttonSize = {
    sm: "!w-20",
    md: "!w-[88px]",
    lg: "!w-24"
  }[size]

  const letterSize = {
    sm: "text-xs",
    md: "text-sm",
    lg: "text-base"
  }[size]

  const dots = "..."

  useEffect(() => {
    if (!pageCount) return

    const pageNumberArray = []
    if (pageCount < 8) {
      Array(pageCount || 0)
        .fill(0)
        .map((e, i) => pageNumberArray.push(i + 1))
    } else {
      pageNumberArray.push(1)
      if (currentPage < 5) {
        pageNumberArray.push(2)
        pageNumberArray.push(3)
        pageNumberArray.push(4)
        pageNumberArray.push(5)
        pageNumberArray.push(dots)
      } else if (currentPage > pageCount - 4) {
        pageNumberArray.push(dots)
        pageNumberArray.push(pageCount - 4)
        pageNumberArray.push(pageCount - 3)
        pageNumberArray.push(pageCount - 2)
        pageNumberArray.push(pageCount - 1)
      } else {
        pageNumberArray.push(dots)
        pageNumberArray.push(currentPage - 1)
        pageNumberArray.push(currentPage)
        pageNumberArray.push(currentPage + 1)
        pageNumberArray.push(dots)
      }
      pageNumberArray.push(pageCount)
    }
    setPageNumbers(
      pageNumberArray.map((pageNumber, i) => {
        return (
          <div
            key={`pagination-page-number-${i}`}
            className={`mr-6 
              ${currentPage === pageNumber && pageNumber !== dots && "underline"} 
              ${pageNumber !== dots && currentPage !== pageNumber && "cursor-pointer"}
              ${letterSize}`}
            onClick={() => pageNumber !== dots && onClickPage(pageNumber)}
          >
            {pageNumber}
          </div>
        )
      })
    )
  }, [pageCount, currentPage])

  const onClickPrevious = () => {
    onClickPage(currentPage - 1)
  }
  const onClickNext = () => {
    onClickPage(currentPage + 1)
  }

  return (
    <>
      <hr className="mt-2 mb-2" />
      <div className="ml-3 mr-3 flex justify-between">
        <KButton
          text="Previous"
          onClick={onClickPrevious}
          background="white"
          width="auto"
          disabled={!pageCount || currentPage === 1}
        />
        <div className="flex justify-between mt-3 ">{pageNumbers}</div>
        <KButton
          text="Next"
          onClick={onClickNext}
          background="white"
          width="auto"
          disabled={!pageCount || currentPage === pageCount}
        />
      </div>
    </>
  )
}

export default Pagination
