import "./styles/Reports.css"
import ReportsTable from "../../ui/table/ReportsTable"
import reportsColumns from "./columns/reportsColumns"
import React, { FC, useEffect, useState } from "react"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { ReportsProps, SelectOption, User } from "../../../types"
import { fetchReportsData } from "../../../actions/reportsActions"
import { SearchSelect } from "../../ui/form/select/SearchSelect"
import { handleAuthorizedRequest, users } from "../../../apis"

const Reports: FC<ReportsProps> = (props) => {
  const { fetchReportsData } = props

  const [userOptions, setUserOptions] = useState<SelectOption[]>([])
  const [loading, setLoading] = useState(true)
  const [userLoading, setUserLoading] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User>()
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>()
  const [approveDisabled, setApproveDisabled] = useState<boolean>(false)

  const limit = 10

  useEffect(() => {
    if (!props.isAdmin) {
      fetchReportsData().then(() => setLoading(false))
    } else if (props.isAdmin && selectedUser?.id) {
      fetchReportsData(selectedUser?.id, approveDisabled).then(() => setLoading(false))
    }
  }, [selectedUser, approveDisabled])

  const onClickPage = () => {
    setLoading(true)
    fetchReportsData(selectedUser?.id).then(() => setLoading(false))
  }

  const onSearchTextChanged = async (event) => {
    setUserOptions([])
    setUserLoading(true)
    clearTimeout(timeoutId)
    setTimeoutId(
      setTimeout(async () => {
        await handleAuthorizedRequest(async () => {
          const response = await users(`/?limit=10&offset=0&username=${event.target.value}`, "get")
          setUserOptions(
            response.data.results.map((user) => ({
              value: user,
              label: user.username
            }))
          )
          setUserLoading(false)
        }, "")
      }, 500)
    )
  }

  return (
    <div className="catalog flex-grow bg-[#fff]">
      {props.isAdmin && (
        <div className="flex mt-8">
          <div className="relative" style={{ width: 250 }}>
            <SearchSelect
              className="!w-[250px]"
              inputTextClass="text-[14px]"
              placeholder={"Search user to filter"}
              options={userOptions}
              onTextChange={onSearchTextChanged}
              loading={userLoading}
              value={selectedUser}
              // @ts-ignore
              onChange={setSelectedUser}
            />
          </div>
          <div className={"ml-12 flex"}>
            <input
              style={{ cursor: "pointer" }}
              type="checkbox"
              checked={approveDisabled}
              onChange={() => {
                setApproveDisabled(!approveDisabled)
              }}
            />
            <p className={"text-gray-500 pl-2 mt-2"} style={{ width: 300 }}>
              Disable Approve Status
            </p>
          </div>
        </div>
      )}
      <div className="mt-6">
        <ReportsTable
          title="Reports"
          pagination
          minRowCount={limit}
          limit={limit}
          columns={reportsColumns}
          data={props.paymentHistory}
          count={props.paymentHistory.length}
          onClickPage={onClickPage}
          rowHeightInPx={40}
          loading={loading}
          isAdmin={props.isAdmin}
          selectedUserId={selectedUser?.id}
          approveDisabled={approveDisabled}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user,
    paymentHistory: state.reportsData.paymentHistory
  }
}

export default connect(mapStateToProps, { fetchReportsData })(Reports)
