import { KButton, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
import React, { FC } from "react"
import { ArtistSmartLink } from "../../../../types"
import "../styles/smartlink.css"
import { users } from "../../../../apis"
import { errorToastOptions, successToastOptions } from "../../../../constants"
import { toast } from "react-toastify"

export enum SmartLinkSteps {
  CUSTOMIZE = 1,
  PUBLISH = 2
}

type CustomizePageProps = {
  linkText: string
  setLinkText: (linkText: string) => void
  setSelectedStep: (selectedStep: number) => void
  selectedStep: number
  artistSmartLink?: ArtistSmartLink
  setArtistSmartLink: (artistSmartLink: ArtistSmartLink) => void
}

const CustomizePage: FC<CustomizePageProps> = (props) => {
  const { linkText, setLinkText, selectedStep, setSelectedStep, artistSmartLink, setArtistSmartLink } = props

  const saveSmartLinkUrl = () => {
    users("/save_smart_link/", "post", { link: linkText }).then((resp) => {
      if (resp.data.success) {
        toast.success("Link save successfully.", successToastOptions)
        setArtistSmartLink({ ...artistSmartLink, link: linkText })
        setSelectedStep(SmartLinkSteps.PUBLISH)
      } else {
        toast.error("Error saving link.", errorToastOptions)
      }
    })
  }

  return (
    <div className="flex flex-row items-start justify-start gap-2.5 w-4/5 false">
      <div className="flex flex-col items-start rounded-b-[10px] p-6 gap-1" style={{ width: 248 }}>
        <div className="pl-4 pb-6">
          <KSpan text="Steps" />
        </div>
        <div>
          <KButton
            gap="8px"
            text="Customize"
            leftIcon="/account_icons/sparkle.svg"
            onClick={() => setSelectedStep(SmartLinkSteps.CUSTOMIZE)}
            background={selectedStep === SmartLinkSteps.CUSTOMIZE ? "#F3F3F3" : "transparent"}
            fontWeight={400}
            shadowDisabled={true}
          />
        </div>
        <div>
          <KButton
            gap="8px"
            text="Publish"
            leftIcon="/navigation_icons/earth.svg"
            onClick={() => artistSmartLink && setSelectedStep(SmartLinkSteps.PUBLISH)}
            background={selectedStep === SmartLinkSteps.PUBLISH ? "#F3F3F3" : "transparent"}
            fontWeight={400}
            shadowDisabled={true}
          />
        </div>
      </div>
      <div className="justify-center items-center flex">
        <div className="block" style={{ minWidth: 616 }}>
          <div>
            <KTitleSpan text={"Let's create a link"} fontSize={32} fontWeight={500} />
          </div>
          <div className="mt-6">
            <KInput
              leftIcon="/account_icons/music-note-sparkle.svg"
              onChange={(text) => setLinkText(text)}
              value={linkText}
              placeholder="https://royalty.kahuna.io/artist/xxx"
            />
          </div>
          <div className="mt-8">
            <KButton
              background="black"
              textColor="white"
              onClick={() => saveSmartLinkUrl()}
              text="Save Changes"
              disabled={!linkText || artistSmartLink?.link === linkText}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomizePage
