import { Dialog } from "@headlessui/react"
import { FC } from "react"
import { tableCellClasses } from "../../../../../constants"
import { X } from "heroicons-react"
import { Field, Form } from "react-final-form"
import { renderInput } from "../../../../ui/form/Input"
import Button from "../../../../ui/form/Button"
import * as React from "react"
import { AnalysisSummary } from "../../../../../types"
import summaryValidator from "../summaryValidator"

type ApprovalModalProps = {
  open: boolean
  onClose: () => void
  summary: AnalysisSummary
  approveSummary: (summary: AnalysisSummary) => void
}
const ApproveModal: FC<ApprovalModalProps> = (props) => {
  const { approveSummary, onClose, open, summary } = props
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="mt-10 bg-white elev-b-sm rounded-lg overflow-hidden">
          <div
            className={`bg-gray-50 text-xs text-gray-500 font-medium 
              uppercase border-b flex justify-between ${tableCellClasses.sm}`}
          >
            <p>Revenue Summary Approval</p>
            <X className="h-4 mr-[-6px] cursor-pointer" onClick={onClose} />
          </div>

          <Form
            initialValues={summary}
            onSubmit={approveSummary}
            validate={summaryValidator}
            render={({ handleSubmit, pristine, submitting }) => {
              return (
                <>
                  <form className="w-full max-w-sm m-auto flex-column p-4" onSubmit={handleSubmit}>
                    <div className=" w-[350px]">
                      <Field name="user" label={"User"} render={renderInput} className={"mb-4"} disabled />
                      <Field name="label" label={"Label"} render={renderInput} className={"mb-4"} disabled />
                      <Field name="date" label={"Period"} render={renderInput} className={"mb-4"} disabled />
                      <Field name="currency" label={"Currency"} render={renderInput} className={"mb-4"} disabled />
                      <Field name="totalAmount" label={"Gross"} render={renderInput} className={"mb-4"} disabled />
                      <Field name="adminFee" label={"Admin Fee"} render={renderInput} className={"mb-4"} disabled />
                      <Field
                        name="totalNet"
                        label={"Net"}
                        render={renderInput}
                        className={"mb-4"}
                        placeholder={"Net amount"}
                      />
                    </div>
                    <Button className="mb-0" text="Approve" buttonType={"submit"} />
                  </form>
                </>
              )
            }}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default ApproveModal
