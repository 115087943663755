import { KButton, KSpan, KTitleSpan, KTooltip } from "kahuna-base-react-components"
//@ts-ignore
import ArrowLeftIcon from "../../../../../assets/release-registry-icons/arrow-left.svg"
import React, { useRef, useState, useEffect } from "react"
//@ts-ignore
import DraggableIcon from "../../../../../assets/release-registry-icons/draggable.svg"
//@ts-ignore
import PlayIcon from "../../../../../assets/release-registry-icons/play.svg"
//@ts-ignore
import PauseIcon from "../../../../../assets/release-registry-icons/pause.svg"
//@ts-ignore
import InfoCustomIcon from "../../../../../assets/release-registry-icons/info-custom.svg"
//@ts-ignore
import EditIcon from "../../../../../assets/release-registry-icons/edit.svg"
//@ts-ignore
import ErrorIcon from "../../../../../assets/release-registry-icons/error.svg"
//@ts-ignore
import CompletedIcon from "../../../../../assets/release-registry-icons/completed.svg"
//@ts-ignore
import IncompleteIcon from "../../../../../assets/release-registry-icons/incomplete.svg"
//@ts-ignore
import DeleteBinIcon from "../../../../../assets/release-registry-icons/delete-bin-wide.svg"
//@ts-ignore
import AddIcon from "../../../../../assets/release-registry-icons/add.svg"
//@ts-ignore
import AvatarIcon from "../../../../../assets/release-registry-icons/avatar.svg"
import { ReleaseRegistryPages, ReleaseRegistryUploadTracksTabs } from "../../../../../actions/types"
import { useOrchestrator } from "../ReleaseRegistryContextProvider"
import { LCReleaseFormData } from "../../../../../types"
import { DragDropContext, Droppable, Draggable, DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import ArtistInitials from "../../../../ui/ArtistInitials"
import { checkMissingTrackFields } from "../../../../../utility"
import AudioPlayer from "../../track-detail/AudioPlayer"
import Loading from "../../../../ui/Loading"

const Upload = ({
  setSelectedTab,
  saveTrackDetail
}: {
  setSelectedTab: (tab: ReleaseRegistryUploadTracksTabs) => void
  saveTrackDetail: (selectedTab: boolean) => void
}) => {
  const {
    releaseForm,
    setReleaseForm,
    loading,
    createTrack,
    onSelectTrack,
    deleteTracks,
    setSelectedNavigationTab,
    setLoading,
    saveTrackPrimitive
  } = useOrchestrator()

  const [playingAudioRefs, setPlayingAudioRefs] = useState<HTMLAudioElement[]>([])
  const [tracksList, setTracksList] = useState(releaseForm.tracks)
  const listContainerRef = useRef<HTMLDivElement>(null)
  const [anyMissingInTracks, setAnyMissingInTracks] = useState<boolean>(false)

  useEffect(() => {
    if (listContainerRef.current) {
      listContainerRef.current.scrollTop = listContainerRef.current.scrollHeight
    }
  }, [tracksList])

  useEffect(() => {
    setTracksList(releaseForm.tracks)

    if (releaseForm?.tracks && releaseForm.tracks.length > 0) {
      const hasMissingFields = releaseForm.tracks.some((track) => {
        const missingFields = checkMissingTrackFields(track)
        return missingFields && missingFields.length > 0
      })
      setAnyMissingInTracks(hasMissingFields)
    }
  }, [releaseForm.tracks])

  const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const onDragEnd = async (result) => {
    if (!result.destination) return
    const reorderedTracks = Array.from(tracksList)
    const [movedTrack] = reorderedTracks.splice(result.source.index, 1)
    reorderedTracks.splice(result.destination.index, 0, movedTrack)

    setLoading(true)
    let trackNumber = 1
    for (const track of reorderedTracks) {
      track.data.attributes["track-number"] = trackNumber
      saveTrackPrimitive(track)
      await delay(2000) // Wait for 2 seconds
      trackNumber++
    }

    setLoading(false)

    setReleaseForm({ ...releaseForm, tracks: reorderedTracks })
  }

  const onTrackSelection = async (track) => {
    await onSelectTrack(track)
    setSelectedTab(ReleaseRegistryUploadTracksTabs.TRACK_DETAILS)
  }

  const renderTracks = (track: LCReleaseFormData, index: number, dragHandleProps: DraggableProvidedDragHandleProps) => {
    const songName: string = track.data?.attributes["title"] || `Empty Track Title`
    const artists: string[] = track.data?.attributes["artist"] || []
    const missingFields: string[] | undefined = checkMissingTrackFields(track)
    const missingFieldNumber = missingFields ? missingFields.length : 0

    return (
      <div
        key={`${index}`}
        className="flex flex-row gap-1.5 items-center justify-between rounded-[6px] p-1.5"
        style={{
          border: missingFieldNumber > 0 ? "1px solid #E52B2B" : "none"
        }}
      >
        {loading && <Loading />}
        <div className="flex flex-row gap-1.5 items-center rounded-[10px]">
          <img
            src={DraggableIcon}
            style={{
              height: "32px",
              width: "32px",
              padding: "6px",
              background: "#FFF",
              rounded: "10px"
            }}
            className="hover:bg-[#F0F0F0]"
            {...dragHandleProps}
          />
        </div>
        <div className="flex flex-row gap-4 items-center grow">
          <div className=" w-8 h-8 flex justify-center items-center cursor-pointer">
            <AudioPlayer
              src={track.data.attributes["preview-url"]}
              playingAudioRefs={playingAudioRefs}
              setPlayingAudioRefs={setPlayingAudioRefs}
            />
          </div>
          {/*<KButton
            height="32px"
            width="32px"
            padding="8px"
            background="#FFF"
            icon={isPlaying && playingTrackIndex === index ? PauseIcon : PlayIcon}
            onClick={() => {
              handleAudio(track)
              setPlayingTrackIndex(index)
            }}
            disabled={!track?.data?.attributes["preview-url"]}
            shadowDisabled
          />*/}
          {/*
            <audio ref={audioRef} controls style={{ display: "none" }}>
              <source src={track?.data?.attributes["preview-url"]} type="audio/mp3" />
            </audio>
          */}
          <div className="flex flex-row gap-2 items-center">
            <KSpan text={songName} color="#111" />
            <span className="">
              <KTooltip
                position="right"
                padding="0px"
                content={
                  <div className="flex flex-col gap-2 justify-center items-start px-4 py-2">
                    <div
                      className="flex flex-row gap-1 whitespace-nowrap items-center"
                      style={{
                        opacity: track?.data.attributes?.audio16bits ? "1" : "0.3"
                      }}
                    >
                      <span className="bg-[#21A494] px-0.5 py-1 flex items-center justify-center w-9 rounded-[3px]">
                        <KSpan text="16-Bit" fontSize={10} lineHeight="10px" fontWeight={600} color="#FFF" />
                      </span>
                      {track?.data.attributes?.audio16bits ? (
                        <img src={CompletedIcon} />
                      ) : (
                        <KSpan text="No File" fontSize={12} lineHeight="12px" color="#1F1F1F" fontWeight={600}></KSpan>
                      )}
                    </div>
                    <div
                      className="flex flex-row gap-1 whitespace-nowrap items-center"
                      style={{
                        opacity: track?.data.attributes?.audio24bits ? "1" : "0.3"
                      }}
                    >
                      <span className="bg-[#BBCC13] px-0.5 py-1 flex items-center justify-center w-9 rounded-[3px]">
                        <KSpan text="24-Bit" fontSize={10} lineHeight="10px" fontWeight={600} color="#FFF" />
                      </span>
                      {track?.data.attributes?.audio24bits ? (
                        <img src={CompletedIcon} />
                      ) : (
                        <KSpan text="No File" fontSize={12} lineHeight="12px" color="#1F1F1F" fontWeight={600}></KSpan>
                      )}
                    </div>
                    <div
                      className="flex flex-row gap-1 whitespace-nowrap items-center"
                      style={{
                        opacity: track?.data.attributes?.["audio-dolby-atmos"] ? "1" : "0.3"
                      }}
                    >
                      <span className="bg-[#30272C] px-0.5 py-1 flex items-center justify-center w-9 rounded-[3px]">
                        <KSpan text="Dolby" fontSize={10} lineHeight="10px" fontWeight={600} color="#FFF" />
                      </span>
                      {track?.data.attributes?.["audio-dolby-atmos"] ? (
                        <img src={CompletedIcon} />
                      ) : (
                        <KSpan text="No File" fontSize={12} lineHeight="12px" color="#1F1F1F" fontWeight={600}></KSpan>
                      )}
                    </div>
                  </div>
                }
              >
                {" "}
                <img src={InfoCustomIcon} alt="info-icon" />
              </KTooltip>
            </span>
          </div>
        </div>
        <div className="flex flex-row gap-8 items-center ">
          <ArtistInitials artists={artists} />
          <div className="flex flex-row gap-1 justify-center items-center">
            {missingFieldNumber > 0 ? (
              <KTooltip
                position="left"
                content={
                  <div className="flex items-center justify-center w-[200px] ">
                    <KSpan text={missingFields?.join(", ") || ""} fontSize={11} fontWeight={500} color="#1F1F1F" />
                  </div>
                }
              >
                <img src={IncompleteIcon} alt="incomplete-icon" />
              </KTooltip>
            ) : (
              <img src={CompletedIcon} alt="completed-icon" />
            )}
            <span className="flex flex-row items-center pt-0.5">
              <KSpan
                text={missingFieldNumber > 0 ? "Incomplete" : "Completed"}
                color="#3D3D3D"
                lineHeight="12px"
                fontSize={12}
                fontWeight={500}
              />
            </span>
          </div>
          <div className="flex flex-row gap-1.5">
            <KButton
              onClick={() => {
                onTrackSelection(track)
              }}
              icon={EditIcon}
              height="32px"
              width="32px"
              padding="6px"
              background="#FFF"
              shadowDisabled
              hoverBackground="#E7E7E7"
            />
            <KButton
              onClick={() => {
                if (track.data.id) {
                  deleteTracks(track.data.id, track.data.attributes["isrc-code"])
                }
              }}
              icon={DeleteBinIcon}
              height="32px"
              width="32px"
              padding="6px"
              background="#FFF"
              hoverBackground="#F0F0F0"
              shadowDisabled
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-[616px] max-sm:w-auto flex flex-col gap-1">
      <div className="flex flex-col gap-3 justify-start">
        <div className="flex flex-row gap-4 items-center">
          <div>
            <KButton
              icon={ArrowLeftIcon}
              onClick={() => {
                setSelectedNavigationTab(ReleaseRegistryPages.RELEASE)
              }}
              width="36px"
              height="36px"
              padding="8px"
              background="#F0F0F0"
              shadowDisabled
              hoverBackground="#E7E7E7"
            />
          </div>
          <KTitleSpan text="Tracks" fontSize={40} lineHeight="48px" letterSpacing="-0.4px" color="#1F1F1F" />
        </div>
        <KSpan text="Your Spotify artist name uniquely identifies you on the platform." />
      </div>
      <div className="flex flex-col gap-2">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="tracksList">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`flex flex-col gap-3 max-h-[45vh] overflow-auto ${tracksList.length > 0 ? "py-10" : "py-5"}`}
              >
                {tracksList.map((track, i) => (
                  <Draggable key={track.data.id} draggableId={track.data?.id?.toString()} index={i}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} className="flex flex-col">
                        {renderTracks(track, i, provided.dragHandleProps)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div>
          <KButton
            text="Add tracks"
            onClick={() => {
              createTrack()
            }}
            leftIcon={AddIcon}
            padding="8px"
            shadowDisabled={true}
            width="120px"
            background="#FFF"
            hoverBackground="#E7E7E7"
          />
        </div>
      </div>
      <div>
        <KButton
          text="Continue"
          onClick={() => {
            setSelectedNavigationTab(ReleaseRegistryPages.SELECT_TRACKS)
          }}
          background="#000"
          textColor="#FFF"
          disabled={anyMissingInTracks || tracksList.length === 0}
        />
      </div>
    </div>
  )
}

export default Upload
