import { ChangePasswordFormValues, PaymentMethod, User } from "../types"
import { Action, Dispatch } from "redux"
import { PAYMENT_METHODS_ACTION, SECURITY_ACTION } from "./types"
import { handleAuthorizedRequest, me, paymentMethods, users } from "../apis"
import { mapListToCamelCase, mapToCamelCase, mapToSnakeCase } from "../utility"
import { toast } from "react-toastify"
import { successToastOptions } from "../constants"
import { AUTH_ACTION } from "./loginActions"

export const changePassword = (formValues: ChangePasswordFormValues) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await users("/password/", "put", mapToSnakeCase(formValues))
      toast.success("Password successfully changed.", successToastOptions)

      dispatch({
        type: SECURITY_ACTION.CHANGE_PASSWORD,
        dispatch: null
      })
    },
    "Error on change password.",
    dispatch
  )
}

export const fetchUserData = () => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await me("/", "get")
      localStorage.setItem("user", JSON.stringify(mapToCamelCase(response.data)))
      dispatch({
        type: AUTH_ACTION.UPDATE_USER,
        payload: mapToCamelCase(response.data)
      })
    },
    "Fetching user has error.",
    dispatch
  )
}

export const saveUserData = (user: User) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await users(`/${user.id}/`, "put", mapToSnakeCase(user))
      dispatch({
        type: AUTH_ACTION.UPDATE_USER,
        payload: user
      })
      toast.success("User data saved.", successToastOptions)
    },
    "Saving user has error: ",
    dispatch
  )
}

export const saveUserProfilePhoto = (photo: FormData) => async (dispatch: Dispatch<Action>) => {
  return await handleAuthorizedRequest(
    async () => {
      const response = await users(`/upload_photo/`, "put", photo)

      dispatch({
        type: AUTH_ACTION.UPLOAD_PROFILE_PHOTO,
        payload: response.data.photo
      })
      toast.success("Profile picture uploaded successfully.", successToastOptions)
    },
    "Error uploading profile picture: ",
    dispatch
  )
}

export const fetchPaymentMethods = (userId?: number) => async (dispatch: Dispatch<Action>) => {
  const url = userId ? `/?user_id=${userId}` : "/"
  await handleAuthorizedRequest(
    async () => {
      const response = await paymentMethods(url, "get")
      dispatch({
        type: PAYMENT_METHODS_ACTION.FETCH_PAYMENT_METHODS,
        payload: mapListToCamelCase(response.data.results)
      })
    },
    "Error fetching payment methods: ",
    dispatch
  )
}

export const createPaymentMethod = (body: PaymentMethod, userId?: number) => async (dispatch: Dispatch<Action>) => {
  const url = userId ? `/?user_id=${userId}` : "/"
  await handleAuthorizedRequest(
    async () => {
      const response = await paymentMethods(url, "post", mapToSnakeCase(body))
      dispatch({
        type: PAYMENT_METHODS_ACTION.ADD_PAYMENT_METHOD,
        payload: mapToCamelCase(response.data)
      })
      toast.success("Payment method created.", successToastOptions)
    },
    "Error creating payment method: ",
    dispatch
  )
}

export const deletePaymentMethod = (id: number) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await paymentMethods(`/${id}/`, "delete")
      if (response.status === 204) {
        dispatch({
          type: PAYMENT_METHODS_ACTION.DELETE_PAYMENT_METHOD,
          payload: id
        })
        toast.success("Payment method deleted.", successToastOptions)
      }
    },
    "Error deleting payment method: ",
    dispatch
  )
}

export const updatePaymentMethod = (formValues: PaymentMethod) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await paymentMethods(`/${formValues.id}/`, "put", mapToSnakeCase(formValues))
      dispatch({
        type: PAYMENT_METHODS_ACTION.UPDATE_PAYMENT_METHOD,
        payload: mapToCamelCase(response.data)
      })
      toast.success("Payment method updated.", successToastOptions)
    },
    "Error updating payment method: ",
    dispatch
  )
}
