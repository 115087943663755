import { Action, AnalysisState } from "../types"
import { ANALYSIS_ACTION } from "../actions/analysisActions"

export default (state = new AnalysisState(), action: Action): AnalysisState => {
  switch (action.type) {
    case ANALYSIS_ACTION.FETCH_TOTALS:
      return { ...state, totals: action.payload, selectedDate: action.payload.slice(-1).pop()?.date }
    case ANALYSIS_ACTION.SELECT_DATE:
      return { ...state, selectedDate: action.payload }
    case ANALYSIS_ACTION.FETCH_SUMMARY:
      return { ...state, summaries: action.payload }
    case ANALYSIS_ACTION.SELECT_FILTER:
      return { ...state, selectedFilter: action.payload }
    case ANALYSIS_ACTION.FETCH_TOP_SONGS:
      return { ...state, topSongs: action.payload }
    case ANALYSIS_ACTION.FETCH_FILTER_OPTIONS:
        return { ...state, filterOptions: action.payload }
    case ANALYSIS_ACTION.FETCH_ALL_SUMMARY:
        return { ...state, allSummaries: action.payload }
    default:
      return state
  }
}
