import * as React from "react"
import { Field, Form } from "react-final-form"
import { renderInput } from "../../../ui/form/Input"
import { X } from "heroicons-react"
import Button from "../../../ui/form/Button"
import { connect } from "react-redux"
import { RootState } from "../../../../store"
import { FC, useEffect, useState } from "react"
import {
  AdminTrackFormProps,
  MainOwnerShareData,
  SelectOption,
  TooltipProps,
  Track,
  UserTrackShareForm
} from "../../../../types"
import { saveTrackShareForm, selectTrackShareData, selectUser } from "../../../../actions/adminActions"
import { roleOptions, ROLES, tableCellClasses } from "../../../../constants"
import adminTrackValidator from "./adminTrackValidator"
import { renderSearchSelect } from "../../../ui/form/select/SearchSelect"
import { handleAuthorizedRequest, releases } from "../../../../apis"
import { uniqBy } from "lodash"
import { mapListToCamelCase } from "../../../../utility"
import { renderToggle } from "../../../ui/form/Toggle"
import Tooltip from "../../../ui/Tooltip"

const AdminTrackForm: FC<AdminTrackFormProps> = (props) => {
  const [trackOptions, setTrackOptions] = useState<SelectOption[]>([])
  const [trackLoading, setTrackLoading] = useState(false)
  const [searchedTracks, setSearchedTracks] = useState<Track[]>([])
  const [timeoutId, setTimeoutId] = useState(null)

  const getTrackById = (trackId: number) => {
    if (!trackId || !props.shareData) return null
    if (trackId === props.shareData.track) {
      return props.shareData.trackDetail
    }
    return searchedTracks.find((trk) => trk.id === trackId)
  }

  const onFormSubmit = async (values: UserTrackShareForm) => {
    props.creatingRef.current = true
    props.saveTrackShareForm({
      ...values,
      user: props.user?.id
    })
  }

  const createTrackShareFormFromData = (): UserTrackShareForm => {
    if (!props.shareData || !props.user) return new UserTrackShareForm()
    const mainOwner = props.shareData.roleList?.some((role) => role.role === ROLES.MAIN_OWNER)
    const share = props.shareData.share
    return {
      id: props.shareData.id,
      user: props.user.id,
      track: props.shareData.track,
      title: `${props.user.username} - ${props.shareData.trackDetail?.title}`,
      roles: props.shareData.roleList?.filter((role) => role.role !== 10).map((role) => role.role),
      mainOwner,
      share
    }
  }

  useEffect(() => {
    if (props.shareData?.trackDetail.id) {
      setTrackOptions(
        uniqBy(
          [...trackOptions, { value: props.shareData.track, label: getTrackSelectLabel(props.shareData.trackDetail) }],
          "value"
        )
      )
    }
  }, [props.shareData])

  const onClickCross = () => {
    props.selectTrackShareData(null)
  }

  const getTrackSelectLabel = (track: Track) => (
    <div>
      {track.title}
      &nbsp;
      <span className="text-xs leading 5 font-normal text-gray-500">
        {`${track.isrc}${track.artistsList?.length > 0 ? ", " : ""}${track.artistsList.map(
          (artist, i) => `${artist.name}${i === track.artistsList.length - 1 ? "" : ", "}`
        )}`}
      </span>
    </div>
  )

  const onSearchTextChanged = async (event) => {
    setTrackOptions([])
    setTrackLoading(true)
    clearTimeout(timeoutId)
    setTimeoutId(
      setTimeout(async () => {
        await handleAuthorizedRequest(async () => {
          const response = await releases(`/track/?search=${event.target.value}&limit=10`, "get")
          const tracks = mapListToCamelCase(response.data.results)
          setSearchedTracks(tracks)
          setTrackOptions(
            tracks.map((track) => ({
              value: track.id,
              label: getTrackSelectLabel(track)
            }))
          )
          setTrackLoading(false)
        }, "")
      }, 500)
    )
  }

  const renderToggleLabel = (text: string) => {
    return <span className="text-sm text-gray-700 mt-1">{text}</span>
  }

  const getTooltipContent = (mainOwnerShare: MainOwnerShareData): TooltipProps => {
    if (!mainOwnerShare) return { content: null }
    if (mainOwnerShare.user === "0") {
      return {
        content: `There is no main owner assigned to this track. Current main owner share is ${mainOwnerShare.share}%.`,
        type: "info"
      }
    }
    return {
      content: `Main owner of this track is ${mainOwnerShare.user} and their share is ${mainOwnerShare.share}%`,
      type: "warn"
    }
  }

  if (props.hidden) return null

  return (
    <div className="h-[401px] mt-10 bg-white elev-b-sm rounded-lg overflow-hidden">
      <div
        className={`bg-gray-50 text-xs text-gray-500 font-medium 
      uppercase border-b flex justify-between ${tableCellClasses.sm}`}
      >
        <p>Track Share Information</p>
        <X className="h-4 mr-[-6px] cursor-pointer" onClick={onClickCross} />
      </div>

      <Form
        initialValues={createTrackShareFormFromData()}
        onSubmit={onFormSubmit}
        validate={adminTrackValidator}
        render={({ handleSubmit, form, pristine, submitting }) => {
          const track = getTrackById(form.getFieldState("track")?.value)
          let tooltipConfig
          let hasMainOwner = false
          if (track) {
            tooltipConfig = getTooltipContent(track.mainOwnerShare)
            hasMainOwner = track.mainOwnerShare?.user !== "0"
            if (form.getFieldState("mainOwner")?.value) {
              form.change("share", track.mainOwnerShare.share.toString())
            }
          }

          return (
            <form className="w-full max-w-sm m-auto flex-column p-4" onSubmit={handleSubmit}>
              <div className="h-[296px] w-[350px]">
                <div className={"mb-4 relative"}>
                  <Field
                    name="track"
                    render={renderSearchSelect}
                    placeholder="Search track"
                    disabled={submitting}
                    onTextChange={onSearchTextChanged}
                    options={trackOptions}
                    loading={trackLoading}
                  />
                </div>

                <Tooltip place="bottom" {...tooltipConfig}>
                  <div className="h-[42px] flex gap-8">
                    {renderToggleLabel("Main Owner")}
                    <Field
                      name="mainOwner"
                      type="checkbox"
                      render={renderToggle}
                      disabled={submitting || hasMainOwner}
                    />
                  </div>
                </Tooltip>
                <div className={"mb-4 relative"}>
                  <Field
                    name="roles"
                    render={renderSearchSelect}
                    placeholder="Select roles"
                    disabled={submitting}
                    multiple
                    options={roleOptions
                      .filter((opt) => !form.getFieldState("mainOwner")?.value || opt.value !== ROLES.OTHER)
                      .sort()}
                  />
                </div>

                {!form.getFieldState("mainOwner")?.value && (
                  <Field
                    name="share"
                    render={renderInput}
                    placeholder="Enter user share"
                    className={"mb-4"}
                    autoComplete="new-password"
                    disabled={submitting}
                  />
                )}
              </div>
              <Button className="mb-0" text="Save" disabled={submitting || pristine} />
            </form>
          )
        }}
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    shareData: state.admin.selectedTrackShareData,
    user: state.admin.selectedUser
  }
}

export default connect(mapStateToProps, { selectTrackShareData, selectUser, saveTrackShareForm })(AdminTrackForm)
