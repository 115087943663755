import "./styles/Analysis.css"
import React, { FC, useEffect, useState } from "react"
import AnalysisChart from "./AnalysisChart"
import AnalysisTopSongs from "./top-songs/AnalysisTopSongs"
import RevenueCard from "./RevenueCard"
import StreamCard from "./StreamCard"
import AnalysisLineChart from "./AnalysisLineChart"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { fetchAllSummaries, fetchFilterOptions, fetchTotals } from "../../../actions/analysisActions"
import { AnalysisSummary, AnalysisTotal } from "../../../types"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import PlatformChart from "./PlatformChart"
import CountryChart from "./CountryChart"
import { useNavigate } from "react-router-dom"
import { KLogo } from "kahuna-base-react-components"

const Analysis = ({
  allSummaries,
  fetchAllSummaries,
  totals,
  fetchTotals,
  filterOptions,
  fetchFilterOptions
}: {
  allSummaries: AnalysisSummary[]
  fetchAllSummaries: (
    type: string,
    value: string | number,
    affiliate: boolean,
    startDate?: string,
    endDate?: string
  ) => Promise<void>
  totals: AnalysisTotal[]
  fetchTotals: () => Promise<void>
  filterOptions: { [key: string]: KSelectOption[] }
  fetchFilterOptions: () => Promise<void>
}) => {
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  const getTotalsAndAllSummaries = async () => {
    setLoading(true)
    try {
      await fetchTotals()
      await fetchAllSummaries("platform", "all_platforms", false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!filterOptions && fetchFilterOptions) {
      fetchFilterOptions().then(() => {})
    }
    getTotalsAndAllSummaries().then(() => {})
  }, [])

  return (
    <div>
      <div className="grid" style={{ height: "68px" }}>
        <div
          className="flex pl-10 pr-10"
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div style={{ textAlign: "left", display: "inline-block", cursor: "pointer" }} onClick={() => navigate(-1)}>
            <KLogo hoverEnabled={true} width={48} height={48} logoColor={"white"} borderRadius={1} />
          </div>
          <div>
            <span className="powered-by-span">Powered by Kahuna</span>
          </div>
        </div>
      </div>
      <div className="w-[100vw] flex justify-center pb-8">
        <div className="max-w-[1000px] w-[90%]">
          {!loading && (
            <div className="flex flex-col justify-start gap-4">
              {/*<AnalysisTotals />*/}
              <div className="mt-6 gap-4  rounded-[10px] pb-6">
                {<AnalysisChart initialData={allSummaries} />}
                {/* Burası hangi rengin hangi sütunu gösterdigini belirtmek icin kurulmus bir yapı su an ihtiyacimiz olmadigi icin kaldirdim. Ama silmeyecegim, belki daha sonra 
          tekrar lazim olur.
          <AnalysisSummaryList />*/}
              </div>
              <div className="flex flex-row gap-6">
                <div
                  style={{
                    width: "calc(50% - 12px)"
                  }}
                >
                  {<RevenueCard initialData={allSummaries} />}
                </div>
                <div
                  style={{
                    width: "calc(50% - 12px)"
                  }}
                >
                  {<StreamCard initialData={allSummaries} />}
                </div>
              </div>
              <div className="flex w-full">{<AnalysisLineChart initialData={allSummaries} totals={totals} />}</div>
              <div className="flex flex-row gap-6 w-full">
                <div
                  style={{
                    width: "calc(50% - 12px)"
                  }}
                >
                  {<CountryChart initialData={allSummaries} />}
                </div>
                <div
                  style={{
                    width: "calc(50% - 12px)"
                  }}
                >
                  {<PlatformChart initialData={allSummaries} />}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    totals: state.analysis.totals,
    currency: state.auth.user.preferredCurrency,
    user: state.auth.user,
    selectedDate: state.analysis.selectedDate,
    selectedFilter: state.analysis.selectedFilter,
    summaries: state.analysis.summaries,
    filterOptions: state.analysis.filterOptions,
    allSummaries: state.analysis.allSummaries
  }
}

export default connect(mapStateToProps, { fetchAllSummaries, fetchTotals, fetchFilterOptions })(Analysis)
