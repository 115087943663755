import { TableColumn, UserTrackShareData } from "../../../../../types"
import { RELEASE_STATUS, ROLES, TABLE_COLUMN_TYPE } from "../../../../../constants"
import { CSSProperties } from "react"
import { Check, X } from "heroicons-react"

const headerStyle: CSSProperties = { textAlign: "left", width: "25%" }
const cellStyle: CSSProperties = { fontWeight: 400, lineHeight: "20px", color: "#111827" }

const adminTrackColumns: TableColumn[] = [
  {
    header: "Track",
    filter: true,
    sortFilterField: "title",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    headerStyle: { ...headerStyle, width: "360px" },
    cellStyle,
    render: (row: UserTrackShareData) => (
      <div className="flex">
        <div className="mr-4">
          <div className="h-[20px] w-[20px] rounded-[100%] bg-gray-800 overflow-hidden">
            <img src={row.trackDetail.releaseDetail.coverFileUrl} alt={"table_image"} />
          </div>
        </div>
        <div>
          <div className="text-xs leading-5 h-5 font-medium text-gray-900 break-all overflow-hidden">
            {row.trackDetail.title}&nbsp;
            <span className="text-xs leading-5 h-5 font-normal text-gray-500">
              {`${row.trackDetail.artistsList?.length > 0 ? "," : ""}${row.trackDetail.artistsList.map(
                (artist) => ` ${artist.name}`
              )}`}
            </span>
          </div>
        </div>
      </div>
    )
  },
  {
    header: "ISRC",
    filter: true,
    sortFilterField: "track__isrc",
    type: TABLE_COLUMN_TYPE.TEXT,
    headerStyle: { ...headerStyle, width: "15%" },
    cellStyle,
    attribute: "trackDetail.isrc"
  },
  {
    header: "Main Owner",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    headerStyle: { ...headerStyle, width: "12%" },
    cellStyle,
    render: (row: UserTrackShareData) =>
      row.roleList.some((role) => role.role === ROLES.MAIN_OWNER) ? (
        <Check className="text-green-400 h-5 w-5" />
      ) : (
        <X className="text-red-400 h-5 w-5" />
      )
  },
  {
    header: "Roles",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    headerStyle: { ...headerStyle, width: "15%" },
    cellStyle,
    render: (row: UserTrackShareData) => {
      const filteredRoles = row.roleList.filter((role) => role.role !== 10)
      return filteredRoles.map((role, i) => role.roleDetail.name + (i + 1 !== filteredRoles.length ? ", " : ""))
    }
  },
  {
    header: "Share",
    attribute: "share",
    type: TABLE_COLUMN_TYPE.TEXT,
    headerStyle: { ...headerStyle, width: "10%" },
    cellStyle
  },
  {
    header: "Status",
    attribute: "trackDetail.releaseDetail.status",
    type: TABLE_COLUMN_TYPE.CONST,
    constType: RELEASE_STATUS,
    headerStyle: { ...headerStyle, width: "15%" },
    cellStyle
  }
]

export default adminTrackColumns
