import React, { useEffect, useState } from "react"
import { schedulers } from "../../../../apis"
import { Scheduler } from "../../../../types"
import Table from "../../../ui/table/Table"
import schedulerColumns from "./schedulerLogColumns"

const SchedulerLog = () => {
  const [loading, setLoading] = useState(false)
  const [schedulerList, setSchedulerList] = useState<Scheduler[]>([])

  useEffect(() => {
    setLoading(true)
    schedulers(`/latest_reports/`, "get").then((resp) => {
      setSchedulerList(resp.data.results)
      setLoading(false)
    })
  }, [])

  return (
    <div className="catalog flex-grow pt-8">
      <div className="container mt-[12px] mb-16 pl-1">
        <Table
          title="Scheduler Logs"
          pagination
          minRowCount={15}
          limit={20}
          columns={schedulerColumns}
          data={schedulerList}
          count={schedulerList.length}
          rowHeightInPx={40}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default SchedulerLog
