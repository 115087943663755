import "../../login/styles/Login.css"
import React, { FC, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import InvitationLayout from "./InvitationLayout"
import { connect } from "react-redux"
import { InvitationAcceptProps } from "../../../../types"
import { INVITATION_ACCEPT_TITLE, INVITATION_ACCEPT_TEXT, INVITATION_STATUS } from "../../../../constants"
import { acceptInvitation } from "../../../../actions/publicActions"
import { RootState } from "../../../../store"
import LoginLayout from "../../login/LoginLayout"
const InvitationAccept: FC<InvitationAcceptProps> = (props) => {
  const [title, setTitle] = useState("")
  const [text, setText] = useState("")
  const [isLoginButtonVisible, setIsLoginButtonVisible] = useState(false)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get("data")

  useEffect(() => {
    props.acceptInvitation(token).then(() => {})
  }, [])

  useEffect(() => {
    if (!props.invitationStatus) return
    setTitle(INVITATION_ACCEPT_TITLE[props.invitationStatus.status])
    setText(INVITATION_ACCEPT_TEXT[props.invitationStatus.status])
    if (
      props.invitationStatus.status === INVITATION_STATUS.AcceptRegistered ||
      props.invitationStatus.status === INVITATION_STATUS.AlreadyAccepted
    ) {
      setIsLoginButtonVisible(true)
    }
  }, [props.invitationStatus])

  return (
    <LoginLayout>
      <InvitationLayout title={title} text={text} showLoginButton={isLoginButtonVisible}></InvitationLayout>
    </LoginLayout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    invitationStatus: state.public.invitationStatus
  }
}

export default connect(mapStateToProps, { acceptInvitation })(InvitationAccept)
