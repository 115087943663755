import { KButton, KInput, KSpan, KTitleSpan, KTooltip } from "kahuna-base-react-components"
//@ts-ignore
import ArrowLeftIcon from "../../../../../assets/release-registry-icons/arrow-left.svg"
//@ts-ignore
import DeleteBinLighter from "../../../../../assets/release-registry-icons/delete-bin-wide-lighter.svg"
//@ts-ignore
import VectorIcon from "../../../../../assets/release-registry-icons/vector.svg"
//@ts-ignore
import EditIcon from "../../../../../assets/release-registry-icons/edit-lighter.svg"
//@ts-ignore
import CompletedIcon from "../../../../../assets/release-registry-icons/completed.svg"
//@ts-ignore
import IncompleteIcon from "../../../../../assets/release-registry-icons/incomplete.svg"
import React, { useEffect, useRef, useState } from "react"
import { ReleaseRegistryUploadTracksTabs } from "../../../../../actions/types"
import { useOrchestrator } from "../ReleaseRegistryContextProvider"
import { AssetRegistryTrackShareHolder, LCReleaseFormData } from "../../../../../types"
import { releases } from "../../../../../apis"
import TrackShareHolderModal from "../modal/TrackShareHolderModal"
import ArtistInitials from "../../../../ui/ArtistInitials"
import { checkMissingTrackFields } from "../../../../../utility"
import AudioPlayer from "../../track-detail/AudioPlayer"
const TrackShareHolders = ({
  setSelectedTab,
  saveTrackDetail
}: {
  setSelectedTab: (tab: ReleaseRegistryUploadTracksTabs) => void
  saveTrackDetail: (selectedTab: boolean) => void
}) => {
  const {
    trackShareHolderList,
    setTrackShareHolderList,
    selectedTrack,
    includedContributors,
    trackShareHolderRoleOptions,
    isPublishing
  } = useOrchestrator()
  const [selectedTrackShareHolder, setSelectedTrackShareHolder] = useState<AssetRegistryTrackShareHolder | undefined>(
    undefined
  )

  const [missingTrackFields, setMissingTrackFields] = useState<string[]>([])

  const [playingAudioRefs, setPlayingAudioRefs] = useState<HTMLAudioElement[]>([])

  useEffect(() => {
    if (!selectedTrack) {
      return
    }
    const missingFields = checkMissingTrackFields(selectedTrack)
    if (missingFields) {
      setMissingTrackFields(missingFields)
    }
  }, [selectedTrack])

  const onDeleteTrackShareHolder = (existedShareHolder) => {
    if (!existedShareHolder.id) {
      return
    }

    releases(
      `/track-share-holder-invitation/delete_not_published/?id=${existedShareHolder.id}&isrc=${existedShareHolder.isrc}`,
      "delete"
    ).then((resp) => {
      if (resp.data) {
        setTrackShareHolderList(resp.data)
      }
    })
  }

  const confirmTrackShareHolder = () => {
    releases(`/track-share-holder-invitation/save_not_published/`, "post", selectedTrackShareHolder).then((resp) => {
      if (resp.data) {
        setTrackShareHolderList(resp.data)
      }
    })
    setSelectedTrackShareHolder(undefined)
  }

  const renderTrackShareHolders = () => {
    if (!selectedTrack) {
      return
    }
    const mainArtists = selectedTrack?.data?.attributes["artist"] || []
    const mainContributors = includedContributors.flatMap((contributor: string) => {
      return selectedTrack?.data?.attributes[contributor]
    })
    const existedtTrackShareHolderList = trackShareHolderList.map((trackShareHolder) => trackShareHolder.name)
    const shareHolders = [...new Set([...mainArtists, ...mainContributors, ...existedtTrackShareHolderList])]

    return (
      <div className="w-full flex flex-col gap-3">
        {shareHolders.map((shareHolder) => {
          const existedShareHolder = trackShareHolderList.find((tsh) => tsh.name === shareHolder) || {
            name: shareHolder,
            email: "",
            roles: "",
            share: "",
            isrc: selectedTrack?.data?.attributes["isrc-code"]
          }
          return (
            <div key={`share_holder_${shareHolder}`} className="flex flex-row justify-between items-center gap-3">
              <div
                className="w-8 h-8 flex items-center justify-center bg-[#DADEF5] rounded-full"
                style={{
                  boxShadow: "0px -8px 16px 0px rgba(71, 91, 204, 0.24) inset"
                }}
              >
                <KSpan text={shareHolder[0].toLocaleUpperCase()} color="#151B3D" fontWeight={500} />
              </div>
              <div className="grow flex flex-row items-center">
                <div className="w-1/2 flex items-center">
                  <KSpan text={shareHolder} color="#111" />
                </div>
                <div className="w-1/2 flex justify-start">
                  <div className="h-5 rounded-[4px] border-y-[1px] border-[#DADEF5] bg-[#EDEFFA] p-1 flex items-center justify-center">
                    <KSpan
                      text={`${existedShareHolder.share || 0}%`}
                      color="#151B3D"
                      fontSize={11}
                      lineHeight="12px"
                      letterSpacing="0.22px"
                      fontWeight={500}
                    />
                  </div>
                </div>
              </div>

              <KButton
                icon={EditIcon}
                onClick={() => {
                  setSelectedTrackShareHolder(existedShareHolder)
                }}
                width="32px"
                height="32px"
                padding="6px"
                background="#FFF"
                shadowDisabled
                hoverBackground="#F0F0F0"
              />
              <img src={VectorIcon} alt="Vector" />
              <KButton
                icon={DeleteBinLighter}
                onClick={() => {
                  onDeleteTrackShareHolder(existedShareHolder)
                }}
                width="32px"
                height="32px"
                padding="6px"
                background="#FFF"
                shadowDisabled
                hoverBackground="#F0F0F0"
              />
            </div>
          )
        })}
      </div>
    )
  }
  return (
    <div className="w-[616px] max-sm:w-auto flex flex-col gap-6">
      <div className="flex flex-col gap-3 justify-start">
        <div className="flex flex-row gap-4 items-center">
          <div>
            <KButton
              icon={ArrowLeftIcon}
              onClick={() => {
                setSelectedTab(ReleaseRegistryUploadTracksTabs.ARTIST)
              }}
              width="36px"
              height="36px"
              padding="8px"
              background="#F0F0F0"
              shadowDisabled
              hoverBackground="#E7E7E7"
            />
          </div>
          <KTitleSpan
            text="Track Share Holders"
            fontSize={40}
            lineHeight="48px"
            letterSpacing="-0.4px"
            color="#1F1F1F"
          />
        </div>
        <KSpan text="Your Spotify artist name uniquely identifies you on the platform." />
      </div>
      <div className="flex flex-grow flex-col gap-3">
        <div className="grow flex flex-row gap-1.5 border-[1px] border-[#E7E7E7] p-1.5 rounded-[6px] items-center justify-between">
          <div className="w-8 h-8 flex items-center justify-center cursor-pointer">
            <AudioPlayer
              src={selectedTrack?.data?.attributes?.["preview-url"]}
              playingAudioRefs={playingAudioRefs}
              setPlayingAudioRefs={setPlayingAudioRefs}
            />
          </div>
          <div className="flex grow">
            <KInput
              value={selectedTrack?.data?.attributes?.title}
              background="#FFF"
              padding="8px 32px 8px 10px"
              placeholder="Add track name"
              disabled
              boxShadow="none"
              width={300}
              onChange={() => {
                //Do Nothing
              }}
            />
          </div>
          <div className="flex flex-row gap-8 items-center">
            {<ArtistInitials artists={selectedTrack?.data?.attributes["artist"] || []} />}
            <div className="flex flex-row gap-1 pr-2">
              {missingTrackFields.length > 0 ? (
                <KTooltip
                  position="left"
                  content={
                    <div className="flex items-center justify-center w-[200px] ">
                      <KSpan
                        text={missingTrackFields?.join(", ") || ""}
                        fontSize={11}
                        fontWeight={500}
                        color="#1F1F1F"
                      />
                    </div>
                  }
                >
                  <img src={IncompleteIcon} alt="incomplete-icon" />
                </KTooltip>
              ) : (
                <img src={CompletedIcon} alt="completed-icon" />
              )}
              <span className="flex flex-row items-center pt-0.5">
                <KSpan
                  text={missingTrackFields.length > 0 ? "Incomplete" : "Completed"}
                  color="#3D3D3D"
                  fontSize={12}
                  lineHeight="12px"
                  fontWeight={500}
                />
              </span>
            </div>
          </div>
        </div>
        <div style={{ overflowY: "scroll", maxHeight: "30vh" }}>{renderTrackShareHolders()}</div>
      </div>
      <div>
        <KButton
          text="Continue"
          onClick={() => {
            setSelectedTab(
              isPublishing
                ? ReleaseRegistryUploadTracksTabs.PUBLISHING_SHARE_HOLDERS
                : ReleaseRegistryUploadTracksTabs.CONTRIBUTOR_CONSENT_FILES
            )
          }}
          background="#000"
          textColor="#FFF"
        />
      </div>
      <div className="flex flex-row justify-center">
        <KButton
          text="Save and Return to Tracks"
          hoverBackground="#E7E7E7"
          onClick={() => {
            saveTrackDetail(false)
          }}
          background="#FFF"
          shadowDisabled
        />
      </div>
      <TrackShareHolderModal
        selectedTrackShareHolder={selectedTrackShareHolder}
        setSelectedTrackShareHolder={setSelectedTrackShareHolder}
        handleConfirm={() => confirmTrackShareHolder()}
        trackShareHolderRoleOptions={trackShareHolderRoleOptions}
      />
    </div>
  )
}
export default TrackShareHolders
