import "./styles/Admin.css"
import React, { FC } from "react"
import AdminUser from "./user/AdminUser"
import AdminAsset from "./asset/AdminAsset"

const AdminUserAsset: FC = () => {
  return (
    <div className="admin flex flex-col">
      <AdminUser />
      <AdminAsset />
    </div>
  )
}

export default AdminUserAsset
