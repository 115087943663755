import Header from "../../ui/header/Header"
import React, { FC } from "react"
import { MasterTemplateProps } from "../../../types"
import Navigation from "../navigation/Navigation"
import { useLocation } from "react-router-dom"
import "../../styles/App.css"
import ChatAssistant from "../chat-assistant/ChatAssistant"

const MasterTemplate: FC<MasterTemplateProps> = (props) => {
  const location = useLocation()
  const path = location.pathname
  const segments = path.split("/").filter(Boolean)

  const checkFullPage = () => {
    return (
      path.includes("login") ||
      path.includes("forgot-password") ||
      path.includes("asset-registry") ||
      path.includes("release-registry") ||
      path.includes("smart-link") ||
      path.includes("invitation") ||
      path.includes("artist-space") ||
      path.includes("artist") ||
      path.includes("analytics")
    )
  }

  const checkHomePage = () => {
    return (
      !path.includes("account") && (!path.includes("catalog/") || (path.includes("catalog") && segments.length === 1))
    )
  }

  const renderContainer = () => {
    const width = checkFullPage() ? "100%" : checkHomePage() ? "80%" : "68%"

    const margin = width === "80%" ? 72 : 0

    return <div style={{ marginLeft: margin, marginRight: margin, width, overflowY: "scroll" }}>{props.children}</div>
  }

  const renderNavigation = () => {
    const width = checkHomePage() ? "20%" : "16%"
    return <div style={{ width, height: "calc(100vh - 64px)" }}>{checkHomePage() && <Navigation />}</div>
  }

  return (
    <>
      {!checkFullPage() && <Header />}
      <div className={"flex"} style={{ width: "100%", overflow: "hidden" }}>
        {!checkFullPage() && renderNavigation()}
        {renderContainer()}
        {<ChatAssistant />}
      </div>
    </>
  )
}

export default MasterTemplate