import { Fragment, FC, useState, useRef, useEffect } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { SelectProps } from "../../../../types"
import * as React from "react"
import { Check, ChevronDown } from "heroicons-react"
import SelectOptionSkeleton from "./SelectOptionSkeleton"

export const Select: FC<SelectProps> = (props) => {
  const [open, setOpen] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  useEffect(() => {
    if (props.loading) {
      setOpen(true)
    }
  }, [props.loading])

  const onChange = (value: any) => {
    if (props.onSelect) props.onSelect(value)
    setOpen(false)
  }

  const renderInput = () => {
    return (
      <Listbox.Button
        className={`relative w-full cursor-default rounded-md
          border border-gray-300 bg-white py-2 px-4 text-left
          shadow-sm text-base ${props.buttonClass}`}
      >
        <div onClick={() => setOpen(!open)} className={`flex justify-between ${props.inputTextClass}`}>
          <span>
            {props.value ? props.options.find((option) => option.value === props.value)?.label : props.placeholder}
          </span>
          <span className="pointer-events-none">
            <ChevronDown />
          </span>
        </div>
      </Listbox.Button>
    )
  }

  const renderOptions = () => {
    const inputPosition = ref.current?.getBoundingClientRect()

    return (
      <Listbox value={props.value} onChange={onChange}>
        <div className={`${props.className}`} ref={ref}>
          {renderInput()}
          {open && (props.loading || props.options.length > 0) && (
            <Transition
              show
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`absolute z-10 mt-1 max-h-[216px] w-auto overflow-auto
                rounded-md bg-white text-base shadow-lg ring-1 ring-black
                ring-opacity-5 focus:outline-none border ${props.className}`}
                style={{
                  width: inputPosition?.width
                }}
              >
                <>
                  {props.loading && (
                    <>
                      <Listbox.Option
                        key={`search-select-option-${Math.random()}`}
                        className="relative cursor-default select-none py-2 px-3"
                        value={null}
                        disabled={true}
                      >
                        <SelectOptionSkeleton />
                      </Listbox.Option>
                      <Listbox.Option
                        key={`search-select-option-${Math.random()}`}
                        className="relative cursor-default select-none py-2 px-3"
                        value={null}
                        disabled={true}
                      >
                        <SelectOptionSkeleton />
                      </Listbox.Option>
                      <Listbox.Option
                        key={`search-select-option-${Math.random()}`}
                        className="relative cursor-default select-none py-2 px-3"
                        value={null}
                        disabled={true}
                      >
                        <SelectOptionSkeleton />
                      </Listbox.Option>
                      <Listbox.Option
                        key={`search-select-option-${Math.random()}`}
                        className="relative cursor-default select-none py-2 px-3"
                        value={null}
                        disabled={true}
                      >
                        <SelectOptionSkeleton />
                      </Listbox.Option>
                    </>
                  )}
                  {props.options.map((option) => (
                    <Listbox.Option
                      key={`search-select-option-${Math.random()}`}
                      className="relative cursor-default select-none py-2 px-4
                      hover:bg-zinc-100 text-gray-900"
                      value={option.value}
                    >
                      <>
                        <div className={`flex items-center ${props.inputTextClass}`}>
                        <span
                          className={`${props.value === option.value ? "font-semibold" : "font-normal"} block truncate`}
                        >
                          {option.label}
                        </span>
                        </div>

                        {props.value === option.value && (
                          <span className="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                          <Check />
                        </span>
                        )}
                      </>
                    </Listbox.Option>
                  ))}
                </>
              </Listbox.Options>
            </Transition>
          )}
        </div>
      </Listbox>
    )
  }

  return renderOptions()
}
