import React, { FC, useState, useEffect } from "react"
import { LCReleaseForm, TerritoryDsp } from "../../../../../types"
import "../../styles/Catalog.css"
import Button from "../../../../ui/form/Button"
import { releases } from "../../../../../apis"
import { KButton } from "kahuna-base-react-components"
import { AssetRegistryPages } from "../../../../../actions/types"
import { mapListToCamelCase } from "../../../../../utility"
import { state51GenreMapping } from "../../../../../constants"
//@ts-ignore
import SpotifyLogo from "../../../../../assets/release-platform-icons/SpotifyLogo.png"

interface TerritoryAndPlatformRightsProps {
  options: any
  releaseForm: LCReleaseForm
  setReleaseForm: (releaseForm: LCReleaseForm) => void
  setSelectedTab: (selectedTab: number) => void
  setErrorText: (errorText?: string) => void
}

interface Dsps {
  name: string
  image_url: string
}

interface ComputedRight {
  name: string
  dsps: Dsps[]
}

const notAllowedProductRelationships = ["company", "default-offer", "distributor", "offers", "performers", "tracks"]

const TerritoryAndPlatformRights: FC<TerritoryAndPlatformRightsProps> = (props) => {
  const { releaseForm, setReleaseForm, setSelectedTab, options } = props

  const [loading, setLoading] = useState(false)
  const [dspList, setDspList] = useState<TerritoryDsp[]>([])
  const [distributedRights, setDistributedRights] = useState([])
  const [notDistributedRights, setNotDistributedRights] = useState([])

  const [requestChange, setRequestChange] = useState(
    releaseForm.product.data.attributes["label-rights-message"] || false
  )

  useEffect(() => {
    try {
      releases(
        `/release/label_camp_product_computed_rights/?product_id=${releaseForm.product.data.id}&label_id=${releaseForm.product.data.relationships.label.data.id}`,
        "get"
      ).then((resp) => {
        if (resp && resp["data"] && resp["data"].length > 0) {
          const rights = resp["data"][0]["rights"]
          setNotDistributedRights(rights.filter((right) => right["name"] === "Not distributed"))
          setDistributedRights(rights.filter((right) => right["name"] !== "Not distributed"))
        }
      })

      releases(`/release/asset_registry_dsp_list/?limit=${50}`, "get").then((resp) => {
        setDspList(mapListToCamelCase(resp.data.results))
      })
    } catch (e) {
      console.error("Error Getting Territory & Platform Rights")
      props.setErrorText(e)
    }
  }, [])

  const updateProduct = (attributeName: string, value: any) => {
    setReleaseForm({
      ...releaseForm,
      product: {
        ...releaseForm.product,
        data: {
          ...releaseForm.product.data,
          attributes: {
            ...releaseForm.product.data.attributes,
            [attributeName]: value
          }
        }
      }
    })
  }

  const saveForm = () => {
    setLoading(true)
    const productRelationships = {}
    Object.entries(releaseForm.product.data.relationships).forEach(([key, value]) => {
      if (value["data"] && !notAllowedProductRelationships.includes(key)) {
        productRelationships[key] = { data: value["data"] }
      }
    })
    const productAttributes = {
      "are-rights-confirmed": true,
      "label-rights-message": releaseForm.product.data.attributes["label-rights-message"]
    }

    const filteredReleaseForm = {
      data: {
        id: releaseForm.product.data.id,
        attributes: productAttributes,
        relationships: productRelationships,
        type: "products"
      }
    }
    try {
      releases(`/release/label_camp_product_update/`, "post", { ...filteredReleaseForm }).then((resp) => {
        setLoading(false)
        if (!resp.data.success) {
          props.setErrorText(`${JSON.stringify(resp.data.result)}`)
          return
        }
        setReleaseForm({
          ...releaseForm,
          product: {
            ...releaseForm.product,
            data: {
              ...releaseForm.product.data,
              attributes: {
                ...releaseForm.product.data.attributes,
                "are-rights-confirmed": true
              }
            }
          }
        })
        setSelectedTab(AssetRegistryPages.EDITORIAL_PITCHING)
      })
    } catch (e) {
      setLoading(false)
      console.error("Error saving product")
      props.setErrorText(e)
    }
  }

  const renderPlatforms = (platformList: Dsps[], territoryName: string) => {
    const releaseGenre = options.distributorProductSubGenreOptions.find(
      (subgenre) => subgenre.value === releaseForm.offer.data?.relationships["subgenre"]?.data?.id
    )?.label
    let releaseGenreMapped = ""
    if (releaseGenre && Object.keys(state51GenreMapping).includes(releaseGenre)) {
      releaseGenreMapped = state51GenreMapping[releaseGenre]
    }

    let allPlatformList: Dsps[] = [...platformList]
    if (territoryName === "Worldwide") {
      const processedDspList: Dsps[] = dspList
        .filter(
          (dsp) => dsp.label !== "Beatport" || releaseGenreMapped === "Electronic" || releaseGenreMapped === "Dance"
        )
        .map((dsp) => {
          return {
            name: dsp.label,
            image_url: dsp.image
          }
        })
      allPlatformList = allPlatformList.concat(processedDspList).sort((a, b) => a.name.localeCompare(b.name))
    }
    return (
      <div
        className="grid"
        style={{ gridTemplateColumns: "repeat(auto-fit, minmax(calc(20% - 16px), calc(20% - 16px)))", gridGap: 16 }}
      >
        {allPlatformList.map((platform) => {
          return platform.name === "Spotify" ? (
            <div>
              <img style={{ width: 105 }} src={SpotifyLogo} />
            </div>
          ) : (
            <div className="flex items-center">
              <img src={platform["image_url"]} style={{ width: 32 }} />
              <span className="text-sm asset-registry-span ml-2">{platform["name"]}</span>
            </div>
          )
        })}
      </div>
    )
  }

  const renderComputedRights = (territoriesIncluded: boolean, computedRights: ComputedRight[]) => {
    if (computedRights.length === 0) {
      return <div />
    }

    if (!territoriesIncluded) {
      return (
        <div style={{ border: "1px solid rgb(230, 230, 230)", padding: 24 }}>
          {renderPlatforms(computedRights[0]["dsps"], "")}
        </div>
      )
    }

    return (
      <div style={{ padding: 24 }}>
        {computedRights.map((computedRight, index) => {
          return (
            <div className={`flex ${index !== 0 && "mt-10"}`} style={{ width: "100%" }}>
              <div style={{ width: "24%", borderRight: "1px solid rgb(235, 235, 235)" }}>
                <p className={"text-sm asset-registry-span"}>{computedRight.name}</p>
              </div>
              <div style={{ width: "1%" }} />
              <div style={{ width: "75%" }}>{renderPlatforms(computedRight.dsps, computedRight.name)}</div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div>
      <div className={"flex"}>
        <div style={{ width: "100%" }}>
          <div className={"m-4 bg-white"}>
            <div className={"p-4"}>
              <p className={"font-bold text-md asset-registry-title"}>Distributed Territories and Platforms</p>
              <div className="block mt-2" style={{ width: "100%", border: "1px solid rgb(230, 230, 230)" }}>
                <div className="flex pl-6 pr-6 pt-2 pb-2" style={{ width: "100%", background: "rgb(242, 242, 242)" }}>
                  <div style={{ width: "25%" }} className="flex items-center">
                    <img src="/catalog_icons/earth-line.svg" className="mr-2" style={{ width: 32, height: 32 }} />
                    <p className={"text-md asset-registry-span"}>Territories</p>
                  </div>
                  <div style={{ width: "75%" }} className="flex items-center">
                    <img src="/catalog_icons/layout-grid-line.svg" className="mr-2" style={{ width: 32, height: 32 }} />
                    <p className={"text-md asset-registry-span"}>Platforms</p>
                  </div>
                </div>
                {renderComputedRights(true, distributedRights)}
              </div>

              {
                // <p className={"font-bold text-md asset-registry-title mt-6 mb-2"}>Not Distributed</p>
                // {renderComputedRights(false, notDistributedRights)}
              }

              <p className={"font-bold text-md asset-registry-title mt-6"}>Confirm Your Album Rights</p>
              <p className={"text-sm mt-3 asset-registry-span"}>Do the above rights apply to your album?</p>
              <div className={"flex mt-3"}>
                <input
                  className={"cursor-pointer"}
                  type="radio"
                  checked={!requestChange}
                  onClick={() => {
                    setRequestChange(false)
                    updateProduct("label-rights-message", null)
                  }}
                />
                <span className={"text-sm ml-2 asset-registry-span"}>Yes, They do.</span>

                <input
                  className={"cursor-pointer ml-6"}
                  type="radio"
                  checked={requestChange}
                  onClick={() => setRequestChange(true)}
                />
                <span className={"text-sm ml-2 asset-registry-span"}>No, I'd like to request a change</span>
              </div>
              {requestChange && (
                <textarea
                  className={`border rounded p-3 mt-3 mb-2 w-[100%]`}
                  placeholder={
                    "Please describe the requested change in the most accurate way possible.\n" +
                    "Examples: WW - UK; WW except UK, Spotify only; all platforms except Spotify"
                  }
                  rows={5}
                  value={releaseForm.product.data.attributes["label-rights-message"]}
                  onChange={(event) => updateProduct("label-rights-message", event.target.value)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={"justify-end flex"} style={{ marginBottom: 40, marginRight: 40 }}>
        <KButton
          width="400px"
          text="Save & Go To Next Step"
          disabled={loading}
          onClick={() => saveForm()}
          background="black"
          textColor="white"
        />
      </div>
    </div>
  )
}

export default TerritoryAndPlatformRights
