export const SECURITY_ACTION = {
  CHANGE_PASSWORD: "CHANGE_PASSWORD"
}

export const TRACK_ACTION = {
  FETCH_TRACK: "FETCH_TRACK"
}

export const PAYMENT_METHODS_ACTION = {
  ADMIN_ENTRY: "ADMIN_ENTRY",
  FETCH_PAYMENT_METHODS: "FETCH_PAYMENT_METHODS",
  ADD_PAYMENT_METHOD: "ADD_PAYMENT_METHOD",
  DELETE_PAYMENT_METHOD: "DELETE_PAYMENT_METHOD",
  UPDATE_PAYMENT_METHOD: "UPDATE_PAYMENT_METHOD"
}

export enum AssetRegistryPages {
  GENERAL_INFORMATION = 1,
  TRACKS_AND_ASSETS = 2,
  TERRITORY_AND_PLATFORM_RIGHTS = 3,
  EDITORIAL_PITCHING = 4,
  SUMMARY = 5
}

export enum ReleaseRegistryPages {
  RELEASE = 1,
  UPLOAD_TRACKS = 2,
  SELECT_TRACKS = 3,
  SUMMARY = 4
}
export enum ReleaseRegistryReleaseTabs {
  RELEASE_NAME =  1,
  RELEASE_ARTIST = 2,
  ARTWORK  = 3,
  GENRES = 4,
  APPLE_DIGITAL_MASTER = 5,
  SELECT_RELEASE_DATE = 6
}
export enum ReleaseRegistryUploadTracksTabs {
  UPLOAD_TRACKS =  1,
  TRACK_DETAILS = 2,
  ARTIST  = 3,
  TRACK_SHARE_HOLDERS = 4,
  PUBLISHING_SHARE_HOLDERS = 5,
  CONTRIBUTOR_CONSENT_FILES = 6,
  LYRICS = 7
}