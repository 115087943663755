import { KDropdown, KSelectDate, KSpan, KTitleSpan } from "kahuna-base-react-components"
import React, { useEffect, useState } from "react"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { fetchAllSummaries, fetchFilterOptions } from "../../../actions/analysisActions"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { AnalysisSummary } from "../../../types"
import { analysisFilterOptionsKDropdown } from "../../../constants"
import { MultiValue } from "react-select"
import {
  addCommasToNumber,
  calculateOffsetMonth,
  convertStringDateToDateObject,
  formatYearMonth,
  getCurrency,
  getSummaries,
  mapListToCamelCase,
  subOptionsSetter
} from "../../../utility"

const RevenueCard = ({
  fetchFilterOptions,
  filterOptions,
  initialData,
  currency
}: {
  fetchFilterOptions: () => Promise<void>
  filterOptions?: { [key: string]: KSelectOption[] }
  initialData: AnalysisSummary[]
  currency: string
}) => {
  const [selectedSubOptions, setSelectedSubOptions] = useState<KSelectOption | MultiValue<KSelectOption> | undefined>(
    [] as KSelectOption[]
  )
  const [subOptions, setSubOptions] = useState<KSelectOption[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [dateValue, setDateValue] = useState<Date | undefined>(
    initialData ? convertStringDateToDateObject(initialData[initialData.length - 1]?.date) : undefined
  )
  const [chosenMonth, setChosenMonth] = useState<string | undefined>(undefined)
  const [data, setData] = useState<AnalysisSummary[]>(initialData)
  const [revenue, setRevenue] = useState<{ currentMonth: number; previousMonth: number }>({
    currentMonth: 0,
    previousMonth: 0
  })

  const [percentChange, setPercentChange] = useState<number | undefined>(undefined)

  useEffect(() => {
    const subOptionSetting = async () => {
      if (!filterOptions) return
      setLoading(true)
      await subOptionsSetter(analysisFilterOptionsKDropdown[0], filterOptions, setSubOptions)
      setLoading(false)
    }
    if (filterOptions) {
      subOptionSetting()
    }
  }, [filterOptions])

  useEffect(() => {
    const getAllSummary = async () => {
      if (chosenMonth) {
        let responseData
        const previousMonthDate = calculateOffsetMonth(chosenMonth, -1).replace("-", "")
        const startDate = chosenMonth.replace("-", "")

        if ((selectedSubOptions as KSelectOption[]).length === 0) {
          // This will show the specific month, not a range, so start and end will be the same.
          responseData = await getSummaries("platform", "all_platforms", false, previousMonthDate, startDate)
        } else {
          const optionsArray = selectedSubOptions as KSelectOption[]
          const value = optionsArray.map((subOption) => subOption.value2).join(",")
          responseData = await getSummaries("platform", value, false, previousMonthDate, startDate)
        }
        setData(mapListToCamelCase(responseData))
      }
    }

    getAllSummary().then(() => {})
  }, [selectedSubOptions, chosenMonth])

  useEffect(() => {
    if (initialData) {
      setData(initialData)
    }
  }, [initialData])

  useEffect(() => {
    if (!data) return

    let totalNetCurrentMonth = 0
    let totalNetPreviousMonth = 0
    const currentMonth = chosenMonth ? chosenMonth.replace("-", "") : data[data.length - 1]?.date
    const previousMonth = calculateOffsetMonth(currentMonth, -1).replace("-", "")
    data.forEach((summary) => {
      if (summary && summary.totalNet && summary.date) {
        if (summary.date === currentMonth) {
          totalNetCurrentMonth += Number(summary.totalNet)
        } else if (summary.date === previousMonth) {
          totalNetPreviousMonth += Number(summary.totalNet)
        }
      }
    })

    if (totalNetCurrentMonth > 0 && totalNetPreviousMonth > 0) {
      const percentChange = ((totalNetCurrentMonth - totalNetPreviousMonth) / totalNetPreviousMonth) * 100
      setPercentChange(percentChange)
    } else {
      setPercentChange(undefined)
    }

    setRevenue({
      currentMonth: totalNetCurrentMonth,
      previousMonth: totalNetPreviousMonth
    })
  }, [data])

  return (
    <div
      className="w-full h-[168px] p-5 flex flex-col justify-between"
      style={{
        borderRadius: "10px",
        border: "1px solid var(--stroke-soft-200, #F3F3F3)",
        background: " var(--bg-white-0, #FFF)",
        boxShadow: "0px 2px 4px 0px rgba(27, 28, 29, 0.04)"
      }}
    >
      <div className="flex flex-row justify-between items-start">
        <div className="flex flex-row gap-2 items-center h-9 pl-2.5">
          <img src="/analytics_icons/money-cash.svg" alt="money-icon" />
          <KSpan text="Revenue" color="#000" fontWeight={500} />
        </div>
        <div className="flex flex-row gap-3 items-start">
          {subOptions && !loading && (
            <div className="max-w-[230px]">
              <KDropdown
                onSelect={(elements) => {
                  if (elements) {
                    setSelectedSubOptions(elements)
                  }
                  if (!chosenMonth && data) {
                    setChosenMonth(formatYearMonth(data[data.length - 1]?.date))
                    setDateValue(convertStringDateToDateObject(data[data.length - 1]?.date))
                  }
                }}
                options={subOptions}
                placeholder="All Platforms"
                isMulti
                defaultValue={selectedSubOptions}
                selected={selectedSubOptions}
                showOnlyIconsInMulti
                menuWidth={170}
                rightIcon="/analytics_icons/caret-down-new.svg"
                enableIndicator = {true}
                placeholderColor="#000"
              />
            </div>
          )}
          <KSelectDate
            value={dateValue}
            hideBody
            onlyMonthSelection
            icon="/analytics_icons/calendar.svg"
            onChange={(event) => {
              if (!event) {
                setChosenMonth(undefined)
                setDateValue(undefined)
              } else if (event && event.getTime() !== dateValue?.getTime()) {
                const year = event.getFullYear()
                const month = String(event?.getMonth() + 1).padStart(2, "0")
                setChosenMonth(`${year}-${month}`)
                setDateValue(event)
              }
            }}
            anchorToButton
            position="left"
            align="center"
          />
        </div>
      </div>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2 pl-3">
          <KTitleSpan
            text={`${getCurrency(currency)}${addCommasToNumber(revenue.currentMonth.toFixed(2))}`}
            color="#000"
            fontSize={32}
            lineHeight="40px"
          />
          {percentChange && (
            <div className="flex flex-row items-center gap-1">
              <img
                src={percentChange > 0 ? "/analytics_icons/caret-up.svg" : "/analytics_icons/caret-down.svg"}
                alt="change-icon"
                height={16}
                width={16}
              />
              <KSpan text={`${percentChange.toFixed(0)}%`} color={percentChange > 0 ? "#56E099" : "#FF5865"} />
              <KSpan text={"vs last month"} color="#999" fontSize={12} lineHeight="16px" fontWeight={500} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    filterOptions: state.analysis.filterOptions,
    allSummaries: state.analysis.allSummaries,
    currency: state.auth.user.preferredCurrency
  }
}

export default connect(mapStateToProps, { fetchFilterOptions, fetchAllSummaries })(RevenueCard)
