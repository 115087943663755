import { CollaboratorData, TableColumn, Track } from "../../../../types"
import { RELEASE_STATUS, TABLE_COLUMN_TYPE } from "../../../../constants"
import React, { CSSProperties } from "react"

const cellStyle = { fontWeight: 400, lineHeight: "20px", color: "#111827" }
const headerStyle: CSSProperties = { textAlign: "left", width: "25%" }

const trackColumns: TableColumn[] = [
  {
    header: "Track Name",
    headerStyle,
    cellStyle,
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Track) => {
      return (
        <div className="flex">
          <div className="mr-4">
            <div className="h-[40px] w-[40px] rounded-[100%] bg-gray-800 overflow-hidden">
              <img src={row.releaseDetail.coverFileUrl} alt={"table_image"} />
            </div>
          </div>
          <div>
            <div className="text-sm leading-5 font-medium text-gray-900">{row.title}</div>
            <div className="text-sm leading 5 font-normal text-gray-500">
              {row.artistsList.map((artist, i) => `${artist.name}${i === row.artistsList.length - 1 ? "" : ", "}`)}
            </div>
          </div>
        </div>
      )
    }
  },
  {
    header: "Total Collaborators",
    headerStyle,
    cellStyle,
    type: TABLE_COLUMN_TYPE.TEXT,
    attribute: "shareData.length"
  },
  {
    header: "My Royalty Share",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    headerStyle,
    cellStyle,
    render: (row) => `${row.shareData.share} %`
  },
  {
    header: "My Roles",
    headerStyle,
    cellStyle,
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row) => (row.shareData ? row.shareData.roles.join(", ") : "")
  },
  {
    header: "",
    type: TABLE_COLUMN_TYPE.LINK,
    attribute: "id"
  }
]

export default trackColumns
