import { KButton, KDropdown, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
import React, { useEffect, useState } from "react"
//@ts-ignore
import ArrowLeftIcon from "../../../../../assets/release-registry-icons/arrow-left.svg"
//@ts-ignore
import ChevronDownIcon from "../../../../../assets/release-registry-icons/chevron-down.svg"
//@ts-ignore
import InfoColoredIcon from "../../../../../assets/release-registry-icons/info-colored.svg"
//@ts-ignore
import InfoBlackIcon from "../../../../../assets/release-registry-icons/info-black.svg"
import { ReleaseRegistryReleaseTabs } from "../../../../../actions/types"
import { useOrchestrator } from "../ReleaseRegistryContextProvider"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"

const Genres = ({ setSelectedTab }: { setSelectedTab: (tab: ReleaseRegistryReleaseTabs) => void }) => {
  const {
    releaseForm,
    setReleaseForm,
    options,
    updateOfferRelationships,
    updateProductRelationships,
    residualForm,
    setResidualForm
  } = useOrchestrator()

  const checkBeatPort = (): boolean => {
    const mainGenre =
      options.distributorProductSubGenreOptions.find(
        (subgenre) => subgenre.value === releaseForm.offer.data?.relationships["subgenre"]?.data?.id
      )?.label || ""
    const secondaryGenre =
      options.distributorProductSubGenreOptions.find(
        (subgenre2) => subgenre2.value === releaseForm.offer.data?.relationships["subgenre2"]?.data?.id
      )?.label || ""
    return mainGenre.toLowerCase().includes("electronic") || secondaryGenre.toLowerCase().includes("electronic")
  }

  const checkContinueKButton = () => {
    if (checkBeatPort()) {
      return !(
        releaseForm.offer.data?.relationships["subgenre"] &&
        releaseForm.product.data?.relationships.language?.data?.id &&
        residualForm.sendToBeatport
      )
    } else {
      return !(
        releaseForm.offer.data?.relationships["subgenre"] && releaseForm.product.data?.relationships.language?.data?.id
      )
    }
  }

  const [distributorProductSubGenreOptions, setDistributorProductSubGenreOptions] = useState<KSelectOption[]>(
    options.distributorProductSubGenreOptions?.map((subgenreOption) => {
      return { value: Number(subgenreOption.value), value2: subgenreOption.value, label: subgenreOption.label }
    }) || []
  )
  const [metadataLanguageOptions, setMetadataLanguageOptions] = useState<KSelectOption[]>(
    options.metadataLanguageOptions?.map((metadataLanguageOption) => {
      return {
        value: Number(metadataLanguageOption.value),
        value2: metadataLanguageOption.value,
        label: metadataLanguageOption.label
      }
    }) || []
  )

  useEffect(() => {
    if (options.metadataLanguageOptions) {
      setMetadataLanguageOptions(
        options.metadataLanguageOptions.map((metadataLanguageOption) => {
          return {
            value: Number(metadataLanguageOption.value),
            value2: metadataLanguageOption.value,
            label: metadataLanguageOption.label
          }
        })
      )
    }
    if (options.distributorProductSubGenreOptions) {
      setDistributorProductSubGenreOptions(
        options.distributorProductSubGenreOptions.map((subgenreOption) => {
          return { value: Number(subgenreOption.value), value2: subgenreOption.value, label: subgenreOption.label }
        })
      )
    }
  }, [options])

  return (
    <div className="w-[616px] max-sm:w-auto flex flex-col gap-6">
      <div className="flex flex-col gap-3 justify-start">
        <div className="flex flex-row gap-4 items-center">
          <div>
            <KButton
              icon={ArrowLeftIcon}
              onClick={() => {
                setSelectedTab(ReleaseRegistryReleaseTabs.ARTWORK)
              }}
              width="36px"
              height="36px"
              padding="8px"
              background="#F0F0F0"
              shadowDisabled
              hoverBackground="#E7E7E7"
            />
          </div>
          <KTitleSpan text="Genres" fontSize={40} lineHeight="48px" letterSpacing="-0.4px" color="#1F1F1F" />
        </div>
        <KSpan text="Your Spotify artist name uniquely identifies you on the platform." />
      </div>
      <div className="w-full flex flex-col gap-3">
        <div className="w-full flex flex-row justify-between">
          <div className="w-[32%]">
            {" "}
            <KDropdown
              placeholder="Primary Genre"
              defaultValuePrimitive={releaseForm.offer.data.relationships["subgenre"]?.data?.id}
              options={distributorProductSubGenreOptions}
              rightIcon={ChevronDownIcon}
              selected={releaseForm.offer.data.relationships["subgenre"]?.data?.id}
              isEllipsis={true}
              onSelect={(selected) => {
                if (selected) {
                  const selectedOption = selected as KSelectOption
                  const value2 = selectedOption.value2
                  if (!value2) {
                    return
                  }
                  updateOfferRelationships("subgenre", {
                    data: { type: "distributor-product-subgenres", id: value2 }
                  })
                } else {
                  delete releaseForm.offer.data.relationships["subgenre"]
                  setReleaseForm({ ...releaseForm })
                }
              }}
            />{" "}
          </div>
          <div className="w-[32%]">
            {" "}
            <KDropdown
              placeholder="Secondary Genre"
              options={distributorProductSubGenreOptions}
              defaultValuePrimitive={releaseForm.offer.data.relationships["subgenre2"]?.data?.id}
              rightIcon={ChevronDownIcon}
              selected={releaseForm.offer.data.relationships["subgenre2"]?.data?.id}
              isClearable={true}
              isEllipsis={true}
              onSelect={(selected) => {
                if (selected) {
                  const selectedOption = selected as KSelectOption
                  const value2 = selectedOption.value2
                  if (!value2) {
                    return
                  }
                  updateOfferRelationships("subgenre2", {
                    data: { type: "distributor-product-subgenres", id: value2 }
                  })
                } else {
                  delete releaseForm.offer.data.relationships["subgenre2"]
                  setReleaseForm({ ...releaseForm })
                }
              }}
            />{" "}
          </div>
          <div className="w-[32%]">
            {" "}
            <KDropdown
              placeholder="Language"
              options={metadataLanguageOptions}
              defaultValuePrimitive={releaseForm.product.data.relationships.language?.data?.id}
              selected={releaseForm.product.data.relationships.language?.data?.id}
              rightIcon={ChevronDownIcon}
              onSelect={(selected) => {
                if (selected) {
                  const selectedOption = selected as KSelectOption
                  const value2 = selectedOption.value2
                  if (!value2) {
                    return
                  }
                  updateProductRelationships("language", {
                    data: { type: "languages", id: value2 }
                  })
                } else {
                  delete releaseForm.product.data.relationships.language
                  setReleaseForm({ ...releaseForm })
                }
              }}
            />{" "}
          </div>
        </div>
        <div className="px-[14px] pt-[14px] pb-4 flex flex-row justify-between items-start gap-3 bg-[#EDEFFA] rounded-[10px]">
          <div className="shrink-0">
            <img src={InfoColoredIcon} alt="info-colored-icon" />
          </div>
          <div className="opacity-80 inline-flex">
            <KSpan
              text={`Select primary genre; optional secondary. Stores may adjust
              for unavailable genres. Use for language targeting if no lyrics.`}
              color="#151B3D"
            />
          </div>
        </div>
        {checkBeatPort() && (
          <div className="flex flex-row gap-2">
            <KInput
              value={"checkTerms"}
              onChange={() => {
                setResidualForm({ ...residualForm, sendToBeatport: !residualForm.sendToBeatport })
              }}
              type={"checkbox"}
              checked={residualForm.sendToBeatport}
              shadowDisabled={true}
              width={16}
              height={16}
              accentColor={"black"}
              padding="0px"
              borderRadius={10}
            />
            <KSpan text="Send your release to Beatport" color="#1F1F1F" fontWeight={500} />
          </div>
        )}
        {checkBeatPort() && residualForm.sendToBeatport && (
          <div className="px-[14px] pt-[14px] pb-4 flex flex-row justify-between items-start gap-3 bg-[#F0F0F0] rounded-[10px]">
            <div className="shrink-0">
              <img src={InfoBlackIcon} />
            </div>
            <div className="opacity-80 inline-flex">
              <KSpan
                text={`Beatport previously only accepted curated content from labels.
               Due to system requirements, releases distributed to Beatport will not show the label name that is in the Kahuna system. 
               Releases will be sent to Beatport with the label name “Kahuna Music” and will be displayed under the Kahuna catalog on Beatport’s website.`}
                color="#1F1F1F"
              />
            </div>
          </div>
        )}
      </div>
      <div>
        <KButton
          text="Continue"
          onClick={() => {
            setSelectedTab(ReleaseRegistryReleaseTabs.APPLE_DIGITAL_MASTER)
          }}
          background="#000"
          textColor="#FFF"
          disabled={checkContinueKButton()}
        />
      </div>
    </div>
  )
}

export default Genres
