import { Dialog } from "@headlessui/react"
import { FC, useEffect, useState } from "react"
import { CURRENCY_TYPES, errorToastOptions, successToastOptions, tableCellClasses } from "../../../../../constants"
import { X } from "heroicons-react"
import * as React from "react"
import { CurrencyExchange } from "../../../../../types"
import { currencyExchange } from "../../../../../apis"
import { mapListToCamelCase, mapToCamelCase } from "../../../../../utility"
import { KInput, KSpan } from "kahuna-base-react-components"
import { toast } from "react-toastify"

type CurrencyExchangeModalProps = {
  open: boolean
  onClose: () => void
}
const CurrencyExchangeModal: FC<CurrencyExchangeModalProps> = (props) => {
  const [currencyExchangeList, setCurrencyExchangeList] = useState<CurrencyExchange[]>([])
  const [initialCurrencyExchangeList, setInitialCurrencyExchangeList] = useState<CurrencyExchange[]>([])

  const { onClose, open } = props

  useEffect(() => {
    if (currencyExchangeList.length !== 0 || !open) {
      return
    }

    currencyExchange(`/list_by_type/?type=${CURRENCY_TYPES.USD_GBP}`, "get").then((resp) => {
      if (resp?.data?.results) {
        setCurrencyExchangeList(mapListToCamelCase(resp.data.results))
        setInitialCurrencyExchangeList(mapListToCamelCase(resp.data.results))
      }
    })
  }, [open])

  const onValueChange = (value: number, currencyExchangeId: number) => {
    setCurrencyExchangeList([
      ...currencyExchangeList.map((currencyExchange) => {
        if (currencyExchange.id === currencyExchangeId) {
          currencyExchange.exchangeRates = value
        }

        return currencyExchange
      })
    ])
  }

  const resetCurrencyExchange = () => {
    setCurrencyExchangeList(mapListToCamelCase(initialCurrencyExchangeList))
  }

  const onSaveCurrencyExchange = (id: number) => {
    const currencyExchangeToSave = currencyExchangeList.find((currencyExchange) => currencyExchange.id === id)

    if (!currencyExchangeToSave) {
      setCurrencyExchangeList(mapListToCamelCase(initialCurrencyExchangeList))
      return
    }

    currencyExchange(`/save_currency_exchange/`, "put", {
      id,
      exchange_rates: currencyExchangeToSave.exchangeRates
    })
      .then((resp) => {
        if (resp?.data?.success) {
          toast.success("User Expense Saved.", successToastOptions)
          setInitialCurrencyExchangeList(mapListToCamelCase(currencyExchangeList))
        } else {
          toast.error("Error saving currency exchange", errorToastOptions)
          resetCurrencyExchange()
        }
      })
      .catch(() => {
        toast.error("Error saving currency exchange", errorToastOptions)
        resetCurrencyExchange()
      })
  }

  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="mt-10 bg-white elev-b-sm rounded-lg overflow-hidden">
          <div
            className={`bg-gray-50 text-xs text-gray-500 font-medium 
              uppercase border-b flex justify-between ${tableCellClasses.sm}`}
          >
            <p>Currency Exchanges (USD/GBP)</p>
            <X className="h-4 mr-[-6px] cursor-pointer" onClick={onClose} />
          </div>
          <div style={{ maxHeight: 500, overflowY: "scroll", width: 400 }}>
            {currencyExchangeList.map((currencyExchange) => (
              <div
                className="p-3 flex justify-between items-center"
                key={`ce-${currencyExchange.id}`}
                style={{ borderBottom: "1px solid lightgrey" }}
              >
                <KSpan text={currencyExchange.date} color="#111" fontWeight={600} />
                <KInput
                  type="number"
                  onChange={(value) => onValueChange(parseFloat(value), currencyExchange.id)}
                  onBlur={() => onSaveCurrencyExchange(currencyExchange.id)}
                  value={currencyExchange.exchangeRates?.toString() || ""}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default CurrencyExchangeModal
