import { KSpan } from "kahuna-base-react-components"
import React, { useEffect, useState } from "react"

const StatusCard = ({
  text,
  choosenStatus,
  setChoosenStatus
}: {
  text: string
  choosenStatus: string[]
  setChoosenStatus: (choosenStatus: string[]) => void
}) => {
  const [clicked, setClicked] = useState<boolean>(false)

  useEffect(() => {
    if (clicked) {
      setChoosenStatus([...choosenStatus, text.toLowerCase()])
    } else {
      setChoosenStatus(choosenStatus.filter((item) => item !== text.toLowerCase()))
    }
  }, [clicked])

  return (
    <div
      className={`px-5 py-1 rounded-[999px] cursor-pointer border-[1px] border-[#E7E7E7] ${
        choosenStatus.includes(text.toLowerCase()) ? "bg-[#000]" : "bg-[#fff]"
      }`}
      onClick={() => setClicked(!clicked)}
    >
      <KSpan text={text} color={!choosenStatus.includes(text.toLowerCase()) ? "#000" : "#fff"} />
    </div>
  )
}

export default StatusCard
