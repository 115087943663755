import axios, { AxiosRequestConfig, Method } from "axios"
import { refreshToken } from "../utility"
import { errorToastOptions, UNAUTHORIZED_ERROR_NAME } from "../constants"
import { Action, Dispatch } from "redux"
import { toast } from "react-toastify"
import { AUTH_ACTION } from "../actions/loginActions"

const createRequest = (path: string) => {
  return axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}${path}`,
    withCredentials: true
  })
}

export const controllers: Record<string, AbortController> = {}

const createAuthorizedRequest = async (path: string, config?: AxiosRequestConfig, abortable = false) => {
  const error = new Error()
  error.name = UNAUTHORIZED_ERROR_NAME
  if (!(await refreshToken())) throw error

  const controllerId = path.split("?")[0]
  const controller = controllers[controllerId]

  if (abortable) {
    if (controller) {
      controller.abort()
    }
    controllers[controllerId] = new AbortController()
  }

  return axios({
    ...config,
    url: `${process.env.REACT_APP_BASE_URL}${path}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "affiliate-user-id": localStorage.getItem("appliedAffiliate") ?? ""
    },
    withCredentials: true,
    ...(abortable && { signal: controllers[controllerId].signal })
  })
}

export const authLogin = createRequest("/login")
export const authRefresh = createRequest("/refresh")
export const codeValidation = createRequest("/code-validation")
export const publicInvitation = createRequest("/public/track-share-holder-invitation/approve_invitation")
export const smartLinks = createRequest(`/public/smart-link`)
export const authLogout = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/logout${path}`, { method, data })
export const me = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/me${path}`, { method, data })
export const users = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/users${path}`, { method, data }, true)
export const paymentMethods = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/payment-method${path}`, { method, data })
export const releases = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/releases${path}`, { method, data }, false)
export const tracks = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/releases/track${path}`, { method, data }, true)
export const trackShareHolders = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/releases/track-share-holder${path}`, { method, data }, true)
export const youtubeVideoShareHolders = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/releases/youtube-video-share-holder${path}`, { method, data }, true)
export const summaries = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/releases/summary${path}`, { method, data })
export const userPayments = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/payments/user-payment-history${path}`, { method, data })
export const currencyExchange = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/currency-exchange${path}`, { method, data })
export const completeShareHolders = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/releases/track-share-holder/complete_share_holders/${path}`, { method, data }, true)
export const collaboratorInvitation = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/releases/track-share-holder-invitation${path}`, { method, data }, true)
export const schedulers = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/scheduler${path}`, { method, data })
export const assistant = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/assistant${path}`, { method, data }, true)
export const affiliate = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/affiliate${path}`, { method, data }, true)
export const applicationSubscriptions = (path: string, method: Method, data: any = null) =>
  createAuthorizedRequest(`/subscription${path}`, { method, data }, true)

export const handleAuthorizedRequest = async (
  callback: () => Promise<any>,
  casualErrorMessage: string,
  dispatch: Dispatch<Action> = null
) => {
  try {
    return await callback()
  } catch (e) {
    if (e.name === UNAUTHORIZED_ERROR_NAME) {
      console.error("Incorrect authorization details. Logging out...")
      if (dispatch) {
        dispatch({
          type: AUTH_ACTION.LOGOUT
        })
      }
      window.location.pathname = "/login"
    }
    if (e.message === "canceled") {
      throw e
    }
    console.error(e)
    toast.error(casualErrorMessage, errorToastOptions)
  }
}
