import React, { FC, useState } from "react"
import "../styles/smartlink.css"
import { Artist, Release } from "../../../../types"
import { KButton, KInput, KLogo, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { releases } from "../../../../apis"
import { errorToastOptions } from "../../../../constants"
import { toast } from "react-toastify"
import { SmartLinkPages } from "../ReleaseSmartLinkPage"

type CustomizeLinkProps = {
  release: Release
  setSelectedPage: (selectedPage: number) => void
  setSmartLinkExist: (smartLinkExist: boolean) => void
  linkText: string
  setLinkText: (linkText: string) => void
}

const headerHeight = 88

const CustomizeLink: FC<CustomizeLinkProps> = (props) => {
  const { release, setSelectedPage, setSmartLinkExist, linkText, setLinkText } = props

  const [loading, setLoading] = useState(false)

  const renderPill = (text: string) => {
    return (
      <div style={{ borderRadius: 4, backgroundColor: "#F5F5F5", padding: 4, paddingTop: 0 }} key={Math.random()}>
        <KSpan text={text} fontSize={11} fontWeight={500} lineHeight="12px" color="#111" letterSpacing="0.22px" />
      </div>
    )
  }

  const saveSmartLinkUrl = () => {
    setLoading(true)
    releases(`/release/save_smart_link_url/`, "put", { id: release.id, smart_link: linkText.trim() })
      .then((resp) => {
        if (resp && resp.data && resp.data.success) {
          setSmartLinkExist(true)
          setSelectedPage(SmartLinkPages.CONNECT)
        } else {
          toast.error(resp.data.message || `Error saving smart link url`, errorToastOptions)
        }
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  return (
    <div>
      <div style={{ height: `calc(100% - ${headerHeight * 2}px)` }} className="justify-center items-center flex">
        <div className="block" style={{ minWidth: 616 }}>
          <div>
            <KTitleSpan text={"Let's create a link"} fontSize={32} fontWeight={500} />
          </div>
          <div>
            <div style={{ width: "100%" }} className={`mt-6`}>
              <div className={`h-[64px] flex justify-between`}>
                <div className="flex" style={{ width: "100%" }}>
                  <img src={release.coverFileUrl} className={`w-[64px] h-[64px] rounded-[6px] cursor-pointer`} />
                  <div className="flex ml-6 justify-between" style={{ width: "100%" }}>
                    <div className="items-center flex" style={{ maxWidth: 330 }}>
                      <KTitleSpan text={release.title} fontSize={24} fontWeight={500} lineHeight="28px" />
                    </div>

                    <div className="flex gap-1 items-center ml-3">
                      {renderPill(release.artistsList.map((artist: Artist) => artist.name).join(", "))}
                      {renderPill(release.productionYear || " - ")}
                      {renderPill(`${release.totalTrack} song${release.totalTrack > 1 ? "s" : ""}`)}
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center"></div>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <KInput
              leftIcon="/account_icons/music-note-sparkle.svg"
              onChange={(text) => setLinkText(text)}
              value={linkText}
              placeholder="https://royalty.kahuna.io/smart-link/xxx"
            />
          </div>
          <div className="mt-8">
            <KButton
              background="black"
              textColor="white"
              onClick={() => saveSmartLinkUrl()}
              text="Save Changes"
              disabled={loading || !linkText || release.smartLink === linkText}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomizeLink
