import React, { FC, useEffect, useState } from "react"
import { LCReleaseForm, ReleaseRegistryProps, ResidualFormProps, SpotifyEditorialPitching } from "../../../../types"
import useScript from "../../../../utility/useScript"
import { useNavigate, useParams } from "react-router-dom"
import { releases } from "../../../../apis"
import { hashids, mapToCamelCase } from "../../../../utility"
import { toast } from "react-toastify"
import { loadingToastOptions, USER_PERMISSION, USER_ROLE } from "../../../../constants"
import Loading from "../../../ui/Loading"
import { ReleaseRegistryPages } from "../../../../actions/types"
import NavigationFlow from "./NavigationFlow"
import { KButton, KLogo, KSpan } from "kahuna-base-react-components"
import { RootState } from "../../../../store"
import { selectRelease } from "../../../../actions/catalogActions"
import { connect } from "react-redux"
import "../styles/Catalog.css"
import ReleaseLanding from "./release/ReleaseLanding"
import { ReleaseRegistryOrchestratorProvider } from "./ReleaseRegistryContextProvider"
import UploadTracksLanding from "./upload-tracks/UploadTracksLanding"
import SpotifyEditorialPitchingPage from "./spotify-editorial-pitching/SpotifyEditorialPitchingPage"
import Summary from "./summary/Summary"

const headerHeight = 56
const navigationFlowHeight = 76

declare global {
  interface Window {
    LyricFind: any
  }
}

const initReleaseForm: LCReleaseForm = {
  product: {
    data: {
      id: undefined,
      attributes: {
        artist: []
      },
      relationships: {},
      type: "products"
    }
  },
  offer: {
    data: {
      id: undefined,
      attributes: {},
      relationships: {
        product: {}
      },
      type: "offers"
    }
  },
  tracks: [],
  import_tasks: [],
  import_sub_tasks: [],
  records: [],
  track_offers: [],
  logs: []
}

const initResidualForm: ResidualFormProps = {
  sendToBeatport: false
}

const ReleaseRegistry: FC<ReleaseRegistryProps> = (props) => {
  const { selectRelease } = props
  const [selectedTab, setSelectedTab] = useState(-1)
  const [releaseForm, setReleaseForm] = useState<LCReleaseForm>(initReleaseForm)
  const [residualForm, setResidualForm] = useState<ResidualFormProps>(initResidualForm)
  const [options, setOptions] = useState<any>({})
  const [loading, setLoading] = useState(true)
  const [lyricsWidget, setLyricsWidget] = useState<any>(null)
  const [isPublishing, setIsPublishing] = useState(false)
  const [bmwCustomIdKeyword, setBmwCustomIdKeyword] = useState("")
  const [summaryPublishingText, setSummaryPublishingText] = useState("")
  const [navigationZIndex, setNavigationZIndex] = useState(0)
  const [appleDigitalMaster, setAppleDigitalMaster] = useState<string>("") // TODO: it is defined here but usage of it is not completed. Complete it later.
  const [spotifyEditorialPitching, setSpotifyEditorialPitching] = useState<SpotifyEditorialPitching>({
    genre: [],
    mood: [],
    style: [],
    instrumentation: [],
    description: ""
  })

  const params = useParams()

  useScript("//lyricfind.music.ai/loader/v1.js")

  const navigate = useNavigate()

  useEffect(() => {
    if (window.LyricFind) {
      const widget = window.LyricFind.initialize({
        license: "20629d84-3267-4f97-aa78-9b5fa83f7a09"
      })
      setLyricsWidget(widget)
    }
  }, [window.LyricFind])

  useEffect(() => {
    if (!props.user.productionHolder || !props.user.copyrightHolder) {
      toast.success("Please enter Production Holder and Copyright Holder.", loadingToastOptions)
      navigate("/account/copyright-and-production")
      return
    }

    const userPermissionIds = props.user.permissionUser.map((up) => up?.permission?.id || 0)
    if (userPermissionIds.includes(USER_PERMISSION.UPLOAD_MESAM)) {
      setIsPublishing(true)
      setSummaryPublishingText("Your work registry submitted to Mesam")
    } else if (userPermissionIds.includes(USER_PERMISSION.UPLOAD_MSG)) {
      setIsPublishing(true)
      setSummaryPublishingText("Your work registry submitted to MSG")
    }

    if (userPermissionIds.includes(USER_PERMISSION.UPLOAD_BMV)) {
      setBmwCustomIdKeyword(props.user.bmwCustomId)
    }

    releases(`/release/label_camp_options/`, "get").then((resp) => {
      const labelOptions = resp.data["label_options"]
      const productTypeOptions = resp.data["product_type_options"]
      const languageOptions = resp.data["language_options"]
      const metadataLanguageOptions = resp.data["metadata_language_options"]
      const productGenreOptions = resp.data["product_genre_options"]
      const distributorProductSubGenreOptions = resp.data["distributor_product_sub_genre_options"]
      const distributorPriceCodeOptions = resp.data["distributor_price_code_options"]
      const distributorTrackPriceOptions = resp.data["distributor_track_price_options"]
      const roleOptions = resp.data["roles"]
      setOptions({
        labelOptions,
        productTypeOptions,
        languageOptions,
        metadataLanguageOptions,
        productGenreOptions,
        distributorProductSubGenreOptions,
        distributorPriceCodeOptions,
        distributorTrackPriceOptions,
        roleOptions
      })

      if (params.releaseId === "new") {
        const currentYear = new Date().getFullYear()

        if (labelOptions?.length === 1) {
          setReleaseForm({
            ...initReleaseForm,
            product: {
              ...releaseForm.product,
              data: {
                ...releaseForm.product.data,
                relationships: {
                  ...releaseForm.product.data.relationships,
                  label: { data: { id: labelOptions[0].value, type: "labels" } }
                },
                attributes: {
                  ...releaseForm.product.data.attributes,
                  "production-line": `${currentYear} ${props.user.productionHolder}`,
                  "copyright-line": `${currentYear} ${props.user.copyrightHolder}`,
                  "production-year": currentYear
                }
              }
            }
          })
        } else {
          setReleaseForm({ ...initReleaseForm })
        }
        setLoading(false)
        setSelectedTab(ReleaseRegistryPages.RELEASE)
      } else if (props.release && props.release.id) {
        setLoading(true)
        releases(`/release/label_camp_product_get/?product_id=${props.release.productId}`, "get").then((resp) => {
          setReleaseForm({ ...resp.data.result })
          setSelectedTab(ReleaseRegistryPages.RELEASE)
          setLoading(false)
        })
        setAppleDigitalMaster(props.release.appleDigitalMaster)
        releases(`/release/list_spotify_editorial_pitching/?release_id=${props.release.id}`, "get").then((resp) => {
          if (resp.data.result) {
            setSpotifyEditorialPitching(mapToCamelCase(resp.data.result))
          }
        })
      } else if (params.releaseId) {
        const decodedId = hashids.decode(params.releaseId || "").toString()
        selectRelease(undefined, decodedId)
        setLoading(false)
      }
    })
  }, [props.release])

  return (
    <div style={{ height: "100vh", backgroundColor: "#F0F0F0", padding: 10 }}>
      <ReleaseRegistryOrchestratorProvider
        data={releaseForm}
        options={options}
        residualData={residualForm}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        lyricsWidget={lyricsWidget}
        setSpotifyEditorialPitching={setSpotifyEditorialPitching}
        spotifyEditorialPitching={spotifyEditorialPitching}
        appleDigitalMaster={appleDigitalMaster}
        setAppleDigitalMaster={setAppleDigitalMaster}
        isPublishing={isPublishing}
        summaryPublishingText={summaryPublishingText}
        bmwCustomIdKeyword={bmwCustomIdKeyword}
      >
        {loading && <Loading />}
        {
          // <ErrorModal text={errorText} setText={setErrorText} />
        }
        <div
          style={{
            backgroundColor: "#fff",
            height: "100%",
            width: "100%",
            borderRadius: 10,
            boxShadow: "0px 2px 2px 0px lightgray"
          }}
        >
          <div style={{ height: `${headerHeight}px` }}>
            <div
              className="flex pl-10 pr-10"
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
                className={`w-auto flex flex-row gap-[8px] justify-start items-center py-[8px] px-[10px] rounded-[10px] group-hover:!bg-[#f0f0f0]`}
              >
                <span className="w-[20px] aspect-square flex justify-center items-center p-0">
                  <img className="w-full h-full" src={"/navigation_icons/arrow-left.svg"}></img>
                </span>
                <span className="flex items-center">
                  <KSpan text={"Back"} color="#111" fontWeight={500} fontSize={14} />
                </span>
              </div>
              <div style={{ textAlign: "left", display: "inline-block" }}>
                <KLogo width={48} height={48} primaryTextVisible={true} logoColor="white" borderRadius={1} />
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {}}
                className={`w-auto flex flex-row gap-[8px] justify-start items-center py-[8px] px-[10px] rounded-[10px] group-hover:!bg-[#f0f0f0]`}
              >
                <span className="w-[20px] aspect-square flex justify-center items-center p-0">
                  <img className="w-full h-full" src={"/catalog_icons/play-line-black.svg"}></img>
                </span>
                <span className="flex items-center gap-4">
                  <KSpan text={"Tutorial"} color="#111" fontWeight={500} fontSize={14} />
                  {(props.user.roleId === USER_ROLE.SuperAdmin || props.user.roleId === USER_ROLE.Admin) && (
                    <KButton
                      onClick={() => {
                        navigate(`${location.pathname.replace("release-registry", "asset-registry")}`)
                      }}
                      text="Switch UI"
                      background="transparent"
                      shadowDisabled={true}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
          <div style={{ height: `calc(100% - ${headerHeight + navigationFlowHeight}px)`, overflowY: "scroll" }}>
            {selectedTab === ReleaseRegistryPages.RELEASE && <ReleaseLanding />}
            {selectedTab === ReleaseRegistryPages.UPLOAD_TRACKS && <UploadTracksLanding />}
            {selectedTab === ReleaseRegistryPages.SELECT_TRACKS && <SpotifyEditorialPitchingPage />}
            {selectedTab === ReleaseRegistryPages.SUMMARY && <Summary />}
          </div>
          <div style={{ height: `${navigationFlowHeight}px` }}>
            <NavigationFlow
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              navigationZIndex={navigationZIndex}
            />
          </div>
        </div>
      </ReleaseRegistryOrchestratorProvider>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user,
    userId: state.auth.user.id,
    release: state.catalogData.selectedRelease
  }
}

export default connect(mapStateToProps, { selectRelease })(ReleaseRegistry)
