// @ts-ignore
import ComputerIcon from "./ui/img/computer-line.svg"
import "./styles/NotFound.css"
import React from "react"
import { KButton, KLogo, KSpan, KTitleSpan } from "kahuna-base-react-components"

const NotAvailableForMobile = () => {
  return (
    <div style={{ background: "white", height: "100%", borderRadius: 10 }} className="">
      <div className="flex justify-center pt-6">
        <KLogo width={32} height={32} hideIcon={true} primaryTextVisible={true} secondaryText="for artists"/>
      </div>
      <div className="flex justify-center items-center" style={{ height: "80%" }}>
        <div className="grid" style={{ justifyItems: "center", padding: 32, gap: 32 }}>
          <img
            src={ComputerIcon}
            style={{
              borderRadius: 999,
              padding: 24,
              boxShadow:
                "0px 32px 24px -12px rgba(17, 17, 17, 0.06), 0px 11px 4px 0px rgba(17, 17, 17, 0.01), 0px 6px 4px 0px rgba(17, 17, 17, 0.02), 0px 3px 3px 0px rgba(17, 17, 17, 0.03), 0px 1px 1px 0px rgba(17, 17, 17, 0.04), 0px 0px 0px 1px rgba(17, 17, 17, 0.04)"
            }}
          />
          <KTitleSpan text="Let's switch to desktop" fontSize={20} lineHeight="16px"/>
          <KSpan
            text="Kahuna isn't optimized for mobile devices, so head to your computer to continue."
            fontSize={14}
          />
          <KButton
            onClick={() => (window.location.href = "https://kahuna.io/")}
            text="Go to Kahuna website"
            background="black"
            textColor="white"
          />
        </div>
      </div>
    </div>
  )
}

export default NotAvailableForMobile
