import { PaymentMethod } from "../../../../types"
import { toast } from "react-toastify"


const paymentMethodValidator = (formValues: PaymentMethod) => {
  const errors: any = {}

  if (!formValues.bankName) {
    errors.bankName = "You must enter bank name"
    toast.error("You must enter bank name")
  }
  if (!formValues.accountHolderName) {
    errors.accountHolderName = "You must enter account holder name"
    toast.error("You must enter account holder name")
  }
  if (!formValues.iban) {
    errors.iban = "You must enter your iban"
    toast.error("You must enter your iban")
  }

  if (formValues.iban && formValues.iban.length < 32) {
    errors.iban = "Enter a valid TR iban"
    toast.error("Enter a valid TR iban")
  } 

  return errors
}

export default paymentMethodValidator
