import { FC, Fragment, useEffect, useRef, useState } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { Input } from "../Input"
import { Check, ChevronDown, X } from "heroicons-react"
import { SearchSelectProps, SearchSelectRenderProps } from "../../../../types"
import * as React from "react"
import SelectOptionSkeleton from "./SelectOptionSkeleton"
import { FieldMetaState } from "react-final-form"

export const SearchSelect: FC<SearchSelectProps> = (props) => {
  // TODO move multiple logic into Select.tsx
  const [open, setOpen] = useState(false)
  const ref = useRef(null)
  const listRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target) && !listRef.current?.contains(e.target)) {
        setOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  useEffect(() => {
    if (props.loading) {
      setOpen(true)
    }
  }, [props.loading])

  const onClickOption = (value: any) => {
    if (props.onSelect) props.onSelect(value)
    if (props.multiple) {
      if (props.value.includes(value)) {
        props.onChange(props.value.filter((v) => v !== value))
      } else {
        // @ts-ignore
        props.onChange([...props.value, value])
      }
    } else {
      if (value === props.value) {
        props.onChange(null)
      } else {
        props.onChange(value)
        setOpen(false)
      }
    }
  }

  const renderInput = () => {
    if ((!props.value && !props.multiple) || (props.value?.length === 0 && props.multiple) || props.loading) {
      return (
        <Input
          containerStyle={{ ...props.containerStyle }}
          className={`border ${props.className}`}
          placeholder={props.placeholder}
          onChange={props.onTextChange}
          onClick={() => setOpen(!open)}
        />
      )
    }

    if (props.multiple) {
      const onClickX = (e: React.MouseEvent, value: any) => {
        e.stopPropagation()
        onClickOption(value)
      }

      return (
        <div
          className={`relative w-full cursor-default rounded-md
            border border-gray-300 bg-white px-3 text-left
            shadow-sm text-base ${props.className}`} style={{ ...props.containerStyle }}
        >
          <span onClick={() => setOpen(!open)} className="flex flex-wrap overflow-y-auto scroll-bar-none">
            {props.value?.length > 0 &&
              props.value.map((v) => (
                <span
                  key={`multi-select-selected-${Math.random()}`}
                  className="flex justify-center items-center m-1 font-medium
                   py-1 px-2 bg-white rounded-full text-gray-900 bg-primary-300
                   border" style={{borderColor: "black"}}
                >
                  <span className="text-xs font-normal leading-none flex-initial">
                    {props.options.find((option) => option.value === v)?.label}
                  </span>
                  {!props.disabledOptions?.includes(v) && (
                    <X className="w-3 h-3 ml-2 cursor-pointer" onClick={(e) => onClickX(e, v)} />
                  )}
                </span>
              ))}
            <div>
              <Input
                placeholder={props.placeholder}
                onChange={(event) => {
                  setOpen(true)
                  props.onTextChange(event)
                }}
              />
            </div>
            {(!props.value || props.value.length === 0) && (
              <div className="text-gray-400 leading-[30px]">{props.placeholder}</div>
            )}
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <ChevronDown />
            </span>
          </span>
        </div>
      )
    }

    return (
      <Listbox.Button
        className={`relative w-full cursor-default rounded-md
          border border-gray-300 bg-white py-2 px-4 text-left
          shadow-sm text-base ${props.className}`}
        style={{ ...props.containerStyle }}
      >
        <div onClick={() => setOpen(!open)}>
          {props.options.find((option) => option.value === props.value)?.label}
          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
            <ChevronDown />
          </span>
        </div>
      </Listbox.Button>
    )
  }

  const renderOptions = () => {
    const inputPosition = ref.current?.getBoundingClientRect()
    return (
      <Listbox value={props.value} onChange={() => ""}>
        {open && (props.loading || props.options.length > 0) && (
          <div ref={listRef}>
            <Transition
              show
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="absolute z-10 mt-1 max-h-[200px] w-full overflow-auto
              rounded-md bg-white text-base shadow-lg ring-1 ring-black
              ring-opacity-5 focus:outline-none border"
                style={{
                  width: inputPosition?.width
                }}
              >
                <>
                  {props.loading && (
                    <>
                      <Listbox.Option
                        key={`search-select-option-${Math.random()}`}
                        className="relative cursor-default select-none py-2 px-3"
                        value={null}
                        disabled={true}
                      >
                        <SelectOptionSkeleton />
                      </Listbox.Option>
                      <Listbox.Option
                        key={`search-select-option-${Math.random()}`}
                        className="relative cursor-default select-none py-2 px-3"
                        value={null}
                        disabled={true}
                      >
                        <SelectOptionSkeleton />
                      </Listbox.Option>
                      <Listbox.Option
                        key={`search-select-option-${Math.random()}`}
                        className="relative cursor-default select-none py-2 px-3"
                        value={null}
                        disabled={true}
                      >
                        <SelectOptionSkeleton />
                      </Listbox.Option>
                      <Listbox.Option
                        key={`search-select-option-${Math.random()}`}
                        className="relative cursor-default select-none py-2 px-3"
                        value={null}
                        disabled={true}
                      >
                        <SelectOptionSkeleton />
                      </Listbox.Option>
                    </>
                  )}
                  {props.options
                    .filter((option) => !props.disabledOptions?.includes(option.value))
                    .map((option) => (
                      <div key={`search-select-option-${Math.random()}`} onClick={() => onClickOption(option.value)}>
                        <Listbox.Option
                          className="relative cursor-default select-none py-2 px-4
                      hover:bg-zinc-100 text-gray-900"
                          value={option.value}
                        >
                          <>
                            <div className={`flex items-center ${props.inputTextClass}`}>
                              <span
                                className={`${
                                  props.value === option.value ? "font-semibold" : "font-normal"
                                } block truncate`}
                              >
                                {option.label}
                              </span>
                            </div>

                            {(!props.multiple && props.value === option.value) ||
                            (props.multiple && props.value.includes(option.value)) ? (
                              <span className="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                                <Check />
                              </span>
                            ) : null}
                          </>
                        </Listbox.Option>
                      </div>
                    ))}
                </>
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    )
  }

  return (
    <>
      <Listbox value={props.value} onChange={() => ""}>
        <div className={"h-min"} ref={ref}>
          {renderInput()}
        </div>
      </Listbox>
      {renderOptions()}
    </>
  )
}

export const renderSearchSelect = (renderProps: SearchSelectRenderProps) => {
  const renderError = (meta: FieldMetaState<any>) => {
    if (meta.touched && meta.error) {
      return <div className="error message">{meta.error}</div>
    }
  }

  const errorClass = renderProps.meta.touched && renderProps.meta.error && "error"
  return (
    <div className={renderProps.className}>
      <SearchSelect {...renderProps.input} {...renderProps} className={`${renderProps.className} ${errorClass}`} />
      {renderError(renderProps.meta)}
    </div>
  )
}
