import { Action, Dispatch } from "redux"
import { mapListToCamelCase, mapToCamelCase } from "../utility"
import { handleAuthorizedRequest, summaries } from "../apis"

export const ANALYSIS_ACTION = {
  FETCH_TOTALS: "FETCH_TOTALS",
  SELECT_DATE: "SELECT_DATE",
  FETCH_SUMMARY: "FETCH_SUMMARY",
  FETCH_ALL_SUMMARY: "FETCH_ALL_SUMMARY",
  SELECT_FILTER: "SELECT_FILTER",
  FETCH_TOP_SONGS: "FETCH_TOP_SONGS",
  FETCH_FILTER_OPTIONS: "FETCH_FILTER_OPTIONS"

}

export const fetchTotals = () => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await summaries("/summary_totals/", "get")

      dispatch({
        type: ANALYSIS_ACTION.FETCH_TOTALS,
        payload: mapListToCamelCase(response.data)
      })
    },
    "Error on fetch totals.",
    dispatch
  )
}

export const fetchFilterOptions = () => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await summaries("/filter_options/", "get")
      dispatch({
        type: ANALYSIS_ACTION.FETCH_FILTER_OPTIONS,
        payload: mapToCamelCase(response.data)
      })
    },
    "Error on fetching filter options.",
    dispatch
  )
}

export const fetchAllSummaries =
  (type: string, value: string | number, affiliate: boolean, startDate?: string, endDate?: string) =>
  async (dispatch: Dispatch<Action>) => {
    // fetchSummaries ile farki bunda sorgu olarak date girmiyoruz. Donen degerler tarihleri ve diger verileri iceren objecelrden olusan bir array olacak
    await handleAuthorizedRequest(
      async () => {
        const affiliateParam = affiliate ? "&affiliate=1" : ""
        const dateParam = startDate && endDate ? `&start_date=${startDate}&end_date=${endDate}` : ""
        const reqUrl = `/analytics_table/?type=${type}&value=${value}${affiliateParam}${dateParam}`

        const response = await summaries(reqUrl, "get")

        dispatch({
          type: ANALYSIS_ACTION.FETCH_ALL_SUMMARY,
          payload: mapListToCamelCase(response.data)
        })
      },
      "Error on fetching all summaries.",
      dispatch
    )
  }

export const selectDate = (date: string) => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ANALYSIS_ACTION.SELECT_DATE,
    payload: date
  })
}

export const selectFilter = (filter: string) => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ANALYSIS_ACTION.SELECT_FILTER,
    payload: filter
  })
}

export const fetchSummaries = (date: string) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await summaries(`/summary_table/?date=${date}`, "get")

      dispatch({
        type: ANALYSIS_ACTION.FETCH_SUMMARY,
        payload: mapToCamelCase(response.data)
      })
    },
    "Error on fetch totals.",
    dispatch
  )
}

export const fetchTopSongs = (date: string) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await summaries(`/user_top_songs/?date=${date}`, "get")

      dispatch({
        type: ANALYSIS_ACTION.FETCH_TOP_SONGS,
        payload: mapListToCamelCase(response.data)
      })
    },
    "Error on fetching top songs.",
    dispatch
  )
}
