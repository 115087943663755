import { ButtonProps } from "../../../types"
import { Link } from "react-router-dom"
import { buttonClasses } from "../../../constants"

const Button = (props: ButtonProps) => {
  const { light, disabled, to, size = "lg", text, type, onClick, buttonType } = props

  let colorClass = light
    ? "bg-white border-gray-300 border-solid border-thin border hover:bg-gray-100 active:bg-gray-200"
    : "bg-primary-300 hover:bg-primary-400 active:bg-primary-500"

  if (disabled) {
    colorClass = light
      ? "bg-white border-gray-300 border-solid border-thin border opacity-30"
      : "bg-primary-300 opacity-30"
  }

  const className = `${colorClass} ${props.className} ${buttonClasses[size]} py-2 px-4 mb-2 rounded-md
          text-black-90 w-full transition ease-in duration-200
          text-center font-medium shadow-sm
          flex justify-center`

  const renderButton = () => {
    if (type === "link") {
      return (
        <Link to={to || "#"} className={className}>
          {text}
        </Link>
      )
    }

    return (
      <button onClick={onClick} className={className} disabled={disabled} type={buttonType}>
        {text}
      </button>
    )
  }

  return renderButton()
}

export default Button
