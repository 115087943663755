import { KInput, KButton } from "kahuna-base-react-components"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

const ReleaseDetailFilterComponents = ({
  filterDataBySearchText
}: {
  filterDataBySearchText: (searchText: string) => void
}) => {
  const [searchText, setSearchText] = useState<string>("")

  const handleChange = (newValue: string) => {
    setSearchText(newValue)
    filterDataBySearchText(newValue)
  }

  return (
    // TODO: icon missing in platform selection dropdown list
    // Bunlara hem selected hem de defaultValue ayarlamak mı gerekiyor?
    <>
      <div className="flex flex-row justify-between items-center">
        <KInput
          width={250}
          onChange={handleChange}
          value={searchText}
          placeholder="Search.."
          leftIcon="/search.svg"
          background="#f5f5f5"
          hoverBackground="#fff"
        />
      </div>
    </>
  )
}

export default ReleaseDetailFilterComponents
