import "./linearProgress.css"
import React from "react"

const LinearProgress = () => {
    return (
        <div className="w-full h-1.5 bg-[#F0F0F0] rounded-full">
            <div className="linearProgress h-1.5 rounded-full"/>
        </div>
    )
}

export default LinearProgress