import { KSpan } from "kahuna-base-react-components"
import React from "react"
import { Track } from "../../../../types"
import AudioPlayer from "../track-detail/AudioPlayer"
import { avatarColorList } from "../../../../constants"

const ReleaseDetailListComponent = ({
  i,
  row,
  playingAudioRefs,
  setPlayingAudioRefs,
  setSelectedTrack
}: {
  i: number
  row: Track
  playingAudioRefs: HTMLAudioElement[]
  setPlayingAudioRefs: (playingAudioRefs: HTMLAudioElement[]) => void
  setSelectedTrack: (selectedTrack: number | undefined) => void
}) => {
  const divHeight = 72

  const formatNumber = (index: number) => {
    return index ? index.toString().padStart(2, "0") : ""
  }

  const getRandomColor = (shareHolderId: number) => {
    const randomIndex = shareHolderId % 8
    return avatarColorList[randomIndex]
  }

  const getInitials = (input: string): string => {
    const filteredInput = input || "-"
    return filteredInput
      .split(",")
      .map((name) => name.trim()[0].toUpperCase())
      .join("")
  }

  return (
    <li
      className={` rounded-[10px] p-[20px] gap-[20px] h-[${divHeight}] table-row-hover cursor-pointer`}
      key={`data-row-${i}`}
      onClick={() => setSelectedTrack(row.id)}
    >
      <div className={`flex flex-row items-center`} style={{ width: "100%" }}>
        <div style={{ width: "5%" }}>
          <KSpan text={formatNumber(i + 1)} lineHeight="20px" fontSize={14} fontWeight={500} color="#737373" />
        </div>

        <div style={{ width: "5%" }}>
          <AudioPlayer
            src={row.audioFileUrl}
            playingAudioRefs={playingAudioRefs}
            setPlayingAudioRefs={setPlayingAudioRefs}
          />
        </div>

        <div style={{ width: "40%" }}>
          <KSpan text={row.title} fontWeight={400} color="#111" fontSize={14} />
        </div>

        <div style={{ width: "13%" }}>
          <div className="flex">
            {row.shareHolders?.map((shareHolder) => {
              return (
                <div
                  key={shareHolder.id}
                  className="w-6 h-6 rounded-[16px] flex justify-center items-center"
                  style={{
                    backgroundColor: getRandomColor(shareHolder.id),
                    marginLeft: -6
                  }}
                >
                  {getInitials(shareHolder.userName)}
                </div>
              )
            })}
          </div>
        </div>

        <div style={{ width: "12%" }}>
          <KSpan
            text={row.shareData ? `${row.shareData.share}%` : "0%"}
            fontWeight={500}
            color="#111"
            hoverText="Royalty Share"
          />
        </div>

        <div style={{ width: "25%" }}>
          <KSpan
            text={row.shareData && row.shareData.roles.length > 0 ? row.shareData.roles.join(", ") : "-"}
            fontWeight={500}
            color="#111"
            hoverText="Roles"
          />
        </div>
      </div>
    </li>
  )
}

export default ReleaseDetailListComponent
