import { KButton, KSpan, KTitleSpan } from "kahuna-base-react-components"
import React, { useEffect, useRef, useState } from "react"
//@ts-ignore
import ArrowLeftIcon from "../../../../../assets/release-registry-icons/arrow-left.svg"
import { ReleaseRegistryReleaseTabs } from "../../../../../actions/types"
//@ts-ignore
import UploadCloudIcon from "../../../../../assets/release-registry-icons/upload-cloud.svg"
//@ts-ignore
import LoadingIcon from "../../../../../assets/release-registry-icons/loader.svg"
//@ts-ignore
import DeleteFileIcon from "../../../../../assets/release-registry-icons/delete-bin.svg"
//@ts-ignore
import DeleteFileRedIcon from "../../../../../assets/release-registry-icons/delete-bin-red.svg"
//@ts-ignore
import CancelIcon from "../../../../../assets/release-registry-icons/cancel.svg"
//@ts-ignore
import JpgIcon from "../../../../../assets/release-registry-icons/jpg-format.svg"
//@ts-ignore
import ErrorIcon from "../../../../../assets/release-registry-icons/error-in-upload.svg"
//@ts-ignore
import CompletedIcon from "../../../../../assets/release-registry-icons/completed.svg"
import { useOrchestrator } from "../ReleaseRegistryContextProvider"
import LinearProgress from "../component/linear-progress/LinearProgress"

const Artwork = ({ setSelectedTab }: { setSelectedTab: (tab: ReleaseRegistryReleaseTabs) => void }) => {
  const { releaseForm, updateProduct, setReleaseForm } = useOrchestrator()
  const fileReaderRef = useRef<FileReader | null>(null) // Ref to store FileReader instance
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [dragging, setDragging] = useState<boolean>(false)

  const [errorInUpload, setErrorInUpload] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const deleteFile = () => {
    delete releaseForm.product.data.attributes["front-cover"]
    setReleaseForm({ ...releaseForm })
    fileInputRef.current!.value = "" //it is necessary to be able to choose the current file in the next step.
  }

  const cancelUpload = () => {
    delete releaseForm.product.data.attributes["front-cover"]
    setReleaseForm({ ...releaseForm })
    if (fileReaderRef.current) {
      fileReaderRef.current.abort()
      setLoading(false)
    }
    fileInputRef.current!.value = ""
  }

  const handleImageChange = (e: any, attributeName: string) => {
    setErrorInUpload(false)
    setLoading(true)
    if (e.target.files.length > 0) {
      const file: File = e.target.files[0]
      if (file) {
        const reader = new FileReader()
        fileReaderRef.current = reader

        reader.onloadend = () => {
          updateProduct(attributeName, reader.result)
        }
        reader.onerror = () => {
          setErrorInUpload(true)
          setLoading(false)
        }

        // Forced error for testing purposes (it is only for test, causes to forced error. Do not use it)
        /*setTimeout(() => {
          const errorEvent = new ProgressEvent('error')
          reader.dispatchEvent(errorEvent)
        }, 1000)*/

        reader.readAsDataURL(file)
      } else {
        setLoading(false)
      }

      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
  }
  const handleDragOver = (e: any) => {
    e.preventDefault()
    setDragging(true)
  }

  const handleDragLeave = () => {
    setDragging(false)
  }

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()
    setDragging(false)

    const file = e.dataTransfer.files[0]
    if (file) {
      handleImageChange({ target: { files: [file] } }, "front-cover")
    }
  }

  const renderUploadedFile = () => {
    return (
      <div key={`file`} style={{ width: "100%", display: "flex" }}>
        <div
          className="flex flex-col gap-4 w-full p-4 pl-[14px] "
          style={{
            border: !errorInUpload ? "1px solid #E7E7E7" : "1px solid #E52B2B",
            borderRadius: "10px"
          }}
        >
          <div key={""} className={"flex justify-between items-center "}>
            <div className="flex flex-row items-center justify-start gap-3">
              <div className="h-10 flex items-center justify-center rounded-[4px]">
                <img
                  className="h-10 w-auto rounded-[4px]"
                  src={
                    releaseForm.product.data.attributes["front-cover"] ||
                    releaseForm.product.data.attributes["cover-url"]
                  }
                />
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row gap-1 items-center">
                  <img src={errorInUpload ? ErrorIcon : loading ? LoadingIcon : CompletedIcon} />
                  <span className="flex flex-row items-center pt-0.5">
                    <KSpan
                      text={errorInUpload ? "Failed" : loading ? "Uploading..." : "Completed"}
                      fontSize={12}
                      lineHeight="12px"
                      color="#1F1F1F"
                    />
                  </span>
                </div>
                {errorInUpload && (
                  <div
                    className="p-0 flex mt-1"
                    onClick={() => {
                      handleBrowseClick()
                    }}
                  >
                    <KSpan
                      text="Try Again"
                      fontSize={12}
                      fontWeight={500}
                      lineHeight="16px"
                      hoverStyle={{
                        textDecoration: "underline",
                        cursor: "pointer"
                      }}
                      color="#E52B2B"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="self-start">
              <KButton
                icon={errorInUpload ? DeleteFileRedIcon : loading ? CancelIcon : DeleteFileIcon}
                shadowDisabled={true}
                padding="2px"
                width="24px"
                height="24px"
                borderRadius={6}
                background="transparent"
                hoverBackground="#E7E7E7"
                onClick={loading ? cancelUpload : deleteFile}
              />
            </div>
          </div>
          {loading && (
            <div className="w-full h-auto ">
              <LinearProgress />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="w-[616px] max-sm:w-auto flex flex-col gap-6">
      <div className="flex flex-col gap-3 justify-start">
        <div className="flex flex-row gap-4 items-center">
          <div>
            <KButton
              icon={ArrowLeftIcon}
              onClick={() => {
                setSelectedTab(ReleaseRegistryReleaseTabs.RELEASE_ARTIST)
              }}
              width="36px"
              height="36px"
              padding="8px"
              background="#F0F0F0"
              shadowDisabled
              hoverBackground="#E7E7E7"
            />
          </div>
          <KTitleSpan text="Artwork" fontSize={40} lineHeight="48px" letterSpacing="-0.4px" color="#1F1F1F" />
        </div>
        <KSpan text="Your Spotify artist name uniquely identifies you on the platform." />
      </div>
      <div>
        <div
          className={`bg-[#F5F5F5] hover:bg-[#F0F0F0] rounded-[10px]`}
          style={{
            display:
              !releaseForm.product.data.attributes["front-cover"] && !releaseForm.product.data.attributes["cover-url"]
                ? ""
                : "none",
            backgroundColor: dragging ? "rgba(0, 0, 0, 0.32)" : ""
          }}
        >
          <label
            htmlFor="file"
            className={`${dragging ? "bg-[rgba(0, 0, 0, 0.32)]" : "bg-transparent"}
            `}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div className="flex items-center justify-center w-full">
              {
                <div className="flex flex-col items-center justify-center gap-5 p-8">
                  <div className={"flex justify-center"} style={{ height: 40 }}>
                    <img src={UploadCloudIcon} />
                  </div>
                  <div className={"flex flex-col gap-1 justify-center items-center"}>
                    <KSpan text={"Choose a file or drag & drop it here."} color={"#111"} fontWeight={500} />
                    <KSpan
                      text={"Cover art should be in JPG or JPEG format, with a maximum size of 10 MB."}
                      fontSize={12}
                      lineHeight="16px"
                    />
                  </div>
                  <div
                    style={{
                      pointerEvents: "auto"
                    }}
                  >
                    <KButton
                      text="Browse File"
                      onClick={handleBrowseClick}
                      height="32px"
                      padding="6px 10px"
                      background="#FFF"
                      textColor="#111"
                    />
                  </div>
                </div>
              }
              <input
                className="hidden"
                ref={fileInputRef}
                id="file"
                type="file"
                accept=".jpeg, .png, .jpg"
                multiple
                onChange={(e) => {
                  handleImageChange(e, "front-cover")
                }}
              />
            </div>
          </label>
        </div>

        {releaseForm.product.data.attributes["front-cover"] && renderUploadedFile()}
        {releaseForm.product.data.attributes["cover-url"] &&
          !releaseForm.product.data.attributes["front-cover"] &&
          renderUploadedFile()}
      </div>
      <div>
        <KButton
          text="Continue"
          onClick={() => {
            setSelectedTab(ReleaseRegistryReleaseTabs.GENRES)
          }}
          background="#000"
          textColor="#FFF"
          disabled={!releaseForm.product.data.attributes["front-cover"] && !releaseForm.product.data.attributes["cover-url"]}
        />
      </div>
    </div>
  )
}

export default Artwork
