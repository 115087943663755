import { AdminCollaboratorShareForm } from "../../../../types"
import { emailPattern } from "../../../../constants"

const adminCollaboratorValidator = (formValues: AdminCollaboratorShareForm) => {
  const errors: any = {}
  if (!formValues.email) {
    errors.email = "You must provide an email."
  } else if (!formValues.email.match(emailPattern)) {
    errors.email = "You must enter a valid email"
  }

  if (!formValues.share) {
    errors.share = "You must enter share"
  } else if (!/^\d*\.?\d*$/.test(formValues.share)) {
    errors.share = "You must enter a number"
  } else if (Number(formValues.share) > 100) {
    errors.share = "Share can't be greater that 100"
  }

  if (formValues.roles?.length === 0) {
    errors.roles = "You must select a role"
  }

  return errors
}

export default adminCollaboratorValidator
