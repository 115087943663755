import { AuthUser, ForgotPasswordFormValues, LoginFormValues, ResetPasswordFormValues } from "../types"
import { Action, Dispatch } from "redux"
import { mapToCamelCase, mapToSnakeCase } from "../utility"
import { toast } from "react-toastify"
import { errorToastOptions, successToastOptions } from "../constants"
import { codeValidation as codeValidationApi, authLogin } from "../apis"

export const AUTH_ACTION = {
  LOGIN: "LOGIN",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGOUT: "LOGOUT",
  LOGOUT_ERROR: "LOGOUT_ERROR",
  UPDATE_USER: "UPDATE_USER",
  UPLOAD_PROFILE_PHOTO: "UPLOAD_PROFILE_PHOTO",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  GET_ROLE: "GET_ROLE"
}
export const login = (formValues: LoginFormValues, callback: () => void) => async (dispatch: Dispatch<Action>) => {
  try {
    localStorage.removeItem("appliedAffiliate")
    const response = await authLogin.post("/", formValues)
    const data = mapToCamelCase<AuthUser>(response.data)

    dispatch({
      type: AUTH_ACTION.LOGIN,
      payload: data
    })
    callback()
  } catch (e) {
    console.log(e)
    toast.error("Incorrect authentication credentials!", errorToastOptions)
    dispatch({
      type: AUTH_ACTION.LOGIN_ERROR,
      payload: e
    })
  }
}
export const logout = (callback: () => void) => async (dispatch: Dispatch<Action>) => {
  localStorage.removeItem("appliedAffiliate")
  dispatch({
    type: AUTH_ACTION.LOGOUT
  })
  callback()
}
export const forgotPassword = (formValues: ForgotPasswordFormValues, callback: Function) => async () => {
  try {
    toast.loading("Password reset email is being prepared...", { toastId: AUTH_ACTION.FORGOT_PASSWORD })
    const response = await codeValidationApi.post("/send_validation/", mapToSnakeCase(formValues))
    toast.dismiss(AUTH_ACTION.FORGOT_PASSWORD)
    if (response.status === 200) {
      toast.success("The code for changing password has been sent to your email address", successToastOptions)
      callback()
    } else {
      toast.error("An error occurred during validation code sending process.", errorToastOptions)
    }
  } catch {
    toast.error("An error occurred during validation code sending process.", errorToastOptions)
  }
}
export const resetPassword = (formValues: ResetPasswordFormValues, callback?: Function) => async () => {
  try {
    toast.loading("Your password is being changed...", { toastId: AUTH_ACTION.RESET_PASSWORD })
    const response = await codeValidationApi.put("/change_password/", formValues)
    toast.dismiss(AUTH_ACTION.RESET_PASSWORD)
    if (response.status === 200) {
      toast.success("You password changed successfully.", successToastOptions)
      callback()
    } else {
      toast.error("An error occurred during password change.", errorToastOptions)
    }
  } catch {
    toast.error("An error occurred during password change.", errorToastOptions)
  }
}
