import { Action, PublicState } from "../types"
import { PUBLIC_ACTION } from "../actions/publicActions"

export default (state: PublicState = {}, action: Action) => {
  switch (action.type) {
    case PUBLIC_ACTION.APPROVE_INVITATION:
      return { ...state, invitationStatus: action.payload }
    default:
      return state
  }
}
