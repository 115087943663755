import * as React from "react"
import { FC } from "react"
import { ToggleProps, ToggleRenderProps } from "../../../types"

const Toggle: FC<ToggleProps> = (props) => {
  return (
    <div className={`relative inline-block w-10 mr-2 align-middle select-none h-[30px] w-[50px] ${props.className}`}>
      <input
        type="checkbox"
        name={props.name}
        id={`${props.name}-toggle`}
        checked={props.checked}
        onChange={props.onChange}
        disabled={props.disabled}
        className={`checked:right-[1px] outline-none focus:outline-none right-[21px]
          duration-200 ease-in absolute block rounded-full bg-white border-4 border-gray-100
          appearance-none cursor-pointer w-[28px] h-[28px] mt-[1px] ${props.inputClass}`}
      />
      <label
        htmlFor={`${props.name}-toggle`}
        className={`h-[30px] w-[50px] block overflow-hidden
        rounded-full cursor-pointer 
        ${props.checked ? "bg-primary-300" : "bg-gray-200"} ${props.labelClass}`}
      />
    </div>
  )
}

export const renderToggle = (renderProps: any) => {
  return <Toggle {...renderProps.input} {...renderProps} />
}
