import * as React from "react"
import { FC, useEffect, useState } from "react"
import Table from "../../../ui/table/Table"
import {
  AnalysisSummaries,
  AnalysisSummary,
  CurrencyExchange,
  ReportsApprovePaymentRequest,
  SelectOption,
  User
} from "../../../../types"
import { connect } from "react-redux"
import { RootState } from "../../../../store"
import { ANALYSIS_FILTER, analysisFilterOptions, errorToastOptions, successToastOptions } from "../../../../constants"
import { Select } from "../../../ui/form/select/Select"
import {
  reportsApprovalAllPlatformTotalColumns,
  reportsApprovalPlatformColumns,
  reportsApprovalStoreFrontColumns,
  reportsApprovalTrackColumns
} from "./columns/reportsApprovalColumns"
import { approveSummary, fetchApprovalSummaries, selectFilter, selectUser } from "../../../../actions/adminActions"
import {
  currencyExchange,
  handleAuthorizedRequest,
  summaries as summariesApi,
  userPayments,
  users
} from "../../../../apis"
import ApproveModal from "./modal/ApproveModal"
import { toast } from "react-toastify"
import { mapListToCamelCase, mapToSnakeCase } from "../../../../utility"
import Tooltip from "../../../ui/Tooltip"
import { CurrencyIcon, SumIcon } from "../../../ui/icons"
import { sum } from "lodash"
import { SearchSelect } from "../../../ui/form/select/SearchSelect"
import Button from "../../../ui/form/Button"
import ApproveAllModal from "./modal/ApproveAllModal"
import ApprovePaymentModal from "./modal/ApprovePaymentModal"
import CurrencyExchangeModal from "./modal/CurrenyExchangeModal"
import { KButton } from "kahuna-base-react-components"

type ReportsApprovalProps = {
  selectFilter: (filter: string) => void
  summaries: AnalysisSummaries
  selectedFilter: string
  fetchApprovalSummaries: (
    type: string,
    filters?: { [key: string]: string | number | boolean },
    sortField?: string,
    sortDirection?: "asc" | "desc"
  ) => Promise<void>
  approveSummary: (summary: AnalysisSummary, type: string) => Promise<void>
}

const ReportsApproval: FC<ReportsApprovalProps> = (props) => {
  const [loading, setLoading] = useState(true)
  const [timeoutId, setTimeoutId] = useState(null)
  const [periods, setPeriods] = useState<SelectOption[]>([])
  const [selectedPeriod, setSelectedPeriod] = useState<string>(null)
  const [data, setData] = useState<AnalysisSummary[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const [approveAllModalOpen, setApproveAllModalOpen] = useState(false)
  const [approvePaymentModalOpen, setApprovePaymentModalOpen] = useState(false)
  const [userPaymentPeriodId, setUserPaymentPeriodId] = useState<number | undefined>()
  const [editedSummary, setEditedSummary] = useState<AnalysisSummary>()
  const [currencyRates, setCurrencyRates] = useState<CurrencyExchange[]>([])
  const [userOptions, setUserOptions] = useState<SelectOption[]>([])
  const [userLoading, setUserLoading] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User>(null)
  const [columns, setColumns] = useState(reportsApprovalPlatformColumns)
  const [offset, setOffset] = useState(0)
  const [currencyOfferModal, setCurrencyOfferModal] = useState(false)

  const { approveSummary, fetchApprovalSummaries, selectedFilter, selectFilter, summaries } = props
  const limit = 18

  useEffect(() => {
    const getSummaryPeriods = async () => {
      await handleAuthorizedRequest(
        async () => {
          const response = await summariesApi("/summary_periods", "get")
          if (response.status === 200) {
            const sortedList = response.data.sort().reverse()
            setPeriods(sortedList.map((period) => ({ label: period, value: period })))
            setSelectedPeriod(sortedList[0])
          }
        },
        "Error on fetch summery periods.",
        null
      )
    }
    getSummaryPeriods().then()
    selectFilter(ANALYSIS_FILTER.TOP_PLATFORMS)
  }, [])

  useEffect(() => {
    if (selectedFilter === "platform") {
      setColumns(reportsApprovalPlatformColumns)
    } else if (selectedFilter === "topTracks") {
      setColumns(reportsApprovalTrackColumns)
    } else if (selectedFilter === "storeFront") {
      setColumns(reportsApprovalStoreFrontColumns)
    } else if (selectedFilter === "allPlatformTotal") {
      setColumns(reportsApprovalAllPlatformTotalColumns)
    }
    setData(summaries[selectedFilter]?.slice(offset, limit + offset))
    setUserPaymentPeriodId(null)
    if (selectedUser?.id) {
      ;[ANALYSIS_FILTER.TOP_COUNTRIES, ANALYSIS_FILTER.TOP_PLATFORMS, ANALYSIS_FILTER.TOP_TRACKS].forEach((filter) => {
        if (summaries[filter].length > 0 && summaries[filter][0].paymentPeriodId) {
          setUserPaymentPeriodId(summaries[filter][0].paymentPeriodId)
        }
      })
    }
  }, [summaries, selectedFilter])

  useEffect(() => {
    const getCurrencies = async () => {
      const currencyResponse = await currencyExchange(`/?date=${selectedPeriod}`, "get")
      if (currencyResponse.status === 200) {
        setCurrencyRates(mapListToCamelCase(currencyResponse.data.results))
      } else {
        toast.error("Error while fetching currencies.", errorToastOptions)
      }
    }
    if (selectedPeriod) {
      getCurrencies().then()
    }
  }, [selectedPeriod])

  useEffect(() => {
    setData(summaries[selectedFilter]?.slice(0, limit))
    setOffset(0)
  }, [selectedPeriod, selectedFilter])

  const onSortOrFilterChange = async (
    filters: { [key: string]: string | number | boolean },
    sortField: string,
    sortDirection?: "asc" | "desc",
    debounce?: boolean
  ) => {
    setLoading(true)
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    setTimeoutId(
      //@ts-ignore
      setTimeout(
        () => {
          fetchApprovalSummaries(selectedFilter, filters, sortField, sortDirection).then(() => setLoading(false))
        },
        debounce ? 500 : 0
      )
    )
  }

  const onClickPage = (pageNumber: number) => {
    setData(summaries[selectedFilter]?.slice(limit * (pageNumber - 1), limit * pageNumber))
    setOffset((pageNumber - 1) * limit)
  }

  const onSelectRow = (index) => {
    if (index !== null && index >= 0 && selectedFilter === ANALYSIS_FILTER.TOP_PLATFORMS) {
      setEditedSummary(data[index])
      setModalOpen(true)
    }
  }

  const approveReportSummary = async (values: AnalysisSummary) => {
    await approveSummary({ ...values, totalNetAll: values.totalNet }, selectedFilter)
    setModalOpen(false)
  }

  const currencyTooltipContent = (
    <div>
      Currency rates for {selectedPeriod}
      <ul className={"pt-1"}>
        {currencyRates.map((currency) => (
          <li
            className={"mt-1 px-1"}
            key={currency.exchangeRates}
          >{`- ${currency.currencyTypeDetail.name} : ${currency.exchangeRates}`}</li>
        ))}
      </ul>
    </div>
  )

  const sumTooltipContent = (
    <div>
      Totals for {selectedPeriod}
      <ul className={"pt-1"}>
        <li className={"mt-1 px-1"}>{`- GROSS : ${sum(data.map((d) => parseFloat(String(d.totalAmount)))).toFixed(
          2
        )}`}</li>
        <li className={"mt-1 px-1"}>{`- NET : ${sum(data.map((d) => parseFloat(String(d.totalNet)))).toFixed(2)}`}</li>
      </ul>
    </div>
  )

  const onSearchTextChanged = async (event) => {
    setUserOptions([])
    setUserLoading(true)
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    setTimeoutId(
      //@ts-ignore
      setTimeout(async () => {
        await handleAuthorizedRequest(async () => {
          const response = await users(`/?limit=10&offset=0&username=${event.target.value}`, "get")
          setUserOptions(
            response.data.results.map((user) => ({
              value: user,
              label: user.username
            }))
          )
          setUserLoading(false)
        }, "")
      }, 500)
    )
  }

  const approveTable = async () => {
    const response = await summariesApi(
      `/approve_status/?approve=True&date=${selectedPeriod}&type=all&user=${selectedUser.id}`,
      "put"
    )
    if (response.status === 200) {
      setApproveAllModalOpen(false)
      toast.success(response.data, successToastOptions)
    }
  }

  const approvePayment = async (paymentDay: string, date: string[]) => {
    if (!selectedUser.id) {
      return
    }
    const requestBody: ReportsApprovePaymentRequest = {
      userId: selectedUser.id,
      date,
      paymentDay
    }
    const response = await userPayments(`/approve_report_payment/`, "post", mapToSnakeCase(requestBody))
    if (response.status === 200) {
      setApprovePaymentModalOpen(false)
      toast.success(`Successfully updated ${response.data.count} records`, successToastOptions)
    } else {
      toast.error("Error payment approve", errorToastOptions)
    }
  }

  const topFilters = (
    <div className="flex gap-2">
      {selectedUser?.id && userPaymentPeriodId && (
        <KButton text={"Approve Payment"} onClick={() => setApprovePaymentModalOpen(true)} />
      )}
      {selectedUser?.id && selectedFilter === ANALYSIS_FILTER.TOP_PLATFORMS && (
        <KButton text={"Approve Table"} onClick={() => setApproveAllModalOpen(true)} />
      )}
      <Tooltip width={200} content={sumTooltipContent}>
        <div className="cursor-pointer" onClick={() => onClickTotals()}>
          <SumIcon className={"w-[40px] h-[40px] mt-[2px] mr-1 "} />
        </div>
      </Tooltip>
      {currencyRates.length > 0 && (
        <Tooltip width={200} content={currencyTooltipContent}>
          <div className="cursor-pointer" onClick={() => setCurrencyOfferModal(true)}>
            <CurrencyIcon className={"w-[40px] h-[40px] mt-[2px] mr-1"} />
          </div>
        </Tooltip>
      )}
      <div className="relative">
        <SearchSelect
          className="!w-[220px]"
          inputTextClass="text-[14px]"
          placeholder={"Search user to filter"}
          options={userOptions}
          onTextChange={onSearchTextChanged}
          loading={userLoading}
          value={selectedUser}
          // @ts-ignore
          onChange={setSelectedUser}
        />
      </div>
      <Select
        className="!w-[120px]"
        inputTextClass="text-[14px]"
        options={periods}
        value={selectedPeriod}
        onSelect={setSelectedPeriod}
      />
      <Select
        className="!w-[200px]"
        inputTextClass="text-[14px]"
        options={analysisFilterOptions}
        value={selectedFilter}
        onSelect={selectFilter}
      />
    </div>
  )

  return (
    <div className={"container pl-1 mt-8"}>
      <Table
        title={"Reports Approval"}
        size={"md"}
        topRightElement={topFilters}
        columns={columns}
        data={data}
        loading={loading}
        pagination
        minRowCount={limit}
        limit={limit}
        onSortOrFilterChange={onSortOrFilterChange}
        count={summaries[selectedFilter]?.length}
        onClickPage={onClickPage}
        additionalFilters={{ date: selectedPeriod, ...(selectedUser?.id && { user__username: selectedUser.username }) }}
        onSelectRow={onSelectRow}
      />
      <ApproveModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        summary={editedSummary}
        approveSummary={approveReportSummary}
      />
      <ApproveAllModal
        open={approveAllModalOpen}
        onClose={() => setApproveAllModalOpen(false)}
        approve={approveTable}
      />
      <ApprovePaymentModal
        selectedPeriod={selectedPeriod}
        userPaymentPeriodId={userPaymentPeriodId}
        selectedUsername={selectedUser?.username}
        open={approvePaymentModalOpen}
        onClose={() => setApprovePaymentModalOpen(false)}
        approve={approvePayment}
      />
      <CurrencyExchangeModal open={currencyOfferModal} onClose={() => setCurrencyOfferModal(false)} />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedFilter: state.admin.selectedFilter,
    summaries: state.admin.summaries
  }
}

export default connect(mapStateToProps, { fetchApprovalSummaries, selectFilter, approveSummary })(ReportsApproval)
