import { Dialog } from "@headlessui/react"
import React, { FC } from "react"
import { X } from "heroicons-react"
import { Affiliate } from "../../../../types"
import { KSpan } from "kahuna-base-react-components"

type AdminAffiliateToUserModalProps = {
  open: boolean
  onClose: () => void
  affiliateList: Affiliate[]
}
const AdminAffiliateToUserModal: FC<AdminAffiliateToUserModalProps> = (props) => {
  const { onClose, open } = props

  const onDialogClose = () => {
    onClose()
  }

  return (
    <Dialog open={open} onClose={() => onDialogClose()} className="relative z-50">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="bg-white elev-b-sm rounded-lg overflow-hidden">
          <div className={`flex justify-end`}>
            <X className="h-4 cursor-pointer mt-1" style={{ position: "absolute" }} onClick={() => onDialogClose()} />
          </div>
          <div className="flex">
            <div style={{ height: "60vh", width: "80vh", padding: 42, borderLeft: "1px solid grey" }}>
              <div className="text-2xl font-bold pb-6">{"Affiliate To List"}</div>
              <div style={{ overflowY: "scroll", height: "90%" }}>
                {props.affiliateList.map((affiliate: Affiliate) => {
                  return (
                    <div
                      className="flex justify-between pb-2 pt-2"
                      style={{ borderBottom: "1px dashed grey" }}
                      key={affiliate.fromUserId}
                    >
                      <div className="grid">
                        <KSpan
                          text={`${affiliate.fromUser?.email || ""} (${affiliate.fromUser?.firstName} ${
                            affiliate.fromUser?.lastName
                          })`}
                        />
                        <KSpan text={`Commission: ${affiliate.commission?.toString() || "-"}`} />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default AdminAffiliateToUserModal
