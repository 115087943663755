import * as React from "react"
import { FC, useEffect, useState } from "react"
import { AdminUserProps, SelectOption, User } from "../../../../types"
import Button from "../../../ui/form/Button"
import Table from "../../../ui/table/Table"
import adminUserColumns from "./columns/adminUserColumns"
import AdminUserForm from "./AdminUserForm"
import { connect } from "react-redux"
import {
  fetchAllUsers,
  fetchLastUpdate,
  refreshLabelCamp,
  refreshReports,
  refreshSummaries,
  saveUser,
  selectUser
} from "../../../../actions/adminActions"
import { RootState } from "../../../../store"
import { adminTableLimit } from "../../../../constants"
import { toast } from "react-toastify"
import Tooltip from "../../../ui/Tooltip"
import { handleAuthorizedRequest, summaries as summariesApi } from "../../../../apis"
import { Select } from "../../../ui/form/select/Select"
import { RefreshIcon } from "../../../ui/icons"

const AdminUser: FC<AdminUserProps> = (props) => {
  const [loading, setLoading] = useState(true)
  const [timeoutId, setTimeoutId] = useState(null)
  const [periods, setPeriods] = useState<SelectOption[]>([])
  const [selectedPeriod, setSelectedPeriod] = useState<string>(null)
  const [refreshSummaryForUser, setRefreshSummaryForUser] = useState<boolean>(false)

  useEffect(() => {
    props.fetchAllUsers(adminTableLimit, 0, {}).then(() => setLoading(false))
    props.fetchLastUpdate().then()
  }, [])

  const onClickPage = async (
    pageNumber: number,
    filters: { [key: string]: string | number | boolean },
    sortField?: string,
    sortDirection?: "asc" | "desc"
  ) => {
    setLoading(true)
    await props.fetchAllUsers(adminTableLimit, (pageNumber - 1) * adminTableLimit, filters, sortField, sortDirection)
    setLoading(false)
  }

  const onSortOrFilterChange = async (
    filters: { [key: string]: string | number | boolean },
    sortField: string,
    sortDirection?: "asc" | "desc",
    debounce?: boolean
  ) => {
    clearTimeout(timeoutId)
    setTimeoutId(
      setTimeout(
        async () => {
          setLoading(true)
          await props.fetchAllUsers(adminTableLimit, 0, filters, sortField, sortDirection)
          setLoading(false)
        },
        debounce ? 500 : 0
      )
    )
  }

  const onClickAdd = () => {
    props.selectUser(new User())
  }

  const onSelectRow = (index: number) => {
    props.selectUser(props.paginatedUsers.results[index])
  }

  const onRefreshLabelCamp = async () => {
    if (selectedPeriod) {
      try {
        await props.refreshLabelCamp()
        props.fetchLastUpdate().then()
      } catch (error) {
        console.error(error)
        toast.error("Failed to refresh Label Camp.")
      }
    } else {
      toast.error("Please select a period.")
    }
  }

  const onRefreshReports = async () => {
    if (selectedPeriod) {
      try {
        await props.refreshReports(selectedPeriod)
        props.fetchLastUpdate().then()
      } catch (error) {
        console.error(error)
        toast.error("Failed to refresh reports.")
      }
    } else {
      toast.error("Please select a period.")
    }
  }

  const onRefreshSummaries = async () => {
    if (selectedPeriod) {
      try {
        const userId = props.selectedUser && refreshSummaryForUser ? props.selectedUser.id : 0
        await props.refreshSummaries(selectedPeriod, userId)
        props.fetchLastUpdate().then()
      } catch (error) {
        console.error(error)
        toast.error("Failed to refresh summaries.")
      }
    } else {
      toast.error("Please select a period.")
    }
  }

  useEffect(() => {
    const getSummaryPeriods = async () => {
      await handleAuthorizedRequest(
        async () => {
          const response = await summariesApi("/summary_periods", "get")
          if (response.status === 200) {
            const sortedList = response.data.sort().reverse()
            setPeriods(sortedList.map((period) => ({ label: period, value: period })))
            setSelectedPeriod(sortedList[0])
          }
        },
        "Error on fetching summary periods.",
        null
      )
    }
    getSummaryPeriods().then()
  }, [])

  const renderButtons = () => (
    <div className="flex items-end">
      <div className="ml-1">
        <Button text="Add" onClick={onClickAdd} className="w-max font-medium !text-xs !py-1 leading-3 !mb-0" />
      </div>
      <Tooltip
        place="bottom"
        content={
          <div>
            BMV & Labelcamp Assets
            <br />
            Last update:
            <br />
            {props.lastUpdate ? props.lastUpdate : "N/A"}
          </div>
        }
        type="info"
      >
        <div className="ml-3">
          <Button
            text={
              <div className="flex items-center justify-between">
                <RefreshIcon className="w-3.5 h-3.5" />
                <div className="ml-1">Assets</div>
              </div>
            }
            onClick={onRefreshLabelCamp}
            className="w-max font-medium !text-xs !py-1 leading-3 !mb-0"
          />
        </div>
      </Tooltip>
      <div className="ml-3">
        <Button
          text={
            <div className="flex items-center justify-between">
              <RefreshIcon className="w-3.5 h-3.5" />
              <div className="ml-1">Reports</div>
            </div>
          }
          onClick={onRefreshReports}
          className="w-max font-medium !text-xs !py-1 leading-3 !mb-0"
        />
      </div>
      <div className="ml-3">
        <Button
          text={
            <div className="flex items-center justify-between">
              <RefreshIcon className="w-3.5 h-3.5" />
              <div className="ml-1">Summaries</div>
            </div>
          }
          onClick={onRefreshSummaries}
          className="w-max font-medium !text-xs !py-1 leading-3 !mb-0"
        />
      </div>
      <div className="relative ml-3">
        <Select
          className="!w-[112px]"
          inputTextClass="text-xs items-center"
          options={periods}
          value={selectedPeriod}
          onSelect={setSelectedPeriod}
          buttonClass="!h-6 !py-0 !pl-2 !pr-1"
        />
      </div>
    </div>
  )

  return (
    <div className="mt-2 mb-2 container flex gap-4 pl-1">
      <Table
        title="Users"
        size={"sm"}
        className="flex-grow"
        pagination
        limit={adminTableLimit}
        columns={adminUserColumns}
        minRowCount={adminTableLimit}
        data={props.paginatedUsers.results}
        count={props.paginatedUsers.count}
        onClickPage={onClickPage}
        topRightElement={renderButtons()}
        onSelectRow={onSelectRow}
        loading={loading}
        onSortOrFilterChange={onSortOrFilterChange}
      />
      <AdminUserForm
        refreshSummaryForUser={refreshSummaryForUser}
        setRefreshSummaryForUser={setRefreshSummaryForUser}
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    paginatedUsers: state.admin.paginatedUsers,
    selectedUser: state.admin.selectedUser,
    lastUpdate: state.admin.lastUpdate
  }
}

export default connect(mapStateToProps, {
  fetchAllUsers,
  saveUser,
  selectUser,
  fetchLastUpdate,
  refreshLabelCamp,
  refreshReports,
  refreshSummaries
})(AdminUser)
