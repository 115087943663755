import * as React from "react"
import Router from "../router/Router"
import "react-toastify/dist/ReactToastify.css"
import "../styles/index.css"
import IToastContainer from "./ui/toast/IToastContainer"

const App = () => (
  <>
    <Router />
    <IToastContainer />
  </>
)

export default App
