import { KButton, KSpan } from "kahuna-base-react-components"
import React from "react"

const CalendarDate = ({ quarter }: { quarter: string }) => {
  //prop olan quarter data'dan gelir.
  //const [year, quar] = quarter.split(' ')
  //svg elemanlari public icine ekledndi. Cunku baska bir klasore eklendiginde prop olarak
  //girildiginde relative pathden dolayi svg bulunamiyor.
  const [year, quar] = quarter!.split(" ")

  return (
    <div className="flex flex-row h-full items-center gap-2 ">
      <div>
        <img
          src="/reports_icons/calendar-line.svg"
          style={{ backgroundColor: "#F5F5F5", borderRadius: 999, padding: 10 }}
        />
      </div>
      <div className="flex flex-col ml-2 ">
        <KSpan
          text={year}
          color="#111"
          fontWeight={500}
          fontSize={16}
          lineHeight="24px"
          letterSpacing="-0.176px"
        ></KSpan>
        <KSpan text={quar} color="#737373" fontWeight={500}></KSpan>
      </div>
    </div>
  )
}

export default CalendarDate
