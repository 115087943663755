import { FC } from "react"

const SelectOptionSkeleton: FC = () => {
  return (
    <div className="h-9 mx-auto border-1 rounded-md">
      <div className="flex flex-row items-center justify-start h-full space-x-5 animate-pulse">
        <div className="flex flex-col space-y-3">
          <div className="h-3 bg-gray-300 rounded-md w-[200px]" />
        </div>
      </div>
    </div>
  )
}

export default SelectOptionSkeleton
