import { platformIcons, chartLabels } from "../../../constants"
import { addCommasToNumber, convertToMonthYear, getCurrency, platformLogos } from "../../../utility"
import { ChartData } from "./AnalysisLineChart"

export const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div")

  if (!tooltipEl) {
    tooltipEl = document.createElement("div")
    tooltipEl.style.background = "rgba(255, 255, 255, 1)"
    tooltipEl.style.border = "1px solid #F3F3F3"
    tooltipEl.style.boxShadow = "0px 2px 4px 0px rgba(27, 28, 29, 0.04)"
    tooltipEl.style.borderRadius = "6px"
    tooltipEl.style.color = "black"
    tooltipEl.style.opacity = 1
    tooltipEl.style.pointerEvents = "none"
    tooltipEl.style.position = "absolute"
    tooltipEl.style.transform = "translate(-50%, 0)"
    tooltipEl.style.transition = "all .1s ease"
    tooltipEl.style.zIndex = 2

    const table = document.createElement("table")
    table.style.margin = "0px"
    table.style.minWidth = "100px"

    tooltipEl.appendChild(table)
    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

export const externalTooltipHandler = (context, chartData: ChartData, currency: string) => {
  // Tooltip Element
  const { chart, tooltip } = context
  const tooltipEl = getOrCreateTooltip(chart)
  const length = tooltip.dataPoints.length
  const datasetIndex = tooltip.dataPoints[0].datasetIndex
  const dataIndex = tooltip.dataPoints[0].dataIndex
  let valuesToBeShown
  if (datasetIndex === 0) {
    valuesToBeShown = chartData.totalNetDatas[dataIndex].labelSpecificTotalNet
  } else {
    valuesToBeShown = chartData.streamDatas[dataIndex].labelSpecificStream
  }

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0
    return
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || []
    const bodyLines = tooltip.body.map((b) => b.lines)
    const tableHead = document.createElement("thead")

    titleLines.forEach((title) => {
      const tr = document.createElement("tr")
      tr.style.borderWidth = "0px"

      const th = document.createElement("th")
      th.style.borderWidth = "0px"
      th.style.cssText = `
        display: flex;
        justify-content: flex-start;`
      //const text = document.createTextNode(convertToMonthYear(title))
      const titleSpan = document.createElement("span")
      titleSpan.style.cssText = `
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
      `
      titleSpan.textContent = convertToMonthYear(title)
      th.appendChild(titleSpan)
      tr.appendChild(th)
      tableHead.appendChild(tr)
    })

    const tableBody = document.createElement("tbody")
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i]

      const tr = document.createElement("tr")
      tr.style.backgroundColor = "inherit"
      tr.style.borderWidth = "0px"

      const td = document.createElement("td")
      td.style.cssText = `
        width: auto;
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
        `

      const divMain = document.createElement("div")
      divMain.style.cssText = `
        display:flex;
        flex-direction: column;
        width:auto;
        align-items: space-between;
        gap: 6px;
        `
      valuesToBeShown.forEach((item) => {
        const divTop = document.createElement("div")
        divTop.style.cssText = `
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;  
            align-items:center;
            gap:18px;    
            `
        const divContainer = document.createElement("div")
        divContainer.style.cssText = `
            display: flex;
            flex-direction: row;
            align-items:center;
            gap: 12px;
            `
        const divRight = document.createElement("div")
        divRight.style.cssText = `
            font-family: Inter;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            `
        divRight.innerHTML =
          datasetIndex === 0
            ? `${getCurrency(currency)}${item.totalNet.toFixed(2)}`
            : addCommasToNumber(String(item.stream))

        const divLeft = document.createElement("div")
        divLeft.style.cssText = `
            display:flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;
            `
        const img = document.createElement("img")
        img.style.width = item.label === "spo-spotify" ? "80px" : "21px"
        img.style.height = item.label === "spo-spotify" ? "24.0135478" : "21px"
        img.style.minWidth = item.label === "spo-spotify" ? "80px" : "21px"
        img.style.minHeight = item.label === "spo-spotify" ? "24.0135478" : "21px"
        img.src = item.label === "spo-spotify" ? platformLogos[item.label] : platformIcons[item.label]

        const span = document.createElement("span")
        span.innerHTML = item.label === "spo-spotify" ? "" : `${chartLabels["platform"][item.label]}`
        span.style.cssText = `
            font-family: Inter;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px
            `
        divLeft.appendChild(img)
        divLeft.appendChild(span)
        divContainer.appendChild(divLeft)
        divTop.appendChild(divContainer)
        divTop.appendChild(divRight)
        divMain.appendChild(divTop)
      })
      const bodySpan = document.createElement("span")
      bodySpan.style.cssText = `
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px
            `
      if (body[0] === "Revenue: 0" || body[0] === "Stream: 0") {
        bodySpan.textContent = body
      }
      td.appendChild(bodySpan)
      td.appendChild(divMain)
      tr.appendChild(td)
      tableBody.appendChild(tr)
    })

    const tableRoot = tooltipEl.querySelector("table")

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove()
    }

    // Add new children
    tableRoot.appendChild(tableHead)
    tableRoot.appendChild(tableBody)
  }

  const canvasRect = chart.canvas.getBoundingClientRect()
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop

  const positionX = canvasRect.left + scrollLeft
  const positionY = canvasRect.top + scrollTop

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1
  tooltipEl.style.left = `${positionX + tooltip.caretX}px`
  tooltipEl.style.top = `${positionY + tooltip.caretY + 10}px`
  tooltipEl.style.font = tooltip.options.bodyFont.string
  tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px `
  tooltipEl.style.borderRadius = "6px"
}
