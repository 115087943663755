import { KButton, KDropdown, KInput, KSpan, KTitleSpan, KTooltip } from "kahuna-base-react-components"
import React, { useState, useEffect, useDebugValue } from "react"
//@ts-ignore
import MusicIcon from "../../../../../assets/release-registry-icons/music.svg"
//@ts-ignore
import AddIcon from "../../../../../assets/release-registry-icons/add.svg"
//@ts-ignore
import DeleteIcon from "../../../../../assets/release-registry-icons/delete.svg"
//@ts-ignore
import ChevronDownIcon from "../../../../../assets/release-registry-icons/chevron-down.svg"
//@ts-ignore
import VersionIcon from "../../../../../assets/release-registry-icons/mv.svg"
import { useOrchestrator } from "../ReleaseRegistryContextProvider"
import { ReleaseRegistryReleaseTabs } from "../../../../../actions/types"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"

const ReleaseName = ({ setSelectedTab }: { setSelectedTab: (tab: ReleaseRegistryReleaseTabs) => void }) => {
  const {
    releaseForm,
    updateProduct,
    setReleaseForm,
    updateProductRelationships,
    options,
    selectedLabelName,
    setSelectedLabelName
  } = useOrchestrator()

  const [addVersion, setAddVersion] = useState<boolean>(releaseForm.product.data.attributes.subtitle)

  const [labelOptions, setLabelOptions] = useState<KSelectOption[]>(
    options.labelOptions?.map((labelOption) => {
      return {
        value: Number(labelOption.value),
        value2: labelOption.value,
        label: labelOption.label
      }
    }) || []
  )

  const [productTypeOptions, setProductTypeOptions] = useState<KSelectOption[]>(
    options.productTypeOptions?.map((productTypeOption) => {
      return {
        value: Number(productTypeOption.value),
        value2: productTypeOption.value,
        label: productTypeOption.label
      }
    }) || []
  )

  useEffect(() => {
    if (options.labelOptions) {
      setLabelOptions(
        options.labelOptions?.map((labelOption) => {
          return {
            value: Number(labelOption.value),
            value2: labelOption.value,
            label: labelOption.label
          }
        })
      )

      if (!selectedLabelName) {
        setSelectedLabelName(
          options.labelOptions?.find((label) => label.value === releaseForm.product.data.relationships.label?.data?.id)
            ?.label || ""
        )
      }
    }
    if (options.productTypeOptions) {
      setProductTypeOptions(
        options.productTypeOptions
          ?.filter((option) => option.label !== "Video")
          ?.map((productTypeOption) => {
            return {
              value: Number(productTypeOption.value),
              value2: productTypeOption.value,
              label: productTypeOption.label
            }
          })
      )
    }
  }, [options])

  return (
    <div className="w-[616px] max-sm:w-auto flex flex-col gap-6">
      <div className="flex flex-col gap-3 justify-start">
        <KTitleSpan text="Release" fontSize={40} lineHeight="48px" letterSpacing="-0.4px" color="#1F1F1F" />
        <KSpan text="Your Spotify artist name uniquely identifies you on the platform." />
      </div>
      <div className="flex flex-col gap-4 ">
        <KTooltip
          content={
            <div className="whitespace-nowrap flex items-center justify-center">
              <KSpan text="Release Name" fontSize={10} fontWeight={500} color="#1F1F1F" />
            </div>
          }
        >
          <KInput
            leftIcon={MusicIcon}
            placeholder="Release Name"
            value={releaseForm.product.data.attributes.name || ""}
            onChange={(text) => updateProduct("name", text)}
            padding="12px 12px 12px 14px"
            gap="8px"
            boxShadow="none"
          />
        </KTooltip>
        <div className="flex flex-row justify-between gap-2">
          <div className="grow">
            <KTooltip
              content={
                <div className="whitespace-nowrap flex items-center justify-center">
                  <KSpan text="Copyright Holder" fontSize={10} fontWeight={500} color="#1F1F1F" />
                </div>
              }
            >
              <KInput
                placeholder="Copyright Holder"
                onChange={(text) => {
                  updateProduct("copyright-line", text)
                }}
                value={releaseForm.product.data.attributes["copyright-line"] || ""}
                boxShadow=""
              />
            </KTooltip>
          </div>
          <div className="grow">
            <KTooltip
              content={
                <div className="whitespace-nowrap flex items-center justify-center">
                  <KSpan text="Production Holder" fontSize={10} fontWeight={500} color="#1F1F1F" />
                </div>
              }
            >
              <KInput
                placeholder="Production Holder"
                onChange={(text) => {
                  updateProduct("production-line", text)
                }}
                value={releaseForm.product.data.attributes["production-line"] || ""}
              />
            </KTooltip>
          </div>
          <div className="grow">
            <KTooltip
              content={
                <div className="whitespace-nowrap flex items-center justify-center">
                  <KSpan text="Production Year" fontSize={10} fontWeight={500} color="#1F1F1F" />
                </div>
              }
            >
              <KInput
                placeholder="Production Year"
                type="number"
                onChange={(text) => {
                  updateProduct("production-year", text)
                }}
                value={releaseForm.product.data.attributes["production-year"] || ""}
              />
            </KTooltip>
          </div>
        </div>
        <div className="flex flex-row gap-2 justify-between">
          <div
            className=""
            style={{
              width: "calc(50% - 4px)"
            }}
          >
            <KTooltip
              content={
                <div className="whitespace-nowrap flex items-center justify-center">
                  <KSpan text="Label" fontSize={10} fontWeight={500} color="#1F1F1F" />
                </div>
              }
            >
              <KDropdown
                placeholder="Label"
                options={labelOptions}
                defaultValuePrimitive={releaseForm.product.data.relationships?.["label"]?.data?.id}
                selected={releaseForm.product.data.relationships?.["label"]?.data?.id}
                rightIcon={ChevronDownIcon}
                onSelect={(selected) => {
                  if (selected) {
                    const selectedOption = selected as KSelectOption
                    const value2 = selectedOption.value2
                    if (!value2) {
                      return
                    }
                    setSelectedLabelName(
                      labelOptions.find((label) => label.value2 === selectedOption.value2)?.label || ""
                    )
                    updateProductRelationships("label", {
                      data: { type: "labels", id: value2 }
                    })
                  } else {
                    delete releaseForm.product.data.relationships?.["label"]
                    setReleaseForm({ ...releaseForm })
                  }
                }}
              />
            </KTooltip>
          </div>
          <div
            className=""
            style={{
              width: "calc(50% - 4px)"
            }}
          >
            <KTooltip
              content={
                <div className="whitespace-nowrap flex items-center justify-center">
                  <KSpan text="Product Type" fontSize={10} fontWeight={500} color="#1F1F1F" />
                </div>
              }
            >
              <KDropdown
                placeholder="Product Type"
                options={productTypeOptions}
                rightIcon={ChevronDownIcon}
                defaultValuePrimitive={releaseForm.product.data.relationships?.["product-type"]?.data?.id}
                selected={releaseForm.product.data.relationships?.["product-type"]?.data?.id}
                onSelect={(selected) => {
                  if (selected) {
                    const selectedOption = selected as KSelectOption
                    const value2 = selectedOption.value2
                    if (!value2) {
                      return
                    }

                    updateProductRelationships("product-type", {
                      data: { type: "product-types", id: value2 }
                    })
                  } else {
                    delete releaseForm.product.data.relationships?.["product-type"]
                    setReleaseForm({ ...releaseForm })
                  }
                }}
              />
            </KTooltip>
          </div>
        </div>
        {!addVersion && (
          <KButton
            leftIcon={AddIcon}
            text="Add Version"
            width="110px"
            textColor="#1F1F1F"
            padding="0px"
            height="20px"
            onClick={() => {
              setAddVersion(true)
            }}
            shadowDisabled
            background="#FFF"
            textDecoration="underline"
          />
        )}
        {addVersion && (
          <div className="flex flex-row gap-2">
            <div className="grow">
              <KInput
                leftIcon={VersionIcon}
                placeholder="Version"
                value={releaseForm.product.data.attributes.subtitle || ""}
                onChange={(text) => updateProduct("subtitle", text)}
                padding="12px 12px 12px 14px"
                gap="8px"
                boxShadow="none"
              />
            </div>
            <KButton
              padding="12px 12px"
              background="#FFF"
              width="44px"
              icon={DeleteIcon}
              onClick={() => {
                setAddVersion(false)
                delete releaseForm.product.data.attributes.subtitle
                setReleaseForm({ ...releaseForm })
              }}
            />
          </div>
        )}
        {releaseForm.product.data.attributes.name &&
          (!addVersion || (addVersion && releaseForm.product.data.attributes.subtitle)) && (
            <div className="py-6">
              <KButton
                text="Continue"
                onClick={() => {
                  setSelectedTab(ReleaseRegistryReleaseTabs.RELEASE_ARTIST)
                }}
                background="#111"
                textColor="#FFF"
                disabled={
                  !releaseForm.product.data?.attributes?.name ||
                  !releaseForm.product.data.relationships?.["product-type"]?.data?.id ||
                  !releaseForm.product.data.relationships?.["label"]?.data?.id ||
                  !releaseForm.product.data.attributes["copyright-line"] ||
                  !releaseForm.product.data.attributes["production-line"] ||
                  !releaseForm.product.data.attributes["production-year"]
                }
              />
            </div>
          )}
      </div>
    </div>
  )
}

export default ReleaseName
