import { TableColumn, UserYoutubeVideoShareData } from "../../../../../types"
import { ROLES, TABLE_COLUMN_TYPE } from "../../../../../constants"
import { CSSProperties } from "react"
import { Check, X } from "heroicons-react"

const headerStyle: CSSProperties = { textAlign: "left", width: "25%" }
const cellStyle: CSSProperties = { fontWeight: 400, lineHeight: "20px", color: "#111827" }

const adminYoutubeVideoColumns: TableColumn[] = [
  {
    header: "Youtube Video",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    headerStyle: { ...headerStyle, width: "360px" },
    cellStyle,
    render: (row: UserYoutubeVideoShareData) => row.youtubeVideoDetail.title
  },
  {
    header: "Main Owner",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    headerStyle: { ...headerStyle, width: "15%" },
    cellStyle,
    render: (row: UserYoutubeVideoShareData) =>
      row.roleList.some((role) => role.role === ROLES.MAIN_OWNER) ? (
        <Check className="text-green-400 h-5 w-5" />
      ) : (
        <X className="text-red-400 h-5 w-5" />
      )
  },
  {
    header: "Roles",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    headerStyle,
    cellStyle,
    render: (row: UserYoutubeVideoShareData) => {
      const filteredRoles = row.roleList.filter((role) => role.role !== 10)
      return filteredRoles.map((role, i) => role.roleDetail.name + (i + 1 !== filteredRoles.length ? ", " : ""))
    }
  },
  {
    header: "Share",
    attribute: "share",
    type: TABLE_COLUMN_TYPE.TEXT,
    headerStyle: { ...headerStyle, width: "15%" },
    cellStyle
  }
]

export default adminYoutubeVideoColumns
