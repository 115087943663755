import { KSpan } from "kahuna-base-react-components"
//@ts-ignore
import AvatarIcon from "../../assets/release-registry-icons/avatar.svg"
import React from "react"

const ArtistInitials = ({ artists }: { artists: string[] }) => {
  const reversedArtists = [...artists].reverse() // must destructure the array like this.
  return (
    <div className="flex flex-row w-auto">
      {artists.length > 0 &&
        artists.map((artist, i: number) => {
          return (
            <span
              key={`${artist}-${i}`}
              className="w-8 h-8 flex justify-center items-center bg-[#F0F0F0] rounded-full border-2 border-[#FFF]"
              style={{
                position: "relative",
                left: `calc(-${6 * i}px)`,
                boxShadow: "0px -8px 16px 0px rgba(181, 181, 181, 0.48) inset"
                
              }}
            >
              <KSpan text={artist[0].toLocaleUpperCase()} color="#1F1F1F" fontWeight={500} />
            </span>
          )
        })}
      {artists.length === 0 && (
        <span className="w-8 h-8 flex justify-center items-center rounded-full">
          <img src={AvatarIcon} alt="Avatar" />
        </span>
      )}
    </div>
  )
}

export default ArtistInitials
