import { Dialog } from "@headlessui/react"
import React, { FC, useEffect, useState } from "react"
import { X } from "heroicons-react"
import { handleAuthorizedRequest, users } from "../../../../apis"
import { Affiliate, SelectOption, User } from "../../../../types"
import { SearchSelect } from "../../../ui/form/select/SearchSelect"
import { Input } from "../../../ui/form/Input"
import { KButton, KSpan } from "kahuna-base-react-components"
import { mapToCamelCase } from "../../../../utility"
import { toast } from "react-toastify"
import { errorToastOptions, successToastOptions } from "../../../../constants"

type AdminAffiliateFromUserModalProps = {
  open: boolean
  onClose: () => void
  user: User
  saveAffiliate: (affiliateList: Affiliate[]) => void
  affiliateList: Affiliate[]
}
const AdminAffiliateFromUserModal: FC<AdminAffiliateFromUserModalProps> = (props) => {
  const { onClose, open } = props

  const [affiliateList, setAffiliateList] = useState<Affiliate[]>([])

  const [commission, setCommission] = useState<number | undefined>(0)
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)

  const [userOptions, setUserOptions] = useState<SelectOption[]>([])
  const [userLoading, setUserLoading] = useState(false)
  const [timeoutId, setTimeoutId] = useState()

  useEffect(() => {
    setAffiliateList([...props.affiliateList])
  }, [props.affiliateList])

  const onSearchTextChanged = async (event: { target: { value: any } }) => {
    setUserOptions([])
    setUserLoading(true)
    clearTimeout(timeoutId)

    setTimeoutId(
      //@ts-ignore
      setTimeout(async () => {
        await handleAuthorizedRequest(async () => {
          const response = await users(`/?limit=10&offset=0&username=${event.target.value}`, "get")
          setUserOptions(
            response.data.results.map((user: { username: any }) => ({
              value: mapToCamelCase(user),
              label: user.username
            }))
          )
          setUserLoading(false)
        }, "")
      }, 500)
    )
  }

  const onDialogClose = () => {
    setSelectedUser(undefined)
    setCommission(0)
    setAffiliateList([...props.affiliateList])
    onClose()
  }

  const onAddNewAffiliate = () => {
    if (!props.user.id || !commission || !selectedUser?.id) {
      return
    }

    if (selectedUser.id === props.user.id) {
      toast.error("Same User!", errorToastOptions)
      return
    }

    if (affiliateList.filter((entity) => entity.toUserId === selectedUser.id).length > 0) {
      toast.error("User Already Exist!", errorToastOptions)
      return
    }

    setAffiliateList([
      ...affiliateList,
      { commission, fromUserId: props.user.id, toUserId: selectedUser.id, toUser: selectedUser }
    ])
  }

  const onDeleteAffiliate = (affiliate: Affiliate) => {
    setAffiliateList([...affiliateList.filter((entity) => entity.toUserId !== affiliate.toUserId)])
  }

  const onSave = () => {
    setSelectedUser(undefined)
    setCommission(0)
    props.saveAffiliate(affiliateList)
  }

  return (
    <Dialog open={open} onClose={() => onDialogClose()} className="relative z-50">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="bg-white elev-b-sm rounded-lg overflow-hidden">
          <div className={`flex justify-end`}>
            <X className="h-4 cursor-pointer mt-1" style={{ position: "absolute" }} onClick={() => onDialogClose()} />
          </div>
          <div className="flex">
            <div style={{ height: "50vh", width: "50vh", padding: 42 }}>
              <div className="text-2xl font-bold pb-6">{"Add New Affiliate"}</div>
              <label>Username</label>
              <SearchSelect
                inputTextClass="text-[14px]"
                placeholder={"Search user to filter"}
                options={userOptions}
                onTextChange={onSearchTextChanged}
                loading={userLoading}
                value={selectedUser}
                // @ts-ignore
                onChange={setSelectedUser}
              />
              <div className="mb-5" />
              <label className="mt-6">Commission (%)</label>
              <Input
                className={`border mt-1 mb-2`}
                placeholder={"5"}
                value={commission}
                onChange={(event) => {
                  const value = event.target.value ? parseFloat(event.target.value) : 0
                  setCommission(value)
                }}
              />
              <div className="flex mt-4" style={{ width: "100%" }}>
                <KButton text="+ Add New" onClick={() => onAddNewAffiliate()} disabled={!selectedUser || !commission} />
              </div>
            </div>
            <div style={{ height: "60vh", width: "80vh", padding: 42, borderLeft: "1px solid grey" }}>
              <div className="text-2xl font-bold pb-6">{"Affiliate From List"}</div>
              <div style={{ overflowY: "scroll", height: "90%" }}>
                {affiliateList.map((affiliate: Affiliate) => {
                  console.log(affiliate)
                  return (
                    <div
                      className="flex justify-between pb-2 pt-2"
                      style={{ borderBottom: "1px dashed grey" }}
                      key={affiliate.toUserId}
                    >
                      <div className="grid">
                        <KSpan
                          text={`${affiliate.toUser?.email || ""} (${affiliate.toUser?.firstName} ${
                            affiliate.toUser?.lastName
                          })`}
                        />
                        <div className="flex gap-6">
                          <KSpan text={`Commission: ${affiliate.commission?.toString() || "-"}%`} />
                          <KSpan text={`Distribution Fee: ${affiliate.toUser?.userExpense?.distributionFee}%`} />
                        </div>
                      </div>
                      <KButton
                        background="transparent"
                        shadowDisabled={true}
                        padding="0px"
                        width="40px"
                        icon="/account_icons/trash.svg"
                        onClick={() => onDeleteAffiliate(affiliate)}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div
            className="justify-end items-center flex mb-4 pt-4 pr-4"
            style={{ width: "100%", borderTop: "1px solid grey" }}
          >
            <KButton
              width="250px"
              background="transparent"
              shadowDisabled={true}
              text="Close"
              onClick={() => onDialogClose()}
            />
            <KButton width="250px" background="black" textColor="white" text="Save" onClick={() => onSave()} />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default AdminAffiliateFromUserModal
