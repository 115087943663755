import { KButton, KSpan, KTitleSpan } from "kahuna-base-react-components"
//@ts-ignore
import ArrowLeftIcon from "../../../../../assets/release-registry-icons/arrow-left.svg"
import React from "react"
import { ReleaseRegistryUploadTracksTabs } from "../../../../../actions/types"
import { useOrchestrator } from "../ReleaseRegistryContextProvider"
import UploadContributorConsentFileComponent from "../../../../ui/components/UploadContributorConsentFile"

const ContributorConsentFiles = ({
  setSelectedTab,
  saveTrackDetail
}: {
  setSelectedTab: (tab: ReleaseRegistryUploadTracksTabs) => void
  saveTrackDetail: (selectedTab: boolean) => void
}) => {
  const { trackRegistryConsentFileList, setTrackRegistryConsentFileList, selectedTrack, releaseForm, isPublishing } =
    useOrchestrator()

  return (
    <div className="w-[616px] max-sm:w-auto flex flex-col gap-6">
      <div className="flex flex-col gap-3 justify-start">
        <div className="flex flex-row gap-4 items-center">
          <div>
            <KButton
              icon={ArrowLeftIcon}
              onClick={() => {
                setSelectedTab(
                  isPublishing
                    ? ReleaseRegistryUploadTracksTabs.PUBLISHING_SHARE_HOLDERS
                    : ReleaseRegistryUploadTracksTabs.TRACK_SHARE_HOLDERS
                )
              }}
              width="36px"
              height="36px"
              padding="8px"
              background="#F0F0F0"
              shadowDisabled
              hoverBackground="#E7E7E7"
            />
          </div>
          <KTitleSpan
            text="Contributor Consent Files"
            fontSize={40}
            lineHeight="48px"
            letterSpacing="-0.4px"
            color="#1F1F1F"
          />
        </div>
        <KSpan text="Provide consent file for the broadcast of the song." />
      </div>
      <UploadContributorConsentFileComponent
        trackRegistryConsentFileList={trackRegistryConsentFileList}
        setTrackRegistryConsentFileList={setTrackRegistryConsentFileList}
        isrc={selectedTrack?.data?.attributes["isrc-code"]}
        upc={releaseForm.product.data.attributes["upc-code"]}
      />
      <div>
        <KButton
          text="Continue"
          onClick={() => {
            setSelectedTab(ReleaseRegistryUploadTracksTabs.LYRICS)
          }}
          background="#000"
          textColor="#FFF"
        />
      </div>
      <div className="flex flex-row justify-center">
        <KButton
          text="Save and Return to Tracks"
          hoverBackground="#E7E7E7"
          onClick={() => {
            saveTrackDetail(false)
          }}
          background="#FFF"
          shadowDisabled
        />
      </div>
    </div>
  )
}
export default ContributorConsentFiles
