import { KSpan } from "kahuna-base-react-components"
import CalendarDate from "./CalendarDate"
import DownloadReportButton from "../../layouts/reports/DownloadReportButton"
import React from "react"
import { formatDate } from "../../../utility"

const ListComponent = ({ i, row, isAdmin, selectedUserId, approveDisabled }) => {
  const divHeight = 40

  const removeDuplicates = (data) => {
    return data.filter((value, index) => data.indexOf(value) === index)
  }

  return (
    <li
      key={`data-row-${i}`}
      onClick={() => {
        //onClickRow(i) buradaki onClickRow ReportsTable.tsx kismind", ne ise yaradigini anlamadigim ve
        // kullabimi gerekli mi emin olamadigim icin simdilik buraya aktarmakla ugrasmadim.
      }}
    >
      <div className="table-row-hover rounded-lg p-[20px] gap-[20px] h-[80px]">
        <div className={`h-[${divHeight}px] flex flex-row justify-between items-center `}>
          <div className="w-[15%] flex flex-row justify-start">
            <CalendarDate quarter={row.quarter} />
          </div>
          <div className="w-[25%] flex flex-row justify-center">
            <KSpan
              text={`${row.revenue?.toFixed(2)} ${row.currency}`}
              color="#111"
              fontWeight={500}
              hoverText="Revenue"
              letterSpacing="-0.176px"
              fontSize={16}
            ></KSpan>
          </div>
          <div className="w-[25%] flex flex-row justify-center">
            <KSpan
              text={`${row.total_payment?.toFixed(2)} ${row.currency}`}
              color="#111"
              fontWeight={500}
              hoverText="Total Payment"
              letterSpacing="-0.176px"
              fontSize={16}
            ></KSpan>
          </div>
          <div className="w-[25%] flex flex-col justify-center items-center">
            {row.payment_day ? (
              removeDuplicates(row.payment_day).map((payment, index) => {
                return (
                  <KSpan
                    text={formatDate(payment)}
                    key={`payment-${index}`}
                    fontWeight={500}
                    color="#111"
                    letterSpacing="-0.176px"
                    fontSize={16}
                    hoverText="Payment Date"
                  />
                )
              })
            ) : (
              <KSpan
                text="-"
                fontWeight={500}
                color="#111"
                hoverText="Payment Date"
                letterSpacing="0.176px"
                fontSize={16}
              />
            )}
          </div>
          <div className={`w-[10%] flex flex-row justify-end`}>
            <DownloadReportButton
              reportId={`${parseInt(row.quarter)}${row.quarter.split(" ")[1].toLowerCase()}`}
              isAdmin={isAdmin}
              selectedUserId={selectedUserId}
              approveDisabled={approveDisabled}
            />
          </div>
        </div>
      </div>
    </li>
  )
}

export default ListComponent
