import React from "react"

import "./styles/Loading.css"

const LoaderLine = () => {
  return (
    <div className="loader-line"></div>
  )
}

export default LoaderLine
