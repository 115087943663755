import { KSpan } from "kahuna-base-react-components"
import React from "react"

const Title = ({
  sectionTitle,
  sectionDetail,
  iconPath
}: {
  sectionTitle: string
  sectionDetail: string
  iconPath: string
}) => {
  return (
    <KSpan text={sectionTitle} color="#000" fontWeight={500} lineHeight="24px" fontSize={18} letterSpacing="-0.27px" />
  )
}

export default Title
