import { Scheduler, SchedulerReport, TableColumn } from "../../../../types"
import { TABLE_COLUMN_TYPE } from "../../../../constants"
import { CheckCircle, DotsCircleHorizontal, MinusCircle, XCircle } from "heroicons-react"
import Tooltip from "../../../ui/Tooltip"
import React from "react"

const renderSchedulerReportLogs = (reportList: SchedulerReport[]) => {
  const reverseOrderList: SchedulerReport[] = []
  for (let i = reportList.length - 1; i >= 0; i--) {
    reverseOrderList.push(reportList[i])
  }
  return (
    <div className={"flex"}>
      {reverseOrderList.map((report) => {
        let Icon = CheckCircle
        let color = "green"
        if (report.status === "partial_success") {
          Icon = MinusCircle
          color = "#82822c"
        }
        if (report.status === "failed") {
          Icon = XCircle
          color = "red"
        }
        if (report.status === "in_progress") {
          Icon = DotsCircleHorizontal
          color = "grey"
        }

        return (
          <div className="relative" style={{ direction: 'rtl', textAlign: "left" }}>
            <Tooltip
              key={report.id}
              width={300}
              content={
                <div className={"grid text-lg p-4"}>
                  <span>ID: {report.id}</span>
                  <span className={"mt-2"}>UserId: {report.user_id || "-"}</span>
                  <span className={"mt-2"}>Date: {report.date.substring(0, 16)}</span>
                  <span>Status: {report.status}</span>
                  <span className={"mt-3"}>Read Count: {report._read}</span>
                  <span>Insert Count: {report._insert}</span>
                  <span>Update Count: {report._update}</span>
                  <span className={"mt-3"}>Error Count: {report._error}</span>
                  <span>Warning Count: {report._warning}</span>
                </div>
              }
            >
              <Icon className={"ml-8"} style={{ minWidth: 20, maxWidth: 20 }} color={color} />
            </Tooltip>
          </div>
        )
      })}
    </div>
  )
}

const schedulerColumns: TableColumn[] = [
  {
    header: "Name",
    headerStyle: {
      textAlign: "left",
      width: "26%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Scheduler) => <span>{row.name}</span>
  },
  {
    header: "Enabled",
    headerStyle: {
      textAlign: "left",
      width: "10%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Scheduler) => {
      return <input type={"checkbox"} checked={row.enabled} readOnly={true} />
    }
  },
  {
    header: "Previous Run",
    headerStyle: {
      textAlign: "left",
      width: "17%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (scheduler: Scheduler) => {
      return <span>{scheduler.previous_run.substring(0, 16)}</span>
    }
  },
  {
    header: "Next Run",
    headerStyle: {
      textAlign: "left",
      width: "17%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (scheduler: Scheduler) => {
      return <span>{scheduler.next_run.substring(0, 16)}</span>
    }
  },
  {
    header: "Results",
    headerStyle: {
      textAlign: "center",
      width: "30%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (scheduler: Scheduler) => {
      return renderSchedulerReportLogs(scheduler.report_list)
    }
  }
]

export default schedulerColumns
