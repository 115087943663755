import React, { useEffect, useState } from "react"
import { applicationSubscriptions } from "../../../../apis"
import { MeetDetails, SubscriptionsType } from "../../../../types"
import Table from "../../../ui/table/Table"
import subscriptionColumns from "./subscriptionColumns"
import SubscriptionDetailModal from "./SubscriptionDetailModal"
import { KSpan } from "kahuna-base-react-components"
import PreviousNextButtons from "./PreviousNextButtons"

const Subscriptions = () => {
  const [loading, setLoading] = useState(false)
  const [subscriptions, setSubscriptions] = useState<SubscriptionsType[]>([])
  const [details, setDetails] = useState<MeetDetails>({} as MeetDetails)
  const [openModal, setOpenModal] = useState(false)
  const [description, setDescription] = useState<{ [key: string]: string }>({})
  const [dbStatus, setDbStatus] = useState<{ [key: string]: string }>({})
  const [startTime, setStartTime] = useState<{ [key: string]: string }>({})
  const [showPastEvents, setShowPastEvents] = useState<boolean>(false)
  const [showCanceledEvents, setShowCanceledEvents] = useState<boolean>(false)
  const [pageTokens, setPageTokens] = useState<{ previousPageToken: string | null; nextPageToken: string | null }>({
    previousPageToken: null,
    nextPageToken: null
  })
  const count = 5

  const fetchMeets = (pastEvents: boolean, canceledEvents: boolean, previousOrNextPageToken?: string | null) => {
    setLoading(true)
    const showPast = pastEvents ? "&show_past=true" : ""
    const showCanceled = canceledEvents ? "" : "&status=active"
    const pageToken = previousOrNextPageToken ? `&page_token=${previousOrNextPageToken}` : ""
    const requestUrl = `/get_filtered_meetings/?count=${count}${showPast}${showCanceled}${pageToken}`
    applicationSubscriptions(requestUrl, "get").then((resp) => {
      const fetchedSubscriptions: SubscriptionsType[] = resp.data.collection?.map((meeting_event: any) => {
        const meetingId = meeting_event.uri.replace("https://api.calendly.com/scheduled_events/", "")
        setDescription((prev) => {
          return {
            ...prev,
            [meetingId]: meeting_event?.description || ""
          }
        })
        setDbStatus((prev) => {
          return {
            ...prev,
            [meetingId]: meeting_event?.db_status || ""
          }
        })
        setStartTime((prev) => {
          return {
            ...prev,
            [meetingId]: meeting_event?.start_time || ""
          }
        })
        return {
          calendly_id: meetingId,
          start_time: meeting_event.start_time,
          calendly_status: meeting_event.status,
          db_status: meeting_event?.db_status || "-",
          description: meeting_event?.description || "-",
          created_at: meeting_event?.created_at || ""
        }
      })
      setPageTokens({
        previousPageToken: resp.data.pagination?.previous_page_token,
        nextPageToken: resp.data.pagination?.next_page_token
      })
      setSubscriptions(fetchedSubscriptions)
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchMeets(showPastEvents, showCanceledEvents)
  }, [])

  const onClickDetails = (meetingId: string) => {
    setOpenModal(false)
    applicationSubscriptions(`/get_meeting_details/?meet_id=${meetingId}`, "get").then((resp) => {
      const meet = resp.data.collection[0]
      if (!meet) return
      const details: MeetDetails = {
        calendly_id: meetingId,
        firstName: meet?.first_name || "",
        lastName: meet?.last_name || "",
        email: meet?.email || "",
        phoneNumber: meet?.questions_and_answers.find((question) => question.question === "Phone Number")?.answer || "",
        companyName: meet?.questions_and_answers.find((question) => question.question === "Company Name")?.answer || "",
        anySpecificRequirement: meet?.questions_and_answers.find((question) => question.position === 0)?.answer || "",
        created_at: meet?.created_at || ""
      }
      setDetails(details)
      setOpenModal(true)
    })
  }

  const addToDatabase = (
    meetingId: string,
    description: string,
    db_status: string,
    type: string,
    start_time: string,
    created_at: string
  ) => {
    applicationSubscriptions(`/add_to_database/`, "post", {
      calendly_id: meetingId,
      description,
      db_status,
      type,
      start_time,
      created_at
    }).then((resp) => {})
  }

  const columns = subscriptionColumns(description, setDescription, onClickDetails, dbStatus, setDbStatus, addToDatabase)

  const topRightElements = () => (
    <div className="flex items-end gap-4">
      <div className="flex items-center gap-2">
        <KSpan text="Show Past Events:" />
        <input
          type="checkbox"
          value=""
          checked={showPastEvents}
          disabled={loading}
          onChange={() => {
            const pastEvents = !showPastEvents
            setShowPastEvents(!showPastEvents)
            fetchMeets(pastEvents, showCanceledEvents)
          }}
        />
      </div>
      <div className="flex items-center gap-2">
        <KSpan text="Show Cancelled Events:" />
        <input
          type="checkbox"
          value=""
          checked={showCanceledEvents}
          disabled={loading}
          onChange={() => {
            const canceledEvents = !showCanceledEvents
            setShowCanceledEvents(!showCanceledEvents)
            fetchMeets(showPastEvents, canceledEvents)
          }}
        />
      </div>
    </div>
  )

  return (
    <div className="catalog flex-grow">
      <div className="mt-[56px] mb-16" style={{ paddingLeft: 1, paddingRight: 1 }}>
        <Table
          title="Subscriptions"
          minRowCount={count + 1}
          limit={count + 1}
          columns={columns}
          data={subscriptions}
          count={subscriptions?.length || 0}
          rowHeightInPx={40}
          loading={loading}
          topRightElement={topRightElements()}
        />
        <div>
          <PreviousNextButtons
            isPreviousDisabled={pageTokens.previousPageToken && !loading ? false : true}
            isNextDisabled={pageTokens.nextPageToken && !loading ? false : true}
            previousClick={() => {
              fetchMeets(showPastEvents, showCanceledEvents, pageTokens.previousPageToken)
            }}
            nextClick={() => {
              fetchMeets(showPastEvents, showCanceledEvents, pageTokens.nextPageToken)
            }}
          />
        </div>
      </div>
      {openModal && (
        <SubscriptionDetailModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          meetDetails={details}
          description={description}
          setDescription={setDescription}
          dbStatus={dbStatus}
          setDbStatus={setDbStatus}
          addToDatabase={addToDatabase}
          startTime={startTime}
        />
      )}
    </div>
  )
}

export default Subscriptions
