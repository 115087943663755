import { InputProps, InputRenderProps } from "../../../types"
import * as React from "react"
import { FieldMetaState } from "react-final-form"

export const Input = (props: InputProps) => {
  return (
    <div className="flex flex-col w-[100%]">
      <input
        style={{ fontFamily: "Inter", ...props.containerStyle }}
        id={props.id}
        key={props.id}
        type={props.type || "text"}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={props.onKeyPress}
        checked={props.checked}
        maxLength={props.maxLength}
        disabled={props.disabled}
        pattern={props.pattern}
        onClick={props.onClick}
        className={`rounded-md flex-1 appearance-none border-gray-300 w-full py-2 px-4 bg-white 
          placeholder-gray-400 shadow-sm text-base focus:outline-none ${props.className} ${props.inputClass} 
          ${props.disabled ? "text-gray-500" : "text-gray-700 "}`}
      />
    </div>
  )
}

export const renderInput = (fieldRenderProps: InputRenderProps) => {
  const renderError = (meta: FieldMetaState<any>) => {
    if (meta.touched && meta.error) {
      return <div className="error message">{meta.error}</div>
    }
  }

  const errorClass = fieldRenderProps.meta.touched && fieldRenderProps.meta.error && "error"
  const borderClass = `border-b border-r border-t ${fieldRenderProps.inlineElement ? "rounded-l-none" : "border-l"}`

  return (
    <div className={fieldRenderProps.className}>
      <label htmlFor={fieldRenderProps.input.name} className="mb-3 text-sm">
        {fieldRenderProps.label}
      </label>
      <div className={`flex mb-2 ${fieldRenderProps.label ? "mt-1" : ""}`}>
        {fieldRenderProps.inlineElement && (
          <span
            className={`${errorClass} rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b
              border-gray-300 text-gray-500 shadow-sm text-sm border bg-gray-50 input-decoration`}
          >
            {fieldRenderProps.inlineElement}
          </span>
        )}
        <Input {...fieldRenderProps.input} {...fieldRenderProps} className={`${borderClass} ${errorClass}`} />
      </div>
      {renderError(fieldRenderProps.meta)}
    </div>
  )
}
