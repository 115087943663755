import * as React from "react"
import { Field, Form } from "react-final-form"
import { renderInput } from "../../../ui/form/Input"
import { X } from "heroicons-react"
import Button from "../../../ui/form/Button"
import { connect } from "react-redux"
import { RootState } from "../../../../store"
import { FC, useEffect, useState } from "react"
import { AdminCollaboratorFormProps, SelectOption, User, AdminCollaboratorShareForm } from "../../../../types"
import { saveCollaboratorForm, selectCollaboratorData, selectUser } from "../../../../actions/adminActions"
import { completeRoleOptions, tableCellClasses, COLLABORATOR_FORM_USER_MESSAGE, ROLES } from "../../../../constants"
import { renderSearchSelect } from "../../../ui/form/select/SearchSelect"
import { handleAuthorizedRequest, users } from "../../../../apis"
import { uniqBy } from "lodash"
import { mapListToCamelCase } from "../../../../utility"
import adminCollaboratorValidator from "./adminCollaboratorValidator"

const AdminCollaboratorForm: FC<AdminCollaboratorFormProps> = (props) => {
  const [userOptions, setUserOptions] = useState<SelectOption[]>([])
  const [userLoading, setUserLoading] = useState(false)
  const [timeoutId, setTimeoutId] = useState(null)
  const [searchedUsers, setSearchedUsers] = useState<User[]>([])
  const [isUnregisteredUser, setIsUnregisteredUser] = useState(false)

  const onClickCross = () => {
    props.selectCollaboratorData(null)
    setUserOptions([])
    setIsUnregisteredUser(false)
  }

  const createCollaboratorFormFromData = (): AdminCollaboratorShareForm => {
    if (!props.selectedCollaborator) return new AdminCollaboratorShareForm()
    return {
      email: props.selectedCollaborator.email,
      roles: props.selectedCollaborator.role_ids,
      share: props.selectedCollaborator.share,
      track_id: props.track_id,
      name: `${props.selectedCollaborator.first_name} ${props.selectedCollaborator.last_name}`
    }
  }

  const onFormSubmit = async (values: AdminCollaboratorShareForm) => {
    props.saveCollaboratorForm({
      ...values,
      track_id: props.track_id,
      name: isUnregisteredUser
        ? "New Collaborator"
        : `${props.selectedCollaborator?.first_name} ${props.selectedCollaborator?.last_name}`
    })
    onClickCross()
  }

  const onSearchTextChanged = async (event) => {
    setUserOptions([])
    setUserLoading(true)
    clearTimeout(timeoutId)
    setTimeoutId(
      setTimeout(async () => {
        await handleAuthorizedRequest(async () => {
          const response = await users(`/?username=${event.target.value}&limit=10`, "get")
          const usersList = mapListToCamelCase(response.data.results)
          setSearchedUsers(usersList)
          if (usersList.length === 0) setIsUnregisteredUser(true)
          setUserOptions(
            usersList.length > 0
              ? usersList.map((user) => ({
                  value: user.username,
                  label: user.username
                }))
              : [
                  {
                    value: event.target.value,
                    label: event.target.value
                  }
                ]
          )
          setUserLoading(false)
        }, "")
      }, 500)
    )
  }

  useEffect(() => {
    if (props.selectedCollaborator?.email) {
      setUserOptions(
        uniqBy(
          [...userOptions, { value: props.selectedCollaborator.email, label: props.selectedCollaborator.email }],
          "value"
        )
      )
    }
  }, [props.selectedCollaborator])

  if (props.hidden) return null

  return (
    <div className="h-[401px] mt-10 bg-white elev-b-sm rounded-lg overflow-hidden">
      <div
        className={`bg-gray-50 text-xs text-gray-500 font-medium 
      uppercase border-b flex justify-between ${tableCellClasses.sm}`}
      >
        <p>Collaborator Information</p>
        <X className="h-4 mr-[-6px] cursor-pointer" onClick={onClickCross} />
      </div>

      <Form
        initialValues={createCollaboratorFormFromData()}
        onSubmit={onFormSubmit}
        validate={adminCollaboratorValidator}
        render={({ handleSubmit, form, pristine, submitting }) => {
          return (
            <>
              <form className="w-full max-w-sm m-auto flex-column p-4" onSubmit={handleSubmit}>
                <div className="h-[296px] w-[350px]">
                  <div className="relative mb-4">
                    <Field
                      name="email"
                      render={renderSearchSelect}
                      placeholder="Search user"
                      disabled={submitting}
                      onTextChange={onSearchTextChanged}
                      options={userOptions}
                      loading={userLoading}
                    />
                  </div>
                  {isUnregisteredUser && COLLABORATOR_FORM_USER_MESSAGE}
                  <div className="relative mb-4">
                    <Field
                      name="roles"
                      render={renderSearchSelect}
                      placeholder="Select roles"
                      disabled={submitting}
                      multiple
                      options={completeRoleOptions}
                      disabledOptions={[ROLES.MAIN_OWNER]}
                    />
                  </div>

                  <div className="relative mb-4">
                    <Field
                      name="share"
                      render={renderInput}
                      placeholder="Enter user share"
                      disabled={submitting}
                    />
                  </div>
                </div>
                <Button className="mb-0" text="Save" disabled={submitting || pristine} />
              </form>
            </>
          )
        }}
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedCollaborator: state.admin.selectedCollaboratorData,
    track_id: state.admin.selectedTrackShareData.trackDetail.id
  }
}

export default connect(mapStateToProps, { selectCollaboratorData, selectUser, saveCollaboratorForm })(
  AdminCollaboratorForm
)
