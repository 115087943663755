import React, { FC, useState } from "react"

import { KSpan } from "kahuna-base-react-components"
import { ReleaseRegistryPages } from "../../../../actions/types"

type NavigationFlowProps = {
  selectedTab: number
  setSelectedTab: (value: number) => void
  navigationZIndex: number
}

interface NavigationOption {
  value: number
  label: string
}

const navigationOptions: NavigationOption[] = [
  { value: ReleaseRegistryPages.RELEASE, label: "Release" },
  { value: ReleaseRegistryPages.UPLOAD_TRACKS, label: "Upload tracks" },
  { value: ReleaseRegistryPages.SELECT_TRACKS, label: "Select tracks" },
  { value: ReleaseRegistryPages.SUMMARY, label: "Summary" }
]

const NavigationFlow: FC<NavigationFlowProps> = (props) => {
  const renderNavigationOptions = () => {
    const len = navigationOptions.length
    const width = `calc((50vw - ${len * 8}px) / (${len - 1}))`
    return (
      <div
        className={` flex flex-row justify-between`}
        style={{
          width: "100%", zIndex: props.navigationZIndex
        }}
      >
        {navigationOptions.map((option, index) => {
          return (
            <div
              key={`navigation-${option.value}`}
              className={"flex cursor-pointer"}
              onClick={() => props.setSelectedTab(option.value)}
            >
              <div
                className="w-2 h-2 rounded-full relative"
                style={{
                  backgroundColor: props.selectedTab >= option.value ? "#111" : "#F0F0F0"
                }}
              >
                <div
                  className="absolute text-nowrap"
                  style={{
                    left: "50%",
                    top: "14px",
                    transform: "translateX(-50%)"
                  }}
                >
                  <KSpan
                    text={option.label}
                    fontWeight={props.selectedTab >= option.value ? 500 : 400}
                    color={`${props.selectedTab >= option.value ? "#111" : "#737373"}`}
                  />
                </div>
                {index < 3 && (
                  <div
                    className={`h-0.5 absolute top-[3px] left-2 `}
                    style={{
                      width,
                      backgroundColor: props.selectedTab >= option.value + 1 ? "#111" : "#F0F0F0"
                    }}
                  />
                )}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div style={{ height: "100%", width: "100%" }} className={"flex justify-center pt-4"}>
      <div className={`w-[50vw] flex flex-row justify-center  `}>{renderNavigationOptions()}</div>
    </div>
  )
}

export default NavigationFlow
