import { TableColumn, AssetRegistryIsrcCodes } from "../../../../types"
import { TABLE_COLUMN_TYPE } from "../../../../constants"
import React from "react"

const trackIsrcCodesColumns: (onDelete: (id: number) => void) => TableColumn[] = (onDelete) => [
  {
    header: "Id",
    headerStyle: {
      textAlign: "left",
      width: "20%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: AssetRegistryIsrcCodes) => <span>{row.id}</span>
  },
  {
    header: "In Use",
    headerStyle: {
      textAlign: "left",
      width: "20%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: AssetRegistryIsrcCodes) => {
      const checked = row.releaseId ? true : false
      return <input type={"checkbox"} checked={checked} readOnly={true} />
    }
  },
  {
    header: "Isrc",
    headerStyle: {
      textAlign: "left",
      width: "50%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: AssetRegistryIsrcCodes) => {
      return <span>{row.isrc}</span>
    }
  },
  {
    header: "Remove",
    headerStyle: {
      textAlign: "left",
      width: "10%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: AssetRegistryIsrcCodes) => {
      return <img style={{cursor: "pointer"}} onClick={() => onDelete(row.id)} src="/account_icons/trash.svg" />
    }
  }
]

export default trackIsrcCodesColumns
