import { KButton, KSpan, KTitleSpan, KTooltip } from "kahuna-base-react-components"
import React, { useState } from "react"
import { formatIsoDate, hashids } from "../../../utility"
import { Release, Artist } from "../../../types"
import { useNavigate, useParams } from "react-router-dom"

const ReleaseComponent = ({
  release,
  overrideBorder,
  fullScreen,
  titleOverride,
  canEdit
}: {
  release: Release
  overrideBorder: boolean
  fullScreen: boolean
  titleOverride?: string
  canEdit?: boolean
}) => {
  const [hover, setHover] = useState(false)

  const navigate = useNavigate()
  const params = useParams()

  const getStatusIcon = () => {
    if (release.status === "active") return "/catalog_icons/active-status.svg"
    else if (release.status === "reviewing") return "/catalog_icons/reviewing-status.svg"
    else if (release.status === "archived") return "/catalog_icons/archived-status.svg"
    else if (release.status === "draft") return "/catalog_icons/draft-status.svg"
    else return "/catalog_icons/draft-status.svg"
  }

  const getStatusText = () => {
    const text = release.status || " - "
    const status = text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

    return `${status}: ${formatIsoDate(release.releaseDate)}`
  }

  const renderPill = (text: string) => {
    return (
      <div style={{ borderRadius: 4, backgroundColor: "#F5F5F5", padding: 4, paddingTop: 0 }} key={Math.random()}>
        <KSpan text={text} fontSize={11} fontWeight={500} lineHeight="12px" color="#111" letterSpacing="0.22px" />
      </div>
    )
  }

  const onClickSmartLink = () => {
    navigate(`/catalog/smart-link/${hashids.encode(release.id?.toString() || "")}`)
  }

  return (
    <div
      style={{ width: "100%" }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`rounded-lg p-[16px] mt-2 ${
        overrideBorder ? "table-row-border-override" : "table-row-hover"
      } cursor-pointer`}
    >
      <div className={`h-[${fullScreen ? "96" : "64"}px] flex justify-between`}>
        <div className="flex">
          <img
            src={release.coverFileUrl}
            className={`w-[${fullScreen ? "96" : "64"}px] h-[${
              fullScreen ? "96" : "64"
            }px] rounded-[6px] cursor-pointer`}
          />
          <div className="grid ml-6">
            <KTitleSpan text={titleOverride || release.title} fontSize={20} fontWeight={700} lineHeight="28px" />
            {fullScreen && (
              <span className="flex flex-row items-center">
                <img src={getStatusIcon()} className="w-4 h-4 mr-1" />
                <KSpan text={getStatusText()} fontSize={12} fontWeight={500} lineHeight="16px" color="#3D3D3D" />
              </span>
            )}
            <span className="flex gap-1 items-end">
              {renderPill(release.productionYear || " - ")}
              {renderPill(`${release.totalTrack} song${release.totalTrack > 1 ? "s" : ""}`)}
              {renderPill(release.genre || " - ")}
            </span>
          </div>
        </div>
        <div className="flex justify-center items-center">
          {fullScreen && (
            <div className="flex">
              <div className="grid" style={{ justifyItems: "end" }}>
                <KSpan text="ARTISTS" fontSize={11} fontWeight={500} lineHeight="12px" letterSpacing="0.22px" />
                <div className="flex mt-2 gap-1">
                  {release.artistsList.map((artist: Artist) => renderPill(artist.name))}
                </div>
              </div>
              {fullScreen && hover && (
                <div
                  style={{ alignContent: "center" }}
                  className="ml-6"
                  onClick={(event) => {
                    event.stopPropagation()
                    onClickSmartLink()
                  }}
                >
                  <KTooltip
                    backgroundColor={"#595959"}
                    width="100px"
                    position="left"
                    children={
                      <KButton
                        onClick={() => {}}
                        icon="/account_icons/link.svg"
                        background="white"
                        padding="0px"
                        width="36px"
                        height="36px"
                      />
                    }
                    content={<KSpan color="white" text={"Let's create a smart link?"} />}
                  />
                </div>
              )}
              {hover && canEdit && (
                <div style={{ alignContent: "center", marginLeft: 8 }}>
                  <KButton
                    height="36px"
                    text="Edit"
                    background="transparent"
                    onClick={() => navigate(`/catalog/asset-registry/${params.releaseId}`)}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReleaseComponent
