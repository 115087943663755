import { Dialog } from "@headlessui/react"
import { KTitleSpan, KSpan, KButton, KInput, KSelectDate } from "kahuna-base-react-components"
import React, { useEffect, useState } from "react"
import { Event } from "../../../../../types"
import { title } from "process"

const initialEvent: Event = {
  title: "",
  startDate: undefined,
  endDate: undefined,
  address: "",
  url: ""
}

const AddEventModal = ({
  open,
  setOpen,
  handleConfirm,
  loading
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleConfirm: (event: Event) => void
  loading: boolean
}) => {
  const [event, setEvent] = useState<Event>({ ...initialEvent })
  const [startTime, setStartTime] = useState("")
  const [endTime, setEndTime] = useState("")

  const renderDate = (label: string, date?: Date) => {
    if (!date) {
      return label
    }
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0") // January is 0!
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
  }

  const handleHourChange = (val, setTime) => {
    let value = val.replace(/\D/g, "") // Only keep digits
    if (value.length > 4) value = value.slice(0, 4) // Limit input to 4 characters

    // Format the time string as HH:MM
    if (value.length > 2) {
      value = `${value.slice(0, 2)}:${value.slice(2)}`
    }
    setTime(value)
  }

  const onHourBlur = (time: string, setTime: (time: string) => void, isStart: boolean) => {
    if (time.length !== 5) {
      setTime("")
      return
    }

    const timeSplit = time.split(":")
    const hour = timeSplit[0]
    const minute = timeSplit[1]

    if (hour > "23" || hour < "00") {
      setTime("")
      return
    }

    if (minute > "59" || minute < "00") {
      setTime("")
      return
    }

    if (isStart) {
      const startDate = event.startDate
      startDate?.setHours(parseInt(hour))
      startDate?.setMinutes(parseInt(minute))
      setEvent({ ...event, startDate })
    } else {
      const endDate = event.endDate
      endDate?.setHours(parseInt(hour))
      endDate?.setMinutes(parseInt(minute))
      setEvent({ ...event, endDate })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
        setEvent({ ...initialEvent })
      }}
    >
      <div className="fixed top-0 z-200 left-0 w-screen h-screen flex items-center justify-center bg-[#0000004d] ">
        <div className="pt-8 pb-4 px-6 w-[440px] shadow-md rounded-[10px] bg-[#fff]">
          <div className="flex flex-col items-center gap-8">
            <div className="flex flex-col gap-8 items-center">
              <span
                style={{
                  padding: 16,
                  borderRadius: "9999px",
                  background: "var(--bg-white-0, #FFF)",
                  boxShadow:
                    "0px 32px 24px -12px rgba(17, 17, 17, 0.06), 0px 11px 4px 0px rgba(17, 17, 17, 0.01), 0px 6px 4px 0px rgba(17, 17, 17, 0.02), 0px 3px 3px 0px rgba(17, 17, 17, 0.03), 0px 1px 1px 0px rgba(17, 17, 17, 0.04), 0px 0px 0px 1px rgba(17, 17, 17, 0.04)"
                }}
              >
                <img className="w-8 h-8" src="/analytics_icons/calendar.svg" />
              </span>
              <KTitleSpan text={"Add Event"} fontSize={24} lineHeight="28px" color="#000" />
            </div>
            <div className="flex flex-col gap-3 items-center w-full">
              <div className="flex justify-between" style={{ width: "100%" }}>
                <div
                  className="flex items-center gap-2 event-date-container"
                  style={{ background: event.startDate ? "#FFFFFF" : "rgb(245, 245, 245)" }}
                >
                  <KSelectDate
                    minimumDate={new Date()}
                    boxShadow={"none"}
                    hideBody={true}
                    value={event.startDate || undefined}
                    onChange={(value) => {
                      setEvent({ ...event, startDate: value })
                      setStartTime("")
                    }}
                  />
                  <KSpan text={renderDate("Start Date", event.startDate)} />
                </div>
                <KInput
                  value={startTime}
                  onChange={(value) => handleHourChange(value, setStartTime)}
                  onBlur={() => onHourBlur(startTime, setStartTime, true)}
                  placeholder="__:__"
                />
              </div>
              <div className="flex justify-between" style={{ width: "100%" }}>
                <div
                  className="flex items-center gap-2 event-date-container"
                  style={{ background: event.endDate ? "#FFFFFF" : "rgb(245, 245, 245)" }}
                >
                  <KSelectDate
                    minimumDate={new Date()}
                    boxShadow={"none"}
                    hideBody={true}
                    value={event.endDate || undefined}
                    onChange={(value) => {
                      setEvent({ ...event, endDate: value })
                      setEndTime("")
                    }}
                  />
                  <KSpan text={renderDate("End Date", event.endDate)} />
                </div>
                <KInput
                  value={endTime}
                  onChange={(value) => handleHourChange(value, setEndTime)}
                  onBlur={() => onHourBlur(endTime, setEndTime, false)}
                  placeholder="__:__"
                />
              </div>

              <KInput
                value={event.title || ""}
                placeholder="Title"
                padding="14px"
                onChange={(value) => {
                  setEvent({ ...event, title: value })
                }}
              />
              <KInput
                value={event.address || ""}
                placeholder="Address"
                padding="14px"
                onChange={(value) => {
                  setEvent({ ...event, address: value })
                }}
              />
              <KInput
                value={event.url || ""}
                placeholder="Url"
                padding="14px"
                onChange={(value) => {
                  setEvent({ ...event, url: value })
                }}
              />
              <div className="flex mt-8 gap-3" style={{ width: "100%" }}>
                <KButton
                  text="Cancel"
                  height="48px"
                  onClick={() => setOpen(false)}
                  shadowDisabled={true}
                  background="#fff"
                  textColor="#000"
                />
                <KButton
                  background="#000"
                  textColor="#FFF"
                  height="48px"
                  disabled={
                    !event.startDate ||
                    !event.endDate ||
                    !event.title ||
                    !event.address ||
                    !event.url ||
                    loading ||
                    startTime.length !== 5 ||
                    endTime.length !== 5
                  }
                  text="Accept"
                  onClick={() => handleConfirm(event)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default AddEventModal
