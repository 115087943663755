import "../styles/Invitation.css"
import { InvitationLayoutProps } from "../../../../types"
import React from "react"
import { KButton, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { useNavigate } from "react-router-dom"

const InvitationLayout = (props: InvitationLayoutProps) => {
  const navigate = useNavigate()

  return (
    <div className="invitation grid align-middle justify-center items-center">
      <div className="invitation-container">
        <div className="invitation-wrapper">
          <div className="mb-8 flex justify-center">
            <KTitleSpan text={props.title || ""} fontSize={40}/>
          </div>
          <div className="mb-12 flex" style={{maxWidth: 440, textAlign: "center"}}>
            <KSpan text={props.text || ""} color="#1F1F1F" fontSize={18} lineHeight="26px"/> 
          </div>
          <KButton onClick={() => navigate("/login")} background="black" textColor="white" text="Back to login" />
        </div>
      </div>
    </div>
  )
}

export default InvitationLayout
