import React, { useEffect, useState } from "react"
import { ReleaseRegistryPages, ReleaseRegistryReleaseTabs } from "../../../../../actions/types"
import { useOrchestrator } from "../ReleaseRegistryContextProvider"
//@ts-ignore
import ArrowLeftIcon from "../../../../../assets/release-registry-icons/arrow-left.svg"
//@ts-ignore
import InfoColoredIcon from "../../../../../assets/release-registry-icons/info-colored.svg"
import { KButton, KSelectDate, KSpan, KTitleSpan } from "kahuna-base-react-components"
import Loading from "../../../../ui/Loading"
import ErrorModal from "../modal/ErrorModal"

const SelectReleaseDate = ({ setSelectedTab }: { setSelectedTab: (tab: ReleaseRegistryReleaseTabs) => void }) => {
  const { releaseForm, updateOffer, setSelectedNavigationTab, saveProductForm, loading, errorText, setErrorText } =
    useOrchestrator()
  const [releaseDateMessage, setReleaseDateMessage] = useState<string>("")

  const onSelectReleaseDate = (date: Date | undefined) => {
    if (!date) {
      updateOffer("release-date", undefined)
      setReleaseDateMessage("Missing Release Date")
      return
    }

    date.setHours(12)

    const dateMinLimit = new Date()
    dateMinLimit.setDate(dateMinLimit.getDate() + 7)

    const dateWarningLimit = new Date()
    dateWarningLimit.setDate(dateMinLimit.getDate() + 21)

    if (dateMinLimit.getTime() > date.getTime()) {
      updateOffer("release-date", undefined)
      setReleaseDateMessage("The date must be at least 1 week from now")
      return
    } else if (dateWarningLimit.getTime() > date.getTime()) {
      setReleaseDateMessage("(Recommended at least 3 weeks from now)")
    } else {
      setReleaseDateMessage("")
    }

    updateOffer("release-date", date?.toISOString())
  }

  const saveAndContinue = async () => {
    const productResponse = await saveProductForm()

    if (productResponse) {
      setSelectedNavigationTab(ReleaseRegistryPages.UPLOAD_TRACKS)
    }
  }

  const renderReleaseDateView = () => {
    const releaseDate = releaseForm.offer.data.attributes["release-date"]

    return (
      <div className="flex flex-row items-center justify-start gap-2">
        <KSpan text="Release Date:" fontSize={16} fontWeight={500} color="#111111" />
        <KSpan
          text={releaseDate?.substring(0, 10) || releaseDateMessage}
          fontSize={16}
          color={releaseDate ? "#111111" : "#FF0000"}
          fontStyle={releaseDate ? "normal" : "italic"}
        />
        {releaseDate && releaseDateMessage && (
          <KSpan text={releaseDateMessage} fontSize={16} color={"#11111150"} fontStyle={"italic"} />
        )}
      </div>
    )
  }

  return (
    <div className="w-[616px] max-sm:w-auto flex flex-col gap-6">
      {loading && <Loading />}
      <ErrorModal text={errorText} setText={setErrorText} />
      <div className="flex flex-col gap-3 justify-start">
        <div className="flex flex-row gap-4 items-center">
          <div>
            <KButton
              icon={ArrowLeftIcon}
              onClick={() => {
                setSelectedTab(ReleaseRegistryReleaseTabs.APPLE_DIGITAL_MASTER)
              }}
              width="36px"
              height="36px"
              padding="8px"
              background="#F0F0F0"
              shadowDisabled
              hoverBackground="#E7E7E7"
            />
          </div>
          <KTitleSpan
            text="Select Release Date"
            fontSize={40}
            lineHeight="48px"
            letterSpacing="-0.4px"
            color="#1F1F1F"
          />
        </div>
        <KSpan text="Your Spotify artist name uniquely identifies you on the platform." />
      </div>
      <div className="flex flex-col gap-4">
        {renderReleaseDateView()}
        <div>
          <KSelectDate
            minimumDate={new Date()}
            value={
              releaseForm.offer.data.attributes["release-date"] &&
              new Date(releaseForm.offer.data.attributes["release-date"])
            }
            onChange={(date: Date | undefined) => {
              onSelectReleaseDate(date)
            }}
          />
        </div>
        <div className="px-[14px] pt-[14px] pb-4 flex flex-row justify-between items-start gap-3 bg-[#EDEFFA] rounded-[10px]">
          <div className="shrink-0">
            <img src={InfoColoredIcon} />
          </div>
          <div className="opacity-80 inline-flex">
            <KSpan
              text={`Selecting Friday is crucial to getting into playlists. If you want the song to quickly gain traction and be featured in playlists, choose Friday.`}
              color="#151B3D"
            />
          </div>
        </div>
      </div>
      <div>
        <div>
          <KButton
            text="Save Product & Continue"
            onClick={() => {
              saveAndContinue()
            }}
            background="#000"
            textColor="#FFF"
            disabled={!releaseForm.offer.data.attributes["release-date"] || loading}
          />
        </div>
      </div>
    </div>
  )
}
export default SelectReleaseDate
