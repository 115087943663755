import React from "react"

import "./styles/Loading.css"

const Loading = () => {
  return (
    <div
      className="flex justify-center items-center"
      style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        inset: 0,
        background: "rgb(153, 153, 153, 0.6)",
        zIndex: 999
      }}
    >
      <div className="loader" />
    </div>
  )
}

export default Loading
