import { KSpan } from "kahuna-base-react-components"
import React, { SetStateAction, useEffect, useState } from "react"
import { Link } from "react-router-dom"

const NavListElement = ({
  iconPath,
  text,
  bRadius,
  bgColor,
  index,
  selectedIndex,
  setSelectedIndex,
  href
}: {
  iconPath: string
  text: string
  bgColor: string
  bRadius: number
  index: number
  selectedIndex: number
  setSelectedIndex: React.Dispatch<SetStateAction<number>>
  href: string
}) => {
  const onClickEvent = () => {
    setSelectedIndex(index)
    //iik rebnderda selectedindex = 0 oldugu için analytics kısmına tıklayınca bir sey olmuyor. Cunku orda da selectedindex 0 oldugundan dolayi
    // deger degismedigi icin re-render olmuyor
  }

  const [param, setParam] = useState<string>(window.location.pathname)

  useEffect(() => {
    setParam(window.location.pathname)
    //dependency'e param da eklesem sonsuz donguye girer mi?
  }, [selectedIndex])

  return (
    <Link to={href} className="group">
      <div onClick={onClickEvent} className={`w-auto flex flex-row justify-start items-center`}>
        <div
          className="flex group-hover:!bg-[#f0f0f0]"
          style={{
            backgroundColor: param === href ? "#f3f3f3" : bgColor,
            borderRadius: 10,
            padding: "8px 10px"
          }}
        >
          <img src={iconPath} className="mr-2" />
          <KSpan text={text} color={param === href ? "#000" : "#666"} fontWeight={500} />
        </div>
      </div>
    </Link>
  )
}

export default NavListElement
