import { Action, Dispatch } from "redux"
import { handleAuthorizedRequest, releases, tracks, trackShareHolders } from "../apis"
import { TRACK_ACTION } from "./types"
import { hashids, mapPaginatedDataToCamelCase, mapToCamelCase } from "../utility"
import { CatalogFilter, Release, Track } from "../types"

export const CATALOG_ACTION = {
  FETCH_DATA: "FETCH_DATA",
  FETCH_RELEASE_TRACKS: "FETCH_RELEASE_TRACKS",
  SELECT_RELEASE: "SELECT_RELEASE",
  SELECT_TRACK: "SELECT_TRACK",
  FETCH_TRACK_SHARE_DATA: "FETCH_TRACK_SHARE_DATA",
  FETCH_RELEASE_TRACK_SHARE_DATA: "FETCH_RELEASE_TRACK_SHARE_DATA",
  SELECT_CATALOG_FILTER: "SELECT_CATALOG_FILTER",
  UPDATE_RELEASE_LIST: "UPDATE_RELEASE_LIST",
  DELETE_RELEASE: "DELETE_RELEASE"
}

export const fetchCatalogData =
  (limit: number, offset: number = 0, userId: number, filter: CatalogFilter, search?: string, searchUpc?: string) =>
  async (dispatch: Dispatch<Action>) =>
    await handleAuthorizedRequest(
      async () => {
        const ownerStatusParam = `&owner_status=${filter.ownerStatus}`
        const choosenStatusParam =
          filter.choosenStatus.length > 0 ? `&choosen_status=${filter.choosenStatus.join(",")}` : ""
        const choosenGenreParam =
          filter.choosenGenre.length > 0 ? `&choosen_genre=${filter.choosenGenre.join(",,,")}` : ""
        let path = `/release/?user=${userId}&limit=${limit}&offset=${offset}${ownerStatusParam}${choosenStatusParam}${choosenGenreParam}`
        if (search) path += `&search=${search}`
        if (searchUpc) path += `&search_upc=${searchUpc}`
        const response = await releases(path, "get")

        dispatch({
          type: CATALOG_ACTION.FETCH_DATA,
          payload: mapPaginatedDataToCamelCase<Release>(response.data)
        })
      },
      "Error fetching releases: ",
      dispatch
    )

export const fetchReleaseTracks =
  (limit: number, offset: number, releaseId: string, userId: number) => async (dispatch: Dispatch<Action>) => {
    await handleAuthorizedRequest(
      async () => {
        const response = await tracks(
          `/track_with_share_holders/?limit=${limit}&offset=${offset}&release=${releaseId}&user=${userId}`,
          "get"
        )
        const paginatedTracksList = mapPaginatedDataToCamelCase<Track>(response.data)

        const tracksWithShareData = []
        for (const track of paginatedTracksList.results) {
          const trackShareResponse = track["shareHolders"]
          let shareDataForUser = []
          if (userId === 0) {
            shareDataForUser = [...trackShareResponse]
          } else if (localStorage.getItem("appliedAffiliate")) {
            shareDataForUser = trackShareResponse.find(
              (result: any) => result.user === parseInt(localStorage.getItem("appliedAffiliate"))
            )
          } else {
            shareDataForUser = trackShareResponse.find((result: any) => result.user === userId)
          }

          if (shareDataForUser) {
            const data = {
              roles: shareDataForUser?.roleList?.map((role: any) => role.roleDetail.name) || [],
              share: shareDataForUser?.share || 0,
              length: trackShareResponse.length
            }
            tracksWithShareData.push({
              ...track,
              shareData: data
            })
          }
        }
        paginatedTracksList.results = tracksWithShareData

        dispatch({
          type: CATALOG_ACTION.FETCH_RELEASE_TRACKS,
          payload: paginatedTracksList
        })
      },
      "Fetch release tracks has error:",
      dispatch
    )
  }

export const fetchTrack = (trackId: string) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const decodedId = hashids.decode(trackId).toString()

      const trackResponse = await releases(`/track/${decodedId}`, "get")
      const releaseResponse = await releases(`/release/${trackResponse.data.release}`, "get")

      dispatch({
        type: TRACK_ACTION.FETCH_TRACK,
        payload: {
          ...mapToCamelCase<Track>(trackResponse.data),
          releaseObject: {
            ...mapToCamelCase<Release>(releaseResponse.data)
          }
        }
      })
    },
    "Fetching track has error: ",
    dispatch
  )
}

export const selectRelease = (release?: Release, releaseId?: string) => async (dispatch: Dispatch<Action>) => {
  if (!release) {
    await handleAuthorizedRequest(
      async () => {
        const response = await releases(`/release/${releaseId}/`, "get")

        dispatch({
          type: CATALOG_ACTION.SELECT_RELEASE,
          payload: mapToCamelCase(response.data)
        })
      },
      "!!!!",
      dispatch
    )
  } else {
    dispatch({
      type: CATALOG_ACTION.SELECT_RELEASE,
      payload: mapToCamelCase(release)
    })
  }
}

export const selectTrack = (track?: Track, trackId?: string) => async (dispatch: Dispatch<Action>) => {
  if (!track) {
    await handleAuthorizedRequest(
      async () => {
        const response = await tracks(`/${trackId}/`, "get")

        dispatch({
          type: CATALOG_ACTION.SELECT_TRACK,
          payload: mapToCamelCase(response.data)
        })
      },
      "Error getting track: ",
      dispatch
    )
  } else {
    dispatch({
      type: CATALOG_ACTION.SELECT_TRACK,
      payload: mapToCamelCase(track)
    })
  }
}

export const fetchTrackShareData = (trackId: number) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await trackShareHolders(`/?track_id=${trackId}`, "get")
      const data = response.data.results.map((result: any) => ({
        photo: result.user_detail.photo,
        first_name: result.user_detail.first_name,
        last_name: result.user_detail.last_name,
        email: result.user_detail.email,
        roles: result.role_list.map((role: any) => role.role_detail.name),
        share: result.share
      }))

      dispatch({
        type: CATALOG_ACTION.FETCH_TRACK_SHARE_DATA,
        payload: data
      })
    },
    "Error fetching track share data: ",
    dispatch
  )
}

export const selectCatalogFilter =
  (choosenStatus: string[], ownerStatus: string, choosenGenre: string[]) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: CATALOG_ACTION.SELECT_CATALOG_FILTER,
      payload: { choosenStatus, ownerStatus, choosenGenre }
    })
  }

export const updateReleaseLabelCamp = (id: number, labelCampStatus: boolean) => async (dispatch: Dispatch<Action>) =>
  await handleAuthorizedRequest(
    async () => {
      const path = `/release/update_label_camp_status/?id=${id}&status=${labelCampStatus}`

      const response = await releases(path, "put")

      if (!response.data.success) {
        return false
      }

      dispatch({
        type: CATALOG_ACTION.UPDATE_RELEASE_LIST,
        payload: { id, labelCamp: labelCampStatus }
      })

      return true
    },
    "Error updating label camp status: ",
    dispatch
  )

export const updateReleaseStatus = (id: number, status: string) => async (dispatch: Dispatch<Action>) =>
  await handleAuthorizedRequest(
    async () => {
      const path = `/release/update_label_status/?id=${id}&status=${status}`

      const response = await releases(path, "put")

      if (!response.data.success) {
        return false
      }

      dispatch({ type: CATALOG_ACTION.UPDATE_RELEASE_LIST, payload: { id, status } })

      return true
    },
    "Error updating status: ",
    dispatch
  )


  export const deleteRelease = (id: number) => async (dispatch: Dispatch<Action>) =>
    await handleAuthorizedRequest(
      async () => {
        const response = await releases(`/release/remove/?id=${id}`, "delete")
  
        if (!response.data.success) {
          return false
        }
  
        dispatch({ type: CATALOG_ACTION.DELETE_RELEASE, payload: { id } })
  
        return true
      },
      "Error deleting release: ",
      dispatch
    )
