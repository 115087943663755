import { Action, PaymentMethod } from "../types"
import { PAYMENT_METHODS_ACTION } from "../actions/types"
import { mapKeys, omit } from "lodash"

export default (state: Record<string, PaymentMethod> = {}, action: Action) => {
  switch (action.type) {
    case PAYMENT_METHODS_ACTION.FETCH_PAYMENT_METHODS:
      return { ...mapKeys(action.payload, "id") }
    case PAYMENT_METHODS_ACTION.ADD_PAYMENT_METHOD:
      return { ...state, [action.payload.id]: action.payload }
    case PAYMENT_METHODS_ACTION.DELETE_PAYMENT_METHOD:
      const id = action.payload as number
      return omit(state, id)
    case PAYMENT_METHODS_ACTION.UPDATE_PAYMENT_METHOD:
      const updatedId = action.payload?.id as number
      state[updatedId] = action.payload as PaymentMethod
      return { ...state }
    default:
      return state
  }
}
