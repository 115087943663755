import { Action, Dispatch } from "redux"
import { handleAuthorizedRequest, userPayments, users } from "../apis"
import { toast } from "react-toastify"
import { successToastOptions } from "../constants"
import { mapListToCamelCase, mapToSnakeCase } from "../utility"
import { Event, SocialMedia } from "../types"

export const USERS_ACTION = {
  FETCH_SOCIAL_MEDIA_LINKS: "FETCH_SOCIAL_MEDIA_LINKS",
  DELETE_SOCIAL_MEDIA_LINK: "DELETE_SOCIAL_MEDIA_LINK",
  FETCH_EVENTS: "FETCH_EVENTS",
  DELETE_EVENT: "DELETE_EVENT"
}

export const fetchSocialMediaLinks = (userId?: number) => async (dispatch: Dispatch<Action>) => {
  const url = `/social_media_links/?user_id=${userId}`
  await handleAuthorizedRequest(
    async () => {
      const response = await users(url, "get")
      dispatch({
        type: USERS_ACTION.FETCH_SOCIAL_MEDIA_LINKS,
        payload: mapListToCamelCase(response.data.results)
      })
    },
    "Error fetching social media links: ",
    dispatch
  )
}

export const saveSocialMediaLink = (socialMedia: SocialMedia) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await users(`/save_social_media/`, "post", mapToSnakeCase(socialMedia))
      if (response.data.results) {
        toast.success("Link added successfully", successToastOptions)
      }
      dispatch({
        type: USERS_ACTION.FETCH_SOCIAL_MEDIA_LINKS,
        payload: mapListToCamelCase(response.data.results)
      })
    },
    "Error saving social media link: ",
    dispatch
  )
}

export const deleteSocialMediaLink = (id: number) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const response = await users(`/delete_social_media/?id=${id}`, "delete")
      if (!response.data.success) {
        toast.error("Error deleting social media link", successToastOptions)
        return
      }

      if (response.data.success) {
        toast.success("Successfully deleted social media link", successToastOptions)
      }

      dispatch({
        type: USERS_ACTION.DELETE_SOCIAL_MEDIA_LINK,
        payload: id
      })
    },
    "Error saving social media link: ",
    dispatch
  )
}

export const fetchEvents = (userId?: number) => async (dispatch: Dispatch<Action>) => {
  const url = `/events/?user_id=${userId}`
  await handleAuthorizedRequest(
    async () => {
      const response = await users(url, "get")
      dispatch({
        type: USERS_ACTION.FETCH_EVENTS,
        payload: mapListToCamelCase(response.data.results)
      })
    },
    "Error fetching events: ",
    dispatch
  )
}

export const saveEvent = (event: Event) => async (dispatch: Dispatch<Action>) => {
  return await handleAuthorizedRequest(
    async () => {
      const response = await users(`/save_event/`, "post", {
        ...mapToSnakeCase(event),
        start_date: event.startDate,
        end_date: event.endDate
      })

      dispatch({
        type: USERS_ACTION.FETCH_EVENTS,
        payload: mapListToCamelCase(response.data.results)
      })

      if (response.data.results) {
        toast.success("Event added successfully", successToastOptions)
        return true
      }

      return false
    },
    "Error saving event: ",
    dispatch
  )
}

export const deleteEvent = (id: number) => async (dispatch: Dispatch<Action>) => {
  return await handleAuthorizedRequest(
    async () => {
      const response = await users(`/delete_event/?id=${id}`, "delete")
      if (!response.data.success) {
        toast.error("Error deleting event", successToastOptions)
        return false
      }

      if (response.data.success) {
        toast.success("Successfully deleted event", successToastOptions)
      }

      dispatch({
        type: USERS_ACTION.DELETE_EVENT,
        payload: id
      })

      return true
    },
    "Error saving event: ",
    dispatch
  )
}
