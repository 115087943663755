import * as React from "react"
import { FC } from "react"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { NavigationProps } from "../../../types"
import { logout } from "../../../actions/loginActions"
import NavSection from "./NavSection"
import InfoSection from "./InfoSection"
import SocialSection from "./SocialSection"

const Navigation: FC<NavigationProps> = (props) => {
  return (
    <div className={"p-8 flex flex-col  justify-between h-full"} style={{ overflowY: "scroll" }}>
      <NavSection user={props.user} />
      <div className="flex flex-col justify-between gap-4">
        <InfoSection />
        <SocialSection />
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return { user: state.auth.user }
}

export default connect(mapStateToProps, { logout })(Navigation)
