import { Release, TableColumn } from "../../../../types"
import { RELEASE_STATUS, TABLE_COLUMN_TYPE } from "../../../../constants"
import React from "react"

const catalogColumns: TableColumn[] = [
  {
    header: "Release Name",
    headerStyle: {
      textAlign: "left",
      width: "30%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => (
      <div className="flex">
        <div className="mr-4">
          <div className="h-[40px] w-[40px] rounded-[100%] bg-gray-800 overflow-hidden">
            <img src={row.coverFileUrl} alt={"table_image"} />
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="text-sm leading-5 font-medium text-gray-900">{row.title}</div>
        </div>
      </div>
    )
  },
  {
    header: "Artists",
    headerStyle: {
      textAlign: "left",
      width: "35%"
    },
    cellStyle: {
      fontWeight: 400,
      lineHeight: "20px",
      color: "#111827"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      return (
        <div className="flex">
          <div>
            <div className="text-sm leading-5 font-normal text-gray-500">
              {row.artistsList.map((artist) => artist.name).join(", ")}
            </div>
          </div>
        </div>
      )
    }
  },
  {
    header: "Release Date",
    headerStyle: {
      textAlign: "left",
      width: "15%"
    },
    cellStyle: {
      fontWeight: 400,
      lineHeight: "20px",
      color: "#6B7280"
    },
    type: TABLE_COLUMN_TYPE.DATE,
    attribute: "releaseDate",
    datePattern: "d LLL, yyyy",
    dateParsePattern: "yyyy-LL-dd"
  },
  {
    header: "Status",
    headerStyle: {
      textAlign: "center",
      width: "10%"
    },
    type: TABLE_COLUMN_TYPE.CONST,
    constType: RELEASE_STATUS,
    attribute: "status"
  },
  {
    header: "",
    type: TABLE_COLUMN_TYPE.LINK,
    attribute: "id"
  }
]

export default catalogColumns
