import React, { FC, useEffect, useState } from "react"
import { LCReleaseForm, SpotifyEditorialPitching } from "../../../../../types"
import { KButton, KInput, KSpan, KTitleSpan, KTextArea } from "kahuna-base-react-components"
import { AssetRegistryPages } from "../../../../../actions/types"
import { releases } from "../../../../../apis"
import { getDuration, mapToCamelCase } from "../../../../../utility"
import AudioPlayer from "../../track-detail/AudioPlayer"
import StatusCard from "../../StatusCard"
import {
  spotifyEditorialPitchingGenreOptions,
  spotifyEditorialPitchingInstrumentionOptions,
  spotifyEditorialPitchingMoodOptions,
  spotifyEditorialPitchingStyleOptions
} from "../../../../../constants"

interface SpotifyEditorialPitchingPageProps {
  releaseForm: LCReleaseForm
  spotifyEditorialPitching: SpotifyEditorialPitching
  setSpotifyEditorialPitching: (spotifyEditorialPitching: SpotifyEditorialPitching) => void
  setSelectedTab: (selectedTab: number) => void
  setErrorText: (errorText?: string) => void
}

enum EditorialPitchingTabs {
  SELECT_TRACKS = 1,
  MUSIC_GENRE = 2,
  MUSIC_MOOD = 3,
  SONG_STYLE = 4,
  INSTRUMENTION = 5,
  DESCRIPTION = 6
}

const SpotifyEditorialPitchingPage: FC<SpotifyEditorialPitchingPageProps> = (props) => {
  const { releaseForm, spotifyEditorialPitching, setSpotifyEditorialPitching, setSelectedTab, setErrorText } = props

  const [selectedPitchingTab, setSelectedPitchingTab] = useState(EditorialPitchingTabs.SELECT_TRACKS)
  const [playingAudioRefs, setPlayingAudioRefs] = useState<HTMLAudioElement[]>([])
  const [instumentOptions, setInstrumentOptions] = useState([...spotifyEditorialPitchingInstrumentionOptions])
  const [instrumentSearchText, setInstrumentSearchText] = useState("")

  useEffect(() => {
    console.log(spotifyEditorialPitching)
  }, [spotifyEditorialPitching])

  useEffect(() => {
    if (!instrumentSearchText) {
      setInstrumentOptions([...spotifyEditorialPitchingInstrumentionOptions])
    }
    setInstrumentOptions(
      [...spotifyEditorialPitchingInstrumentionOptions].filter((option) =>
        option.toLocaleLowerCase().includes(instrumentSearchText.toLowerCase())
      )
    )
  }, [instrumentSearchText])

  const onSubmit = () => {
    if (!releaseForm.product.data.attributes["upc-code"] || !spotifyEditorialPitching.trackIsrc) {
      setErrorText("Please save Release and select Track")
      return
    }

    releases(`/release/save_spotify_editorial_pitching/`, "post", {
      ...spotifyEditorialPitching,
      upc: releaseForm.product.data.attributes["upc-code"],
      track_isrc: spotifyEditorialPitching.trackIsrc
    }).then((resp) => {
      if (resp.data.result) {
        setSpotifyEditorialPitching(mapToCamelCase(resp.data.result))
        setSelectedTab(AssetRegistryPages.SUMMARY)
      }
    })
  }

  const renderTitle = (title: string, previousTab: EditorialPitchingTabs) => {
    return (
      <div className="flex items-center">
        <div className="mr-4">
          <KButton
            background="#F0F0F0"
            width="36px"
            height="36px"
            padding="0px"
            icon="/navigation_icons/arrow-left-line.svg"
            onClick={() => setSelectedPitchingTab(previousTab)}
          />
        </div>
        <KTitleSpan text={title} fontSize={40} />
      </div>
    )
  }

  const renderSelectTracks = () => {
    return (
      <div className="grid">
        <KTitleSpan text={"Select Tracks"} fontSize={40} />
        <KSpan text="Select 1 track for editorial pitching" fontSize={14} lineHeight="40px" />
        {releaseForm.tracks.map((track, index) => {
          return (
            <div className="flex p-4" key={`select_track_${index}`}>
              <div className="items-center flex" style={{ width: 40 }}>
                <KSpan text={index.toString()} />
              </div>
              <div className="items-center flex" style={{ width: 40 }}>
                <AudioPlayer
                  src={track.data.attributes["preview-url"]}
                  playingAudioRefs={playingAudioRefs}
                  setPlayingAudioRefs={setPlayingAudioRefs}
                />
              </div>

              <div className="items-center flex" style={{ width: 300 }}>
                <KSpan text={track.data.attributes["title"]} color="#111" />
              </div>

              <div className="items-center flex" style={{ width: 120 }}>
                <KSpan text={getDuration(track)} color="#111" />
              </div>

              <div className="items-center flex justify-end" style={{ width: 50 }}>
                <input
                  key={`select_track_${index}_checkbox`}
                  type="checkbox"
                  style={{ accentColor: "black" }}
                  onChange={() => {
                    setSpotifyEditorialPitching({
                      ...spotifyEditorialPitching,
                      trackIsrc: track.data.attributes["isrc-code"]
                    })
                  }}
                  checked={spotifyEditorialPitching.trackIsrc === track.data.attributes["isrc-code"]}
                />
              </div>
            </div>
          )
        })}
        <div className="flex p-3 mt-2" style={{ width: 600, borderRadius: 10, background: "#EDEFFA" }}>
          <img src="/catalog_icons/information-line.svg" className="mr-2" />
          <KSpan text="You need to choose the song that will be added to playlists for the album. This allows each album you share to enter relevant playlists based on its emotion, music genre, and mood." />
        </div>
        <div className="mt-6">
          <KButton
            disabled={!spotifyEditorialPitching.trackIsrc}
            onClick={() => setSelectedPitchingTab(EditorialPitchingTabs.MUSIC_GENRE)}
            background="black"
            textColor="white"
            text="Continue"
            width="600px"
          />
        </div>
      </div>
    )
  }

  const renderMusicGenre = () => {
    return (
      <div className="grid gap-2">
        {renderTitle("Music Genre", EditorialPitchingTabs.SELECT_TRACKS)}
        <KSpan text="Select your music genre for editorial pitching" fontSize={14} lineHeight="40px" />
        <KSpan text="SELECT UP TO 3" fontSize={12} />
        <div className="flex flex-row gap-2 mt-3">
          {spotifyEditorialPitchingGenreOptions.map((option) => {
            return (
              <div key={`genre_${option}`} style={{ width: "auto" }}>
                <StatusCard
                  text={option}
                  choosenStatus={spotifyEditorialPitching.genre}
                  setChoosenStatus={(values) => {
                    if (values.length > 3) {
                      return
                    }
                    setSpotifyEditorialPitching({ ...spotifyEditorialPitching, genre: values })
                  }}
                />
              </div>
            )
          })}
        </div>
        <div className="mt-6">
          <KButton
            onClick={() => setSelectedPitchingTab(EditorialPitchingTabs.MUSIC_MOOD)}
            background="black"
            textColor="white"
            disabled={!spotifyEditorialPitching.genre?.length}
            text="Continue"
            width="600px"
          />
        </div>
      </div>
    )
  }

  const renderMusicMood = () => {
    return (
      <div className="grid gap-2">
        {renderTitle("Music Mood", EditorialPitchingTabs.MUSIC_GENRE)}
        <KSpan text="Select your music mood for editorial pitching" fontSize={14} lineHeight="40px" />
        <KSpan text="SELECT UP TO 3" fontSize={12} />
        <div className="flex flex-row flex-wrap gap-2 mt-3" style={{ width: 600 }}>
          {spotifyEditorialPitchingMoodOptions.map((option) => {
            return (
              <div key={`mood_${option}`} style={{ width: "auto" }}>
                <StatusCard
                  text={option}
                  choosenStatus={spotifyEditorialPitching.mood}
                  setChoosenStatus={(values) => {
                    if (values.length > 3) {
                      return
                    }
                    setSpotifyEditorialPitching({ ...spotifyEditorialPitching, mood: values })
                  }}
                />
              </div>
            )
          })}
        </div>
        <div className="mt-6">
          <KButton
            onClick={() => setSelectedPitchingTab(EditorialPitchingTabs.SONG_STYLE)}
            background="black"
            textColor="white"
            disabled={!spotifyEditorialPitching.mood?.length}
            text="Continue"
            width="600px"
          />
        </div>
      </div>
    )
  }

  const renderSongStyle = () => {
    return (
      <div className="grid gap-2">
        {renderTitle("Song Style", EditorialPitchingTabs.MUSIC_MOOD)}
        <KSpan text="Select your song style for editorial pitching" fontSize={14} lineHeight="40px" />
        <KSpan text="SELECT UP TO 2" fontSize={12} />
        <div className="flex flex-row flex-wrap gap-2 mt-3" style={{ width: 600 }}>
          {spotifyEditorialPitchingStyleOptions.map((option) => {
            return (
              <div key={`mood_${option}`} style={{ width: "auto" }}>
                <StatusCard
                  text={option}
                  choosenStatus={spotifyEditorialPitching.style}
                  setChoosenStatus={(values) => {
                    if (values.length > 2) {
                      return
                    }
                    setSpotifyEditorialPitching({ ...spotifyEditorialPitching, style: values })
                  }}
                />
              </div>
            )
          })}
        </div>
        <div className="mt-6">
          <KButton
            onClick={() => setSelectedPitchingTab(EditorialPitchingTabs.INSTRUMENTION)}
            background="black"
            textColor="white"
            disabled={!spotifyEditorialPitching.style?.length}
            text="Continue"
            width="600px"
          />
        </div>
      </div>
    )
  }

  const renderInstrumention = () => {
    return (
      <div className="grid gap-2">
        {renderTitle("Instrumentation", EditorialPitchingTabs.SONG_STYLE)}
        <KSpan text="Select your instrumentation for editorial pitching" fontSize={14} lineHeight="40px" />
        <KInput
          value={instrumentSearchText}
          onChange={(value) => setInstrumentSearchText(value)}
          placeholder="Search"
          leftIcon="/catalog_icons/search-2-line.svg"
        />
        <div className="mt-1" style={{ width: 600, maxHeight: 130, minHeight: 130, overflowY: "scroll" }}>
          <div className="flex flex-row flex-wrap gap-2 mt-3">
            {instumentOptions.map((option) => {
              return (
                <div key={`mood_${option}`} style={{ width: "auto" }}>
                  <StatusCard
                    text={option}
                    choosenStatus={spotifyEditorialPitching.instrumentation}
                    setChoosenStatus={(values) =>
                      setSpotifyEditorialPitching({ ...spotifyEditorialPitching, instrumentation: values })
                    }
                  />
                </div>
              )
            })}
          </div>
        </div>

        <div className="mt-6">
          <KButton
            onClick={() => setSelectedPitchingTab(EditorialPitchingTabs.DESCRIPTION)}
            background="black"
            textColor="white"
            text="Continue"
            disabled={!spotifyEditorialPitching.instrumentation?.length}
            width="600px"
          />
        </div>
      </div>
    )
  }

  const renderDescription = () => {
    return (
      <div className="grid gap-2">
        {renderTitle("Description", EditorialPitchingTabs.INSTRUMENTION)}
        <KSpan
          text="Please share with us the creation process and promotional plans for this song."
          fontSize={14}
          lineHeight="40px"
        />
        <div>
          <KTextArea
            placeholder="Add a description"
            value={spotifyEditorialPitching.description}
            onChange={(value) => setSpotifyEditorialPitching({ ...spotifyEditorialPitching, description: value })}
            border={500 - spotifyEditorialPitching.description.length < 0 ? "1px solid red" : ""}
          />
        </div>
        <div className="flex justify-end">
          <KSpan text={`${500 - spotifyEditorialPitching.description.length} characters remaining`} />
        </div>
        <div className="mt-6">
          <KButton
            onClick={() => onSubmit()}
            background="black"
            textColor="white"
            text="Submit"
            width="600px"
            disabled={500 - spotifyEditorialPitching.description.length < 0}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="grid items-center justify-center" style={{ width: "100%", height: "calc(100% - 120px)" }}>
      {selectedPitchingTab === EditorialPitchingTabs.SELECT_TRACKS && renderSelectTracks()}
      {selectedPitchingTab === EditorialPitchingTabs.MUSIC_GENRE && renderMusicGenre()}
      {selectedPitchingTab === EditorialPitchingTabs.MUSIC_MOOD && renderMusicMood()}
      {selectedPitchingTab === EditorialPitchingTabs.SONG_STYLE && renderSongStyle()}
      {selectedPitchingTab === EditorialPitchingTabs.INSTRUMENTION && renderInstrumention()}
      {selectedPitchingTab === EditorialPitchingTabs.DESCRIPTION && renderDescription()}
    </div>
  )
}

export default SpotifyEditorialPitchingPage
