import { KButton, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
//@ts-ignore
import ArrowLeftIcon from "../../../../../assets/release-registry-icons/arrow-left.svg"
//@ts-ignore
import InfoColoredIcon from "../../../../../assets/release-registry-icons/info-colored.svg"
//@ts-ignore
import MailIcon from "../../../../../assets/release-registry-icons/mail.svg"
import React, { useState } from "react"
import { ReleaseRegistryReleaseTabs } from "../../../../../actions/types"
import { useOrchestrator } from "../ReleaseRegistryContextProvider"

const AppDigitalMaster = ({ setSelectedTab }: { setSelectedTab: (tab: ReleaseRegistryReleaseTabs) => void }) => {
  const { appleDigitalMaster, setAppleDigitalMaster } = useOrchestrator()

  const [checkSoundEngineer, setCheckSoundEngineer] = useState<boolean>(appleDigitalMaster ? true : false)

  return (
    <div className="w-[616px] max-sm:w-auto flex flex-col gap-6">
      <div className="flex flex-col gap-3 justify-start">
        <div className="flex flex-row gap-4 items-center">
          <div>
            <KButton
              icon={ArrowLeftIcon}
              onClick={() => {
                setSelectedTab(ReleaseRegistryReleaseTabs.GENRES)
              }}
              width="36px"
              height="36px"
              padding="8px"
              background="#F0F0F0"
              shadowDisabled
              hoverBackground="#E7E7E7"
            />
          </div>
          <KTitleSpan
            text="Apple digital master"
            fontSize={40}
            lineHeight="48px"
            letterSpacing="-0.4px"
            color="#1F1F1F"
          />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="px-[14px] pt-[14px] pb-4 flex flex-row justify-between items-start gap-3 bg-[#EDEFFA] rounded-[10px]">
          <div className="shrink-0">
            <img src={InfoColoredIcon} />
          </div>
          <div className="opacity-80 inline-flex">
            <KSpan
              text={`Ensure the highest quality sound for your album by verifying that the sound engineer listed is an Apple Digital Masters Provider. 
                This certification guarantees that your music meets Apple's strict audio standards for superior listening experiences.`}
              color="#151B3D"
            />
          </div>
        </div>
        <div className="flex flex-row gap-1 p-1 bg-[#F5F5F5] rounded-full mt-2">
          <div
            className="flex-grow rounded-full"
            style={{
              boxShadow: checkSoundEngineer
                ? "0px 32px 24px -12px rgba(17, 17, 17, 0.06), 0px 11px 4px 0px rgba(17, 17, 17, 0.01), 0px 6px 4px 0px rgba(17, 17, 17, 0.02), 0px 3px 3px 0px rgba(17, 17, 17, 0.03), 0px 1px 1px 0px rgba(17, 17, 17, 0.04), 0px 0px 0px 1px rgba(17, 17, 17, 0.04)"
                : "none"
            }}
          >
            <KButton
              text="Yes"
              onClick={() => {
                setCheckSoundEngineer(true)
              }}
              borderRadius={999}
              padding="8px 4px"
              shadowDisabled
              height="36px"
              background={checkSoundEngineer ? "#FFF" : "#F5F5F5"}
              textColor={checkSoundEngineer ? "#111" : "#989898"}
            />
          </div>
          <div
            className="flex-grow rounded-full"
            style={{
              boxShadow: !checkSoundEngineer
                ? "0px 32px 24px -12px rgba(17, 17, 17, 0.06), 0px 11px 4px 0px rgba(17, 17, 17, 0.01), 0px 6px 4px 0px rgba(17, 17, 17, 0.02), 0px 3px 3px 0px rgba(17, 17, 17, 0.03), 0px 1px 1px 0px rgba(17, 17, 17, 0.04), 0px 0px 0px 1px rgba(17, 17, 17, 0.04)"
                : "none"
            }}
          >
            <KButton
              text="No"
              onClick={() => {
                setCheckSoundEngineer(false)
                setAppleDigitalMaster("")
              }}
              borderRadius={999}
              padding="8px 4px"
              shadowDisabled
              height="36px"
              background={!checkSoundEngineer ? "#FFF" : "#F5F5F5"}
              textColor={!checkSoundEngineer ? "#111" : "#989898"}
            />
          </div>
        </div>
        <div className="">
          {checkSoundEngineer && (
            <KInput
              value={appleDigitalMaster}
              onChange={(text: string) => {
                setAppleDigitalMaster(text)
              }}
              placeholder={"Sound engineer e-mail"}
              leftIcon={MailIcon}
              shadowDisabled={true}
              padding="12px 12px 12px 14px"
              gap="8px"
            />
          )}
        </div>
      </div>
      <div>
        <KButton
          text="Continue"
          onClick={() => {
            setSelectedTab(ReleaseRegistryReleaseTabs.SELECT_RELEASE_DATE)
          }}
          background="#000"
          textColor="#FFF"
          disabled={checkSoundEngineer && !appleDigitalMaster}
        />
      </div>
    </div>
  )
}

export default AppDigitalMaster
