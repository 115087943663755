import { FC, useEffect, useState } from "react"
import { connect } from "react-redux"
import { RootState } from "../../../../store"
import Table from "../../../ui/table/Table"
import adminTrackShareHolderColumns from "./columns/adminTrackShareHolderColumns"
import { fetchTrackCollaboratorData, selectCollaboratorData } from "../../../../actions/adminActions"
import { AdminTrackShareHolderTableProps } from "../../../../types"
import { TABLE_ROW_STATUS } from "../../../../constants"

const AdminTrackShareHolderTable: FC<AdminTrackShareHolderTableProps> = (props) => {
  const [loading, setLoading] = useState(true)
  const trackId = props.trackId

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      if (trackId) {
        await props.fetchTrackCollaboratorData(trackId)
      }
    }
    fetchData().then(() => setLoading(false))
  }, [trackId])

  const inactiveRowIndexes = props.trackShareData.reduce((indexes, rowData, index) => {
    if (rowData.status !== Number(TABLE_ROW_STATUS.Active)) {
      indexes.push(index)
    }
    return indexes
  }, [])

  const onCollaboratorSelectRow = (index: number) => {
    props.selectCollaboratorData(props.trackShareData[index])
  }

  return (
    <div className="catalog flex-grow">
      <div className="mt-[16px] mb-16">
        <Table
          title="Collaborators"
          minRowCount={1}
          limit={1}
          columns={adminTrackShareHolderColumns}
          data={props.trackShareData}
          count={props.trackShareData.length}
          rowHeightInPx={40}
          loading={loading}
          inactiveRows={inactiveRowIndexes}
          onSelectRow={onCollaboratorSelectRow}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user,
    trackShareData: state.admin.trackShareData,
    selectedCollaboratorData: state.admin.selectedCollaboratorData
  }
}

export default connect(mapStateToProps, { fetchTrackCollaboratorData, selectCollaboratorData })(
  AdminTrackShareHolderTable
)
