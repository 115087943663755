import React, { useState, useRef, useEffect, FC } from "react"
import { Range } from "react-range"
import "./AudioPlayer.css"
import { PlayOutline, StopOutline } from "heroicons-react"
import { KSpan } from "kahuna-base-react-components"

const INTERVAL = 60

const AudioPlayerRange = ({
  src,
  updateTrackMultiple,
  previewStart
}: {
  src: string
  updateTrackMultiple: (attributeName: string, value: any, attributeName2: string, value2: any) => void
  previewStart: number
}) => {
  const [startTime, setStartTime] = useState(previewStart)
  const [duration, setDuration] = useState(previewStart + INTERVAL)
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef(null)

  useEffect(() => {
    if (audioRef && audioRef.current) {
      const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration)
      }
      audioRef.current.addEventListener("loadedmetadata", handleLoadedMetadata)

      return () => {
        if (audioRef.current) {
          audioRef.current.removeEventListener("loadedmetadata", handleLoadedMetadata)
        }
      }
    }
  }, [])

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (audioRef.current.currentTime > startTime + INTERVAL) {
        audioRef.current.pause()
        audioRef.current.currentTime = startTime
        setIsPlaying(false)
      }
    }
    if (audioRef.current) {
      audioRef.current.addEventListener("timeupdate", handleTimeUpdate)
      return () => {
        if (audioRef.current) {
          audioRef.current.removeEventListener("timeupdate", handleTimeUpdate)
        }
      }
    }
  }, [startTime])

  const handlePlay = () => {
    if (audioRef?.current) {
      audioRef.current.currentTime = startTime
      audioRef.current.play()
      setIsPlaying(true)
    }
  }

  const handleStop = () => {
    if (audioRef?.current) {
      audioRef.current.pause()
      audioRef.current.currentTime = startTime
      setIsPlaying(false)
    }
  }

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60

    const formattedMinutes = String(minutes).padStart(2, "0")
    const formattedSeconds = String(remainingSeconds).padStart(2, "0")

    return `${formattedMinutes}:${formattedSeconds}`
  }

  return (
    <div>
      <div style={{ visibility: "hidden", height: 12 }}>
        <audio ref={audioRef} controls>
          <source src={src} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      </div>
      <div
        style={{
          width: "100%",
          background: "rgb(242, 242, 242)",
          padding: 20,
          boxShadow: "0px 40px 96px -8px rgba(88, 92, 95, 0.20)",
          borderRadius: 6,
          backgroundImage: "url('/catalog_icons/stroke.svg')"
        }}
        className="flex justify-center pl-16 pr-16"
      >
        <Range
          step={1}
          min={0}
          max={duration}
          values={[startTime]}
          onChange={(values) => {
            const previewEnd = values[0] + INTERVAL
            setStartTime(values[0])
            updateTrackMultiple(
              "preview-start",
              values[0],
              "preview-end",
              previewEnd < duration ? previewEnd : duration
            )
            if (audioRef?.current) {
              audioRef.current.currentTime = startTime
            }
          }}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                height: "1px",
                width: "90%"
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                position: "relative",
                outline: "none",
                cursor: "pointer",
                width: 130
              }}
            >
              <div className="flex justify-center">
                <div className="absolute" style={{ background: "white", marginTop: 2, marginRight: 9 }}>
                  <KSpan text="60s" color="#111" />
                </div>
              </div>
              <img src="/catalog_icons/clip-drag.svg" alt="thumb" />
            </div>
          )}
        />
      </div>
      <div className="flex mt-4">
        <div className="mr-4 cursor-pointer">
          {!isPlaying ? (
            <PlayOutline className="text-gray-300 ml-3" onClick={handlePlay} />
          ) : (
            <StopOutline className="text-gray-300 ml-3" onClick={handleStop} />
          )}
        </div>
        <KSpan text={`Preview Start Time: ${formatTime(startTime)}`} />
      </div>
    </div>
  )
}

export default AudioPlayerRange
