import * as React from "react"
import { Field, Form } from "react-final-form"
import { renderInput } from "../../../ui/form/Input"
import { X } from "heroicons-react"
import Button from "../../../ui/form/Button"
import { connect } from "react-redux"
import { RootState } from "../../../../store"
import { FC, useEffect, useRef, useState } from "react"
import {
  AdminYoutubeVideoFormProps,
  MainOwnerShareData,
  SelectOption,
  TooltipProps,
  UserYoutubeVideoShareForm,
  YoutubeVideo
} from "../../../../types"
import { saveYoutubeVideoShareForm, selectUser, selectYoutubeVideoShareData } from "../../../../actions/adminActions"
import { roleOptions, ROLES, tableCellClasses } from "../../../../constants"
import { renderSearchSelect } from "../../../ui/form/select/SearchSelect"
import { handleAuthorizedRequest, releases } from "../../../../apis"
import { uniqBy } from "lodash"
import adminYoutubeVideoValidator from "./adminYoutubeVideoValidator"
import { FormApi } from "final-form"
import { mapListToCamelCase } from "../../../../utility"
import Tooltip from "../../../ui/Tooltip"
import { renderToggle } from "../../../ui/form/Toggle"

const AdminYoutubeVideoForm: FC<AdminYoutubeVideoFormProps> = (props) => {
  const [youtubeVideoOptions, setYoutubeVideoOptions] = useState<SelectOption[]>([])
  const [youtubeVideoLoading, setYoutubeVideoLoading] = useState(false)
  const [searchedVideos, setSearchedVideos] = useState<YoutubeVideo[]>([])
  const [timeoutId, setTimeoutId] = useState(null)

  const formApiRef = useRef<FormApi<UserYoutubeVideoShareForm>>(null)

  const getVideoById = (videoId: number) => {
    if (!videoId) return null
    if (videoId === props.shareData.youtubeVideo) {
      return props.shareData.youtubeVideoDetail
    }
    return searchedVideos.find((video) => video.id === videoId)
  }

  const onFormSubmit = async (values: UserYoutubeVideoShareForm) => {
    props.creatingRef.current = true
    props.saveYoutubeVideoShareForm({
      ...values,
      user: props.user?.id,
      title: `${props.user.firstName} ${props.user.lastName} - ${youtubeVideoOptions
        .find((trackOption) => trackOption.value === values.youtubeVideo)
        ?.label.toString()}`
    })
  }

  const createYoutubeVideoShareFormFromData = (): UserYoutubeVideoShareForm => {
    if (!props.shareData || !props.user) return new UserYoutubeVideoShareForm()
    const mainOwner = props.shareData.roleList?.some((role) => role.role === ROLES.MAIN_OWNER)
    const share = props.shareData.share
    return {
      id: props.shareData.id,
      user: props.user.id,
      youtubeVideo: props.shareData.youtubeVideo,
      title: `${props.user.username} - ${props.shareData.youtubeVideoDetail?.title}`,
      roles: props.shareData.roleList?.filter((role) => role.role !== 10).map((role) => role.roleDetail.id),
      trackIsrc: props.shareData.youtubeVideoDetail.trackIsrc,
      share,
      mainOwner
    }
  }

  useEffect(() => {
    if (props.shareData?.youtubeVideoDetail.id) {
      setYoutubeVideoOptions(
        uniqBy(
          [
            ...youtubeVideoOptions,
            { value: props.shareData.youtubeVideo, label: props.shareData.youtubeVideoDetail.title }
          ],
          "value"
        )
      )
    }
  }, [props.shareData])

  const onClickCross = () => {
    props.selectYoutubeVideoShareData(null)
  }

  const onSearchTextChanged = async (event) => {
    setYoutubeVideoOptions([])
    setYoutubeVideoLoading(true)
    clearTimeout(timeoutId)
    setTimeoutId(
      setTimeout(async () => {
        await handleAuthorizedRequest(async () => {
          const response = await releases(`/youtube-video/?search=${event.target.value}&limit=10`, "get")
          setSearchedVideos(mapListToCamelCase(response.data.results))
          setYoutubeVideoOptions(
            response.data.results.map((video) => ({
              value: video.id,
              label: video.title
            }))
          )
          setYoutubeVideoLoading(false)
        }, "")
      }, 500)
    )
  }

  const onSelectYoutubeVideo = (value: any) => {
    formApiRef.current?.change("trackIsrc", searchedVideos.find((video) => video.id === value)?.trackIsrc)
  }

  const renderToggleLabel = (text: string) => {
    return <span className="text-sm text-gray-700 mt-1">{text}</span>
  }

  const getTooltipContent = (mainOwnerShare: MainOwnerShareData): TooltipProps => {
    if (mainOwnerShare.user === "0") {
      return {
        content: `There is no main owner assigned to this video. Current main owner share is ${mainOwnerShare.share}%.`,
        type: "info"
      }
    }
    return {
      content: `Main owner of this video is ${mainOwnerShare.user} and their share is ${mainOwnerShare.share}%`,
      type: "warn"
    }
  }

  if (props.hidden) return null

  return (
    <div className="h-[401px] mt-10 bg-white elev-b-sm rounded-lg overflow-hidden">
      <div
        className={`bg-gray-50 text-xs text-gray-500 font-medium 
      uppercase border-b flex justify-between ${tableCellClasses.sm}`}
      >
        <p>Youtube Video Share Information</p>
        <X className="h-4 mr-[-6px] cursor-pointer" onClick={onClickCross} />
      </div>

      <Form
        initialValues={createYoutubeVideoShareFormFromData()}
        onSubmit={onFormSubmit}
        validate={adminYoutubeVideoValidator}
        render={({ handleSubmit, pristine, form, submitting }) => {
          formApiRef.current = form
          const video = getVideoById(form.getFieldState("youtubeVideo")?.value)
          let tooltipConfig
          let hasMainOwner = false
          if (video) {
            tooltipConfig = getTooltipContent(video.mainOwnerShare)
            hasMainOwner = video.mainOwnerShare.user !== "0"
            if (form.getFieldState("mainOwner")?.value) {
              form.change("share", video.mainOwnerShare.share.toString())
            }
          }
          return (
            <>
              <form className="w-full max-w-sm m-auto flex-column p-4" onSubmit={handleSubmit}>
                <div className="h-[296px] w-[350px]">
                  <div className={"mb-4"}>
                    <Field
                      name="youtubeVideo"
                      render={renderSearchSelect}
                      placeholder="Search video"
                      disabled={submitting}
                      onTextChange={onSearchTextChanged}
                      options={youtubeVideoOptions}
                      loading={youtubeVideoLoading}
                      onSelect={onSelectYoutubeVideo}
                    />
                  </div>
                  <Tooltip place="bottom" {...tooltipConfig}>
                    <div className="h-[42px] flex gap-8">
                      {renderToggleLabel("Main Owner")}
                      <Field
                        name="mainOwner"
                        type="checkbox"
                        render={renderToggle}
                        disabled={submitting || hasMainOwner}
                      />
                    </div>
                  </Tooltip>
                  <div className={"mb-4"}>
                    <Field
                      name="roles"
                      render={renderSearchSelect}
                      placeholder="Select roles"
                      disabled={submitting}
                      multiple
                      options={roleOptions
                        .filter((opt) => !form.getFieldState("mainOwner")?.value || opt.value !== ROLES.OTHER)
                        .sort()}
                    />
                  </div>
                  <Field
                    name="share"
                    render={renderInput}
                    placeholder="Enter user share"
                    className={"mb-4"}
                    autoComplete="new-password"
                    disabled={submitting}
                  />
                  <Field
                    name="trackIsrc"
                    render={renderInput}
                    placeholder="Enter user track isrc"
                    className={"mb-4"}
                    autoComplete="new-password"
                    disabled={submitting}
                  />
                </div>
                <Button className="mb-0" text="Save" disabled={submitting || pristine} />
              </form>
            </>
          )
        }}
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    shareData: state.admin.selectedYoutubeVideoShareData,
    user: state.admin.selectedUser
  }
}

export default connect(mapStateToProps, {
  selectYoutubeVideoShareData,
  selectUser,
  saveYoutubeVideoShareForm
})(AdminYoutubeVideoForm)
