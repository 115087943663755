import { BrowserRouter as AppRouter, Route } from "react-router-dom"
import { Routes } from "react-router"
import { ROUTES } from "./routes"
import React from "react"
import PrivateRoute from "./PrivateRoute"
import NotFound from "../components/NotFound"
import { DEFAULT_ROUTE } from "../constants"

const Router = () => {
  return (
    <AppRouter basename={process.env.REACT_APP_BASENAME}>
      <Routes>
        {ROUTES.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<PrivateRoute {...route}>{route.element}</PrivateRoute>}
            />
          )
        })}
        <Route
          path="*"
          element={
            <NotFound
              title={"Sorry"}
              description={"This page you have been looking has not been found"}
              buttonText={"Go to Overview"}
              buttonLink={DEFAULT_ROUTE}
              imageWidth={350}
              titleSize={36}
              descriptionSize={20}
            />
          }
        />
      </Routes>
    </AppRouter>
  )
}

export default Router
