import { Action, AuthState, User } from "../types"
import { omit } from "lodash"
import { AUTH_ACTION } from "../actions/loginActions"

const initialState = (): AuthState => ({
  loggedIn: !!localStorage.getItem("token"),
  user: localStorage.getItem("user") ?  (JSON.parse(localStorage.getItem("user")) as User) : new User(),
  token: localStorage.getItem("token")
})

export default (state: AuthState = initialState(), action: Action) => {
  switch (action.type) {
    case AUTH_ACTION.LOGIN:
      localStorage.setItem("user", JSON.stringify(omit(action.payload, ["token"])))
      localStorage.setItem("token", action.payload.token)
      return {
        loggedIn: true,
        user: action.payload,
        token: action.payload.token,
        userRole: action.payload.roleId
      }
    case AUTH_ACTION.LOGIN_ERROR:
      return { ...state, loggedIn: false, error: action.payload }
    case AUTH_ACTION.LOGOUT:
      localStorage.setItem("user", "{}")
      localStorage.setItem("token", "")
      return { loggedIn: false, user: new User(), token: "" }
    case AUTH_ACTION.UPDATE_USER:
      return { ...state, user: action.payload }
    case AUTH_ACTION.UPLOAD_PROFILE_PHOTO:
      return { ...state, user: { ...state.user, photo: action.payload } }
    case AUTH_ACTION.GET_ROLE:
      return { ...state, userRole: action.payload }
    default:
      return state
  }
}
