import React, { FC, useEffect, useState } from "react"
import Profile from "./profile/Profile"
import NavListElement from "../navigation/NavListElements"
import { navElement } from "../navigation/NavSection"
import { allRoleList } from "../../../constants"
import Payment from "./payment/Payment"
import CopyrightAndProduction from "./copyright-and-production/CopyrightAndProduction"
import { connect } from "react-redux"
import { RootState } from "../../../store"
import { fetchUserData } from "../../../actions"
import { AccountProps } from "../../../types"
import Contracts from "./contracts/Contracts"

const navElements = [
  {
    name: "Profile",
    iconPath: "/navigation_icons/profile_dropdownmenu_icons/user-filled.svg",
    href: "/account/profile",
    bgColor: "#ffffff",
    permittedRoles: allRoleList,
    bRadius: 2,
    type: "link",
    borderBottom: false
  },
  {
    name: "Payment Methods",
    iconPath: "/navigation_icons/profile_dropdownmenu_icons/wallet-filled.svg",
    href: "/account/payment-methods",
    bgColor: "#ffffff",
    permittedRoles: allRoleList,
    bRadius: 2,
    type: "link",
    borderBottom: false
  },
  {
    name: "Copyright and Production",
    iconPath: "/navigation_icons/profile_dropdownmenu_icons/briefcase-filled.svg",
    href: "/account/copyright-and-production",
    bgColor: "#ffffff",
    permittedRoles: allRoleList,
    bRadius: 2,
    type: "link"
  }
  /**
   * TODO: Will be implemented once conracts are ready
   * {
    name: "Contracts",
    iconPath: "/navigation_icons/profile_dropdownmenu_icons/sign-contract.svg",
    href: "/account/contracts",
    bgColor: "#ffffff",
    permittedRoles: allRoleList,
    bRadius: 10,
    type: "link"
  }
   */
]

const Account: FC<AccountProps> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)

  useEffect(() => {
    props.fetchUserData()
  }, [])

  const renderNavigation = () => {
    return navElements.map((nav: navElement, index: number) => {
      return (
        <div className={"mb-2"} key={`nav_index_account_${index}`}>
          <NavListElement
            index={index}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            key={`navlist-element-${index}`}
            iconPath={nav.iconPath}
            text={nav.name}
            bgColor={nav.bgColor}
            bRadius={nav.bRadius}
            href={nav.href}
          />
          {nav.borderBottom && <div className="border-dashed border-b-[1px] pb-2" style={{ width: 250 }} />}
        </div>
      )
    })
  }
  const renderSelectedTab = () => {
    if (props.selected === "profile") return <Profile />
    else if (props.selected === "paymentMethods") return <Payment />
    else if (props.selected === "copyrightAndProduction") return <CopyrightAndProduction />
    // else if (props.selected === "socialMediaLink") return <SocialMediaPage userId={props.user.id || 0} />
    else if (props.selected === "contracts") return <Contracts />
    // else if (props.selected === "events") return <Events userId={props.user.id || 0} />
    else return <div />
  }

  return (
    <div className="flex mt-6">
      <div style={{ minWidth: 300 }}>{renderNavigation()}</div>
      <div style={{ width: "100%" }}>{renderSelectedTab()}</div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user
  }
}

export default connect(mapStateToProps, { fetchUserData })(Account)
