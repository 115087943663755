import React, { useEffect, useState } from "react"
import { releases } from "../../../../apis"
import Table from "../../../ui/table/Table"
import state51BatchIdsColumns from "./state51BatchIdsColumns"
import { AssetRegistryState51BatchId, Scheduler } from "../../../../types"
import { mapListToCamelCase, mapToCamelCase } from "../../../../utility"
import { KButton } from "kahuna-base-react-components"
import { toast } from "react-toastify"
import { errorToastOptions } from "../../../../constants"

const State51BatchIds = () => {
  const [loading, setLoading] = useState(false)
  const [batchIdList, setBatchIdList] = useState<AssetRegistryState51BatchId[]>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [count, setCount] = useState<number>(1)
  const [limit, setLimit] = useState(10)

  const onDelete = (id: number) => {
    if (!window.confirm(`Are you sure want to delete ID: ${id}?`)) {
      return
    }

    setLoading(true)
    releases(`/release/asset_registry_state_51_batch_ids_delete/?id=${id}`, "delete").then((resp) => {
      if (resp.data.success) {
        setBatchIdList(batchIdList.filter((row) => row.id !== id))
      } else {
        toast.error(`Error: ${resp.data.message}`, errorToastOptions)
      }
      setLoading(false)
    })
  }

  const onAddNew = () => {
    setLoading(true)
    const batchId = window.prompt("Please enter batch id")
    releases(`/release/asset_registry_state_51_batch_ids_add/?batch_id=${batchId}`, "post").then((resp) => {
      if (resp.data.result) {
        setBatchIdList([mapToCamelCase(resp.data.result), ...batchIdList])
        setLoading(false)
      }
    })
  }

  const colums = state51BatchIdsColumns(onDelete)

  useEffect(() => {
    setLoading(true)
    releases(`/release/asset_registry_state_51_batch_ids/?limit=${limit}`, "get").then((resp) => {
      setBatchIdList(mapListToCamelCase(resp.data.results))
      setCount(resp.data.count)
      setLoading(false)
    })
  }, [])

  const onClickPage = async (pageNumber: number) => {
    setLoading(true)
    releases(
      `/release/asset_registry_state_51_batch_ids/?offset=${(pageNumber - 1) * limit}&limit=${limit}`,
      "get"
    ).then((resp) => {
      setBatchIdList(mapListToCamelCase(resp.data.results))
      setLoading(false)
      setPageNumber(pageNumber + 1)
    })
  }

  return (
    <div className="catalog flex-grow pl-1">
      <div className="mt-8 flex justify-end">
        <KButton
          width="220px"
          text="+ Add New Batch Id"
          onClick={() => onAddNew()}
          background="black"
          textColor="white"
        />
      </div>
      <div className="mb-16">
        <Table
          title="State 51 Batch Ids"
          pagination
          minRowCount={limit}
          onClickPage={onClickPage}
          limit={limit}
          columns={colums}
          data={batchIdList}
          count={count}
          rowHeightInPx={40}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default State51BatchIds
