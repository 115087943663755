import React from "react"
import { Link } from "react-router-dom"
import { KSpan } from "kahuna-base-react-components"

const ProfileListElement = ({
    href,
    onClick,
    iconPath,
    text

}:
{
    href: string,
    onClick?: any,
    iconPath: string,
    text: string
}) => {
    return (
        <Link to={href} className="group">
        <div
          onClick={onClick}
          className={`w-auto flex flex-row gap-[8px] justify-start items-center py-[8px] px-[10px] rounded-[10px] group-hover:!bg-[#f0f0f0]`}
        >
          <span className="w-[20px] aspect-square flex justify-center items-center p-0">
            <img className="w-full h-full" src={iconPath}></img>
          </span>
          <span className="flex items-center">
            <KSpan text={text} color="#111" fontWeight={500} fontSize={12}/>
          </span>
        </div>
      </Link>
    )
}

export default ProfileListElement