import { Release, TableColumn } from "../../../../types"
import { RELEASE_STATUSES, releaseStatusOptions, TABLE_COLUMN_TYPE } from "../../../../constants"
import React from "react"
import { Select } from "../../../ui/form/select/Select"
import { KButton } from "kahuna-base-react-components"

const adminCatalogColumns = (
  onClickLabelCampCheckbox: (id: number, checked: boolean, title: string) => void,
  onSelectStatus: (id: number, status: string, title: string) => void,
  onDelete: (id: number, title: string) => void,
  onSubmitBmv: (release: Release) => void,
  onSubmitMesamMsg: (release: Release) => void,
  onSubmitEditorialPitching: (release: Release) => void,
  onSubmitState51: (release: Release) => void,
  isAdmin: boolean
): TableColumn[] => {
  const idColum: TableColumn = {
    header: "",
    type: TABLE_COLUMN_TYPE.LINK,
    attribute: "id",
    headerStyle: {
      textAlign: "center",
      width: "2%"
    }
  }

  const releaseNameColumn: TableColumn = {
    header: "Release Name",
    headerStyle: {
      textAlign: "left",
      width: "32%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => (
      <div className="flex">
        <div className="mr-4">
          <div
            className="h-[60px] w-[60px] bg-gray-800 overflow-hidden cursor-pointer"
            style={{ borderRadius: 9 }}
            onClick={() => window.open(row.coverFileUrl, "_blank")}
          >
            <img src={row.coverFileUrl} alt={"table_image"} />
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="text-sm leading-5 font-medium text-gray-900">{row.title}</div>
        </div>
      </div>
    )
  }

  const artistColumn: TableColumn = {
    header: "Artists",
    headerStyle: {
      textAlign: "left",
      width: "20%"
    },
    cellStyle: {
      fontWeight: 400,
      lineHeight: "20px",
      color: "#111827"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      return (
        <div className="flex">
          <div>
            <div className="text-sm leading-5 font-normal text-gray-500">
              {row.artistsList.map((artist) => artist.name).join(", ")}
            </div>
          </div>
        </div>
      )
    }
  }

  const labelColumn: TableColumn = {
    header: "Label",
    headerStyle: {
      textAlign: "left",
      width: "12%"
    },
    cellStyle: {
      fontWeight: 400,
      lineHeight: "20px",
      color: "#111827"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      return (
        <div className="flex">
          <div>
            <div className="text-sm leading-5 font-normal text-gray-500">{row.labelDetail?.name || ""}</div>
          </div>
        </div>
      )
    }
  }

  const upcColumn: TableColumn = {
    header: "UPC",
    headerStyle: {
      textAlign: "left",
      width: "8%"
    },
    cellStyle: {
      fontWeight: 400,
      lineHeight: "20px",
      color: "#111827"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      return (
        <div className="flex">
          <div>
            <div className="text-sm leading-5 font-normal text-gray-500">{row.upc}</div>
          </div>
        </div>
      )
    }
  }

  const bmvDriveId: TableColumn = {
    header: "BMV",
    headerStyle: {
      textAlign: "left",
      width: "8%"
    },
    cellStyle: {
      fontWeight: 400,
      lineHeight: "20px",
      color: "#111827"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      return (
        <div className="flex gap-2">
          <div style={{ width: 44, display: "grid", alignItems: "center" }}>
            {row.driveId ? (
              <img
                onClick={() => window.open(`https://drive.google.com/drive/folders/${row.driveId}`, "_blank")}
                src={"/catalog_icons/drive_icon.png"}
                alt={`${row.id}_bmv_drive`}
                style={{ width: 24, height: "fit-content", cursor: "pointer" }}
              />
            ) : (
              <span>-</span>
            )}
          </div>
          {isAdmin && (
            <div>
              <KButton
                icon="/catalog_icons/upload-cloud.svg"
                onClick={() => onSubmitBmv(row)}
                padding="8px"
                height="36px"
                width="36px"
                background="white"
              />
            </div>
          )}
        </div>
      )
    }
  }

  const publishingDriveId: TableColumn = {
    header: "MESAM MSG",
    headerStyle: {
      textAlign: "left",
      width: "8%"
    },
    cellStyle: {
      fontWeight: 400,
      lineHeight: "20px",
      color: "#111827"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      return (
        <div className="flex gap-2">
          <div style={{ width: 44, display: "grid", alignItems: "center" }}>
            {row.mesamMsgId ? (
              <img
                src={"/catalog_icons/drive_icon.png"}
                alt={`${row.id}_publishing_drive`}
                style={{ width: 24, cursor: "pointer" }}
                onClick={() => window.open(`https://drive.google.com/drive/folders/${row.mesamMsgId}`, "_blank")}
              />
            ) : (
              <span>-</span>
            )}
          </div>
          {isAdmin && (
            <div>
              <KButton
                icon="/catalog_icons/upload-cloud.svg"
                onClick={() => onSubmitMesamMsg(row)}
                padding="8px"
                height="36px"
                width="36px"
                background="white"
              />
            </div>
          )}
        </div>
      )
    }
  }

  const editorialPitchingDriveId: TableColumn = {
    header: "Editorial Pitching",
    headerStyle: {
      textAlign: "left",
      width: "8%"
    },
    cellStyle: {
      fontWeight: 400,
      lineHeight: "20px",
      color: "#111827"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      return (
        <div className="flex gap-2">
          <div style={{ width: 44, display: "grid", alignItems: "center" }}>
            {row.editorialPitchingId ? (
              <img
                src={"/catalog_icons/drive_icon.png"}
                alt={`${row.id}_pitching_drive`}
                style={{ width: 24, cursor: "pointer" }}
                onClick={() =>
                  window.open(`https://docs.google.com/spreadsheets/d/${row.editorialPitchingId}`, "_blank")
                }
              />
            ) : (
              <span>-</span>
            )}
          </div>
          {isAdmin && (
            <div>
              <KButton
                icon="/catalog_icons/upload-cloud.svg"
                onClick={() => onSubmitEditorialPitching(row)}
                padding="8px"
                height="36px"
                width="36px"
                background="white"
              />
            </div>
          )}
        </div>
      )
    }
  }

  const state51Column: TableColumn = {
    header: "State51 ID",
    headerStyle: {
      textAlign: "left",
      width: "8%"
    },
    cellStyle: {
      fontWeight: 400,
      lineHeight: "20px",
      color: "#111827"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      return (
        <div className="flex gap-2" style={{ alignItems: "center" }}>
          <div style={{ width: 44, display: "grid", alignItems: "center" }}>
            {row.state51Id ? (
              <div className="text-sm leading-5 font-normal text-gray-500">{row.state51Id}</div>
            ) : (
              <span>-</span>
            )}
          </div>
          {isAdmin && (
            <div style={{ display: "grid", alignItems: "center" }}>
              <KButton
                icon="/catalog_icons/upload-cloud.svg"
                onClick={() => onSubmitState51(row)}
                padding="8px"
                height="36px"
                width="36px"
                background="white"
              />
            </div>
          )}
        </div>
      )
    }
  }

  const releaseDateColumn: TableColumn = {
    header: "Release Date",
    headerStyle: {
      textAlign: "left",
      width: "12%"
    },
    cellStyle: {
      fontWeight: 400,
      lineHeight: "20px",
      color: "#6B7280"
    },
    type: TABLE_COLUMN_TYPE.DATE,
    attribute: "releaseDate",
    datePattern: "d LLL, yyyy",
    dateParsePattern: "yyyy-LL-dd"
  }

  const statusColumn: TableColumn = {
    header: "Status",
    headerStyle: {
      textAlign: "center",
      width: "9%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => {
      let background = "red"
      if (row.status === RELEASE_STATUSES.ACTIVE) background = "green"
      else if (row.status === RELEASE_STATUSES.DRAFT) background = "grey"
      else if (row.status === RELEASE_STATUSES.REVIEWING) background = "lightblue"

      return (
        <div className="relative" style={{ padding: 4, background }}>
          <Select
            className="!w-[140px]"
            inputTextClass="text-[14px]"
            options={releaseStatusOptions}
            value={row.status}
            onSelect={(selected) => {
              if (row.id) {
                onSelectStatus(row.id, selected, row.title)
              }
            }}
          />
        </div>
      )
    }
  }

  const showColumn: TableColumn = {
    header: "Show",
    headerStyle: {
      textAlign: "center",
      width: "3%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => (
      <div className="flex">
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={row.labelCamp}
          onChange={() => {
            if (row.id) {
              onClickLabelCampCheckbox(row.id, !row.labelCamp, row.title)
            }
          }}
        />
      </div>
    )
  }

  const actionColumn: TableColumn = {
    header: "Action",
    headerStyle: {
      textAlign: "center",
      width: "3%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Release) => (
      <img
        style={{ cursor: "pointer" }}
        onClick={() => onDelete(row.id || 0, row.title)}
        src="/account_icons/trash.svg"
      />
    )
  }

  const columns = [
    releaseNameColumn,
    artistColumn,
    labelColumn,
    releaseDateColumn,
    upcColumn,
    bmvDriveId,
    publishingDriveId,
    editorialPitchingDriveId,
    state51Column,
    statusColumn,
    showColumn
  ]

  if (isAdmin) {
    columns.unshift(idColum)
    columns.push(actionColumn)
  }

  return columns
}

export default adminCatalogColumns
