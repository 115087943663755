import { CATALOG_ACTION } from "../actions/catalogActions"
import { Action, CatalogData, Track } from "../types"
import { omit } from "lodash"

export default (state = new CatalogData(), action: Action) => {
  switch (action.type) {
    case CATALOG_ACTION.FETCH_DATA:
      return { ...state, paginatedReleases: action.payload }
    case CATALOG_ACTION.SELECT_RELEASE:
      return { ...state, selectedRelease: action.payload }
    case CATALOG_ACTION.SELECT_TRACK:
      return {
        ...state,
        selectedTrack: { ...action.payload, releaseDetail: omit(state.selectedRelease, ["tracksList"]) }
      }
    case CATALOG_ACTION.FETCH_RELEASE_TRACKS:
      return {
        ...state,
        selectedRelease: {
          ...state.selectedRelease,
          tracksList: action.payload.results.map((track: Track) => {
            track.releaseDetail = omit(state.selectedRelease, ["tracksList"])
            return track
          })
        },
        paginatedReleaseTracks: action.payload
      }
    case CATALOG_ACTION.FETCH_TRACK_SHARE_DATA:
      return {
        ...state,
        trackShareData: action.payload
      }
    case CATALOG_ACTION.FETCH_RELEASE_TRACK_SHARE_DATA:
      return {
        ...state,
        shareData: {
          ...state.shareData,
          [action.payload.trackId]: action.payload.shareData
        }
      }
    case CATALOG_ACTION.SELECT_CATALOG_FILTER:
      return {
        ...state,
        selectedCatalogFilter: action.payload
      }
    case CATALOG_ACTION.UPDATE_RELEASE_LIST:
      const results = [...state.paginatedReleases.results].map((result) => {
        if (result.id === action.payload.id) {
          return { ...result, ...action.payload }
        }
        return result
      })

      return { ...state, paginatedReleases: { ...state.paginatedReleases, results } }
    case CATALOG_ACTION.DELETE_RELEASE:
      const filteredReleases = [...state.paginatedReleases.results].filter((result) => result.id !== action.payload.id)

      return { ...state, paginatedReleases: { ...state.paginatedReleases, results: filteredReleases } }
    default:
      return state
  }
}
