import React from "react"
import { KSpan } from "kahuna-base-react-components"
const InfoSection = () => {
  return (
    <div className="flex flex-col gap-3 px-2">
      <a href="https://kahuna.io/pages/terms-of-services" target="_blank">
        <KSpan text="Terms of Service"></KSpan>
      </a>
      <a href="https://kahuna.io/pages/privacy-policy" target="_blank">
        <KSpan text="Privacy Policy"></KSpan>
      </a>
    </div>
  )
}

export default InfoSection
