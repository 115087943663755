import { Dialog } from "@headlessui/react"
import { KTitleSpan, KSpan, KButton, KInput, KDropdown } from "kahuna-base-react-components"
import React from "react"
import { AssetRegistryTrackShareHolder, SelectOption } from "../../../../../types"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { MultiValue } from "react-select"
const TrackShareHolderModal = ({
  selectedTrackShareHolder,
  setSelectedTrackShareHolder,
  handleConfirm,
  trackShareHolderRoleOptions
}: {
  selectedTrackShareHolder: AssetRegistryTrackShareHolder | undefined
  setSelectedTrackShareHolder: (selectedTrackShareHolder: AssetRegistryTrackShareHolder | undefined) => void
  handleConfirm: () => void
  trackShareHolderRoleOptions: KSelectOption[]
}) => {
  const renderDefaultRoles = () => {
    if (!selectedTrackShareHolder?.roles) {
      return []
    }

    const roleIdList = selectedTrackShareHolder.roles.split(",")
    return trackShareHolderRoleOptions.filter((trackShareHolderRoleOption) =>
      roleIdList.includes(trackShareHolderRoleOption.value.toString())
    )
  }

  return (
    <Dialog
      open={selectedTrackShareHolder !== undefined}
      onClose={() => {
        setSelectedTrackShareHolder(undefined)
      }}
    >
      <div className="fixed w-screen h-screen top-0 left-0 z-[10001] flex items-center justify-center bg-[#0000004d] ">
        <div className="p-6 shadow-md w-[400px] rounded-[10px] bg-[#fff]">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4 items-center justify-center">
              <span className="w-[72px] aspect-square rounded-[48px] shadow-md flex justify-center items-center mb-6">
                <img className="w-8 h-8" src="/catalog_icons/edit-line.svg"></img>
              </span>
              <KTitleSpan text={`Edit Track Share Holder`} fontSize={24} lineHeight="24px" />
              <KSpan text={selectedTrackShareHolder?.name || ""} fontSize={20} />
              <KInput
                leftIcon="/catalog_icons/mail-line.svg"
                placeholder="Email address"
                value={selectedTrackShareHolder?.email || ""}
                onChange={(value) => {
                  if (selectedTrackShareHolder) {
                    setSelectedTrackShareHolder({ ...selectedTrackShareHolder, email: value })
                  }
                }}
              />
              <KInput
                leftIcon="/catalog_icons/music-line.svg"
                placeholder="Share (10%)"
                type="number"
                value={selectedTrackShareHolder?.share || ""}
                onChange={(value) => {
                  if (selectedTrackShareHolder) {
                    setSelectedTrackShareHolder({ ...selectedTrackShareHolder, share: value })
                  }
                }}
              />
              <KDropdown
                options={trackShareHolderRoleOptions}
                leftIcon="/catalog_icons/user-search-line.svg"
                isMulti={true}
                placeholder=" _Select Roles"
                defaultValue={renderDefaultRoles()}
                onSelect={(elements) => {
                  if (!elements || !selectedTrackShareHolder) {
                    return
                  }
                  setSelectedTrackShareHolder({
                    ...selectedTrackShareHolder,
                    roles: (elements as MultiValue<KSelectOption>).map((element) => element.value).join(",")
                  })
                }}
              />
            </div>
            <div className="flex gap-3 justify-center items-center">
              <KButton
                text="Cancel"
                onClick={() => {
                  setSelectedTrackShareHolder(undefined)
                }}
                background="#fff"
                shadowDisabled={true}
              ></KButton>
              <KButton
                text="Confirm"
                textColor="white"
                background="black"
                disabled={
                  !selectedTrackShareHolder?.email || !selectedTrackShareHolder.roles || !selectedTrackShareHolder.share
                }
                onClick={() => {
                  handleConfirm()
                }}
              ></KButton>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default TrackShareHolderModal
