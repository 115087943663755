import { Dialog } from "@headlessui/react"
import { KButton } from "kahuna-base-react-components"
import React, { useState } from "react"
import ChangePasswordForm from "./ChangePasswordForm"

const ChangePasswordButton = ({
}) => {

    const [open, setOpen] = useState(false)
    return (
      <>
        <KButton text="Change Password" onClick={() => {
            setOpen(true)
        }} width="auto" background="#fff" height="48px" />
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false)
          }}
        >
          <div className="fixed z-[400] w-screen h-screen top-0 left-0 flex items-center justify-center bg-[#0000004d] ">
            <ChangePasswordForm open={open} setOpen={setOpen} />
          </div>
        </Dialog>
      </>
    )
}

export default ChangePasswordButton