import { ChangePasswordFormValues } from "../../../../types"
import { toast } from "react-toastify"

const changePasswordValidator = (formValues: ChangePasswordFormValues) => {
  const errors: any = {}

  if (!formValues.currentPassword) {
    errors.currentPassword = "You must enter your current password"
    toast.error("You must enter your current password")
  }
  if (!formValues.newPassword || !formValues.newPasswordAgain) {
    if (!formValues.newPassword) {
      errors.newPassword = "You must enter a new password"
      toast.error("You must enter a new password")
    }
    if (!formValues.newPasswordAgain) {
      errors.repeatedPassword = "You must enter confirm your new password"
      toast.error("You must confirm your new password")
    }
  } else if (formValues.newPasswordAgain !== formValues.newPassword) {
    errors.repeatedPassword = "New passwords must match"
    toast.error("New passwords must match")
  }

  return errors
}

export default changePasswordValidator
