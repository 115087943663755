import { Dialog } from "@headlessui/react"
import { FC, useEffect } from "react"
import * as React from "react"
import Payment from "../../account/payment/Payment"
import { X } from "heroicons-react"

type AdminPaymentMethodModalProps = {
  open: boolean
  onClose: () => void
  paymentMethodsAdminEntry: (userId?: number) => void
  userId: number | undefined
}
const AdminPaymentMethodModal: FC<AdminPaymentMethodModalProps> = (props) => {
  const { onClose, open } = props

  useEffect(() => {
    if (!props.userId) {
      return
    }

    props.paymentMethodsAdminEntry(props.userId)

    return () => {
      if (props.userId) {
        props.paymentMethodsAdminEntry(undefined)
      }
    }

  }, [props.userId])

  return (
    <Dialog open={open} onClose={onClose} className="relative">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div>
          <div
            className={`flex justify-end`}
          >
            <X className="h-4 cursor-pointer mt-1" style={{position: "absolute"}} onClick={onClose} />
          </div>
          <div
            className="bg-white elev-b-sm rounded-lg overflow-hidden"
            style={{ overflowY: "auto", height: "80vh", width: "100vh", padding: 42 }}
          >
            <Payment />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default AdminPaymentMethodModal
