import { Dialog } from "@headlessui/react"
import { FC } from "react"
import { tableCellClasses } from "../../../../../constants"
import { X } from "heroicons-react"
import * as React from "react"
import Button from "../../../../ui/form/Button"

type ApprovalModalProps = {
  open: boolean
  onClose: () => void

  approve: () => void
}
const ApproveAllModal: FC<ApprovalModalProps> = (props) => {
  const { onClose, open } = props
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="mt-10 bg-white elev-b-sm rounded-lg overflow-hidden">
          <div
            className={`bg-gray-50 text-xs text-gray-500 font-medium 
              uppercase border-b flex justify-between ${tableCellClasses.sm}`}
          >
            <p>Approve Table</p>
            <X className="h-4 mr-[-6px] cursor-pointer" onClick={onClose} />
          </div>
          <div className="w-full max-w-sm m-auto flex-column p-4 w-[650px] text-center">
            Are you sure you want to approve all reports on the table?
          </div>
          <div className="flex gap-4 p-4 px-8">
            <Button text={"Cancel"} light className={"mb-0"} size={"md"} onClick={props.onClose} />
            <Button text={"Approve"} className={"mb-0"} size={"md"} onClick={props.approve} />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ApproveAllModal
