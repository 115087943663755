import { REPORTS_ACTION } from "../actions/reportsActions"
import { Action, ReportsData } from "../types"

export default (state = new ReportsData(), action: Action) => {
  switch (action.type) {
    case REPORTS_ACTION.FETCH_DATA:
      return { ...state, paymentHistory: action.payload }
    case REPORTS_ACTION.DOWNLOAD_REPORT:
      return { ...state, downloadedReport: action.payload }
    default:
      return state
  }
}
