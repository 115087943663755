import { TableProps } from "../../../types"
import React, { FC, useEffect, useRef, useState } from "react"
import { tableCellClasses } from "../../../constants"
import { isEqual } from "lodash"
import { KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
import ListComponent from "./ListComponent"
import NotFound from "../../NotFound"

const ReportsTable: FC<TableProps> = (props) => {
  const {
    topRightElement,
    className,
    size = "lg",
    title,
    columns,
    data,
    minRowCount = 10,
    rowHeightInPx,
    count,
    onClickPage,
    loading,
    onSelectRow,
    setTableAPI,
    hidden,
    onSortOrFilterChange,
    additionalFilters,
    inactiveRows
  } = props

  interface Data {
    quarter: string
    revenue: number
    total_payment: number
    currency: string
    index: number
    payment_day?: string[]
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [filters, setFilters] = useState<{ [key: string]: string | number | boolean }>({})
  const [searchText, setSearchText] = useState<string>("")
  const [filteredData, setFilteredData] = useState<Data[]>(data)
  const additionalFiltersRef = useRef(additionalFilters)

  const handleChange = (newValue: string) => {
    setSearchText(newValue)
  }

  const filterDataBySearchText = (data: Data[], searchText: string): Data[] => {
    if (data.length > 0) {
      return data.filter((item) => {
        return item.quarter.toLowerCase().includes(searchText.toLowerCase())
      })
    } else {
      return []
    }
  }

  useEffect(() => {
    const filterData = filterDataBySearchText(data, searchText)
    setFilteredData(filterData)
  }, [searchText, data])

  useEffect(() => {
    if (setTableAPI) setTableAPI({ currentPage, setCurrentPage })
  }, [currentPage, count])

  useEffect(() => {
    if (!isEqual(additionalFiltersRef.current, additionalFilters)) {
      const newFilters = { ...filters, ...additionalFilters }
      setFilters(newFilters)
      additionalFiltersRef.current = additionalFilters
    }
  }, [additionalFilters])

  if (hidden) return null

  return (
    <div>
      <div className="flex flex-row justify-between">
        <div className="grid items-center">
          <KTitleSpan text={"Reports"} fontSize={28} color="#111" lineHeight={"24px"} fontWeight={500} />
        </div>

        {/* Bunu daha generic de yapabilirim.*/}
        {topRightElement ? (
          topRightElement
        ) : (
          <div style={{ width: 300 }}>
            <KInput
              onChange={handleChange}
              value={searchText}
              placeholder="Search.."
              padding="14px"
              leftIcon="/catalog_icons/search-grey.svg"
              hoverBackground="white"
              background="#f5f5f5"
            />
          </div>
        )}
        {/*KInput figmada 300px genislige sahip, burada bir sey eklemedim gayet guzel durdu. Eklememe gerek var mi?*/}
      </div>
      <div className="mt-4 bg-white elev-b-sm rounded-lg w-full overflow-hidden shadow-none">
        <ul>
          {/*<ColumnTitles columns={columns} />*/}

          {!loading &&
            filteredData?.map((row, i) => {
              return (
                <ListComponent
                  key={`list-component-${i}`}
                  i={i}
                  row={row}
                  isAdmin={props.isAdmin}
                  selectedUserId={props.selectedUserId}
                  approveDisabled={props.approveDisabled}
                />
              )
            })}
          {!loading && filteredData.length === 0 && (
            <div className="flex justify-center mt-12">
              <NotFound
                title={"No report yet"}
                description={"Launch your release and go live quickly"}
                imageWidth={225}
              />
            </div>
          )}
        </ul>
      </div>
    </div>
  )
}

export default ReportsTable
