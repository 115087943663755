import { UserYoutubeVideoShareForm } from "../../../../types"

const adminYoutubeVideoValidator = (formValues: UserYoutubeVideoShareForm) => {
  const errors: any = {}

  if (!formValues.youtubeVideo) {
    errors.youtubeVideo = "You must select a Youtube video"
  }
  if (!formValues.mainOwner) {
    if (!formValues.share) {
      errors.share = "You must enter share"
    } else if (!/^\d*\.?\d*$/.test(formValues.share)) {
      errors.share = "You must enter a number"
    } else if (Number(formValues.share) > 100) {
      errors.share = "Share can't be greater that 100"
    }
    if (!formValues.mainOwner && formValues.roles?.length === 0) {
      errors.roles = "You must select a role"
    }
  }

  return errors
}

export default adminYoutubeVideoValidator
