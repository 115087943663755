import { TableColumn } from "../../../types"
import { TABLE_COLUMN_TYPE } from "../../../constants"
import { Link } from "react-router-dom"
import { LinkIcon } from "../icons"
import { DateTime } from "luxon"
import { Check, X } from "heroicons-react"
import { hashids } from "../../../utility"

export const renderCell = (row: any, rowIndex: number, col: TableColumn, colIndex: number) => {
  try {
    const getAttribute = () => {
      if (col.attribute && col.attribute.includes(".")) {
        let value = row
        col.attribute.split(".").map((attr) => {
          if (value) {
            value = value[attr]
          }
        })
        return value
      } else if (col.attribute) {
        return row[col.attribute!!]
      }
      return null
    }

    if (col.type === TABLE_COLUMN_TYPE.TEXT) {
      return getAttribute()
    } else if (col.type === TABLE_COLUMN_TYPE.IMG) {
      return (
        <div className="h-[40px] w-[40px] rounded-[100%] bg-gray-800" style={col.cellStyle}>
          <img src={getAttribute()} alt={"table_image"} />
        </div>
      )
    } else if (col.type === TABLE_COLUMN_TYPE.LINK) {
      return (
        <Link to={hashids.encode(getAttribute())}>
          <LinkIcon />
        </Link>
      )
    } else if (col.type === TABLE_COLUMN_TYPE.CONST) {
      return col.constType[getAttribute()] // todo
    } else if (col.type === TABLE_COLUMN_TYPE.DATE) {
      return DateTime.fromFormat(getAttribute(), col.dateParsePattern).toFormat(col.datePattern)
    } else if (col.type === TABLE_COLUMN_TYPE.BOOL) {
      return getAttribute() ? <Check className="text-green-400 h-5 w-5" /> : <X className="text-red-400 h-5 w-5" />
    } else if (col.type === TABLE_COLUMN_TYPE.FUNCTION) {
      return <div>{col.render(row)}</div>
    } else if (col.type === TABLE_COLUMN_TYPE.ROW_NUMBER) {
      return <div>{(rowIndex + 1).toString().padStart(2, "0")}</div>
    }
  } catch (e) {
    // console.log(e)
  }
  return " - "
}
