// src/components/ChatAssistant.tsx

import React, { useState, useRef, useEffect } from "react"
import "./ChatAssistant.css"
import { KButton, KInput, KLogo, KSpan, KTextArea, KTitleSpan } from "kahuna-base-react-components"
import { assistant } from "../../../apis"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { User } from "../../../types"

interface Message {
  user?: string
  assistant?: string
  loading?: boolean
  timeStamp?: Date
}

const ChatAssistant: React.FC<{ user: User }> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [messages, setMessages] = useState<Message[]>([])
  const [input, setInput] = useState("")
  const [loading, setLoading] = useState(false)
  const [noInteraction, setNoInteraction] = useState(false)

  const checkInteraction = () => {
    const lastMessageFromUser = [...messages].reverse().find((msg) => msg.user !== undefined)
    if (lastMessageFromUser?.timeStamp) {
      const currentTime = new Date()
      const diffMs = currentTime.getTime() - lastMessageFromUser?.timeStamp?.getTime()
      const diffSec = Math.floor(diffMs / 1000)
      if (diffSec >= 1) {
        setNoInteraction(true)
      }
    }
  }

  useEffect(() => {
    let timeOutId: NodeJS.Timeout | undefined
    const lastMessageFromAssistant = [...messages].reverse().find((msg) => msg.assistant !== undefined)
    setNoInteraction(false)
    if (isOpen && !lastMessageFromAssistant?.loading) {
      
      timeOutId = setTimeout(() => {
        checkInteraction()
      }, 2000) // runs after two seconds
    }

    return () => {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
    }
  }, [isOpen, messages])

  const messagesEndRef = useRef<HTMLDivElement | null>(null)

  const handleSendMessage = async () => {
    if (input.trim() === "" || loading) return
    setNoInteraction(false)
    setLoading(true)

    const currentMessages = [...messages]
    const newMessage: Message = { user: input, loading: false, timeStamp: new Date() }
    const loadingMessage: Message = { assistant: "loading", loading: true, timeStamp: new Date() }
    setMessages([...currentMessages, newMessage, loadingMessage])
    setInput("")

    try {
      const response = await assistant("/user-chat/message/", "post", { message: input })
      const assistantMessage: Message = { assistant: response.data.reply, loading: false, timeStamp: new Date() }
      setMessages([...currentMessages, newMessage, assistantMessage])
    } catch (error) {
      console.error("There was an error sending the message!", error)
      setMessages((prevMessages) => [...prevMessages, { assistant: "Error", loading: false, timeStamp: new Date() }])
    }

    setLoading(false)
  }

  const formatTime = (timeStamp: Date) => {
    const hour = timeStamp.getHours().toString().padStart(2, "0")
    const min = timeStamp.getMinutes().toString().padStart(2, "0")

    return `${hour}:${min}`
  }

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages, isOpen])

  const renderAssistantMessage = (msg: Message, index:number) => {
    const renderAssistantMessage = msg.loading ? (
      <div className="flex flex-col justify-end items-end gap-1 pr-2">
        <div className="bg-[#F7F7F7] rounded-[10px] py-1.5 px-4">
          <img src="/account_icons/loading-3-dots.svg" style={{ width: 30 }} />
        </div>
        <KSpan text="writing" color="#999" />
      </div>
    ) : (
      <div className="flex flex-col gap-1">
        <div className="flex flex-col gap-3 bg-[#F7F7F7] rounded-[10px] py-1.5 px-4">
          {msg.assistant?.split("\n").map((text) => (
            <div className="" key={Math.random()}>
              <KSpan text={text} color="#000" />
            </div>
          ))}
        </div>
        <span className="px-4 flex gap-3 items-center">
          {msg.timeStamp && <KSpan text={formatTime(msg.timeStamp)} />}
          {noInteraction && index === messages.length - 1 && (
            <a href="mailto:support@kahuna.io" className="flex">
              <KSpan text={"Not satisfied? Contact us."} color="#000" textDecoration="underline" />
            </a>
          )}
        </span>
      </div>
    )
    return (
      <div className="message assistant-message flex items-start justify-end gap-3">
        <div className="block">{renderAssistantMessage}</div>
        <div className="w-8 h-8 min-w-8 min-h-8">
          <KLogo width={32} height={32} borderRadius={32}/>
        </div>
      </div>
    )
  }

  return (
    <div>
      <button className="chat-button justify-center items-center flex" onClick={() => setIsOpen(!isOpen)}>
        <img alt="sparkle-icon" src={"/account_icons/sparkle-white.svg"} />
      </button>
      {isOpen && (
        <div className="chat-box mb-2 ">
          <div className="chat-header">
            <div className="flex flex-row gap-3">
              <img src="/account_icons/logo.svg" width={48} height={48} />
              <div className="flex flex-col">
                <KTitleSpan text="Kahuna AI" fontSize={20} lineHeight="28px" color="#000" />
                <KSpan text="AI Chatbot" fontSize={14} lineHeight="20px" color="#666" />
              </div>
            </div>
            <div>
              <KButton
                icon="/account_icons/close-circle.svg"
                onClick={() => setIsOpen(false)}
                background="transparent"
                shadowDisabled={true}
                width="32px"
                height="32px"
                padding="6px"
              />
            </div>
          </div>
          <div className="chat-messages">
            {messages.map((msg, index) => (
              <div key={index} className="message-container">
                {msg.user && (
                  <div className="message user-message flex items-start gap-3">
                    {props.user.firstName ? (
                      <span className={"user-capital-logo"}>{props.user.firstName[0]}</span>
                    ) : (
                      <img src={"/account_icons/avatar.svg"} width={32} height={32} alt="avatar-icon" />
                    )}
                    <div className="flex flex-col gap-1">
                      <span className="bg-[#F7F7F7] rounded-[10px] py-1.5 px-4">
                        <KSpan text={msg.user} color="#000" />
                      </span>
                      <span className="flex px-4">{msg.timeStamp && <KSpan text={formatTime(msg.timeStamp)} />}</span>
                    </div>
                  </div>
                )}
                {msg.assistant && renderAssistantMessage(msg, index)}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div
            style={{ width: "100%" }}
            className="chat-input"
            onKeyDown={(event) => {
              if (event.key === "Enter" && !event.shiftKey) {
                handleSendMessage()
              }
            }}
          >
            <div style={{ width: "100%" }} className="flex">
              <div
                className="flex flex-row items-center pr-1.5"
                style={{
                  width: "100%",
                  height: "auto",
                  minHeight: "44px",
                  backgroundColor: input ? "#FFF" : "#F7F7F7",
                  borderRadius: "10px",
                  boxShadow: input ? "0px 1px 2px 0px rgba(228, 229, 231, 0.24)" : "",
                  border: input ? "1px solid #F3F3F3" : ""
                }}
              >
                <div className="grow flex flex-row justify-between items-center">
                  <div className="grow px-3.5">
                    <KTextArea
                      value={input}
                      onChange={(value) => setInput(value)}
                      placeholder="Type a message..."
                      padding="12px 0px"
                      shadowDisabled
                      maxHeight={100}
                      height={20}
                      clearTextOnPressedEnter
                      
                    />
                  </div>
                  <div>
                    <KButton
                      text="Send"
                      width="55px"
                      height="32px"
                      padding="6px"
                      background="#FFF"
                      onClick={() => {
                        handleSendMessage()
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <span className="kahuna-gradient-text">Powered by Kahuna</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return { user: state.auth.user }
}

export default connect(mapStateToProps)(ChatAssistant)
