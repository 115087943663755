import { Dialog } from "@headlessui/react"
import React, { FC, useEffect, useState } from "react"
import Payment from "../../account/payment/Payment"
import { X } from "heroicons-react"
import { UserCredit } from "../../../../types"
import { errorToastOptions, successToastOptions, USER_CREDIT_TYPES, userCreditTypeOptions } from "../../../../constants"
import { users } from "../../../../apis"
import { mapToCamelCase, mapToSnakeCase } from "../../../../utility"
import { KButton, KDropdown, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { toast } from "react-toastify"

type AdminUserCreditModalProps = {
  open: boolean
  onClose: () => void
  userId: number | undefined
}
const AdminUserCreditModal: FC<AdminUserCreditModalProps> = (props) => {
  const { onClose, open } = props
  const [loading, setLoading] = useState(false)

  const [userCredit, setUserCredit] = useState<UserCredit>()

  useEffect(() => {
    if (!props.userId) {
      setUserCredit(undefined)
      return
    }

    users(`/fetch_user_credit/?user_id=${props.userId}`, "GET").then((resp) => {
      setUserCredit({ ...mapToCamelCase(resp.data.result), userId: props.userId })
    })
  }, [props.userId])

  const onCloseModal = () => {
    setUserCredit(undefined)
    props.onClose()
  }

  const submit = () => {
    setLoading(true)
    users(`/user_credit/`, "POST", mapToSnakeCase(userCredit))
      .then((resp) => {
        if (resp.data.success) {
          toast.success("User Credit Saved Successfully.", successToastOptions)
          props.onClose()
        } else {
          toast.error("Error saving User Credit!.", errorToastOptions)
        }
        setLoading(false)
      })
      .catch(() => {
        toast.error("Error saving User Credit!.", errorToastOptions)
        setLoading(false)
      })
  }

  return (
    <Dialog open={open} onClose={() => onCloseModal()} className="relative">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div>
          <div className={`flex justify-end`}>
            <X className="h-4 cursor-pointer mt-1" style={{ position: "absolute" }} onClick={() => onCloseModal()} />
          </div>
          {userCredit && (
            <div className="bg-white elev-b-sm rounded-lg overflow-hidden" style={{ overflowY: "auto", padding: 42 }}>
              <KTitleSpan text={"User Credits"} fontSize={32} />
              <div className="flex gap-2 items-center mt-6">
                <KSpan text={"Enabled:"} />
                <KInput
                  value=""
                  type="checkbox"
                  accentColor="black"
                  shadowDisabled={true}
                  background="white"
                  checked={userCredit.enabled}
                  onChange={() => {
                    setUserCredit({ ...userCredit, enabled: !userCredit.enabled })
                  }}
                />
              </div>

              <div className="flex gap-8 mt-3 items-center">
                <KSpan text={"Subscription Type:"} />
                <KDropdown
                  selected={userCreditTypeOptions.find((option) => option.value2 === userCredit.subscriptionType)}
                  width={140}
                  options={userCreditTypeOptions}
                  defaultValuePrimitive={userCredit.subscriptionType}
                  onSelect={(selected) => {
                    if (selected) {
                      //@ts-ignore
                      setUserCredit({ ...userCredit, subscriptionType: selected.value2 })
                    }
                  }}
                />
              </div>

              <div className="flex gap-4 mt-3 mb-12 items-center">
                <KSpan text={"Urgent New Release:"} />
                <KInput
                  type="number"
                  width={125}
                  value={userCredit.urgentNewRelease?.toString() || "0"}
                  onChange={(value) => setUserCredit({ ...userCredit, urgentNewRelease: parseInt(value) })}
                />
              </div>

              <div className="flex pt-4" style={{ borderTop: "1px solid rgb(220 220 220)" }}>
                <KButton text="Cancel" background="white" shadowDisabled={true} onClick={() => onCloseModal()} />
                <KButton
                  text="Confirm"
                  background="black"
                  textColor="white"
                  onClick={() => submit()}
                  disabled={loading}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default AdminUserCreditModal
