import { UserExpenseForm } from "../../../../types"

const adminUserExpenseValidator = (formValues: UserExpenseForm) => {
  const errors: any = {}

  if (!formValues.distributionFee || formValues.distributionFee > 100 || formValues.distributionFee < 0) {
    errors.distributionFee = "You must enter a valid distribution fee"
  }
  if (!formValues.adminFee || formValues.adminFee > 100 || formValues.adminFee < 0) {
    errors.adminFee = "You must enter a valid admin fee"
  }

  if (!formValues.paymentPeriod) {
    errors.paymentPeriod = "You must enter a payment period"
  }

  if (!formValues.preferredCurrency) {
    errors.preferredCurrency = "You must enter a preferred currency"
  }

  if (!formValues.taxType) {
    errors.taxType = "You must enter a tax type"
  }

  return errors
}

export default adminUserExpenseValidator
