import { BellOutline } from "heroicons-react"
import * as React from "react"

const Notifications = () => {
  return (
    // TODO
    <div>
      {/*<button
        type="button"
        className="bg-black-70 p-1 rounded-full text-gray-100 hover:text-white focus:outline-none
                  focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
      >
        <span className="sr-only">View notifications</span>
        <BellOutline className="h-6 w-6" aria-hidden="true" />
      </button>*/}
    </div>
  )
}

export default Notifications
