import { TableColumn, TerritoryDsp } from "../../../../types"
import { TABLE_COLUMN_TYPE } from "../../../../constants"
import React from "react"

const adminTerritoryDspColumns: (onDelete: (id: number) => void) => TableColumn[] = (onDelete) => [
  {
    header: "Id",
    headerStyle: {
      textAlign: "left",
      width: "20%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: TerritoryDsp) => <span>{row.id}</span>
  },
  {
    header: "Label",
    headerStyle: {
      textAlign: "left",
      width: "50%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: TerritoryDsp) => {
      return <span>{row.label}</span>
    }
  },
  {
    header: "Image",
    headerStyle: {
      textAlign: "left",
      width: "50%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: TerritoryDsp) => {
      return <img style={{ width: 64 }} src={row.image} />
    }
  },
  {
    header: "Remove",
    headerStyle: {
      textAlign: "left",
      width: "10%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: TerritoryDsp) => {
      return <img style={{ cursor: "pointer" }} onClick={() => onDelete(row.id)} src="/account_icons/trash.svg" />
    }
  }
]

export default adminTerritoryDspColumns
