import { FC } from "react"
import * as React from "react"
import { CloseNotificationIcon } from "../icons"
import { ToastContainer } from "react-toastify"

const CloseButton = ({ closeToast }) => (
  <i className="notification-close-button" style={{ padding: "0.5rem" }} onClick={closeToast}>
    <CloseNotificationIcon color={"#B3B3B3"} />
  </i>
)

const IToastContainer: FC = () => {
  return <ToastContainer closeButton={CloseButton} toastStyle={{ backgroundColor: "white", color: "#111827" }} />
}

export default IToastContainer
