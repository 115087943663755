import React, { FC, useEffect, useState } from "react"
import { useOrchestrator } from "../ReleaseRegistryContextProvider"
import { ReleaseRegistryPages, ReleaseRegistryUploadTracksTabs } from "../../../../../actions/types"
import Upload from "./Upload"
import TrackDetails from "./TrackDetails"
import Artist from "./Artist"
import TrackShareHolders from "./TrackShareHolders"
import Lyrics from "./Lyrics"
import Loading from "../../../../ui/Loading"
import ErrorModal from "../../asset-registry/modal/ErrorModal"
import PublishingShareHolders from "./PublishingShareHolders"
import ContributorConsentFiles from "./ContributorConsentFiles"

interface UploadTracksProps {}

interface Contributor {
  [key: string]: string[]
}

const UploadTracksLanding: FC<UploadTracksProps> = (props) => {
  const { selectedTrack, saveTrackAndOffers, setSelectedNavigationTab, errorText, loading, setErrorText } =
    useOrchestrator()
  const [selectedTab, setSelectedTab] = useState<number>(ReleaseRegistryUploadTracksTabs.UPLOAD_TRACKS)

  const saveTrackDetail = async (newFlow: boolean) => {
    if (selectedTrack) {
      const trackResponse = await saveTrackAndOffers()
      if (!trackResponse) {
        return
      }

      if (newFlow) {
        setSelectedNavigationTab(ReleaseRegistryPages.SELECT_TRACKS)
      } else {
        setSelectedTab(ReleaseRegistryUploadTracksTabs.UPLOAD_TRACKS)
      }
    }
  }

  const renderSelectedTab = () => {
    switch (selectedTab) {
      case ReleaseRegistryUploadTracksTabs.UPLOAD_TRACKS:
        return <Upload setSelectedTab={setSelectedTab} saveTrackDetail={saveTrackDetail} />
      case ReleaseRegistryUploadTracksTabs.TRACK_DETAILS:
        return <TrackDetails setSelectedTab={setSelectedTab} saveTrackDetail={saveTrackDetail} />
      case ReleaseRegistryUploadTracksTabs.ARTIST:
        return <Artist setSelectedTab={setSelectedTab} saveTrackDetail={saveTrackDetail} />
      case ReleaseRegistryUploadTracksTabs.TRACK_SHARE_HOLDERS:
        return <TrackShareHolders setSelectedTab={setSelectedTab} saveTrackDetail={saveTrackDetail} />
      case ReleaseRegistryUploadTracksTabs.PUBLISHING_SHARE_HOLDERS:
        return <PublishingShareHolders setSelectedTab={setSelectedTab} saveTrackDetail={saveTrackDetail} />
      case ReleaseRegistryUploadTracksTabs.CONTRIBUTOR_CONSENT_FILES:
        return <ContributorConsentFiles setSelectedTab={setSelectedTab} saveTrackDetail={saveTrackDetail} />
      case ReleaseRegistryUploadTracksTabs.LYRICS:
        return <Lyrics setSelectedTab={setSelectedTab} saveTrackDetail={saveTrackDetail} />
      default:
        return <Upload setSelectedTab={setSelectedTab} saveTrackDetail={saveTrackDetail} />
    }
  }

  return (
    <div className="w-full h-full flex justify-center items-center">
      {loading && <Loading />}
      <ErrorModal text={errorText} setText={setErrorText} />
      <div className="w-auto h-auto">{renderSelectedTab()}</div>
    </div>
  )
}

export default UploadTracksLanding
