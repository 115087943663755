import "./styles/Login.css"
import { LoginLayoutProps } from "../../../types"
import React from "react"
import { KLogo } from "kahuna-base-react-components"

const LoginLayout = (props: LoginLayoutProps) => {
  return (
    <div style={{ height: "100vh", backgroundColor: "#F0F0F0", padding: 10 }}>
      <div
        style={{ backgroundColor: "#fff", height: "100%", borderRadius: 10, boxShadow: "0px 2px 2px 0px lightgray" }}
      >
        <div style={{ height: "10%" }}>
          <div
            className="flex"
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <div />
            <div className="mt-6 flex">
              <KLogo width={48} height={48} primaryTextVisible={true} logoColor="white" secondaryText="for Artists" borderRadius={1} />
            </div>
            <div />
          </div>
        </div>
        <div style={{ height: "90%" }}>{props.children}</div>
      </div>
    </div>
  )
}

export default LoginLayout
