import React, { FC, useEffect, useState } from "react"
import "../styles/smartlink.css"
import PublishPage, { SmartLinkTabs } from "../pages/PublishPage"
import { KLogo } from "kahuna-base-react-components"
import { User } from "../../../../types"
import { useParams } from "react-router-dom"
import { smartLinks } from "../../../../apis"
import { mapToCamelCase } from "../../../../utility"

const headerHeight = 88

const ArtistSmartLinkPublicPage: FC = () => {
  const [selectedTab, setSelectedTab] = useState(SmartLinkTabs.SOCIAL_MEDIA_LINKS)

  const [user, setUser] = useState<User>()

  const params = useParams()

  useEffect(() => {
    smartLinks(`/artist/?smart_link=${params.smartLink}`).then((resp) => {
      if (resp.data.result) {
        //@ts-ignore
        setUser(mapToCamelCase(resp.data.result))
      }
    })
  }, [])

  return (
    <div style={{ height: "100vh", backgroundColor: "#F0F0F0", padding: 10 }}>
      <div
        style={{
          backgroundColor: "#fff",
          height: "100%",
          width: "100%",
          borderRadius: 10,
          boxShadow: "0px 2px 2px 0px lightgray"
        }}
      >
        <div className="grid" style={{ height: `${headerHeight}px` }}>
          <div
            className="flex pl-10 pr-10"
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <div style={{ textAlign: "left", display: "inline-block", cursor: "pointer" }}>
              <KLogo
                hoverEnabled={true}
                width={48}
                height={48}
                logoColor={"white"}
                borderRadius={1}
                primaryTextVisible={true}
              />
            </div>
          </div>
        </div>
        <div
          className="relative flex flex-row items-start justify-center pb-4"
          style={{ width: "100%", height: `calc(100% - ${headerHeight}px)` }}
        >
          {user && (
            <PublishPage selectedTab={selectedTab} setSelectedTab={setSelectedTab} user={user} isAdmin={false} />
          )}
        </div>
      </div>
    </div>
  )
}

export default ArtistSmartLinkPublicPage
