import { AccordionProps } from "../../types"
import React, { FC, useState } from "react"
import { Arrow } from "./icons"

const Accordion: FC<AccordionProps> = (props) => {
  const [bodyHidden, setBodyHidden] = useState(true) // no need for storing in redux

  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <h2 id="accordion-collapse-heading">
        <button
          type="button"
          className="flex justify-between items-center w-full text-left !text-gray-900
          pl-[13px] pr-[13px] !bg-white elev-b-xs rounded-md"
          onClick={() => setBodyHidden(!bodyHidden)}
        >
          {props.title}
          <Arrow rotate={bodyHidden ? 0 : 180} />
        </button>
      </h2>
      <div
        id="accordion-collapse-body"
        className={bodyHidden ? "hidden" : ""}
        aria-labelledby="accordion-collapse-heading"
      >
        <div className="rounded-lg  border border-gray-200 border-solid" style={{ background: "#7d7d7d17" }}>
          {props.content}
        </div>
      </div>
    </div>
  )
}

export default Accordion
