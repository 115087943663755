import { Dialog } from "@headlessui/react"
import { KTitleSpan, KSpan, KButton } from "kahuna-base-react-components"
import React from "react"

const ConfirmationDialog = ({
  openConfirmation,
  setOpenConfirmation,
  handleConfirm,
  loading
}: {
  openConfirmation: boolean
  setOpenConfirmation: React.Dispatch<React.SetStateAction<boolean>>
  handleConfirm: () => void
  loading: boolean
}) => {
  return (
    <Dialog
      open={openConfirmation}
      onClose={() => {
        setOpenConfirmation(false)
      }}
    >
      <div className="fixed w-screen h-screen top-0 left-0 z-250 flex items-center justify-center bg-[#0000004d] ">
        <div className="p-6 shadow-md w-[400px] rounded-[10px] bg-[#fff]">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-8 items-center justify-center">
              <span className="w-[96px] aspect-square rounded-[48px] shadow-md flex justify-center items-center">
                <img className="w-12 h-12" src="/account_icons/error.svg"></img>
              </span>
              <KTitleSpan text="Are you sure?" fontSize={24} lineHeight="32px" />
              <KSpan text="This action cannot be undone."></KSpan>
            </div>
            <div className="flex gap-3 justify-center items-center">
              <KButton
                text="Cancel"
                onClick={() => {
                  setOpenConfirmation(false)
                }}
                background="#fff"
                shadowDisabled={true}
              ></KButton>
              <KButton
                text="Confirm"
                textColor="white"
                background="black"
                disabled={loading}
                onClick={() => {
                  handleConfirm()
                }}
              ></KButton>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ConfirmationDialog
