import ReleaseName from "./ReleaseName"
import React, { FC, useEffect, useState } from "react"
import { useOrchestrator } from "../ReleaseRegistryContextProvider"
import ReleaseArtist from "./ReleaseArtist"
import { ReleaseRegistryReleaseTabs } from "../../../../../actions/types"
import Artwork from "./Artwork"
import Genres from "./Genres"
import AppDigitalMaster from "./AppDigitalMaster"
import SelectReleaseDate from "./SelectReleaseDate"

interface ReleaseProps {}

interface Contributor {
  [key: string]: string[]
}

const ReleaseLanding: FC<ReleaseProps> = (props) => {
  const { releaseForm, updateProduct, redirectedFromSummary, setRedirectedFromSummary } = useOrchestrator()
  const [selectedTab, setSelectedTab] = useState<number>(ReleaseRegistryReleaseTabs.RELEASE_NAME)

  useEffect(() => {
    if (redirectedFromSummary) {
      setRedirectedFromSummary(false)
      setSelectedTab(ReleaseRegistryReleaseTabs.SELECT_RELEASE_DATE)
    }
  }, [])

  const renderSelectedTab = () => {
    switch (selectedTab) {
      case ReleaseRegistryReleaseTabs.RELEASE_NAME:
        return <ReleaseName setSelectedTab={setSelectedTab} />
      case ReleaseRegistryReleaseTabs.RELEASE_ARTIST:
        return <ReleaseArtist setSelectedTab={setSelectedTab} />
      case ReleaseRegistryReleaseTabs.ARTWORK:
        return <Artwork setSelectedTab={setSelectedTab} />
      case ReleaseRegistryReleaseTabs.GENRES:
        return <Genres setSelectedTab={setSelectedTab} />
      case ReleaseRegistryReleaseTabs.APPLE_DIGITAL_MASTER:
        return <AppDigitalMaster setSelectedTab={setSelectedTab} />
      case ReleaseRegistryReleaseTabs.SELECT_RELEASE_DATE:
        return <SelectReleaseDate setSelectedTab={setSelectedTab} />
      default:
        return <ReleaseName setSelectedTab={setSelectedTab} />
    }
  }

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="w-auto h-auto">{renderSelectedTab()}</div>
    </div>
  )
}

export default ReleaseLanding
