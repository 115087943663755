import { SortParams, TableColumn, TableProps } from "../../../types"
import React, { FC, useEffect, useRef, useState } from "react"
import { renderCell } from "./renderCell"
import { tableCellClasses, tableTitleClasses } from "../../../constants"
import Pagination from "./Pagination"
import { ArrowDown, ArrowUp, Filter, FilterOutline } from "heroicons-react"
import { isEqual } from "lodash"

const Table: FC<TableProps> = (props) => {
  const {
    topRightElement,
    className,
    size = "lg",
    title,
    columns,
    data,
    minRowCount = 5,
    rowHeightInPx,
    limit,
    pagination,
    count,
    onClickPage,
    loading,
    onSelectRow,
    setTableAPI,
    hidden,
    onSortOrFilterChange,
    additionalFilters,
    inactiveRows
  } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>()
  const [sortParams, setSortParams] = useState<SortParams>(new SortParams())
  const [filtersShown, setFiltersShown] = useState(false)
  const [filters, setFilters] = useState<{ [key: string]: string | number | boolean }>({})
  const additionalFiltersRef = useRef(additionalFilters)

  const onClickPagination = (pageNumber) => {
    if (currentPage !== pageNumber) {
      setSelectedRowIndex(null)
      if (onSelectRow) onSelectRow(null)

      onClickPage(pageNumber, filters, sortParams.field, sortParams.direction)
      setCurrentPage(pageNumber)
    }
  }

  const onClickRow = (index: number) => {
    setSelectedRowIndex(index)
    if (onSelectRow) onSelectRow(index)
  }

  useEffect(() => {
    if (setTableAPI) setTableAPI({ currentPage, setCurrentPage })
  }, [currentPage, count])

  const onSort = async (col: TableColumn) => {
    columns.map((cl) => {
      if (cl !== col) {
        cl.sortDirection = undefined
      }
      return cl
    })
    if (!col.sortDirection) {
      col.sortDirection = "asc"
    } else if (col.sortDirection === "asc") {
      col.sortDirection = "desc"
    } else {
      col.sortDirection = undefined
    }
    await onSortOrFilterChange(filters, col.sortFilterField, col.sortDirection)
    setSortParams({ field: col.sortFilterField, direction: col.sortDirection })
    setCurrentPage(1)
  }

  const getSortDirectionDecoration = (col: TableColumn) => {
    if (col.sortDirection === "asc") {
      return (
        <>
          &nbsp;
          <ArrowDown className={"inline h-[10px] mb-[2px]"} />
        </>
      )
    } else if (col.sortDirection === "desc") {
      return (
        <>
          &nbsp;
          <ArrowUp className={"inline h-[10px] mb-[2px]"} />
        </>
      )
    }
  }

  useEffect(() => {
    if (!isEqual(additionalFiltersRef.current, additionalFilters)) {
      const newFilters = { ...filters, ...additionalFilters }
      setFilters(newFilters)
      onSortOrFilterChange(newFilters, sortParams.field, sortParams.direction, false).then()
      additionalFiltersRef.current = additionalFilters
    }
  }, [additionalFilters])

  const handleFilterChange = async (col: TableColumn, value: string | number | boolean) => {
    const newFilters = { ...filters, [col.sortFilterField]: value }
    setFilters(newFilters)
    await onSortOrFilterChange(newFilters, col.sortFilterField, col.sortDirection, true)
  }

  const isRowInactive = (index: number, inactiveRows?: number[]): boolean => {
    return inactiveRows?.includes(index) || false
  }

  if (hidden) return null

  return (
    <div className={`${className}`}>
      <div className="flex flex-row justify-between">
        <div className={`flex font-bold text-gray-900 mt-2 ${tableTitleClasses[size]}`}>
          {title}
          {columns.some((col) => col.filter) && (
            <span onClick={() => setFiltersShown(!filtersShown)}>
              {filtersShown ? (
                <Filter className={"inline h-3 mb-[2px] cursor-pointer"} />
              ) : (
                <FilterOutline className={"inline h-3 mb-[2px] cursor-pointer"} />
              )}
            </span>
          )}
        </div>
        {topRightElement}
      </div>
      <table className="mt-4 bg-white elev-b-sm rounded-lg w-full overflow-hidden">
        <thead>
          <tr className="bg-gray-50 text-xs uppercase leading-4 tracking-wide">
            {columns.map((col, i) => (
              <th
                className={`text-gray-500 font-medium
                  ${tableCellClasses[size]} ${col.sortable ? "cursor-pointer" : ""}`}
                style={col.headerStyle}
                key={`header-cell-${i}`}
                onClick={() => (col.sortable ? onSort(col) : null)}
              >
                {col.header}
                {getSortDirectionDecoration(col)}
              </th>
            ))}
          </tr>
          {filtersShown && (
            <tr className="bg-gray-50 text-xs uppercase leading-4 tracking-wide">
              {columns.map((col, i) => (
                <th
                  className={`text-gray-500 font-medium text-left px-[15px] pb-[2px] pt-0 w-100`}
                  key={`filter-cell-${i}`}
                >
                  {col.filter && (
                    <input
                      placeholder={"Show all"}
                      className={"text-left border focus:outline-none px-1"}
                      onChange={(e) => handleFilterChange(col, e.target.value)}
                    />
                  )}
                </th>
              ))}
            </tr>
          )}
        </thead>
        <tbody>
          {!loading &&
            data?.map((row, i) => {
              const rowClasses = isRowInactive(i, inactiveRows) ? "text-gray-300" : ""
              return (
                <tr
                  className={`border-t rounded-lg ${
                    onSelectRow && selectedRowIndex === i ? "bg-zinc-100" : ""
                  } ${rowClasses}`}
                  key={`data-row-${i}`}
                  onClick={() => onClickRow(i)}
                >
                  {columns.map((col, j) => {
                    return (
                      <td style={col.cellStyle} className={`${tableCellClasses[size]} `} key={`data-cell-${i}-${j}`}>
                        {renderCell(row, i, col, j)}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          {minRowCount &&
            minRowCount >= data?.length &&
            Array(loading ? minRowCount : minRowCount - data?.length)
              .fill(0)
              .map((e, i) => i + 1)
              .map((row) => (
                <tr key={`additional-row-${row}`} className="border-t rounded-lg">
                  {columns.map((col, j) => {
                    return (
                      <td
                        style={col.cellStyle}
                        className={`${tableCellClasses[size]} rounded-t-lg`}
                        key={`additional-data-cell-${row}-${j}`}
                      >
                        <div className={`h-[${rowHeightInPx || 20}px]`}>
                          {loading && (
                            <div className="flex flex-row items-center justify-start h-full space-x-5 animate-pulse">
                              <div style={{ width: "100%" }} className={`h-[20px] bg-gray-300 rounded-md`} />
                            </div>
                          )}
                        </div>
                      </td>
                    )
                  })}
                </tr>
              ))}
        </tbody>
      </table>
      <Pagination
        hidden={!pagination || !data || data.length === 0 || limit >= count}
        size={size}
        pageCount={Math.ceil(count / limit)}
        currentPage={currentPage}
        onClickPage={onClickPagination}
      />
    </div>
  )
}

export default Table