import React from "react"

const SocialSection = () => {
    return (
      <div className="flex flex-row gap-2">
        <a className="w-[32px] p-1.5 aspect-square" href="https://x.com/kahunamusic_" target="_blank">
          <img src="/navigation_icons/twitter-x.svg" />
        </a>
        <a className="w-[32px]  p-1.5 aspect-square" href="https://www.linkedin.com/company/kahunamusic/" target="_blank">
          <img src="/navigation_icons/linkedin.svg" />
        </a>
        <a className="w-[34px]  p-1.5 aspect-square" href="https://www.instagram.com/kahunamusic/" target="_blank">
          <img src="/navigation_icons/instagram-line.svg" />
        </a>
      </div>
    )
}

export default SocialSection