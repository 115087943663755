import React, { useRef, useState } from "react"
import { PDFDocument, rgb } from "pdf-lib"
import { Worker, Viewer } from "@react-pdf-viewer/core"
import "@react-pdf-viewer/core/lib/styles/index.css"
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"
import SignatureCanvas from "./SingatureCanvas"
import { Contract, User } from "../../../../types"
import fontkit from "@pdf-lib/fontkit"

//@ts-ignore
import DejaVuSansFont from "../../../../assets/fonts/DejaVuSans/DejaVuSans.ttf"
import { Dialog } from "@headlessui/react"
import { KButton } from "kahuna-base-react-components"
import { users } from "../../../../apis"

const PdfViewerModal = ({
  selectedContract,
  setSelectedContract,
  user,
  submit,
  loading
}: {
  selectedContract?: Contract
  setSelectedContract: (selectedContract?: Contract) => void
  user: User
  submit: (type: string, formData) => void
  loading: boolean
}) => {
  const [defaultContract, setDefaultContract] = useState<Contract>({ ...selectedContract })
  const [signature, setSignature] = useState()
  const [signatureModalOpen, setSignatureModalOpen] = useState(false)
  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  const addTextAndSignature = async (isSubmit: boolean, signature) => {
    if (!signature || !selectedContract) {
      return
    }

    if (isSubmit) {
      const formData = new FormData()
      formData.append("file", selectedContract.blob)

      submit(selectedContract.type, formData)

      return
    }

    const existingPdfBytes = await fetch(selectedContract.url).then((res) => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(existingPdfBytes)

    pdfDoc.registerFontkit(fontkit)

    const pages = pdfDoc.getPages()
    const firstPage = pages[0]

    const fontBytes = await fetch(DejaVuSansFont).then((res) => res.arrayBuffer())
    const font = await pdfDoc.embedFont(fontBytes)

    firstPage.drawText(`${user.firstName} ${user.lastName}`, { x: 267, y: 682, size: 14, font, color: rgb(0, 0, 0) })

    firstPage.drawText(`${user.firstName} ${user.lastName}`, { x: 145, y: 545, size: 14, font, color: rgb(0, 0, 0) })

    firstPage.drawText(user.email, { x: 120, y: 518, size: 14, font, color: rgb(0, 0, 0) })

    const today = new Date()
    const day = String(today.getDate()).padStart(2, "0")
    const month = String(today.getMonth() + 1).padStart(2, "0") // January is 0!
    const year = today.getFullYear()

    firstPage.drawText(`${day}/${month}/${year}`, { x: 120, y: 490, size: 14, font, color: rgb(0, 0, 0) })

    // Add custom signature
    const signatureImg = await pdfDoc.embedPng(signature)
    firstPage.drawImage(signatureImg, { x: 140, y: 430, width: 100, height: 50 })

    const pdfBytes = await pdfDoc.save()
    const blob = new Blob([pdfBytes], { type: "application/pdf" })
    const url = URL.createObjectURL(blob)
    setSelectedContract({ ...selectedContract, url, blob })
  }

  const submitContract = async () => {
    addTextAndSignature(true, signature)
  }

  const onSaveSignature = (sign) => {
    setSignature(sign)
    addTextAndSignature(false, sign)
  }

  return (
    <Dialog open={selectedContract !== undefined} onClose={() => setSelectedContract(undefined)}>
      <div
        className="fixed w-screen h-screen top-0 left-0 flex items-center justify-center bg-[#0000004d] "
        style={{ zIndex: 99 }}
      >
        <div
          className="p-6 shadow-md rounded-[10px] bg-[#fff]"
          style={{ width: "95vh", height: "95vh", overflowY: "scroll" }}
        >
          {!selectedContract?.id && (
            <div className="mb-4 flex justify-between">
              <KButton
                text="Add Signature"
                background="black"
                textColor="white"
                width="auto"
                onClick={() => {
                  setSignature(undefined)
                  setSelectedContract(defaultContract)
                  setSignatureModalOpen(true)
                }}
              />
              <KButton
                text="Submit"
                background="black"
                textColor="white"
                width="auto"
                disabled={!signature || loading}
                onClick={() => submitContract()}
              />
            </div>
          )}
          <SignatureCanvas
            onSave={(sign) => onSaveSignature(sign)}
            signatureModalOpen={signatureModalOpen}
            setSignatureModalOpen={setSignatureModalOpen}
          />
          {selectedContract && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
              <Viewer fileUrl={selectedContract.url} plugins={[defaultLayoutPluginInstance]} />
            </Worker>
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default PdfViewerModal
