import { KButton, KDropdown, KInput, KSpan, KTitleSpan, KTooltip } from "kahuna-base-react-components"
//@ts-ignore
import ArrowLeftIcon from "../../../../../assets/release-registry-icons/arrow-left.svg"
//@ts-ignore
import PlayIcon from "../../../../../assets/release-registry-icons/play.svg"
//@ts-ignore
import CompletedIcon from "../../../../../assets/release-registry-icons/completed.svg"
//@ts-ignore
import IncompleteIcon from "../../../../../assets/release-registry-icons/incomplete.svg"
//@ts-ignore
import AvatarIcon from "../../../../../assets/release-registry-icons/avatar.svg"
//@ts-ignore
import UploadCloudIcon from "../../../../../assets/release-registry-icons/upload-cloud.svg"
//@ts-ignore
import UploadCloudSmallerIcon from "../../../../../assets/release-registry-icons/upload-cloud-smaller.svg"
//@ts-ignore
import FileBody from "../../../../../assets/release-registry-icons/file-body.svg"
//@ts-ignore
import FileCorner from "../../../../../assets/release-registry-icons/file-corner.svg"
//@ts-ignore
import PauseIcon from "../../../../../assets/release-registry-icons/pause.svg"
//@ts-ignore
//@ts-ignore
import TiktokIcon from "../../../../../assets/release-registry-icons/tiktok.svg"
//@ts-ignore
import AlertIcon from "../../../../../assets/release-registry-icons/alert-triangle.svg"
//@ts-ignore
import MusicIcon from "../../../../../assets/release-registry-icons/music.svg"
//@ts-ignore
import InfoCustomIcon from "../../../../../assets/release-registry-icons/info-custom.svg"
//@ts-ignore
import ChevronDownIcon from "../../../../../assets/release-registry-icons/chevron-down.svg"
import React, { useEffect, useRef, useState } from "react"
import { ReleaseRegistryUploadTracksTabs } from "../../../../../actions/types"
import { useOrchestrator } from "../ReleaseRegistryContextProvider"
import LinearProgress from "../component/linear-progress/LinearProgress"
import { LCReleaseFormData } from "../../../../../types"
import { Play } from "heroicons-react"
import AudioPlayerRange from "../component/AudioPlayerRange"
import ArtistInitials from "../../../../ui/ArtistInitials"
import { checkMissingTrackFields } from "../../../../../utility"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import ErrorModal from "../../asset-registry/modal/ErrorModal"
import Loading from "../../../../ui/Loading"
import { releases } from "../../../../../apis"
import { successToastOptions } from "../../../../../constants"
import { toast } from "react-toastify"
import LoaderLine from "../../../../ui/LoaderLine"

const TrackDetails = ({
  setSelectedTab,
  saveTrackDetail
}: {
  setSelectedTab: (tab: ReleaseRegistryUploadTracksTabs) => void
  saveTrackDetail: (selectedTab: boolean) => void
}) => {
  const {
    options,
    selectedTrack,
    setSelectedTrack,
    updateTrack,
    updateTrackMultiple,
    updateTrackRelationships,
    saveTrackAndOffers,
    processingTrackIds,
    setProcessingTrackIds,
    setErrorText,
    errorText,
    releaseForm
  } = useOrchestrator()

  const [selectedAudioTrack, setSelectedAudioTrack] = useState<LCReleaseFormData>()
  const [errorInUpload, setErrorInUpload] = useState<boolean>(false)
  const [uploadDisabled, setUploadDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const audioRef = useRef<HTMLAudioElement>(null)
  const [dragging, setDragging] = useState<boolean>(false)
  const [languageOptions, setLanguageOptions] = useState<KSelectOption[]>(
    options.languageOptions?.map((languageOption) => {
      return {
        value: Number(languageOption.value),
        value2: languageOption.value,
        label: languageOption.label
      }
    }) || []
  )

  useEffect(() => {
    if (options.languageOptions) {
      setLanguageOptions(
        options.languageOptions?.map((languageOption) => {
          return {
            value: Number(languageOption.value),
            value2: languageOption.value,
            label: languageOption.label
          }
        })
      )
    }
  }, [options])

  const [startTime, setStartTime] = useState<number>(selectedTrack?.data?.attributes["preview-start"])

  const INTERVAL = 60

  const [missingTrackFields, setMissingTrackFields] = useState<string[]>([])

  useEffect(() => {
    if (!selectedTrack) {
      return
    }
    const missingFields = checkMissingTrackFields(selectedTrack)
    if (missingFields) {
      setMissingTrackFields(missingFields)
    }
  }, [selectedTrack])

  useEffect(() => {
    setStartTime(selectedTrack?.data?.attributes["preview-start"])
  }, [selectedTrack?.data?.attributes["preview-start"]])

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (!audioRef.current) {
        return
      }
      if (audioRef.current.currentTime > startTime + INTERVAL) {
        audioRef.current.pause()
        audioRef.current.currentTime = startTime
        setIsPlaying(false)
      }
    }
    if (audioRef.current) {
      audioRef.current.addEventListener("timeupdate", handleTimeUpdate)
      return () => {
        if (audioRef.current) {
          audioRef.current.removeEventListener("timeupdate", handleTimeUpdate)
        }
      }
    }
  }, [startTime])

  const handlePlay = () => {
    if (audioRef?.current) {
      audioRef.current.currentTime = startTime
      audioRef.current.play()
      setIsPlaying(true)
    }
  }

  const handleStop = () => {
    if (audioRef?.current) {
      audioRef.current.pause()
      audioRef.current.currentTime = startTime
      setIsPlaying(false)
    }
  }

  const [openUploadSection, setOpenUploadSection] = useState<boolean>(false)

  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleButtonClick = (track: LCReleaseFormData) => {
    setUploadDisabled(true)
    setSelectedAudioTrack(track)
    if (!fileInputRef?.current) {
      return
    }
    fileInputRef.current.click()
  }

  const handleFileChange = (e: any) => {
    if (!selectedAudioTrack) {
      setUploadDisabled(false)
      return
    }

    const file = e.target.files[0]
    if (file) {
      try {
        const formData = new FormData()
        formData.append("file", file)
        setLoading(true)
        releases(
          `/release/label_camp_upload_track_audio/?product_id=${releaseForm.product.data.id}&track_id=${selectedAudioTrack.data.id}`,
          "post",
          formData
        ).then((resp) => {
          setLoading(false)
          console.log(resp.data)
          if (resp && resp.data && resp.data.status) {
            toast.success(`Upload Started`, successToastOptions)

            processingTrackIds.push(selectedAudioTrack.data.id || "")
            setProcessingTrackIds([...processingTrackIds])
          } else {
            setErrorText(resp.data.message)
          }
          setUploadDisabled(false)
        })
      } catch (error) {
        setErrorText(error)
        setUploadDisabled(false)
        setLoading(false)
      }
    } else {
      console.log("No file selected")
      setUploadDisabled(false)
    }
  }

  const handleDragOver = (e: any) => {
    e.preventDefault()
    setDragging(true)
  }

  const handleDragLeave = () => {
    setDragging(false)
  }

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()
    setDragging(false)

    const file = e.dataTransfer.files[0]
    if (file) {
      handleFileChange({ target: { files: [file] } })
    }
  }

  const renderFileIcons = (fileType: string, isUploaded: boolean) => {
    const background = fileType === "Dolby" ? "#30272C" : fileType === "16-Bit" ? "#21A494" : "#BBCC13"
    return (
      <div className={`relative h-10 ${isUploaded ? "opacity-100" : "opacity-25"}`}>
        <div className="w-9 h-10">
          <img src={FileBody} alt="File Body" />
        </div>
        <div className="absolute right-[3px] top-[1.5px]">
          <img src={FileCorner} alt="File Corner" />
        </div>
        <div
          className={`absolute flex px-1 whitespace-nowrap rounded-[5px] top-[45%] right-2.5 h-4 items-center`}
          style={{
            background
          }}
        >
          <KSpan text={fileType} fontSize={9} lineHeight="9px" fontWeight={600} color="#FFF" />
        </div>
      </div>
    )
  }

  const renderSongs = (fileType: string, isAvailable: boolean) => {
    return (
      <div key={`file-${fileType}`} style={{ width: "100%", display: "flex" }}>
        <div
          className="flex flex-col gap-4 w-full p-2.5 pl-[14px] "
          style={{
            border: "1px solid #E7E7E7",
            borderRadius: "10px"
          }}
        >
          <div key={""} className={"flex justify-between items-center "}>
            <div className="flex flex-row items-center justify-start gap-3">
              <div className="h-10 flex items-center justify-center rounded-[4px]">
                {renderFileIcons(fileType, isAvailable)}
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row gap-3">
                  <KSpan
                    text={isAvailable ? "Audio File Uploaded" : "No File"}
                    fontSize={12}
                    lineHeight="16px"
                    color="#1F1F1F"
                  />
                  <img src={isAvailable ? CompletedIcon : IncompleteIcon} alt="completed-icon" />
                </div>
              </div>
            </div>
            <div className="self-start"></div>
          </div>
          {loading && (
            <div className="w-full h-auto ">
              <LinearProgress />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="w-[616px] max-sm:w-auto flex flex-col gap-6 mt-32">
      <div className="flex flex-col gap-3 justify-start">
        <div className="flex flex-row gap-4 items-center">
          <div>
            <KButton
              icon={ArrowLeftIcon}
              onClick={() => {
                setSelectedTab(ReleaseRegistryUploadTracksTabs.UPLOAD_TRACKS)
              }}
              width="36px"
              height="36px"
              padding="8px"
              background="#F0F0F0"
              shadowDisabled
              hoverBackground="#E7E7E7"
            />
          </div>
          <KTitleSpan
            text={openUploadSection ? "Upload Tracks" : "Track Details"}
            fontSize={40}
            lineHeight="48px"
            letterSpacing="-0.4px"
            color="#1F1F1F"
          />
        </div>
        <KSpan text="Your Spotify artist name uniquely identifies you on the platform." />
      </div>

      {!openUploadSection && (
        <div className="flex flex-col gap-4 grow">
          <div className="flex flex-row gap-1.5 border-[1px] border-[#E7E7E7] p-1.5 rounded-[6px] items-center justify-between">
            <div>
              <KButton
                icon={isPlaying ? PauseIcon : PlayIcon}
                onClick={() => {
                  if (isPlaying) {
                    handleStop()
                  } else {
                    handlePlay()
                  }
                }}
                padding="8px"
                height="36px"
                width="36px"
                background="#FFF"
                shadowDisabled
                disabled={!selectedTrack?.data?.attributes["preview-url"]}
              />
            </div>
            <div className="flex grow">
              <KInput
                value={selectedTrack?.data?.attributes?.title || ""}
                placeholder="Add name"
                background="#FFF"
                padding="8px 32px 8px 10px"
                boxShadow="none"
                onChange={(text) => {
                  updateTrack("title", text)
                }}
              />
            </div>
            <div className="flex flex-row gap-8 items-center">
              {<ArtistInitials artists={selectedTrack?.data?.attributes["artist"] || []} />}
              <div className="flex flex-row gap-1 pr-2 items-center">
                {missingTrackFields.length > 0 ? (
                  <KTooltip
                    position="left"
                    content={
                      <div className="flex items-center justify-center w-[200px] ">
                        <KSpan
                          text={missingTrackFields?.join(", ") || ""}
                          fontSize={11}
                          fontWeight={500}
                          color="#1F1F1F"
                        />
                      </div>
                    }
                  >
                    <img src={IncompleteIcon} alt="incomplete-icon" />
                  </KTooltip>
                ) : (
                  <img src={CompletedIcon} alt="completed-icon" />
                )}
                <span className="flex flex-row items-center pt-0.5">
                  <KSpan
                    text={missingTrackFields.length > 0 ? "Incomplete" : "Completed"}
                    color="#3D3D3D"
                    fontSize={12}
                    fontWeight={500}
                    lineHeight="12px"
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3 w-full">
            <div className="self-end">
              <KButton
                rightIcon={UploadCloudSmallerIcon}
                background="#FFF"
                text="Upload"
                onClick={() => {
                  setOpenUploadSection(!openUploadSection)
                }}
              />
            </div>
            <div className="flex flex-row items-center justify-between gap-2">
              <div
                style={{
                  width: "calc(50% - 4px)"
                }}
              >
                {selectedTrack && (
                  <KTooltip
                    content={
                      <div className="whitespace-nowrap flex items-center justify-center">
                        <KSpan text="Audio Language" fontSize={10} fontWeight={500} color="#1F1F1F" />
                      </div>
                    }
                  >
                    <KDropdown
                      placeholder="Audio Language"
                      options={languageOptions}
                      selected={selectedTrack?.data?.relationships["audio-language"]?.data?.id}
                      defaultValuePrimitive={selectedTrack?.data.relationships["audio-language"]?.data?.id}
                      isEllipsis={true}
                      rightIcon={ChevronDownIcon}
                      onSelect={(selected) => {
                        if (!selectedTrack) {
                          return
                        }
                        if (selected) {
                          const selectedOption = selected as KSelectOption
                          const value2 = selectedOption.value2
                          if (!value2) {
                            return
                          }
                          updateTrackRelationships("audio-language", {
                            data: { type: "languages", id: value2 }
                          })
                        } else {
                          delete selectedTrack?.data?.relationships?.["audio-language"]
                          setSelectedTrack({ ...selectedTrack })
                        }
                      }}
                    />
                  </KTooltip>
                )}
              </div>
              <div
                style={{
                  width: "calc(50% - 4px)",
                  position: "relative"
                }}
              >
                <KInput
                  placeholder="Production"
                  value={selectedTrack?.data.attributes["production-line"] || ""}
                  onChange={(text) => updateTrack("production-line", text)}
                />

                <div
                  className="w-5 h-5"
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)"
                  }}
                >
                  <KTooltip
                    content={
                      <div className="flex items-center justify-center w-[250px]">
                        <KSpan
                          text={`Phonographic Copyright Holder: the name of the current exclusive owner (person or entity)
                             of the content, preceded by the year of its first publication (physical or digital)`}
                          fontSize={11}
                          fontWeight={500}
                          color="#1F1F1F"
                        />
                      </div>
                    }
                  >
                    <img src={InfoCustomIcon} alt="info-icon" />
                  </KTooltip>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row gap-4 items-center">
                <div className="w-8 h-8 flex items-center justify-center">
                  <img src={TiktokIcon} alt="tiktok" />
                </div>
                <div className="flex flex-row whitespace-nowrap justify-start items-center">
                  <KSpan text="Tiktok Song Duration" color="#111" />
                </div>
              </div>
              <div className="flex flex-row items-center w-[300px]">
                <AudioPlayerRange
                  src={selectedTrack?.data?.attributes["preview-url"]}
                  updateTrackMultiple={updateTrackMultiple}
                  previewStart={selectedTrack?.data?.attributes["preview-start"]}
                  previewEnd={selectedTrack?.data?.attributes["preview-end"]}
                  audioRef={audioRef}
                  interval={INTERVAL}
                />
              </div>
            </div>
          </div>
          <div className="px-[14px] pt-[14px] pb-4 flex flex-row justify-between items-start gap-3 bg-[#FFF7EA] rounded-[10px]">
            <div className="shrink-0">
              <img src={AlertIcon} />
            </div>
            <div className="opacity-80 inline-flex">
              <KSpan
                text={`Drag the preview bar to select your track preview start time.
                    The 30-second track preview you select will be featured on your Ditto SmartLink and platforms such as TikTok.`}
                color="#4D360E"
              />
            </div>
          </div>
          <div>
            <KInput
              leftIcon={MusicIcon}
              placeholder="Mix Version"
              value={selectedTrack?.data?.attributes?.subtitle || ""}
              onChange={(text) => updateTrack("subtitle", text)}
              padding="12px 12px 12px 14px"
              gap="8px"
              boxShadow="none"
            />
          </div>
        </div>
      )}
      {openUploadSection && (
        <div className="flex flex-col gap-1">
          <div
            className={`bg-[#F5F5F5] hover:bg-[#F0F0F0] rounded-[10px]`}
            style={{
              backgroundColor: dragging ? "rgba(0, 0, 0, 0.32)" : ""
            }}
          >
            {processingTrackIds.includes(selectedTrack?.data.id?.toString() || "-") ? (
              <div className="w-full h-auto ">
                <LinearProgress />
              </div>
            ) : (
              <label
                htmlFor="file"
                className={`${dragging ? "bg-[rgba(0, 0, 0, 0.32)]" : "bg-transparent"}
                `}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <div className="flex items-center justify-center w-full">
                  {
                    <div className="flex flex-col items-center justify-center gap-5 p-8 py-5">
                      <div className={"flex justify-center"} style={{ height: 40 }}>
                        <img src={UploadCloudIcon} />
                      </div>
                      <div className={"flex flex-col gap-1 justify-center items-center"}>
                        <KSpan text={"Choose a file or drag & drop it here."} color={"#111"} fontWeight={500} />
                        <KSpan
                          text={"Track must be in WAV or FLAC format, with a maximum size of 200 MB."}
                          fontSize={12}
                          lineHeight="16px"
                        />
                      </div>
                      <div
                        style={{
                          pointerEvents: "auto"
                        }}
                      >
                        <KButton
                          text="Browse File"
                          onClick={() => {
                            if (selectedTrack) {
                              handleButtonClick(selectedTrack)
                            }
                          }}
                          height="32px"
                          padding="6px 10px"
                          background="#FFF"
                          textColor="#111"
                        />
                      </div>
                    </div>
                  }
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e)}
                    accept="audio/x-flac, audio/flac, audio/x-wav, audio/x-aiff, audio/aiff, audio/x-wav; charset=binary, audio/x-aifc"
                  />
                </div>
              </label>
            )}
          </div>
          <div className="flex flex-col gap-1">
            {renderSongs("16-Bit", selectedTrack?.data.attributes?.audio16bits)}
            {renderSongs("24-Bit", selectedTrack?.data.attributes?.audio24bits)}
            {renderSongs("Dolby", selectedTrack?.data.attributes?.["audio-dolby-atmos"])}
          </div>
        </div>
      )}
      {openUploadSection && <div></div>}
      <div>
        <KButton
          text={openUploadSection ? "Return" : "Continue"}
          onClick={() => {
            if (!openUploadSection) {
              //saveTrackAndOffers() //It update the values correctly
              setSelectedTab(ReleaseRegistryUploadTracksTabs.ARTIST)
            } else if (openUploadSection) {
              setOpenUploadSection(false)
            }
          }}
          background="#000"
          textColor="#FFF"
          disabled={
            !openUploadSection &&
            (!selectedTrack?.data?.attributes?.title || !selectedTrack?.data?.relationships["audio-language"]?.data?.id)
          }
        />
      </div>
      {!openUploadSection && (
        <div className="flex flex-row justify-center">
          <KButton
            text="Save and Return to Tracks"
            hoverBackground="#E7E7E7"
            onClick={() => {
              saveTrackDetail(false)
            }}
            background="#FFF"
            shadowDisabled
          />
        </div>
      )}
    </div>
  )
}
export default TrackDetails
