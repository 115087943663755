import React from "react"
import { KSpan } from "kahuna-base-react-components"

const Warning = ({warning}:{
    warning: string
}) => {

    return (
      <div className="w-full p-4 !pt-3.5 bg-[#E6F2FF] rounded-[10px]">
        <div className="flex flex-row gap-3 justify-center items-start">
          <span className="flex shrink-0 rounded-[16px] justify-center items-center">
            <img  className="w-8 aspect-square" src="/account_icons/info-blue.svg"></img>
          </span>
          <KSpan text={warning} color="#162B40"></KSpan>
        </div>
      </div>
    )
}

export default Warning