import React, { FC, useEffect, useState } from "react"
import "../styles/smartlink.css"
import { Release } from "../../../../types"
import { KButton, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { hashids } from "../../../../utility"

type ReleasePageProps = {
  releaseList: Release[]
  smallScreen: boolean
  isAdmin: boolean
  fetchReleases: () => void
}

const ReleasePage: FC<ReleasePageProps> = (props) => {
  const { releaseList, smallScreen, isAdmin } = props

  const [releases, setReleases] = useState<Release[]>([])

  useEffect(() => {
    setReleases(releaseList)
  }, [releaseList])

  useEffect(() => {
    props.fetchReleases()
  }, [])

  return (
    <div className="flex flex-col gap-6 pb-6 pt-8">
      <div
        className="grid"
        style={{
          gridTemplateColumns: !props.smallScreen
            ? "repeat(auto-fit, minmax(calc(20% - 16px), calc(20% - 16px)))"
            : "repeat(auto-fit, minmax(calc(50% - 16px), calc(50% - 16px)))",
          gridGap: 16
        }}
      >
        {releases?.map((release) => {
          return (
            <div
              key={`${release.id}`}
              style={{
                background: "#FFFFFF",
                borderRadius: 10,
                boxShadow: "0px 2px 4px 0px rgba(27, 28, 29, 0.04)",
                padding: 16,
                border: "1px solid #F3F3F3",
                cursor: isAdmin || release.smartLink ? "pointer" : ""
              }}
              onClick={() => {
                if (isAdmin) {
                  const encodedId = hashids.encode(release.id?.toString() || "")
                  window.open(`https://royalty.kahuna.io/catalog/smart-link/${encodedId}`, "_blank")
                } else if (release.smartLink) {
                  window.open(`https://royalty.kahuna.io/smart-link/${release.smartLink}`, "_blank")
                }
              }}
            >
              <img
                src={release.coverFileUrl}
                alt={"album_cover"}
                style={{
                  width: "auto",
                  height: "auto",
                  borderRadius: 8,
                  boxShadow: "0px 2px 4px 0px rgba(27, 28, 29, 0.04)"
                }}
              />

              <div className="min-h-10">
                <div className="mt-4 flex">
                  <KSpan
                    text={release?.releaseDate?.substring(0, 4) || ""}
                    fontWeight={500}
                    color="#111"
                    fontSize={11}
                    lineHeight="12px"
                    letterSpacing="0.22px"
                  />
                  <img src={"/account_icons/Circle.svg"} className="ml-1 mr-1" style={{ display: "inline" }} />
                  <KSpan
                    text={release?.type?.toUpperCase() || ""}
                    fontWeight={500}
                    color="#111"
                    fontSize={11}
                    lineHeight="12px"
                    letterSpacing="0.22px"
                  />
                </div>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                    marginTop: 4
                  }}
                >
                  <KTitleSpan text={release?.title || ""} fontWeight={500} fontSize={18} lineHeight="24px" />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ReleasePage
