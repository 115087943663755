import * as React from "react"
import { FC, useEffect, useRef, useState } from "react"
import { AdminAssetProps, TableAPI, UserTrackShareData, UserYoutubeVideoShareData } from "../../../../types"
import Button from "../../../ui/form/Button"
import Table from "../../../ui/table/Table"
import { connect } from "react-redux"
import {
  deleteTrackShareData,
  deleteYoutubeVideoShareData,
  fetchUserTrackShareData,
  fetchUserYoutubeVideoShareData,
  selectTrackShareData,
  selectYoutubeVideoShareData
} from "../../../../actions/adminActions"
import { RootState } from "../../../../store"
import AdminTrackForm from "./AdminTrackForm"
import { adminTableLimit, ASSET_TYPES } from "../../../../constants"
import adminYoutubeVideoColumns from "./columns/adminYoutubeVideoColumns"
import adminTrackColumns from "./columns/adminTrackColumns"
import AdminYoutubeVideoForm from "./AdminYoutubeVideoForm"
import AdminTrackShareHolderTable from "./AdminTrackShareHolderTable"
import AdminCollaboratorForm from "./AdminCollaboratorForm"

const AdminAsset: FC<AdminAssetProps> = (props) => {
  const {
    selectedUser,
    fetchUserTrackShareData,
    fetchUserYoutubeVideoShareData,
    paginatedUserTrackShareData,
    selectTrackShareData,
    deleteTrackShareData,
    selectedTrackShareData,
    paginatedUserYoutubeVideoShareData,
    selectYoutubeVideoShareData,
    deleteYoutubeVideoShareData,
    selectedYoutubeVideoShareData
  } = props

  const [assetType, setAssetType] = useState(ASSET_TYPES.TRACK)
  const [tableAPI, setTableAPI] = useState<TableAPI>(null)
  const [trackLoading, setTrackLoading] = useState(false)
  const [videoLoading, setVideoLoading] = useState(false)
  const [timeoutId, setTimeoutId] = useState(null)
  const deleting = useRef(false)
  const creating = useRef(false)

  useEffect(() => {
    onClickAdd()
    if (selectedUser?.id) {
      setTrackLoading(true)
      setVideoLoading(true)
      tableAPI?.setCurrentPage(1)
      fetchUserTrackShareData(selectedUser.id, adminTableLimit, 0, {}).then(() => setTrackLoading(false))
      fetchUserYoutubeVideoShareData(selectedUser.id, adminTableLimit, 0, {}).then(() => setVideoLoading(false))
    }
  }, [selectedUser])

  useEffect(() => {
    if (!tableAPI) return
    if (assetType === ASSET_TYPES.TRACK) {
      if (creating.current && paginatedUserTrackShareData.count / adminTableLimit > tableAPI.currentPage) {
        const targetPage = Math.ceil(paginatedUserTrackShareData.count / adminTableLimit)
        tableAPI.setCurrentPage(targetPage)
        onClickPage(targetPage, {}).then()
      }
      if (
        deleting.current &&
        paginatedUserTrackShareData.results.length < adminTableLimit &&
        paginatedUserTrackShareData.count >= tableAPI.currentPage * adminTableLimit
      ) {
        fetchUserTrackShareData(
          selectedUser.id,
          adminTableLimit,
          (tableAPI.currentPage - 1) * adminTableLimit,
          {}
        ).then(() => setTrackLoading(false))
      } else if (
        deleting.current &&
        paginatedUserTrackShareData.results.length === 0 &&
        paginatedUserTrackShareData.count > 0
      ) {
        const targetPage = tableAPI.currentPage - 1
        tableAPI.setCurrentPage(targetPage)
        onClickPage(targetPage, {}).then()
      }
    }
    deleting.current = false
    creating.current = false
  }, [paginatedUserTrackShareData.count])

  useEffect(() => {
    if (!tableAPI) return
    if (assetType === ASSET_TYPES.YOUTUBE_VIDEO) {
      if (creating.current && paginatedUserYoutubeVideoShareData.count / adminTableLimit > tableAPI.currentPage) {
        const targetPage = Math.ceil(paginatedUserYoutubeVideoShareData.count / adminTableLimit)
        tableAPI.setCurrentPage(targetPage)
        onClickPage(targetPage, {}).then()
      }
      if (
        deleting.current &&
        paginatedUserYoutubeVideoShareData.results.length < adminTableLimit &&
        paginatedUserYoutubeVideoShareData.count >= tableAPI.currentPage * adminTableLimit
      ) {
        fetchUserYoutubeVideoShareData(
          selectedUser.id,
          adminTableLimit,
          (tableAPI.currentPage - 1) * adminTableLimit,
          {}
        ).then(() => setVideoLoading(false))
      } else if (
        deleting.current &&
        paginatedUserYoutubeVideoShareData.results.length === 0 &&
        paginatedUserYoutubeVideoShareData.count > 0
      ) {
        const targetPage = tableAPI.currentPage - 1
        tableAPI.setCurrentPage(targetPage)
        onClickPage(targetPage, {}).then()
      }
    }
    deleting.current = false
    creating.current = false
  }, [paginatedUserYoutubeVideoShareData.count])

  const onClickPage = async (
    pageNumber: number,
    filters: { [key: string]: string | number | boolean },
    sortField?: string,
    sortDirection?: "asc" | "desc"
  ) => {
    if (assetType === ASSET_TYPES.TRACK) {
      setTrackLoading(true)
      fetchUserTrackShareData(
        selectedUser.id,
        adminTableLimit,
        (pageNumber - 1) * adminTableLimit,
        filters,
        sortField,
        sortDirection
      ).then(() => setTrackLoading(false))
    } else {
      setVideoLoading(true)
      fetchUserYoutubeVideoShareData(
        selectedUser.id,
        adminTableLimit,
        (pageNumber - 1) * adminTableLimit,
        filters,
        sortField,
        sortDirection
      ).then(() => setVideoLoading(false))
    }
  }

  const onClickAdd = () => {
    if (assetType === ASSET_TYPES.TRACK) {
      selectTrackShareData(new UserTrackShareData())
    } else {
      selectYoutubeVideoShareData(new UserYoutubeVideoShareData())
    }
  }

  const onClickDelete = () => {
    if (assetType === ASSET_TYPES.TRACK) {
      deleteTrackShareData(selectedTrackShareData.id)
    } else {
      deleteYoutubeVideoShareData(selectedYoutubeVideoShareData.id)
    }
  }

  const onSelectRow = (index: number) => {
    deleting.current = true
    if (assetType === ASSET_TYPES.TRACK) {
      selectTrackShareData(paginatedUserTrackShareData.results[index])
    } else {
      selectYoutubeVideoShareData(paginatedUserYoutubeVideoShareData.results[index])
    }
  }

  const renderButtons = () => (
    <div className="flex">
      <Button
        text="Delete"
        disabled={
          (assetType === ASSET_TYPES.TRACK && !selectedTrackShareData?.id) ||
          (assetType === ASSET_TYPES.YOUTUBE_VIDEO && !selectedYoutubeVideoShareData?.id)
        }
        onClick={onClickDelete}
        className="w-min font-medium !text-xs !py-1 leading-3 !mb-0 mr-2"
      />
      <Button
        text="Add"
        onClick={onClickAdd}
        disabled={!selectedUser?.id}
        className="w-min font-medium !text-xs !py-1 leading-3 !mb-0"
      />
    </div>
  )

  const renderTitleSwitch = () => {
    return (
      <div className="flex bg-blue-50 font-normal text-xs mt-[-10px] border rounded-md overflow-hidden shadow-sm">
        <div
          className={`py-1 px-3 border-r cursor-pointer hover:bg-blue-100 ${
            assetType === ASSET_TYPES.TRACK ? "!bg-blue-200 font-medium" : ""
          }`}
          onClick={() => setAssetType(ASSET_TYPES.TRACK)}
        >
          Tracks ({paginatedUserTrackShareData.count})
        </div>
        <div
          className={`py-1 px-3 cursor-pointer hover:bg-blue-100 ${
            assetType === ASSET_TYPES.YOUTUBE_VIDEO ? "!bg-blue-200 font-medium" : ""
          }`}
          onClick={() => setAssetType(ASSET_TYPES.YOUTUBE_VIDEO)}
        >
          Youtube Videos ({paginatedUserYoutubeVideoShareData.count})
        </div>
      </div>
    )
  }

  const onSortOrFilterChange = async (
    filters: { [key: string]: string | number | boolean },
    sortField: string,
    sortDirection?: "asc" | "desc",
    debounce?: boolean
  ) => {
    clearTimeout(timeoutId)
    setTimeoutId(
      setTimeout(
        async () => {
          if (assetType === ASSET_TYPES.TRACK) {
            setTrackLoading(true)
            await fetchUserTrackShareData(selectedUser.id, adminTableLimit, 0, filters, sortField, sortDirection)
            setTrackLoading(false)
          } else {
            setVideoLoading(true)
            await fetchUserYoutubeVideoShareData(selectedUser.id, adminTableLimit, 0, filters, sortField, sortDirection)
            setVideoLoading(false)
          }
        },
        debounce ? 500 : 0
      )
    )
  }

  return (
    <div>
      <div className="mt-2 mb-2 container flex gap-4 pl-1">
        <Table
          hidden={assetType !== ASSET_TYPES.TRACK}
          title={renderTitleSwitch()}
          size={"sm"}
          className="flex-grow"
          pagination
          limit={adminTableLimit}
          columns={adminTrackColumns}
          minRowCount={adminTableLimit}
          data={paginatedUserTrackShareData.results}
          count={paginatedUserTrackShareData.count}
          onClickPage={onClickPage}
          topRightElement={renderButtons()}
          onSelectRow={onSelectRow}
          setTableAPI={setTableAPI}
          loading={trackLoading}
          onSortOrFilterChange={onSortOrFilterChange}
        />
        <Table
          hidden={assetType !== ASSET_TYPES.YOUTUBE_VIDEO}
          title={renderTitleSwitch()}
          size={"sm"}
          className="flex-grow"
          pagination
          limit={adminTableLimit}
          columns={adminYoutubeVideoColumns}
          minRowCount={adminTableLimit}
          data={paginatedUserYoutubeVideoShareData.results}
          count={paginatedUserYoutubeVideoShareData.count}
          onClickPage={onClickPage}
          topRightElement={renderButtons()}
          onSelectRow={onSelectRow}
          setTableAPI={setTableAPI}
          loading={videoLoading}
          onSortOrFilterChange={onSortOrFilterChange}
        />

        {selectedUser?.id && (
          <div>
            <AdminTrackForm hidden={assetType !== ASSET_TYPES.TRACK} creatingRef={creating} />
            <AdminYoutubeVideoForm hidden={assetType !== ASSET_TYPES.YOUTUBE_VIDEO} creatingRef={creating} />
          </div>
        )}
      </div>
      {selectedTrackShareData?.trackDetail.id && (
        <div className="mt-2 mb-2 container flex gap-4 pl-1">
          <div>
            <AdminTrackShareHolderTable trackId={selectedTrackShareData?.trackDetail.id} />
          </div>
          <div>
            <AdminCollaboratorForm />
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedUser: state.admin.selectedUser,
    selectedTrackShareData: state.admin.selectedTrackShareData,
    selectedYoutubeVideoShareData: state.admin.selectedYoutubeVideoShareData,
    paginatedUserTrackShareData: state.admin.paginatedUserTrackShareData,
    paginatedUserYoutubeVideoShareData: state.admin.paginatedUserYoutubeVideoShareData
  }
}

export default connect(mapStateToProps, {
  fetchUserTrackShareData,
  fetchUserYoutubeVideoShareData,
  selectTrackShareData,
  deleteTrackShareData,
  selectYoutubeVideoShareData,
  deleteYoutubeVideoShareData
})(AdminAsset)
