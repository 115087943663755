import LoginLayout from "../LoginLayout"
import { connect } from "react-redux"
import { FormPageProps, ResetPasswordFormValues } from "../../../../types"
import ResetPasswordForm from "./ResetPasswordForm"
import { resetPassword } from "../../../../actions/loginActions"
import { useNavigate, useParams } from "react-router-dom"
//@ts-ignore
import EyeIcon from "../../../ui/img/auth/eye.svg"
//@ts-ignore
import EyeSlashIcon from "../../../ui/img/auth/eye-slash.svg"
//@ts-ignore
import PasswordIcon from "../../../ui/img/auth/password.svg"
import React, { useEffect, useState } from "react"
import { KButton, KInput, KTitleSpan } from "kahuna-base-react-components"

const ResetPassword = (props: FormPageProps<ResetPasswordFormValues>) => {
  const [form, setForm] = useState<ResetPasswordFormValues>({ email: "" })
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordAgain, setShowPasswordAgain] = useState(false)

  const navigate = useNavigate()
  const params = useParams()
  const onSubmit = () => {
    props.onSubmit(form, () => navigate("/login"))
  }

  useEffect(() => {
    setForm({ ...form, email: params.email })
  }, [])

  return (
    <LoginLayout>
      <div className={"grid align-middle justify-center items-center"} style={{ width: "100%", height: "90%" }}>
        <div className={"grid"} style={{ width: 500 }}>
          <div className={"flex"}>
            <KTitleSpan text={"Reset Password"} />
          </div>
          <div className={"mt-8"}>
            <KInput
              type="number"
              value={form.code || ""}
              onChange={(text) => setForm({ ...form, code: text })}
              placeholder={"Enter code sent to your email"}
            />
          </div>
          <div className={"mt-4"}>
            <KInput
              value={form.password || ""}
              onChange={(text) => setForm({ ...form, password: text })}
              type={showPassword ? "text" : "password"}
              placeholder={"Password"}
              leftIcon={PasswordIcon}
              rightIconClick={() => setShowPassword(!showPassword)}
              rightIcon={showPassword ? EyeIcon : EyeSlashIcon}
            />
          </div>
          <div className={"mt-4"}>
            <KInput
              value={form.repeatedPassword || ""}
              onChange={(text) => setForm({ ...form, repeatedPassword: text })}
              type={showPasswordAgain ? "text" : "password"}
              placeholder={"Repeat Password"}
              leftIcon={PasswordIcon}
              rightIconClick={() => setShowPasswordAgain(!showPasswordAgain)}
              rightIcon={showPasswordAgain ? EyeIcon : EyeSlashIcon}
            />
          </div>
          <div className={"mt-4"}>
            <KButton onClick={() => onSubmit()} text={"Reset password"} background="#111" textColor="white" />
            <div className="mt-2">
              <KButton
                shadowDisabled={true}
                background="transparent"
                onClick={() => navigate(`/login?username=${form.email}`)}
                text={"Return to login"}
              />
            </div>
          </div>
        </div>
      </div>
    </LoginLayout>
  )
}

export default connect(null, { onSubmit: resetPassword })(ResetPassword)
