import * as React from "react"
import { FC, useEffect, useState } from "react"
import { ProfileProps, User } from "../../../../types"
import { saveUserData, saveUserProfilePhoto } from "../../../../actions"
import { connect } from "react-redux"
import { RootState } from "../../../../store"
import { KInput, KSpan, KTextArea } from "kahuna-base-react-components"
import UploadImage from "./UploadImage"
import { toast } from "react-toastify"
import ChangePasswordButton from "../security/ChangePasswordButton"
import { errorToastOptions, loadingToastOptions } from "../../../../constants"

const Profile: FC<ProfileProps> = (props) => {
  const [initialUser, setInitialUser] = useState<User | null>(props.user)
  const [user, setUser] = useState<User | null>(props.user)
  const [loadingPhoto, setLoadingPhoto] = useState(false)

  const onFormSubmit = async (values: User) => {
    if (values.firstName === "") {
      toast.error("The first name field cannot be left blank. ")
      setUser((prevUser: any) => {
        return { ...prevUser, firstName: props.user.firstName }
      })
    } else if (values.lastName === "") {
      toast.error("The last name field cannot be left blank.")
      setUser((prevUser: any) => {
        return { ...prevUser, lastName: props.user.lastName }
      })
    } else {
      if (
        values.firstName !== initialUser?.firstName ||
        values.lastName !== initialUser?.lastName ||
        values.bio !== initialUser?.bio
      ) {
        setInitialUser((prevUser: any) => {
          return { ...prevUser, firstName: values.firstName, lastName: values.lastName, bio: values.bio }
        })

        props.saveUserData(values)
      }
    }
  }

  const handleFirstNameChange = (newValue: string) => {
    setUser((prevUser: any) => {
      return { ...prevUser, firstName: newValue }
    })
  }
  const handleLastNameChange = (newValue: string) => {
    setUser((prevUser: any) => {
      return { ...prevUser, lastName: newValue }
    })
  }

  const handleBioChange = (newValue: string) => {
    setUser((prevUser: any) => {
      return { ...prevUser, bio: newValue }
    })
  }

  useEffect(() => {
    setUser(props.user)
  }, [props.user])

  const onUploadImage = (photo: FormData) => {
    setLoadingPhoto(true)
    toast.success("Uploading photo...", loadingToastOptions)
    props.saveUserProfilePhoto(photo).then(() => {
      setLoadingPhoto(false)
    })
  }

  return (
    <>
      <div className="flex flex-col gap-6 ">
        <div>
          <UploadImage
            loading={loadingPhoto}
            sectionTitle="Upload Image"
            sectionDetail="Min 400x400px, PNG or JPEG"
            onClick={onUploadImage}
            buttonTitle="Upload"
            iconPath={user?.photo || "/account_icons/avatar.svg"}
          />
        </div>
        <div className="w-full flex flex-row gap-[16px]">
          <div className="w-[calc(50%)]">
            <KInput
              value={user!.firstName}
              placeholder="write your first name..."
              onChange={handleFirstNameChange}
              onBlur={() => {
                onFormSubmit(user!)
              }}
              padding="14px"
              border="1px solid #F3F3F3"
              boxShadow=" 0px 1px 2px 0px rgba(228, 229, 231, 0.24)"
            />
          </div>
          <div className="w-[calc(50%)]">
            <KInput
              value={user!.lastName}
              placeholder="write your last name..."
              onChange={handleLastNameChange}
              onBlur={() => {
                onFormSubmit(user!)
              }}
              padding="14px"
              border="1px solid #F3F3F3"
              boxShadow=" 0px 1px 2px 0px rgba(228, 229, 231, 0.24)"
            />
          </div>
        </div>
        <div className="pb-6 border-dashed border-b-[1px]" />
        <div className="flex justify-between" style={{ paddingRight: 1 }}>
          <div
            className="w-[calc(49%)] flex items-center"
            style={{
              borderRadius: 10,
              background: "var(--bg-weak-100, #F0F0F0)",
              padding: 14
            }}
          >
            <KSpan text={user?.email || " - "} />
          </div>
          <ChangePasswordButton />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return { user: state.auth.user }
}

export default connect(mapStateToProps, { saveUserData, saveUserProfilePhoto })(Profile)
